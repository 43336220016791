import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { Link } from "react-router-dom";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "common/components/MD/MDBox";
import MDButton from "common/components/MD/MDButton";


// Material Dashboard 2 React example components
import DashboardLayout from "layouts/DashboardLayout";
import DashboardNavbar from "layouts/components/Navbars/DashboardNavbar";
import DataTable from "common/components/implemented/Tables/DataTable/DataTables"
import Footer from "layouts/components/Footer";
import HeaderForm from "layouts/components/HeaderForm";
import Alert from '@mui/material/Alert';

// Data
import LocDataService from "services/API/Comuni/ComuniService";
import AllegatoEditModal from "./AllegatoEditModal";
import ComunicazioniService from "services/API/Comunicazioni/ComunicazioniService";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import { Dialog, DialogActions, DialogContentText, DialogTitle } from "@mui/material";
import { DialogContent } from "@mui/material";

function ElencoDocumenti({ onSave, onClose, show}) {

 
  const [documenti, setDocumenti] = useState([]);
 
  useEffect(() => {
    console.log("LOAD");
    retrieveDoc();

  }, []);

   const retrieveDoc = () => {
    ComunicazioniService.getAllAllegati()
      .then(response => {
        setDocumenti(response.data);
      })
      .catch(e => {
        console.log(e);
      });
  };

  const docAttributes = []
  documenti.forEach(doc => {

    docAttributes.push({
      idAllegato: doc.id_allegato,
      nome_allegato: doc.nome_allegato,
      descrizione: doc.descrizione,

      action: (
        <MDBox>

            <MDButton onClick={() => onSave(doc)} variant="outlined" color={"success"} iconOnly={true} >
            <CheckCircleIcon></CheckCircleIcon>

          </MDButton>
         
        </MDBox>
      ),

    })
  });


  const DocTableData = {
    columns: [
      { Header: "Nome Allegato", accessor: "nome_allegato", width: "30%", align: "left" },
      { Header: "Descrizione", accessor: "descrizione", align: "left" },

      { Header: "action", accessor: "action", align: "center", enableGlobalFilter: false },
    ],

    rows: docAttributes,


  };

  const mypagination = { color: 'success', variant: "contained" };


  ///////////////////////////////////////////////////////////////////////////
 
  return (
    <Dialog open={show} onClose={onClose}>
        <DialogTitle>Scegli Allegato</DialogTitle>
            <DialogContent>
                <DialogContentText>
                  Scegli allegato selezionando tra i file del server che hai già caricato.
                </DialogContentText>
              <MDBox pt={3}>
                <DataTable canSearch={true}
                  table={DocTableData}
                  isSorted={false}
                  entriesPerPage={true}
                  showTotalEntries={true}
                  pagination={mypagination}
                  noEndBorder
                />
              </MDBox>
              </DialogContent>

      </Dialog>



  );
}

export default ElencoDocumenti;
