import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { Link } from "react-router-dom";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "common/components/MD/MDBox";
import MDButton from "common/components/MD/MDButton";


// Material Dashboard 2 React example components

// Data

import HeaderToolbar from "layouts/components/HeaderToolbar";
import MessaggiTable from "./MessaggiTable";

function SottoElencoMessaggi({messaggi=[],id_proposta=0,prop=[],add="",chiamante="",exit=""}) {



  const navigate = useNavigate();
  const annulla = () => {
    console.log("ANNULLA");
 

    navigate("/"+chiamante, { state: { modo: 'vis', id_proposta:{id_proposta}, subject: '' } });

}



  return (
   
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>

          <Grid item xs={12}>
            <Card>
              <HeaderToolbar titolo="Messaggi " add={add} chiamante={chiamante} modo="vis" exit={annulla} id_proposta={id_proposta} prop={prop}/>
              <MessaggiTable  messaggi={messaggi}></MessaggiTable>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
  
  );
}

export default SottoElencoMessaggi;
