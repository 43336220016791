import React from 'react';
//prop-types is a library for typechecking of props
import PropTypes from "prop-types";
import MDBox from "common/components/MD/MDBox";
import MDInput from "common/components/MD/MDInput";
import { MenuItem, Select } from '@mui/material';
export const SelectColumnFilterTipo= ({column})=>{

  const{filterValue,setFilter}=column

    return (

        <MDBox ml="auto">

    <Select  name="tipo_stand"  id="tipo_stand" 
          onChange={e => {
            console.log(e.target.value);
           if( e.target.value != 'ALL'){
            setFilter(e.target.value);}
           else {
           setFilter('');}
           }}
          value={filterValue || 'ALL'}
        >
          <MenuItem key="ALL" value="ALL">TUTTI</MenuItem>
          <MenuItem key="Stand al coperto" value="Stand al coperto">Stand al coperto</MenuItem>
          <MenuItem key="Area al coperto" value="Area al coperto">Area al coperto</MenuItem>
          <MenuItem key="Area all'aperto" value="Area all'aperto">Area all'aperto</MenuItem>
         
          
        </Select>


      </MDBox>
    )

}

// Setting default values for the props of DataTable
SelectColumnFilterTipo.defaultProps = {
    filter:PropTypes.string,
    setFilter: PropTypes.func,
  };
  
  // Typechecking props for the DataTable
  SelectColumnFilterTipo.propTypes = {
    filter:'',
    setFilter: null,
  };