import MDTypography from "common/components/MD/MDTypography";
import Grid from "@mui/material/Grid";
import currencyFormat from "common/script/currencyFormat"


function Opzione03() {
    return (
        <Grid container spacing={2} mb={2} columns={13} sx={{
            fontSize: 12,
            boxShadow: 2,
            border: 2,
            borderColor: 'info.light',
            '& .MuiDataGrid-cell:hover': {
                color: '#e91e63',
            },
        }}>
            <Grid item sm={13} md={13} lg={13} >
                <MDTypography variant="button" fontWeight="bold" sx={{ color: 'info.light' }}>TIPO DI STAND: STAND AL COPERTO</MDTypography>
            </Grid>


            <Grid item sm={13} md={13} lg={13} >
                <MDTypography variant="button" fontWeight="bold" sx={{ color: 'info.light' }}>SETTORI MERCEOLOGICI AMMESSI: 01 - 02 - PRODOTTI AGROALIMENTARI VENDITA E SOMMINISTRAZIONE</MDTypography>

            </Grid>

            <Grid item sm={13} md={13} lg={13} >
                <MDTypography variant="caption" fontWeight="regular" >
                    Le tariffe comprendono: area espositiva, pannelli divisori, estintore, servizio antincendio, energia elettrica pari a 3kw/220 V ogni 16 mq, allaccio acqua. inserimento a catalogo, inviti, pass espositori, pass auto.  </MDTypography>

            </Grid>


            <Grid item sm={2} md={2} lg={2} >
                <MDTypography variant="overline" fontWeight="medium" >
                    TIPO AREA</MDTypography>
            </Grid>


            <Grid item xs={3} md={3} >
                <MDTypography variant="overline" fontWeight="medium" sx={{ textAlign: 'left' }} >
                    SETTORI AMMESSI</MDTypography>
            </Grid>

            <Grid item xs={4} md={4} >
                <MDTypography variant="overline" fontWeight="medium" >
                    TIPO DI STAND</MDTypography>
            </Grid>

            <Grid item xs={2} md={2} >
                <MDTypography variant="overline" fontWeight="medium" >
                    PREZZO UNITARIO</MDTypography>
            </Grid>
            <Grid item xs={2} md={2} >
                <MDTypography variant="overline" fontWeight="medium" >
                    TOT</MDTypography>
            </Grid>
            {/*---------------------------------------------------------------------------------------------*/}
            <Grid item xs={2} md={2} >

                <MDTypography variant="overline" fontWeight="regular"
                    sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}>

                    STAND AL COPERTO
                </MDTypography>
            </Grid>

            <Grid item xs={3} md={3} >
                <MDTypography variant="overline" fontWeight="regular" >
                    02 - SOMMINISTRAZIONE PRODOTTI AGROALIMENTARI</MDTypography>
            </Grid>

            <Grid item xs={4} md={4} >
                <Grid container spacing={2} >
                    <Grid item xs={4} md={4} >
                        <MDTypography variant="overline" fontWeight="regular" >
                            1 LATO APERTO</MDTypography></Grid>
                    <Grid item xs={8} md={8} >
                        <MDTypography variant="overline" fontWeight="regular" >
                            16 mq (mt 4x4) - Nuova Area Nord</MDTypography></Grid>
                </Grid>
            </Grid>

            <Grid item xs={2} md={2} >
                <MDTypography variant="overline" fontWeight="regular" >
                    € 2.400,00

                </MDTypography>
            </Grid>
            <Grid item xs={2} md={2} >
                <MDTypography variant="button" fontWeight="overline" > € 2.400,00
                </MDTypography>
            </Grid>

            {/*---------------------------------------------------------------------------------------------*/}



        </Grid>





    );

}

  
  export default Opzione03;
  