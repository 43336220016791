
import { useEffect, useState } from "react";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import MDBox from "common/components/MD/MDBox";
import MDInput from "common/components/MD/MDInput";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from 'yup';
import ComunicazioniService from "services/API/Comunicazioni/ComunicazioniService";
import { InsertEmoticonRounded } from "@mui/icons-material";
import MDButton from "common/components/MD/MDButton";
import MDTypography from "common/components/MD/MDTypography";

import base64ToArrayBuffer from "common/script/base64ToArrayBuffer";









function AllegatoEditModal({ onSave, onClose, show, soggetto}) {


    useEffect(() => {
        if (soggetto != null) {
            console.log("soggetto non nullo: id=" + soggetto.idAllegato);
            setValue("nome_allegato",soggetto.nome_allegato);
            setValue("descrizione",soggetto.descrizione);
            setValue("nome_file",soggetto.nome_file);
        } else {
            console.log("soggetto nullo");
        }
    }, [soggetto]);


    const validationSchema = Yup.object().shape({
        /*   nome_manifestazione: Yup.string().required('Nome richiesto').max(100, 'Nome non deve superare 100 caratteri'),
           
           pdf_regolamento: Yup.mixed().when("$isPresent", {
               is: (val) => {
                   return modo === 'new';
               },
               then: (s) => s.required('VALIDATION_FIELD_REQUIRED')
                               .test("fileSize", "The file is too large", (value) => {
                                   return value && value[0].size <= 2000000;
                               })
                               .test("type", "Only the following formats are accepted: .pdf ", (value) => {
                                   return value && (
                                       value[0].type === 'application/pdf'
                                   );
                               }),
               otherwise: (s) => s,
           }),
         
       /*  
           pdf_regolamento: Yup.mixed()
               .required('VALIDATION_FIELD_REQUIRED')
               .test("fileSize", "The file is too large", (value) => {
                   return value && value[0].size <= 2000000;
               })
               .test("type", "Only the following formats are accepted: .pdf ", (value) => {
                   return value && (
                       value[0].type === 'application/pdf'
                   );
               }),*/


        /*   pdf_non_accettazione: Yup.mixed().nullable()
               .test("fileSize", "The file is too large", (value) => {
                  return value==null || value[0].size <= 2000000;
               })
               .test("type", "Only the following formats are accepted: .pdf ", (value) => {
                   return (
                       value==null || value[0].type === 'application/pdf'      );
               }),*/
    });
    const {
        register, //register inputs
        getValues, reset, setValue, control,
        handleSubmit,//handle form submit
        formState: { errors }
    } = useForm({
        resolver: yupResolver(validationSchema),
    });



    //sono quelli lato server
    const [error, setError] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);


    const carica = (formData) => {
        setError(null);

        var data = {
            idAllegato: 0,
            descrizione: formData.descrizione,
            file_allegato: formData.allegato,
            nome_allegato: formData.nome_allegato,
            nome_file:formData.nome_file,

        };
        if (data.allegato === '') data.allegato = null;

if(soggetto!=null && soggetto.idAllegato != 0){
    console.log("Modifica "+soggetto.idAllegato);
    ComunicazioniService.updateAllegato(soggetto.idAllegato,data)
        .then(response => {
            handleResponse(response, 'UPD');
        })
        .catch(errore => {
            handleError(errore);
        });

}else{
        console.log("Inserisci ");
        ComunicazioniService.uploadAllegato(data)
            .then(response => {
                handleResponse(response, 'INS');
            })
            .catch(errore => {
                handleError(errore);
            });
        }
    };

    function handleResponse(response, op) {
        console.log(response.data);
        //lo aggiungo alla lista
        var inserted = { idAllegato: response.data.id_allegato, nome_allegato: response.data.nome_allegato, descrizione: response.data.descrizione };
        onSave(inserted,op);
    }

    function handleError(error) {
        setError(error);
        console.log("ERRORE" + error);
        console.log(error.code);

        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log("ERROR:RESPONSE" + error.response.data);
            setErrorMessage(error.response.data);

        } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.log("ERROR:REQUEST" + error.request);
            setErrorMessage(error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message);
            setErrorMessage(error.message);
        }

    }

    async function download_pdf_allegato() {

        ComunicazioniService.download_allegato(soggetto.idAllegato)
            .then(response => {
    
                return response.data;
            })
            .then(blob => {
    
                var arrrayBuffer = base64ToArrayBuffer(blob); //data is the base64 encoded string
                var blobb = new Blob([arrrayBuffer], { type: "application/pdf" });
                var url = URL.createObjectURL(blobb);
                var a = document.createElement('a');
                a.href = url;
                a.target = "_blank";
               
                    a.download =soggetto.nome_allegato;
             ;
            
                document.body.appendChild(a); // append the element to the dom
                a.click();
                a.remove(); // afterwards, remove the element  
            })
            .catch(error => {
                console.error(error);
            });
    
    
    
    
    }
    
    




    return (


        <Dialog open={show} onClose={onClose}
            PaperProps={{
                component: 'form',

            }}  >

            <DialogTitle>Allegato</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Aggiungi allegato selezionando il file dal tuo pc.
                </DialogContentText>
                <MDBox m={2}>
                    <MDInput type="text" label="Nome Allegato" fullWidth name="nome_allegato"
                        {...register('nome_allegato')}
                        error={errors.nome_allegato ? true : false} InputLabelProps={{ shrink: true }} />
                </MDBox>
                <MDBox m={2}> <MDInput type="text" label="Descrizione" fullWidth name="descrizione"
                    multiline rows={2} {...register('descrizione')}
                    error={errors.descrizione ? true : false} InputLabelProps={{ shrink: true }} />
                </MDBox>
                <MDBox m={2}>
                    <MDBox>

                    
                    <MDInput
                        type="file"
                        label="Allegato" fullWidth id="allegato"
                        autocomplete="off"  {...register('allegato')}
                        name="allegato" InputLabelProps={{ shrink: true }}
                    />
                    {
                        errors.allegato ? <span style={{ color: 'red', fontSize: '12px' }}>{errors.allegato?.message}</span> : ''
                    }
                    
                </MDBox>

</MDBox>

<MDBox m={2}>
                    <MDBox>

                    
                    <MDInput
                        type="text"
                        label="Nome File" fullWidth id="nome_file"
                        autocomplete="off"  {...register('nome_file')}
                        name="nome_file" InputLabelProps={{ shrink: true }}
                    />
                    {
                        errors.nome_file ? <span style={{ color: 'red', fontSize: '12px' }}>{errors.nome_file?.message}</span> : ''
                    }
                    
                </MDBox>
                {soggetto &&
                <MDTypography
    variant="button"
    fontWeight="regular"
    color="text"
    sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
    onClick={download_pdf_allegato}
>
    {soggetto.nome_file}
</MDTypography>}
</MDBox>

                <MDBox ml={2}>


                </MDBox>
            </DialogContent>
            <DialogActions>
                <MDButton onClick={onClose}>Cancel</MDButton>
                <MDButton type="submit" onClick={handleSubmit(carica)}>Upload</MDButton>
            </DialogActions>
        </Dialog>


    );




}
export default AllegatoEditModal;