import MDTypography from "common/components/MD/MDTypography";
import Grid from "@mui/material/Grid";
import currencyFormat from "common/script/currencyFormat"


function Opzione06() {
    return (
        <Grid container spacing={2} mb={2} columns={13} sx={{
            fontSize: 12,
            boxShadow: 2,
            border: 2,
            borderColor: '#e91e63',
            '& .MuiDataGrid-cell:hover': {
                color: '#e91e63',
            },
        }}>
            <Grid item sm={13} md={13} lg={13} >
                <MDTypography variant="button" fontWeight="bold" sx={{ color: '#e91e63' }}>TIPO DI STAND: STAND AL COPERTO</MDTypography>

            </Grid>


            <Grid item sm={13} md={13} lg={13} >
                <MDTypography variant="button" fontWeight="bold" sx={{ color: '#e91e63' }}>SETTORI MERCEOLOGICI AMMESSI: 03-04-05-06-07-08-09-10</MDTypography>

            </Grid>

            <Grid item sm={13} md={13} lg={13} >
                <MDTypography variant="caption" fontWeight="regular" >
                    Le tariffe comprendono: area espositiva, pannelli divisori, estintore, servizio antincendio, energia elettrica pari a 3kw/220 V ogni 16 mq, inserimento a catalogo, inviti, pass espositori, pass auto.
                </MDTypography>

            </Grid>


            <Grid item sm={2} md={2} lg={2} >
                <MDTypography variant="overline" fontWeight="medium" >
                    TIPO AREA</MDTypography>
            </Grid>


            <Grid item xs={3} md={3} >
                <MDTypography variant="overline" fontWeight="medium" sx={{ textAlign: 'left' }} >
                    SETTORI AMMESSI</MDTypography>
            </Grid>

            <Grid item xs={3} md={3} >
                <MDTypography variant="overline" fontWeight="medium" >
                    TIPO DI STAND</MDTypography>
            </Grid>
            <Grid item xs={1} md={1} >
                <MDTypography variant="overline" fontWeight="medium" >
                    OLTRE MQ</MDTypography>
            </Grid>
            <Grid item xs={2} md={2} >
                <MDTypography variant="overline" fontWeight="medium" >
                    PREZZO UNITARIO</MDTypography>
            </Grid>
            <Grid item xs={2} md={2} >
                <MDTypography variant="overline" fontWeight="medium" >
                    TOT</MDTypography>
            </Grid>
            {/*---------------------------------------------------------------------------------------------*/}
            <Grid item xs={2} md={2} >
                <MDTypography variant="overline" fontWeight="regular"
                    sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}>
                    STAND AL COPERTO
                </MDTypography>
            </Grid>

            <Grid item xs={3} md={3} >
                <MDTypography variant="overline" fontWeight="regular" >
                    03 - 10 ALTRI SETTORI MERCEOLOGICI</MDTypography>
            </Grid>

            <Grid item xs={3} md={3} >
                <Grid container spacing={2} >
                    <Grid item xs={6} md={6} >
                        <MDTypography variant="overline" fontWeight="regular" >
                            1 LATO APERTO</MDTypography></Grid>
                    <Grid item xs={6} md={6} >
                        <MDTypography variant="overline" fontWeight="regular" >
                            24 mq (mt 4x6)</MDTypography></Grid>
                </Grid>
            </Grid>
            <Grid item xs={1} md={1} >

            </Grid>
            <Grid item xs={2} md={2} >
                <MDTypography variant="overline" fontWeight="regular" >
                    € 2.300,00
                </MDTypography>
            </Grid>
            <Grid item xs={2} md={2} >
                <MDTypography variant="button" fontWeight="overline" >2.300,00 €</MDTypography>
            </Grid>
        </Grid>

    );

}

  
  export default Opzione06;
  