import { useState } from "react";

import MDBox from "common/components/MD/MDBox";
import Opzione01 from "pages/riservata/expo_richieste/Opzione01";
import Opzione02 from "pages/riservata/expo_richieste/Opzione02";
import Opzione03 from "pages/riservata/expo_richieste/Opzione03";
import Opzione04 from "pages/riservata/expo_richieste/Opzione04";
import Opzione05 from "pages/riservata/expo_richieste/Opzione05";
import Opzione06 from "pages/riservata/expo_richieste/Opzione06";
import Opzione07 from "pages/riservata/expo_richieste/Opzione07";
import Opzione08 from "pages/riservata/expo_richieste/Opzione08";
import Opzione09 from "pages/riservata/expo_richieste/Opzione09";
import Opzione10 from "pages/riservata/expo_richieste/Opzione10";
import Opzione11 from "pages/riservata/expo_richieste/Opzione11";
import Opzione12 from "pages/riservata/expo_richieste/Opzione12";
import Opzione13 from "pages/riservata/expo_richieste/Opzione13";
import MDTypography from "common/components/MD/MDTypography";
import currencyFormat from "common/script/currencyFormat";

function ExpoRichiesteTipologiaVis({ prop,tot_10,tot_11,tot_12,tot_13 }) {

    

return(
    <MDBox>   
    <MDBox>
    <MDBox mb={2} mt={4} >

        {prop.tipologia_espositiva_scelta === '01' &&

            <Opzione01 />
        }

        {prop.tipologia_espositiva_scelta === '02' &&
            <Opzione02 />
        }
        {prop.tipologia_espositiva_scelta === '03' &&

            <Opzione03 />
        }
        {prop.tipologia_espositiva_scelta === '04' &&
            <Opzione04 />

        }
        {prop.tipologia_espositiva_scelta === '05' &&

            <Opzione05 />

        }
        {prop.tipologia_espositiva_scelta === '06' &&
            <Opzione06 />
        }
        {prop.tipologia_espositiva_scelta === '07' &&

            <Opzione07 />
        }
        {prop.tipologia_espositiva_scelta === '08' &&

            <Opzione08 />

        }
        {prop.tipologia_espositiva_scelta === '09' &&
            <Opzione09 />
        }
        {prop.tipologia_espositiva_scelta === '10' &&

            <Opzione10 q_10={prop.q_10} tot_10={tot_10}></Opzione10>

        }
        {prop.tipologia_espositiva_scelta === '11' &&
            <Opzione11 q_11={prop.q_11} tot_11={tot_11}></Opzione11>


        }
        {prop.tipologia_espositiva_scelta === '12' &&

            <Opzione12 q_12={prop.q_12} tot_12={tot_12}></Opzione12>
        }
        {prop.tipologia_espositiva_scelta === '13' &&

            <Opzione13 q_13={prop.q_13} tot_13={tot_13}></Opzione13>

        }


    </MDBox>
</MDBox>
<MDBox mb={2} sx={{ display: 'grid', gap: 1, gridTemplateColumns: ' 1fr 1fr 1fr 1fr' }}>

    <MDTypography variant="body2" fontWeight="regular" >IMPONIBILE : {currencyFormat(prop.imponibile)} €</MDTypography>
    <MDTypography variant="body2" fontWeight="regular" >IVA : {currencyFormat(prop.iva)} €</MDTypography>
    <MDTypography variant="body2" fontWeight="regular" >TOTALE : {currencyFormat(prop.totale)} €</MDTypography>
    <MDTypography variant="body2" fontWeight="regular" >ANTICIPO VERSATO :{currencyFormat(prop.anticipo_versato)} €</MDTypography>
</MDBox>
</MDBox>
);

}
export default ExpoRichiesteTipologiaVis;