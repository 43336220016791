import axios from 'axios';
import authHeader from 'services/API/Auth/AuthHeader';
import AuthService from 'services/API/Auth/AuthService.js';
import authHeaderMultipart from '../Auth/AuthHeaderMultipart';

const seleziona_partecipazione =(man)=>{
 
    console.log("manifestazione "+ man+" id_utente="+AuthService.getCurrentUser().id);
   
    return axios.get(axios.defaults.baseURL + `/expo_partecipazioni/seleziona/${AuthService.getCurrentUser().id}/${man}`,{headers: authHeader()});
  
  
}
const genera_pdf_contratto =(id)=>{

    console.log("genera pdf contratto= "+id);
const response= axios.get(axios.defaults.baseURL + `/expo_partecipazioni/contratto/${id}`,{headers: authHeader()}, {responseType: 'blob'},);

return response;
}

const invia_contratto = (id, data) => {
    console.log("invia contratto= "+id);
    return axios.post(axios.defaults.baseURL + `/expo_partecipazioni/carica_contratto/${id}`, data,{headers: authHeaderMultipart()});
  };


  const download_pdf_contratto= (idPartecipazione) => {

    
    const response = axios.get(axios.defaults.baseURL + `/expo_partecipazioni/download_contratto/${idPartecipazione}`, { headers: authHeader() }, { responseType: 'blob' },);
    console.log(response);
    return response;
}
const ExpoPartecipazioniService = {
   
    seleziona_partecipazione,genera_pdf_contratto,invia_contratto,download_pdf_contratto
}

export default ExpoPartecipazioniService;