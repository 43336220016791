import React, { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import { Link } from "react-router-dom";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "common/components/MD/MDBox";
import MDButton from "common/components/MD/MDButton";

import MDTypography from "common/components/MD/MDTypography";


// Material Dashboard 2 React example components
import DashboardLayout from "layouts/DashboardLayout";
import DashboardNavbar from "layouts/components/Navbars/DashboardNavbar";
import Footer from "layouts/components/Footer";
import HeaderForm from "layouts/components/HeaderForm";
import DataTable from "common/components/implemented/Tables/DataTable/DataTables"

import Alert from '@mui/material/Alert';
//prop-types is a library for typechecking of props
import PropTypes from "prop-types";
// Data
import ExpoRichiesteService from "services/API/ExpoRichieste/ExpoRichiesteService";
import AuthService from 'services/API/Auth/AuthService.js';
import MDAlert from "common/components/MD/MDAlert/MDAlert";


function ElencoRichieste() {

  const [proposte, setProposte] = useState([]);

  const isExpo = AuthService.isExpo();
  const isAdmin = AuthService.isAdmin();

  useEffect(() => {

    if (isExpo) {
      ExpoRichiesteService.getMyRichieste()
        .then(response => {
          setProposte(response.data);
          // console.log(response.data);

        })
        .catch(e => {
          console.log(e);
        });
    } else {
      if (isAdmin) {

        ExpoRichiesteService.getAllRichieste()
          .then(response => {
            // console.log(response.data);
            setProposte(response.data);
          })
          .catch(e => {
            console.log(e);
          });
      } else {

      }
    }

  }, []);



  //per passarmi la variabiule se ho salvato e far comparire l'alert
  const location = useLocation();
  //console.log(location);

  const { state } = location != null ? location : { state: { submitted: false, salvato: 'NO' } }; // Read values passed on state

  const ppAttributes = [];

  if (proposte.length > 0) {
    proposte.forEach(prop => {

      let action = (
        <MDBox><MDButton variant="outlined" color={"success"} iconOnly={true} disabled={true}>
          <Icon>edit</Icon>
        </MDButton>
          <Link to="/expo_richiestaPDFVis" state={{ modo: 'vis', soggetto: prop, subject: prop.manifestazione }}
          >
            <MDButton variant="outlined" color={"success"} iconOnly={true} >
              <Icon>visibility</Icon>
            </MDButton>
          </Link>
        </MDBox>
      );
      if (prop.stato === "IN COMPILAZIONE") {
        action = (

          <MDBox><Link to="/expo_richiestaEdit1" state={{ modo: 'edit', soggetto: prop, subject: prop.manifestazione }}
          >

            <MDButton variant="outlined" color={"success"} iconOnly={true} disabled={prop.stato === "INVIATA"}>
              <Icon>edit</Icon>
            </MDButton>
          </Link>
            <MDButton variant="outlined" color={"success"} iconOnly={true} disabled={true} >
              <Icon>visibility</Icon>
            </MDButton>
          </MDBox>
        );


      }




      ppAttributes.push({
        manifestazione: prop.manifestazione,
        responsabile: prop.responsabile_fiera,
        data_inserimento: prop.string_data_ins,
        data_modifica: prop.string_data_agg,
        stato: prop.stato,
        action: action,

      })
    });
  }
  const ppTableData = {
    columns: [
      { Header: "Manifestazione", accessor: "manifestazione", width: "15%", align: "left" },
      { Header: "Responsabile", accessor: "responsabile", width: "15%", align: "left" },
      { Header: "Data Inserimento", accessor: "data_inserimento", align: "left" },
      { Header: "Data Ultimo Aggiornamento", accessor: "data_modifica", align: "left" },
      { Header: "Stato", accessor: "stato", align: "left" },
      { Header: "Azioni", accessor: "action", align: "center", width: "5%", enableGlobalFilter: false },

    ],

    rows: ppAttributes,


  };

  const mypagination = { color: 'success', variant: "contained" };


  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>

          <Grid item xs={12}>
            <Card>
              <HeaderForm titolo={isExpo ? "Le mie Proposte di Partecipazione" :
                "Proposte di Partecipazione"} add="/expo_richiesteStart" modo="vis" />
              <MDBox pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center">

                {(state != null && state.submitted && (state.salvato === 'INS' || state.salvato === 'MOD')) &&

                  <Alert severity="success">Dati Proposta salvati con successo!</Alert>}
                {(state != null && state.submitted && (state.salvato === 'SENT')) &&

                  <Alert severity="success">Proposta di partecipazione inviata con successo!</Alert>}
                {(state != null && state.submitted && state.salvato === 'DEL') &&

                  <Alert severity="success">Proposta eliminata con successo!</Alert>}
              </MDBox>

              <MDBox>
                <Alert severity="warning">Attenzione! Puoi creare quante richieste vuoi, ma puoi inviare una sola proposta di partecipazione per ogni manifestazione!</Alert>
              </MDBox>


              {(proposte != null && proposte.length > 0) &&
                <MDBox pt={3}>
                  <DataTable canSearch={true}
                    table={ppTableData}
                    isSorted={false}
                    entriesPerPage={true}
                    showTotalEntries={true}
                    pagination={mypagination}
                    noEndBorder
                  />      </MDBox>}
              {(proposte == null || proposte.length === 0) &&
                <MDBox style={{ height: "200px" }} textAlign='center'>
                  <MDTypography
                    variant="button"
                    fontWeight="regular"
                    color="text"
                    sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                  >
                    Non ci sono Proposte di partecipazione in archivio. Clicca su Nuovo per crearne una!
                  </MDTypography></MDBox>
              }



            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}



export default ElencoRichieste;
