
import { useEffect, useState } from "react";
import Menu from "@mui/material/Menu";
import Notifica from "./Notifica";
import { Icon } from "@mui/material";
import UtilityService from "services/API/utility/UtilityService";
import AuthService from "services/API/Auth/AuthService";

function NotificaMenu({ openNotification, handleCloseNotification, onNewNotifications }) {


    //const [time, setTime] = useState(new Date());
    const [lista_notifiche, setLista_notifiche] = useState([
        { id_nofica: 0, messaggio: '', data_inserimento: '', icona: '' },
    ]);
    const [ultimaNotificaId, setUltimaNotificaId] = useState(
        localStorage.getItem("ultimaNotificaId") || null // 👈 Recupera l'ultima notifica salvata
        );

    useEffect(() => {
        // Esegue il codice ogni 5 secondi
        const interval = setInterval(() => {
            console.log("Controllo eseguito!", new Date());

            async function fetchInitialData2() {
                const user = AuthService.getCurrentUser();
             //   console.log(user);
                if (user) {
                    const response = await UtilityService.getNotifiche(user.roles[0],user.id);
                    // response.errors ? console.log(response.errors) : setLista_notifiche([...response.data]);                
                    if (!response.errors) {
                        const nuove = response.data;
                        if (nuove.length > 0) {
                            const idUltimaNotificaNuova = nuove[0].id_notifica;
                           // console.log("memorizzata="+ultimaNotificaId);
                            //console.log("ricevuta="+idUltimaNotificaNuova);
                            if (idUltimaNotificaNuova != ultimaNotificaId) {
                                
                                //  if (lista_notifiche.length!==0  && JSON.stringify(nuove) !== JSON.stringify(lista_notifiche)) {
                              //  console.log("📢 Nuove notifiche trovate!");
                                setLista_notifiche(nuove);
                                setUltimaNotificaId(idUltimaNotificaNuova); // 👈 Aggiorna l'ultima notifica vista
                                localStorage.setItem("ultimaNotificaId", idUltimaNotificaNuova); // 👈 Salva nel localStorage
                                onNewNotifications(); // 👇 Avvisa il componente padre
                            }
                            else{
                                if(lista_notifiche.length<=1){
                                    setLista_notifiche(nuove);
                                }
                            }
                        }
                    }
                }

            }
            fetchInitialData2();



            //setTime(new Date()); // Aggiorna lo stato
        }, 60000);
        return () => clearInterval(interval);
    }, [lista_notifiche]);

    return (

        <Menu
            anchorEl={openNotification}
            anchorReference={null}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
            }}
            open={Boolean(openNotification)}
            onClose={handleCloseNotification}
            sx={{ mt: 2 }}
        >
            {lista_notifiche.map((n) => (
                <Notifica
                    icon={<Icon>{n.icona}</Icon>}
                    title={n.data_inserimento}
                    text={n.messaggio}
                />

            ))

            }


        </Menu>


    );
}


export default NotificaMenu;