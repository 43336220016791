import React, { useEffect, useState } from 'react';

import MDBox from "common/components/MD/MDBox";

import { MenuItem, Select } from '@mui/material';

import UtilityService from 'services/API/utility/UtilityService';
export const SelectColumnFilterCM = ({ column }) => {

  const [lista_codici_m, setLista_codici_m] = useState([
    { codice: '', descrizione: '' },
  ]);


  useEffect(() => {
    //console.log("carico gli spazi");

    async function fetchInitialData2() {  
      const response = await UtilityService.getAllCodiciM();
      response.errors ? console.log(response.errors) : setLista_codici_m([...response.data]);
    }
    fetchInitialData2();

  }, []);

  const { filterValue, setFilter } = column

  return (

    <MDBox ml="auto">

      <Select name="cm" id="cm"
        onChange={e => {
          console.log(e.target.value);
          sessionStorage.setItem("filter_" + column.id, e.target.value);
          if (e.target.value != 'ALL') {
            setFilter(e.target.value);
          }
          else {
            setFilter('');
          }
        }}
        value={lista_codici_m.length>1 ?(filterValue || 'ALL'):'ALL'}
      > <MenuItem key="ALL" value="ALL">TUTTI</MenuItem>
        {lista_codici_m.length>1 && lista_codici_m.map((s) => (<MenuItem
          key={s.codice}
          value={s.descrizione} >
          {s.codice+"-"+s.descrizione}</MenuItem>
        ))}

      </Select>


    </MDBox>
  )

}
/*
// Setting default values for the props of DataTable
SelectColumnFilterProfessione.propTypes = {

  filter: PropTypes.string,
  setFilter: PropTypes.func,
};

// Typechecking props for the DataTable
SelectColumnFilterProfessione.defaultProps = {
  filter: '',
  setFilter: null,
};*/