import React, { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import { Link } from "react-router-dom";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "common/components/MD/MDBox";
import MDButton from "common/components/MD/MDButton";

import MDTypography from "common/components/MD/MDTypography";


// Material Dashboard 2 React example components
import DashboardLayout from "layouts/DashboardLayout";
import DashboardNavbar from "layouts/components/Navbars/DashboardNavbar";
import Footer from "layouts/components/Footer";
import HeaderForm from "layouts/components/HeaderForm";


import Alert from '@mui/material/Alert';

import loading_image from 'common/assets/images/icons/loading-green-loading.gif'



import { ColumnFilter } from "common/components/implemented/Tables/DataTable/ColumnFilter";


import ExpoPartecipazioniService from "services/API/expo_partecipazioni/ExpoPartecipazioniService";
import { NoneFilter } from "../expo_richieste_admin/NoneFilter";

import AuthService from "services/API/Auth/AuthService";
import ExpoPartecipazioniDCTable from "./ExpoPartecipazioniDCTable";
import DatiCatalogoEditModal from "../expo_partecipazioni/DatiCatalogoEditModal";
import UtilityService from "services/API/utility/UtilityService";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";


function DatiCatalogoAdminList() {

  const [datiCatalogo, setDatiCatalogo] = useState([]);
  //const  {manifestazione}= useManifestazione();

  const manifestazione = "Agriumbria 2025";

  const [loading, setLoading] = useState(true);


  //per passarmi la variabiule se ho salvato e far comparire l'alert
  const location = useLocation();
  //console.log(location);

  const { state } = location != null ? location : { state: { submitted: false, salvato: 'NO', indietro: false } }; // Read values passed on state

  const [lista_codici_m, setLista_codici_m] = useState([
    { codice: '', descrizione: '' },
  ]);



  useEffect(() => {
    console.log("carico i codici merceologici vis");

    async function fetchInitialData2() {
      const response = await UtilityService.getAllCodiciM();
      response.errors ? console.log(response.errors) : setLista_codici_m([...response.data]);
    }
    fetchInitialData2();

  }, []);



  useEffect(() => {
    if (AuthService.isTokenOk()) {
      console.log("FILTRO");
      setDatiCatalogo([]);
      setLoading(true);
      filtra();
    }
  }, []);


  function filtra() {
    console.log("FILTRO");

    ExpoPartecipazioniService.getAllDatiCatalogo(manifestazione)
      .then(response => {
        console.log(response.data);
        setDatiCatalogo(response.data);
        setLoading(false);
      })
      .catch(e => {
        console.log(e);
        setLoading(false);
      });


  }


  const ppAttributes = [];

  if (datiCatalogo.length > 0) {
    datiCatalogo.forEach(dc => {

      let action = (
        <MDBox>


          <MDButton variant="outlined" color={"success"} iconOnly={true} onClick={() => modifica(dc.espositore_azienda, dc.idCatalogo)}>
            <Icon>edit</Icon>
          </MDButton>
          <MDButton variant="outlined" color={"success"} iconOnly={true} onClick={() => elimina(dc.idCatalogo)} >
            <Icon>delete</Icon>
          </MDButton>

        </MDBox>
      );


      let ea = "E";
      if (dc.espositore_azienda == 1) {
        ea = "AC";
      }

      let lavorato = "NO";
      if (dc.lavorato) {
        lavorato = "OK";

      }

      const p = dc.protocollo + ") " + dc.nome_espositore;
      ppAttributes.push({
        dc: dc,
        idcatalogo: dc.idcatalogo,
        id_partecipazione: dc.id_partecipazione,
        nome_espositore: dc.nome_espositore,
        nome_espositore_prot: p,
        protocollo: dc.protocollo,
        ea: ea,
        ragione_sociale: dc.ragione_sociale,
        action: action,
        rx: dc.data_agg,
        lavorato: lavorato,


      })
    });
  }



  const ppTableData = {
    rows: ppAttributes,

    columns: [
      { Header: "Espositore", aggregate: "count", accessor: "nome_espositore_prot", Filter: ColumnFilter, width: "25%", align: "left", id: "dc_1", groupBy: true, Aggregated: ({ value }) => `${value} espositori`, },

      { Header: "E/AC", accessor: "ea", Filter: ColumnFilter, align: "left", with: "5%", id: "dc_3" },
      { Header: "Ragione Sociale", accessor: "ragione_sociale", Filter: ColumnFilter, align: "left", id: "dc_4" },
      { Header: "Ricevuto il", accessor: "rx", Filter: ColumnFilter, align: "left", width: "15%", id: "dc_5" },
      { Header: "Lavorato", accessor: "lavorato", Filter: ColumnFilter, align: "left", width: "5%", id: "dc_6" },
      { Header: "Azioni", accessor: "action", Filter: NoneFilter, width: "5%", align: "center", enableGlobalFilter: false, id: "dc_2", disableSortBy: true },

    ],



  };

  const mypagination = { color: 'success', variant: "contained" };
  /*-----------------------------------------------------------------------------------------------------------------------------*/



  /*---------------------------------------------------------------------------------------------------------------------------*/
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  //sono quelli lato server
  const [error, setError] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);


  const [submitted, setSubmitted] = useState(false);


  const [id_cat, setId_cat] = useState(0);

  const modifica = (espositore_azienda, idc) => {

    setId_cat(idc);
    if (espositore_azienda == 0)
      handleShow2();
    else
      handleShow3();
  }



  const salva_azienda = (formData) => {
    if (AuthService.isTokenOk()) {
      console.log("SALVO DATI ESPOSITORE");

      setError(null);


      let cm1 = null, cm2 = null;
      let c1 = lista_codici_m.find(c => c.codice === formData.codice_merceologico_01);
      if (c1 != null) cm1 = c1.codice;
      let c2 = lista_codici_m.find(c => c.codice === formData.codice_merceologico_02);
      if (c2 != null) cm2 = c2.codice;

      let dati0 = datiCatalogo.filter(d => d.idCatalogo == id_cat)[0];

      dati0.ragione_sociale = formData.ragione_sociale;
      dati0.indirizzo = formData.indirizzo;
      dati0.civico = formData.civico;
      dati0.cap = formData.cap;
      dati0.localita = formData.localita;
      dati0.indirizzo2 = formData.indirizzo2;
      dati0.provincia = formData.provincia;
      dati0.piva = formData.piva;
      dati0.cf = formData.cf;
      dati0.telefono = formData.telefono;
      dati0.fax = formData.fax;
      dati0.www = formData.www;
      dati0.email = formData.email;
      dati0.pec = formData.pec;
      dati0.cm_01 = cm1;
      dati0.cm_02 = cm2;
      dati0.prodotti_esposti = formData.prodotti_esposti;




      ExpoPartecipazioniService.updateOneDC(dati0.idCatalogo, dati0)
        .then(response => {
          handleResponse(response, "UPD");
        })
        .catch(errore => {
          handleError(errore);
        });

    }
  };



  function handleResponse(response, operazione) {

    setSubmitted(true);

    handleClose2();
    handleClose3();
    window.location.reload();
  }

  function handleError(error) {
    setError(error);
    console.log("ERRORE" + error);
    console.log(error.code);

    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.log("ERROR:RESPONSE" + error.response.data);
      console.log(error.response.data);
      setErrorMessage(error.response.data);


    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      console.log("EEROR:REQUEST" + error.request);
      setErrorMessage(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log('Error', error.message);
      setErrorMessage(error.message);
    }

    handleClose2();
    handleClose3();
  }
  const [open, setOpen] = useState(false);
  const [openExistent, setOpenExistent] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [confirmExistent, setConfirmExistent] = useState(false);

  const [idc_da_cancellare, setIdc_da_cancellare] = useState(0);

  function elimina(idc) {
    console.log("elimina azienda con id=" + idc);
    setIdc_da_cancellare(idc);

    setOpen(true);


  }



  function handleClose() {
    setOpen(false);
  }

  function handleConfirm() {
    setConfirm(true);
    setOpen(false);
    deleteObject();
  }


  const deleteObject = () => {

    if (AuthService.isTokenOk()) {
      setError(null);

      console.log("idc_da_cancellare=" + idc_da_cancellare);

      ExpoPartecipazioniService.deleteOneDC(idc_da_cancellare)
        .then(response => {
          handleResponse(response, "DEL");
        })
        .catch(errore => {
          handleError(errore);
        });

    }
  };



  /* ------------------------------navigazione -----------------------------------------------------------------*/

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>

        <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" >
          <DialogTitle id="alert-dialog-title">
            Eliminare questo record?
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Sei sicuro di voler eliminare questa azienda dalle aziende rappresentate?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <MDButton onClick={handleClose}>No</MDButton>
            <MDButton onClick={handleConfirm} autoFocus>
              SI
            </MDButton>
          </DialogActions>
        </Dialog>




        <Grid container spacing={6}>

          <Grid item xs={12}>
            <Card>
              <HeaderForm titolo={"Dati per il catalogo " + manifestazione} add="" modo="vis" />
              <MDBox pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center">

                {(state != null && state.submitted && (state.salvato === 'INS' || state.salvato === 'MOD')) &&

                  <Alert severity="success">Dati salvati con successo!</Alert>}

                {(state != null && state.submitted && state.salvato === 'DEL') &&

                  <Alert severity="success">Dati eliminati con successo!</Alert>}
              </MDBox>


              <MDBox pt={3}>
                <ExpoPartecipazioniDCTable

                  table={ppTableData}
                  canSearch={true}
                  nome_tabella="partecipazioni_dc"
                  isSorted={true}
                  entriesPerPage={true}
                  showTotalEntries={true}
                  pagination={mypagination}
                  noEndBorder
                  responsive={true}
                  first_load={state == null || !state.indietro}
                  filtra={filtra}
                  canExport={true}
                  canSend={true}
                  enableColumnFilters={true}
                  chiamante='dc_admin'

                />
              </MDBox>
              {loading &&
                <MDBox opacity={1} textAlign="center">
                  <img src={loading_image} alt="loading" width={100} />
                </MDBox>
              }

              {(datiCatalogo == null || datiCatalogo.length === 0) &&
                <MDBox style={{ height: "200px" }} textAlign='center'>
                  <MDTypography
                    variant="button"
                    fontWeight="regular"
                    color="text"
                    sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                  >
                    Non ci sono Dati per il Catalogo x la manifestazione selezionata.
                  </MDTypography></MDBox>
              }
            </Card>
          </Grid>
        </Grid>
      </MDBox>

      <DatiCatalogoEditModal
        espositore_azienda={0}
        onSave={salva_azienda}
        id_catalogo={id_cat}
        onClose={handleClose2}
        show={show2}


      />
      <DatiCatalogoEditModal
        espositore_azienda={1}
        id_catalogo={id_cat}
        onSave={salva_azienda}
        onClose={handleClose3}
        show={show3}
      />

      <Footer />
    </DashboardLayout>
  );
}



export default DatiCatalogoAdminList;
