export default function authHeader() {

  
  const user = JSON.parse(localStorage.getItem('user'));
  

    if (user && user.accessToken) {

    //  console.log('HEADER:' + user.accessToken);

      return {  'Content-Type': 'application/json' ,Authorization: 'Bearer ' + user.accessToken , 'Access-Control-Allow-Origin': '*',     };
    //  return { Accept: "application/json ,text/plain, */*",
      //  "Content-Type": "multipart/form-data",
       // Authorization: 'Bearer ' + user.accessToken      };

    } else {
      return {};
    }
  }

    