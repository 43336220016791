/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

// react-router components
import { useLocation, Link, useParams } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @material-ui core components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Icon from "@mui/material/Icon";
import Switch from "@mui/material/Switch"
import ExitToApp from '@mui/icons-material/ErrorOutlineOutlined';
// Material Dashboard 2 React components
import MDBox from "common/components/MD/MDBox";
import MDInput from "common/components/MD/MDInput";
import MDTypography from "common/components/MD/MDTypography";
// Material Dashboard 2 React example components
import Breadcrumbs from "layouts/components/Breadcrumbs";
import NotificationItem from "layouts/components/NotificationItem";
import AuthService from "services/API/Auth/AuthService.js";
// Custom styles for Das hboardNavbar
import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
  navbarMobileMenu,
} from "./styles";

// Material Dashboard 2 React context
import {
  useMaterialUIController,
  setTransparentNavbar,
  setMiniSidenav,
  setOpenConfigurator,
  setDarkMode
} from "common/context";
import {
  useManifestazione
} from "common/context/GlobalContext";
import UtilityService from "services/API/utility/UtilityService";
import Notifica from "layouts/components/NotificationItem/Notifica";
import NotificaMenu from "layouts/components/NotificationItem/NotificaMenu";
import { Badge } from "@mui/material";



//function DashboardNavbar({ absolute, light, isMini,currentUser,userReady,onClickLogout}) {
function DashboardNavbar({ absolute, light, isMini }) {

  const { manifestazione, setManifestazione, lista_man } = useManifestazione();

  // console.log (manifestazione);
  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentNavbar, fixedNavbar, openConfigurator, darkMode } = controller;
  const [openMenu, setOpenMenu] = useState(false);
  const [openNotification, setOpenNotification] = useState(false);
  const [nuoveNotifiche, setNuoveNotifiche] = useState(false);

  const route = useLocation().pathname.split("/").slice(1);



  {/*
  useEffect(() => {
    UtilityService.getAllManifestazioni()
      .then(response => {
        if (response.errors) {
          console.log(response.errors);
        } else {
          const data = response.data; // Supponiamo che l'API restituisca un array di opzioni
          setLista_man(data);
  
          // Imposta il valore selezionato alla prima opzione (o un valore specifico)
          if (data.length > 0) {
            console.log(data);

            setManifestazione(data.find(option => option.attiva === true)?.nome_manifestazione || data[0].nome_manifestazione); // Sostituisci `value` con il campo corretto dell'oggetto opzione
          
          }
        
        }
      })
      .catch(e => {
        console.log(e);
      });
  }, []);*/}

  // Gestore del cambiamento della selezione
  const handleChange = (event) => {
    if (manifestazione != event.target.value) {
      setManifestazione(event.target.value);
    }

  };



  const { id } = useParams();

  if (route[route.length - 1] === id) {
    route.pop();

  }

  // console.log("ROUTE:"+route);

  const handleDarkMode = () => setDarkMode(dispatch, !darkMode);

  const [userReady, setUserReady] = useState(false);
  const [currentUser, setCurrentUser] = useState({ id: 0, username: '', email: '', roles: [] });


  useEffect(() => {
    const user = AuthService.getCurrentUser();

    if (user) {
      setUserReady(true);
      setCurrentUser(user);
      console.log(user.roles);

    }




  }, []);


  async function logOut() {
    AuthService.logout();
    setCurrentUser({ id: 0, username: '', email: '', roles: [] });
    setUserReady(false);
  }



  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
    }

    /** 
     The event listener that's calling the handleTransparentNavbar function when 
     scrolling the window.
    */
    window.addEventListener("scroll", handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);
  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(false);

  const handleOpenNotification = (event) => {
    setOpenNotification(event.currentTarget);
    setNuoveNotifiche(false)
  }
  const handleCloseNotification = () => setOpenNotification(false);






  // Render the  menu
  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      sx={{ mt: 2 }}
    >
      {/* <NotificationItem icon={<Icon>email</Icon>} title={currentUser} />*/}
      <NotificationItem component={Link} to="/login" icon={<ExitToApp />} title="Logout" onClick={logOut} />
      {/* <NotificationItem icon={<Icon>shopping_cart</Icon>} title="Payment successfully completed" />*/}
    </Menu>
  );

  // Render the notifications menu
  const renderNotification = () => (
    <NotificaMenu openNotification={openNotification} handleCloseNotification={handleCloseNotification}
      onNewNotifications={() => setNuoveNotifiche(true)} ></NotificaMenu>
  );




  // Styles for the navbar icons
  const iconsStyle = ({ palette: { dark, white, text }, functions: { rgba } }) => ({
    color: () => {
      let colorValue = light || darkMode ? white.main : dark.main;

      if (transparentNavbar && !light) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }

      return colorValue;
    },
  });

  return (
    <AppBar
      position={absolute ? "absolute" : navbarType}
      color="inherit"
      sx={(theme) => navbar(theme, { transparentNavbar, absolute, light, darkMode })}
    >
      <Toolbar sx={(theme) => navbarContainer(theme)}>
        <MDBox color="inherit" mb={{ xs: 1, md: 0 }} sx={(theme) => navbarRow(theme, { isMini })}>
          {/* <Breadcrumbs icon="home" title={route[route.length - 1]} route={route} light={light} />*/}
        </MDBox>
        {isMini ? null : (
          <MDBox sx={(theme) => navbarRow(theme, { isMini })}>
            <MDBox pr={1}>
              {/* <MDInput label="Search here" />*/}
              <select value={manifestazione} onChange={handleChange}>
                {lista_man.map((option, index) => (
                  <option key={index} value={option.nome_manfestazione}>
                    {option.nome_manifestazione}
                  </option>
                ))}
              </select>

            </MDBox>
            <MDBox display="flex" justifyContent="space-between" alignItems="center" lineHeight={1}>
              <MDTypography variant="button"
                fontWeight="regular"
                textTransform="capitalize"
                color={light ? "white" : "dark"}>Light / Dark</MDTypography>

              <Switch checked={darkMode} onChange={handleDarkMode} />
            </MDBox>

            <MDBox color={light ? "white" : "inherit"}>
              <MDTypography variant="button"
                fontWeight="regular"
                textTransform="capitalize"
                color={light ? "white" : "dark"}>{currentUser.username}</MDTypography>

              {/* <Link to="/authentication/sign-in/basic">*/}
              <IconButton sx={navbarIconButton} size="small" disableRipple onClick={handleOpenMenu}>
                <Icon sx={iconsStyle}>account_circle</Icon>
              </IconButton>
              {/* </Link>*/}

              <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarMobileMenu}
                onClick={handleMiniSidenav}
              >
                <Icon sx={iconsStyle} fontSize="medium">
                  {miniSidenav ? "menu_open" : "menu"}
                </Icon>
              </IconButton>
              {/*<IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarIconButton}
                onClick={handleConfiguratorOpen}
              >
                <Icon sx={iconsStyle}>settings</Icon>
        </IconButton>*/}

              {/* Pulsante per aprire le notifiche */}

              <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarIconButton}
                aria-controls="notification-menu"
                aria-haspopup="true"
                variant="contained"
                onClick={handleOpenNotification}
              >
                <Badge color="error" variant={nuoveNotifiche ? "dot" : "standard"}>
                  <Icon sx={iconsStyle}>notifications</Icon>
                </Badge>

              </IconButton>
              {renderMenu()}
              {renderNotification()}
            </MDBox>
          </MDBox>
        )}
      </Toolbar>
    </AppBar>
  );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default DashboardNavbar;
