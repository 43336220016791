import { useEffect, useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';

import AuthService from "services/API/Auth/AuthService";
import ERService from 'services/API/ExpoRichieste/ExpoRichiesteService.js';

// Material Dashboard 2 React components
import MenuItem from '@mui/material/MenuItem';
import CancelIcon from '@mui/icons-material/Cancel';
import MDBox from "common/components/MD/MDBox";
import MDButton from "common/components/MD/MDButton";
import MDInput from "common/components/MD/MDInput";
import MDTypography from "common/components/MD/MDTypography";
import ReactHookFormSelect from "common/components/mycomponents/ReactHookFormSelect";

import DashboardLayout from "layouts/DashboardLayout";
import Footer from "layouts/components/Footer";
import HeaderForm from "layouts/components/HeaderForm";
import DashboardNavbar from "layouts/components/Navbars/DashboardNavbar";
// @mui material components
import Card from "@mui/material/Card";
import CardContent from '@mui/material/CardContent';
import Grid from "@mui/material/Grid";
import ExpoRichiesteVis from "pages/riservata/expo_richieste/ExpoRichiesteVis";
import Opzione01 from "pages/riservata/expo_richieste/Opzione01";
import Opzione02 from "pages/riservata/expo_richieste/Opzione02";
import Opzione03 from "pages/riservata/expo_richieste/Opzione03";
import Opzione04 from "pages/riservata/expo_richieste/Opzione04";
import Opzione05 from "pages/riservata/expo_richieste/Opzione05";
import Opzione06 from "pages/riservata/expo_richieste/Opzione06";
import Opzione07 from "pages/riservata/expo_richieste/Opzione07";
import Opzione08 from "pages/riservata/expo_richieste/Opzione08";
import Opzione09 from "pages/riservata/expo_richieste/Opzione09";
import Opzione10 from "pages/riservata/expo_richieste/Opzione10";
import Opzione11 from "pages/riservata/expo_richieste/Opzione11";
import Opzione12 from "pages/riservata/expo_richieste/Opzione12";
import Opzione13 from "pages/riservata/expo_richieste/Opzione13";
//validazione lato client
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';

//script
import currencyFormat from "common/script/currencyFormat";
import currencyToDouble from "common/script/currencyToDouble";
import UtilityService from "services/API/utility/UtilityService";
import ReactHookFormCheckBox from "common/components/mycomponents/ReactHookFormCheckBox";
import { Chip, IconButton } from "@mui/material";


const ExpoRichiestaConfirm = () => {

    const location = useLocation();
    const navigate = useNavigate();

    const { modo, soggetto, subject } = location.state;

    const [stato_accettato, setStato_accettato] = useState("NON LAVORATO");
    const [timeOfDayColor,setTimeOfDayColor] = useState('default');
const [timeOfDayMessage,setTimeOfDayMessage] = useState('');

    const [tot_10, setTot_10] = useState(0);
    const [tot_11, setTot_11] = useState(0);
    const [tot_12, setTot_12] = useState(0);
    const [tot_13, setTot_13] = useState(0);


    const sidenavColors = ["primary","secondary", "info", "success", "warning", "error"];
    const sidenavText = ["ETICHETTA 0","ETICHETTA 1", "ETICHETTA 2", "ETICHETTA 3", "ETICHETTA 4", "ETICHETTA 5"];

    // user state for form
    const [prop, setProp] = useState({
        idContratto: 0,
        idRichiesta: 0,
        id_utente: AuthService.getCurrentUser().id,
        manifestazione: subject,
        ragione_sociale: '',
        rappresentante_legale: '', indirizzo: '', civico: '', cap: '', localita: '', indirizzo2: '', provincia: '',
        piva: '', cf: '', telefono: '', fax: '', www: '', email: '', pec: '', iban: '', codice_sdi: '',
        cm_code_01: '', cm_desc_01: '', cm_code_02: '', cm_desc_02: '', cm_code_03: '', cm_desc_03: '', cm_code_04: '', cm_desc_04: '',
        responsabile_fiera: '', responsabile_fiera_telefono: '', responsabile_fiera_cell: '', responsabile_fiera_email: '',
        prodotti_servizi: '', produttore: false, concessionario: false, distributore: false, rivenditore: false, importatore: false,
        tipologia_espositiva_scelta: '', note: '',
        q_10: 0, q_11: 0, q_12: 0, q_13: 0, importo_area: 0, imponibile: 400, iva: 400 * 0.22, aliquota: 0.22, totale: 488, anticipo_versato: 120,
        stato_richiesta: '',
        idAccettazione: 0, stato_accettazione: "NON LAVORATO", dettaglio: '', mq_richiesti: 0, p_listino: 0, tipologia_concessa: '', settore_concesso: 0,
        mq_concessi: 0, prezzo: 0, quota_iscrizione: 400, imponibile_ok: 0, aliquota_ok: 0.22, iva_ok: 0, tot_a_pagare: 0, anticipo_ok: 0,
        anticipo_controllato: false, rimanenza: 0,
        note_accettazione: '',color:'default',etichetta:''
    });

    /*---------------------------------------CARICAMENTO DELLE COSE CHE SERVONO-------------------------------------*/
    const [lista_tip, setLista_tip] = useState([
        { codicetipologia: '', tipologia_stand: '', settore_m: '', descrizione: '', mq: 0, p_listino: 0 },
    ]);
    useEffect(() => {
        UtilityService.getAllTipologie()
            .then(response => {
                if (response.errors) {
                    console.log(response.errors);
                } else {

                    setLista_tip([...response.data]);

                }
            })
            .catch(e => {
                console.log(e);
            });
    }, []);


    /* ----------------------------CARICAMENTO E RIEMPIMENTO CAMPI------------------------------------------------- */

    useEffect(() => {
        if (soggetto != null) {
            console.log("soggetto non nullo: id=" + soggetto.idRichiesta);
            seleziona_soggetto();
        } else {
            console.log("soggetto nullo");
        }
    }, [soggetto]);

    async function seleziona_soggetto() {
        console.log("Cambiata pp->seleziono i dettagli :" + soggetto.idRichiesta);
        if (soggetto.idRichiesta !== 0) {
            const response = await ERService.seleziona(soggetto.idRichiesta);
            response.errors ? console.log(response.errors) : retrive(response.data);
        }
    }




    function retrive(data) {

        setProp({
            idRichiesta: data.idRichiesta,
            id_utente: data.id_utente,
            manifestazione: data.manifestazione,
            ragione_sociale: data.ragione_sociale,
            rappresentante_legale: data.rappresentante_legale,
            indirizzo: data.indirizzo,
            civico: data.civico,
            cap: data.cap,
            localita: data.localita,
            provincia: data.provincia,
            indirizzo2: data.indirizzo2,
            cf: data.cf,
            piva: data.piva,
            telefono: data.telefono,
            fax: data.fax,
            email: data.email,
            www: data.www,
            pec: data.pec,
            iban: data.iban,
            isEstero:data.estero,
            iban2:data.iban2,
            swift:data.swift,
            codice_sdi: data.codice_sdi,
            cm_01: data.cm_01,
            cm_02: data.cm_02,
            cm_03: data.cm_03,
            cm_04: data.cm_04,
            cm_01_descrizione: data.cm_01_descrizione,
            cm_02_descrizione: data.cm_02_descrizione,
            cm_03_descrizione: data.cm_03_descrizione,
            cm_04_descrizione: data.cm_04_descrizione,
            responsabile_fiera: data.responsabile_fiera,
            responsabile_fiera_telefono: data.responsabile_fiera_telefono,
            responsabile_fiera_cell: data.responsabile_fiera_cell,
            responsabile_fiera_email: data.responsabile_fiera_email,
            prodotti_servizi: data.prodotti_servizi,
            produttore: data.produttore,
            concessionario: data.concessionario,
            distributore: data.distributore,
            rivenditore: data.rivenditore,
            importatore: data.importatore,
            tipologia_espositiva_scelta: data.tipologia_espositiva_scelta,
            q_10: data.q_10, q_11: data.q_11, q_12: data.q_12, q_13: data.q_13,
            importo_area: data.importo_area, imponibile: data.imponibile, iva: data.iva,
            totale: data.totale, anticipo_versato: data.anticipo_versato, aliquota: data.aliquota,
            note: data.note, esigenze_espositive: data.esigenze_espositive,
            idAccettazione: data.idAccettazione,stato: data.stato, stato_accettazione: data.stato_accettazione,
            dettaglio: data.dettaglio, mq_richiesti: data.mq_richiesti, p_listino: data.p_listino,
            tipologia_concessa: data.tipologia_concessa, settore_concesso: data.settore_concesso,
            mq_concessi: data.mq_concessi, prezzo: data.prezzo, quota_iscrizione: data.quota_iscrizione,
            imponibile_ok: data.imponibile_ok, aliquota_ok: data.aliquota_ok, iva_ok: data.iva_ok,
            tot_a_pagare: data.tot_a_pagare, anticipo_ok: data.anticipo_ok,
            anticipo_controllato: data.anticipo_controllato, rimanenza: data.rimanenza,
            note_accettazione: data.note_accettazione,
            color:data.color, etichetta:data.etichetta

        });

        //     console.log(prop.q_13 );
        //   console.log(data.q_13 );
        setTot_10(data.q_10 * 75);
        setTot_11(data.q_11 * 80);
        setTot_12(data.q_12 * 1800);
        setTot_13(data.q_13 * 1000);
        // console.log(tot_13);
        console.log("selezionata  " + prop.idRichiesta);

    }


    /* ----------------------------VALIDAZIONE E SALVATAGGIO----------------------------------------------------------------------- */


    const validationSchema = Yup.object().shape({

    });



    const {
        register, //register inputs
        getValues, setValue, control,
        handleSubmit,//handle form submit
        formState: { errors }
    } = useForm({
        resolver: yupResolver(validationSchema)
    });



    useEffect(() => {

        console.log("Cambiata pp->riempo i campi  " + prop.idRichiesta);

        setValue("stato_accettazione", prop.stato_accettazione);
        visualizza_accettato(prop.stato_accettazione);
        setValue("dettaglio", prop.dettaglio);
        setValue("note_accettazione", prop.note_accettazione);
        setValue("mq_richiesti", prop.mq_richiesti);
        setValue("p_listino", currencyFormat(prop.p_listino));
        setValue("tipologia", prop.tipologia_concessa);
        setValue("settore", prop.settore_concesso);
        setValue("mq_concessi", prop.mq_concessi);
        setValue("prezzo", currencyFormat(prop.prezzo));
        setValue("quota_iscrizione", currencyFormat(prop.quota_iscrizione));
        setValue("aliquota_ok", prop.aliquota_ok*100);
        setValue("imponibile_ok", currencyFormat(prop.imponibile_ok));
        setValue("iva_ok", currencyFormat(prop.iva_ok));
        setValue("tot_a_pagare", currencyFormat(prop.tot_a_pagare));
        setValue("anticipo_ok", currencyFormat(prop.anticipo_ok));
        setValue("anticipo_controllato", prop.anticipo_controllato);
        setValue("rimanenza", currencyFormat(prop.rimanenza));

        setTimeOfDayColor(prop.color);
        setTimeOfDayMessage(prop.etichetta);

       

    }, [prop]);

    /*------------------------FUNZIONALITA PAGINA------------------------------------------- */
    const puntoToVirgola = (event) => {

        let val = event.target.value.replaceAll(".", ",");
        setValue(event.target.name, val);
    }


    const visualizza_accettato = (value) => {

        console.log('cambio accettato:' + value);
        setStato_accettato(value);

        let tip_scelta = lista_tip.find((obj => {
            return obj.codicetipologia == prop.tipologia_espositiva_scelta
        }));
        setValue("tipologia", tip_scelta.tipologia_stand);
        setValue("settore", tip_scelta.settore_m);
      
        let mqr = 0;
        if (lista_tip.indexOf(tip_scelta) <= 9) {
            mqr = tip_scelta.mq;

        } else {
            if (tip_scelta.codicetipologia == "10") {
                mqr = prop.q_10;
            }
            if (tip_scelta.codicetipologia == "11") {
                mqr = prop.q_11;
            }
            if (tip_scelta.codicetipologia == "12") {
                mqr = prop.q_12 * tip_scelta.mq;
            }
            if (tip_scelta.codicetipologia == "13") {
                mqr = prop.q_13 * tip_scelta.mq;
            }
        }

        setValue("p_listino", currencyFormat(prop.importo_area));
        setValue("mq_richiesti", mqr);
        setValue("aliquota_ok", (prop.aliquota_ok ? prop.aliquota_ok*100 : prop.aliquota*100));
      //  setValue("anticipo_ok", (prop.anticipo_ok ? currencyFormat(prop.anticipo_ok) : currencyFormat(prop.anticipo_versato)));

        if (value == "NON ACCETTATO") {
            console.log('metto a 0');
            setValue("mq_concessi", "0");
            setValue("prezzo", currencyFormat(0));
            setValue("quota_iscrizione", currencyFormat(0));
           // setValue("dettaglio", tip_scelta.descrizione);
        }

        if (value == "OK" ||  value == "SOSPESO" || value=="RIAMMESSO") {
            setValue("mq_concessi", mqr);
            setValue("prezzo", currencyFormat(prop.importo_area));
            setValue("quota_iscrizione", "400,00");
            setValue("dettaglio", tip_scelta.descrizione);
        }
/*
        if (value == "MODIFICATO DA ACCETTARE") {
            console.log('metto a 0');
            setValue("mq_concessi", "0,00");
            setValue("prezzo", "0,00");
            setValue("dettaglio", "");
        }

*/
        onChangeQ();
    }

    /*const field_quota = currencyToDouble(watch("quota_iscrizione"));
    const field_prezzo = currencyToDouble(watch("prezzo"));
    const field_aliquota = watch("aliquota_ok");
    const field_anticipo = currencyToDouble(watch("anticipo_ok"));*/




    const onChangeQ = () => {

        //    const multipleValues= getValues(["quota_iscrizione","prezzo","aliquota_ok","anticipo_ok"])
        const field_quota = currencyToDouble(getValues("quota_iscrizione"));
        const field_prezzo = currencyToDouble(getValues("prezzo"));
        const field_aliquota = getValues("aliquota_ok")/100;
        const field_anticipo = currencyToDouble(getValues("anticipo_ok"));

        console.log('onchange field_quota=' + field_quota);
        console.log('onchange: field_prezzo=' + field_prezzo);



        var imponibile = field_prezzo + field_quota;
        console.log('onchange: imponibile=' + imponibile);

        setValue("imponibile_ok", currencyFormat(imponibile));
        console.log('onchange: field_aliquota=' + field_aliquota);
        let iva = imponibile * field_aliquota;
        setValue("iva_ok", currencyFormat(iva));
        let totale = imponibile + iva;
        setValue("tot_a_pagare", currencyFormat(totale));
        console.log('onchange: field_anticipo=' + field_anticipo);
        let rimanenza = totale - field_anticipo;
        setValue("rimanenza", currencyFormat(rimanenza));

    }

const handleClickColor =(color) => {
   
    console.log(color);
    setTimeOfDayColor(color);
    let index = sidenavColors.indexOf(color);
     setTimeOfDayMessage(sidenavText.at(index));

 }
 const resetColor =() => {
   
     setTimeOfDayColor('default');
      setTimeOfDayMessage("");

 }
    /* ----------------------------SALVATAGGIO E REGISTRAZIONE------------------------------------------------- */




    const [submitted, setSubmitted] = useState(false);


    const [error, setError] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);



    const salva_pp = (formData) => {


        console.log("salva :::::::: MODO=" + modo);
        setError(null);

        var data = {
            idRichiesta: prop.idRichiesta,
            idAccettazione: prop.idAccettazione,
            stato_accettazione: formData.stato_accettazione,
            dettaglio: formData.dettaglio,
            tipologia_concessa: formData.tipologia,
            settore_concesso: formData.settore,
            mq_richiesti: formData.mq_richiesti,
            p_listino: currencyToDouble(formData.p_listino),
            mq_concessi: formData.mq_concessi,
            prezzo: currencyToDouble(formData.prezzo),
            quota_iscrizione: currencyToDouble(formData.quota_iscrizione),
            imponibile_ok: currencyToDouble(formData.imponibile_ok),
            aliquota_ok: formData.aliquota_ok/100,
            iva_ok: currencyToDouble(formData.iva_ok),
            tot_a_pagare: currencyToDouble(formData.tot_a_pagare),
            anticipo_ok: currencyToDouble(formData.anticipo_ok),
            rimanenza: currencyToDouble(formData.rimanenza),
            note_accettazione: formData.note_accettazione,
            anticipo_controllato: formData.anticipo_controllato,
            color:timeOfDayColor,
            etichetta:timeOfDayMessage

        };

        if (modo === 'edit') {
           // if (formData.stato_accettazione != 'NON LAVORATO') {
                ERService.salva_accettazione(prop.idRichiesta, data)
                    .then(response => {
                        handleResponse(response, 'OK');
                    })
                    .catch(errore => {
                        handleError(errore);
                    });
          /*  } else {
                if (prop.idAccettazione != 0) {
                    ERService.elimina_accettazione(prop.idRichiesta)
                        .then(response => {
                            handleResponse(response, 'OK');
                        })
                        .catch(errore => {
                            handleError(errore);
                        });
                } else {
                    navigate("/expo_richieste_admin", { state: {indietro:true } });

                }

            }*/
        }


    }

    function handleResponse(response, op) {
        console.log(response.data);
        setSubmitted(true);

        navigate("/expo_richieste_admin", { state: { submitted: true, salvato: op,indietro:true } });
    }


    function handleError(error) {
        setError(error);
        console.log("ERRORE" + error);
        console.log(error.code);

        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log("ERROR:RESPONSE" + error.response.data);
            setErrorMessage(error.response.data);

        } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.log("EEROR:REQUEST" + error.request);
            setErrorMessage(error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message);
            setErrorMessage(error.message);
        }

    }




    /* ------------------------------navigazione -----------------------------------------------------------------*/


    const annulla = () => {


        navigate("/expo_richieste_admin", { state: {indietro:true } });
    }

    const handleKeyDown = (e) => {
        const { key, target } = e

        if (key !== "Enter" || target instanceof HTMLTextAreaElement) {
            return
        }

        e.preventDefault()
    }


    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                {/* <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" >
                    <DialogTitle id="alert-dialog-title">
                        Eliminare questo record?
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Sei sicuro di voler eliminare questa richiesta di partecipazione?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <MDButton onClick={handleClose}>No</MDButton>
                        <MDButton onClick={handleConfirm} autoFocus>
                            SI
                        </MDButton>
                    </DialogActions>
                </Dialog>*/}
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card >
                            <HeaderForm titolo={"Proposta di Partecipazione " + prop.manifestazione} modo='edit' save={handleSubmit(salva_pp)} exit={annulla} />
                            <MDBox pt={1} pb={3} px={3} component="form" role="form" onKeyDown={handleKeyDown}>
                                <CardContent>
                                    <MDBox>
                                        <MDBox opacity={1} textAlign="center">

                                        </MDBox>
                                        <MDBox pt={4} pb={3} px={3} >

                                            <MDBox mt={5}>


                                                <ExpoRichiesteVis prop={prop}></ExpoRichiesteVis>


                                                <MDBox mt={5} mb={4}>

                                                    <MDButton variant="gradient" color="success" fullWidth>
                                                        <MDTypography variant="h5" fontWeight="regular" sx={{
                                                            color: "#FFFFFF"
                                                        }}>TIPOLOGIA ESPOSITIVA RICHIESTA</MDTypography>
                                                    </MDButton>
                                                </MDBox>

                                                <MDBox>
                                                    <MDBox mb={2} mt={4} >

                                                        {prop.tipologia_espositiva_scelta === '01' &&

                                                            <Opzione01 />
                                                        }

                                                        {prop.tipologia_espositiva_scelta === '02' &&
                                                            <Opzione02 />
                                                        }
                                                        {prop.tipologia_espositiva_scelta === '03' &&

                                                            <Opzione03 />
                                                        }
                                                        {prop.tipologia_espositiva_scelta === '04' &&
                                                            <Opzione04 />

                                                        }
                                                        {prop.tipologia_espositiva_scelta === '05' &&

                                                            <Opzione05 />

                                                        }
                                                        {prop.tipologia_espositiva_scelta === '06' &&
                                                            <Opzione06 />
                                                        }
                                                        {prop.tipologia_espositiva_scelta === '07' &&

                                                            <Opzione07 />
                                                        }
                                                        {prop.tipologia_espositiva_scelta === '08' &&

                                                            <Opzione08 />

                                                        }
                                                        {prop.tipologia_espositiva_scelta === '09' &&
                                                            <Opzione09 />
                                                        }
                                                        {prop.tipologia_espositiva_scelta === '10' &&

                                                            <Opzione10 q_10={prop.q_10} tot_10={tot_10}></Opzione10>

                                                        }
                                                        {prop.tipologia_espositiva_scelta === '11' &&
                                                            <Opzione11 q_11={prop.q_11} tot_11={tot_11}></Opzione11>


                                                        }
                                                        {prop.tipologia_espositiva_scelta === '12' &&

                                                            <Opzione12 q_12={prop.q_12} tot_12={tot_12}></Opzione12>
                                                        }
                                                        {prop.tipologia_espositiva_scelta === '13' &&

                                                            <Opzione13 q_13={prop.q_13} tot_13={tot_13}></Opzione13>

                                                        }


                                                    </MDBox>
                                                </MDBox>
                                                <MDBox mb={2} sx={{ display: 'grid', gap: 1, gridTemplateColumns: ' 1fr 1fr 1fr 1fr' }}>

                                                    <MDTypography variant="body2" fontWeight="regular" >IMPONIBILE : {currencyFormat(prop.imponibile)} €</MDTypography>
                                                    <MDTypography variant="body2" fontWeight="regular" >IVA : {currencyFormat(prop.iva)} €</MDTypography>
                                                    <MDTypography variant="body2" fontWeight="regular" >TOTALE : {currencyFormat(prop.totale)} €</MDTypography>
                                                    <MDTypography variant="body2" fontWeight="regular" >ANTICIPO VERSATO :{currencyFormat(prop.anticipo_versato)} €</MDTypography></MDBox>
                                           
                                           {prop.stato === 'INVIATA' && 
                                                    <MDBox>
                                                        <MDBox mt={5} mb={4}>

                                                    <MDButton variant="gradient" color="success" fullWidth>
                                                        <MDTypography variant="h5" fontWeight="regular" sx={{
                                                            color: "#FFFFFF"
                                                        }}>ACCETTAZIONE</MDTypography>
                                                    </MDButton>
                                                </MDBox>
                                                <MDBox mb={2} sx={{ display: 'grid', gap: 1, gridTemplateColumns: '1fr 2fr' }}>
                                                    <MDBox >

                                                        Segna la proposta come  </MDBox><MDBox>  <ReactHookFormSelect
                                                            id="stato_accettazione"
                                                            name="stato_accettazione"
                                                            label=""
                                                            onChange2={visualizza_accettato}
                                                            control={control}
                                                            defaultValue=''
                                                            variant="outlined">
                                                            <MenuItem key="NON LAVORATO" value="NON LAVORATO">NON LAVORATO</MenuItem>
                                                            <MenuItem key="SOSPESO" value="SOSPESO">SOSPESO</MenuItem>
                                                            <MenuItem key="MODIFICATO DA ACCETTARE" value="MODIFICATO DA ACCETTARE">MODIFICATO DA ACCETTARE</MenuItem>
                                                            <MenuItem key="NON ACCETTATO" value="NON ACCETTATO">NON ACCETTATO </MenuItem>
                                                            <MenuItem key="ACCETTATO" value="ACCETTATO">ACCETTATO </MenuItem>
                                                            <MenuItem key="ACCETTATO CON MODIFICHE" value="ACCETTATO CON MODIFICHE">ACCETTATO CON MODIFICHE</MenuItem>
                                                            <MenuItem key="RIAMMESSO" value="RIAMMESSO">RIAMMESSO</MenuItem>
                                                            <MenuItem key="RINUNCIATO" value="RINUNCIATO">RINUNCIATO</MenuItem>
                                                        </ReactHookFormSelect>
                                                    </MDBox></MDBox>
                                              

                                                    <MDBox>
                                                        <MDBox mb={2} sx={{ display: 'grid', gap: 1, gridTemplateColumns: '1fr 1fr 2fr' }}>

                                                            <ReactHookFormSelect
                                                                id="tipologia"
                                                                name="tipologia"
                                                                label="Tipologia Espositiva Concessa"

                                                                control={control}
                                                                defaultValue=''
                                                                variant="outlined"

                                                            >
                                                                <MenuItem key="0" value="Stand al coperto">Stand al coperto</MenuItem>
                                                                <MenuItem key="1" value="Area al coperto">Area al coperto</MenuItem>
                                                                <MenuItem key="2" value="Area all'aperto">Area all'aperto</MenuItem>
                                                            </ReactHookFormSelect>

                                                            <ReactHookFormSelect
                                                                id="settore"
                                                                name="settore"
                                                                label="Settore Merceologico"
                                                                control={control}
                                                                defaultValue=''
                                                                variant="outlined">
                                                                <MenuItem key="01" value="01">01</MenuItem>
                                                                <MenuItem key="02" value="02">02</MenuItem>
                                                                <MenuItem key="03-10" value="03-10">03-10</MenuItem>
                                                                <MenuItem key="11-22" value="11-22">11-22</MenuItem>
                                                            </ReactHookFormSelect>
                                                            <MDInput type="text" label="dettaglio" name='dettaglio' fullWidth   {...register('dettaglio')} InputLabelProps={{ shrink: true }} />
                                                            <MDBox></MDBox>
                                                            <MDBox></MDBox>
                                                            <MDBox></MDBox>
                                                            <MDBox></MDBox>

                                                        </MDBox>


                                                        <MDBox mb={2} >
                                                            <MDInput type="text" label="Note accettazione" multiline rows={5} fullWidth variant="outlined" InputLabelProps={{ shrink: true }}
                                                                name='note_accettazione' {...register('note_accettazione')} />
                                                        </MDBox>

                                                        <MDBox mb={2} sx={{ display: 'grid', gap: 1, gridTemplateColumns: '2fr 2fr 1fr' }}>
          <MDTypography variant="h6">Assegna un colore:</MDTypography>

          <MDBox mb={0.5}>
          <IconButton variant="outlined"  color="success" onClick={resetColor}>
          <CancelIcon fontSize="small" /></IconButton>
            {sidenavColors.map((color) => (
              <IconButton
                key={color}
                sx={({
                  borders: { borderWidth },
                  palette: { white, dark, background },
                  transitions,
                }) => ({
                  width: "24px",
                  height: "24px",
                  padding: 0,
                  border: `${borderWidth[1]} solid ${white.main}`,
                  borderColor: () => {
                    let borderColorValue  = white.main;
                    return borderColorValue;
                  },
                  transition: transitions.create("border-color", {
                    easing: transitions.easing.sharp,
                    duration: transitions.duration.shorter,
                  }),
                  backgroundImage: ({ functions: { linearGradient }, palette: { gradients } }) =>
                    linearGradient(gradients[color].main, gradients[color].state),
                  "&:not(:last-child)": {
                    mr: 1,
                  },
                  "&:hover, &:focus, &:active": {
                    borderColor:  white.main ,
                  },
                })}
                onClick={()=>handleClickColor(color)}
              />
            ))}
          </MDBox>
          <Chip label={timeOfDayMessage} color={timeOfDayColor} />
        </MDBox>
                                                        <MDBox mb={2} sx={{ display: 'grid', gap: 1, gridTemplateColumns: '1fr 1fr 1fr 1fr ' }}>



                                                            <MDInput type="text" label="Mq richiesti" name='mq_richiesti' fullWidth disabled {...register('mq_richiesti')} InputLabelProps={{ shrink: true }} />
                                                            <MDInput type="text" label="Prezzo Tot Listino" name='p_listino' fullWidth disabled {...register('p_listino')} InputLabelProps={{ shrink: true }} />

                                                            <MDInput type="text" label="Mq concessi" name='mq_concessi' fullWidth   {...register('mq_concessi')} InputLabelProps={{ shrink: true }} />

                                                            <MDInput type="text" label="Prezzo" name='prezzo' fullWidth   {...register('prezzo', { onChange: (e) => { onChangeQ(); puntoToVirgola(e); } })} InputLabelProps={{ shrink: true }} />
                                                            <MDBox></MDBox>

                                                            <MDBox>+</MDBox>
                                                            <MDInput type="text" label="Quota Iscrizione" name='quota_iscrizione' fullWidth   {...register('quota_iscrizione', { onChange: (e) => { onChangeQ(); puntoToVirgola(e) } })} InputLabelProps={{ shrink: true }} />
                                                            <MDInput type="text" label="Imponibile" name='imponibile_ok' fullWidth disabled  {...register('imponibile_ok')} InputLabelProps={{ shrink: true }} />


                                                            <MDBox></MDBox>

                                                            <MDInput type="text" label="Aliquota %" name='aliquota_ok' fullWidth  {...register('aliquota_ok', { onChange: (e) => { onChangeQ() } })} InputLabelProps={{ shrink: true }} />
                                                            <MDInput type="text" label="Iva" name='iva_ok' fullWidth disabled {...register('iva_ok')} InputLabelProps={{ shrink: true }} />
                                                            <MDInput type="text" label="Totale ivato" name='tot_a_pagare' fullWidth disabled {...register('tot_a_pagare')} InputLabelProps={{ shrink: true }} />
                                                            <MDBox></MDBox>
                                                            <MDBox></MDBox>
                                                            <MDBox><ReactHookFormCheckBox
                                                                label={<MDBox>
                                                                    <MDTypography
                                                                        variant="button"
                                                                        fontWeight="regular"
                                                                        color="text"
                                                                        sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                                                                    >
                                                                        &nbsp;&nbsp;Anticipo Controllato&nbsp;
                                                                    </MDTypography>

                                                                </MDBox>}
                                                                name="anticipo_controllato"
                                                                control={control} /></MDBox>
                                                            <MDInput type="text" label="Anticipo Versato" name='anticipo_ok' fullWidth  {...register('anticipo_ok', { onChange: (e) => { onChangeQ(e); puntoToVirgola(e); } })} InputLabelProps={{ shrink: true }} />
                                                            <MDBox></MDBox>
                                                            <MDBox></MDBox>
                                                            <MDBox></MDBox>
                                                            <MDInput type="text" label="Rimanenza" name='rimanenza' fullWidth disabled  {...register('rimanenza')} InputLabelProps={{ shrink: true }} />




                                                        </MDBox>

                                                    </MDBox>
                                                
                                                <MDBox mb={2}>
                                                </MDBox>

                                                <MDBox sx={{ display: 'flex', flexDirection: 'row', pt: 2 }} mt={4} mb={1}>
                                                    <MDButton variant="outlined" color="success" onClick={annulla} >
                                                        annulla
                                                    </MDButton>
                                                    <MDBox sx={{ flex: '1 1 auto' }} />

                                                    <MDBox ml={2}>
                                                        <MDButton variant="gradient" color="success" onClick={handleSubmit(salva_pp)} type="submit"  >
                                                            SALVA
                                                        </MDButton>
                                                    </MDBox>

                                                </MDBox>


                                         </MDBox>       
}
                                            </MDBox>
                                        </MDBox>
                                    </MDBox>
                                </CardContent>
                            </MDBox>
                        </Card>
                    </Grid></Grid></MDBox>
            <Footer />
        </DashboardLayout>
    )
}


export default ExpoRichiestaConfirm;