import React, { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import { Link } from "react-router-dom";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "common/components/MD/MDBox";
import MDButton from "common/components/MD/MDButton";

import MDTypography from "common/components/MD/MDTypography";


// Material Dashboard 2 React example components
import DashboardLayout from "layouts/DashboardLayout";
import DashboardNavbar from "layouts/components/Navbars/DashboardNavbar";
import Footer from "layouts/components/Footer";
import HeaderForm from "layouts/components/HeaderForm";


import Alert from '@mui/material/Alert';

import loading_image from 'common/assets/images/icons/loading-green-loading.gif'
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import DoDisturbAltIcon from '@mui/icons-material/DoDisturbAlt';
import { ColumnFilter } from "common/components/implemented/Tables/DataTable/ColumnFilter";



import ExpoPartecipazioniService from "services/API/expo_partecipazioni/ExpoPartecipazioniService";
import { NoneFilter } from "../expo_richieste_admin/NoneFilter";
import { SelectColumnFilterPostazione } from "../expo_richieste_admin/SelectColumnFilterPostazione";
import { SelectColumnFilterMsgS } from "./SelectColumnFilterMsgS";
import AuthService from "services/API/Auth/AuthService";
import ExpoPartecipazioniVerifyTable from "./ExpoPartecipazioniVerifyTable";


function ExpoPartecipazioniVerify() {

  const [partecipazioni, setPartecipazioni] = useState([]);
  //const  {manifestazione}= useManifestazione();

  const manifestazione = "Agriumbria 2025";

  const [loading, setLoading] = useState(true);


  //per passarmi la variabiule se ho salvato e far comparire l'alert
  const location = useLocation();
  //console.log(location);

  const { state } = location != null ? location : { state: { submitted: false, salvato: 'NO', indietro: false } }; // Read values passed on state

  useEffect(() => {
    if (AuthService.isTokenOk()) {
    console.log("FILTRO");
    setPartecipazioni([]);
    setLoading(true);

   filtra();
    }
  }, []);

function filtra(){

  ExpoPartecipazioniService.getAllPartecipazioni(manifestazione)
  .then(response => {
    console.log(response.data);
    setPartecipazioni(response.data);
    setLoading(false);
  })
  .catch(e => {
    console.log(e);
    setLoading(false);
  });

}

  
  const ppAttributes = [];

  if (partecipazioni.length > 0) {
    partecipazioni.forEach(part => {

      let sal="NO";
      if(part.saldato)  sal="SI";

      let cp="NO";
      if(part.contratto_protocollato)  cp="SI";

 
let semaforo=(
  <CheckCircleRoundedIcon color="success" fontSize="large"></CheckCircleRoundedIcon>
);
if(!part.contratto_protocollato || !part.saldato){
    semaforo=(<DoDisturbAltIcon color="error" fontSize="large"></DoDisturbAltIcon>);
}

      ppAttributes.push({
        part:part,
        idPartecipazione:part.idPartecipazione,
        mq: part.mqa,
        protocollo: part.protocollo,
        azienda: part.ragione_sociale,
        tipologia_espositiva_string: part.tipologia_espositiva_string,
        postazione: part.postazione,
     semaforo:semaforo,
        contratto_protocollato:cp,
        saldato: sal,
    
      })
    });
  }



  const ppTableData = {
    rows: ppAttributes,

    columns: [
      { Header: "Verify", accessor: "semaforo", Filter: NoneFilter, width: "5%", align: "left", id: "verifica_00" },
      { Header: "Protocollo", accessor: "protocollo", Filter: ColumnFilter, width: "5%", align: "left", id: "verifica_0" },
      { Header: "Azienda", accessor: "azienda", Filter: ColumnFilter, align: "left",  id: "verifica_1" },
      { Header: "Tipologia Espositiva Assegnata", accessor: "tipologia_espositiva_string", Filter: ColumnFilter, align: "left", with: "10%", id: "verifica_2" },
      { Header: "Mq Asseganti", accessor: "mq", Filter: ColumnFilter, align: "left", width: "5%", id: "verifica_3" },
      { Header: "Postazione", accessor: "postazione", Filter: SelectColumnFilterPostazione, align: "left", width: "5%", id: "verifica_4" },
      { Header: "Contratto Protocollato", accessor: "contratto_protocollato", Filter: ColumnFilter, width: "5%", align: "left", id: "verifica_5" },
      { Header: "Saldato", accessor: "saldato", Filter: ColumnFilter, align: "left", width: "5%", id: "verifica_6" },

    ],



  };

  const mypagination = { color: 'success', variant: "contained" };




  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>

          <Grid item xs={12}>
            <Card>
              <HeaderForm titolo={"Espositori " + manifestazione} add="" modo="vis" />
              <MDBox pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center">

                {(state != null && state.submitted && (state.salvato === 'INS' || state.salvato === 'MOD')) &&

                  <Alert severity="success">Dati salvati con successo!</Alert>}

                {(state != null && state.submitted && state.salvato === 'DEL') &&

                  <Alert severity="success">Dati eliminati con successo!</Alert>}
              </MDBox>


              <MDBox pt={3}>
                <ExpoPartecipazioniVerifyTable 
                  canSearch={true}
                  table={ppTableData}
                  nome_tabella="partecipazioni"
                  isSorted={true}
                  entriesPerPage={true}
                  showTotalEntries={true}
                  pagination={mypagination}
                  noEndBorder
                  responsive={true}
                  first_load={state == null || !state.indietro}
                  filtro_server={true}
                  canExport={true}
                  canSend={true}
                  enableColumnFilters={true}
                  chiamante='expo_partecipazioni_admin'
                  filtra={filtra}
                />
              </MDBox>
              {loading &&
                <MDBox opacity={1} textAlign="center">
                  <img src={loading_image} alt="loading" width={100} />
                </MDBox>
              }

              {(partecipazioni == null || partecipazioni.length === 0) &&
                <MDBox style={{ height: "200px" }} textAlign='center'>
                  <MDTypography
                    variant="button"
                    fontWeight="regular"
                    color="text"
                    sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                  >
                    Non ci sono Espositori x la manifestazione selezionata.
                  </MDTypography></MDBox>
              }
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}



export default ExpoPartecipazioniVerify;
