import MDTypography from "common/components/MD/MDTypography";
import Grid from "@mui/material/Grid";
import currencyFormat from "common/script/currencyFormat"

import PropTypes from "prop-types";


function Opzione13({ q_13,tot_13 }) {
    return (
        <Grid container spacing={2} mb={2} columns={13} sx={{
            fontSize: 12,
            boxShadow: 2,
            border: 2,
            borderColor: '#ff9800',
            '& .MuiDataGrid-cell:hover': {
                color: '#ff9800',
            },
        }}>
            <Grid item sm={13} md={13} lg={13} >
                <MDTypography variant="button" fontWeight="bold" sx={{ color: '#ff9800' }}>AREA ALL'APERTO</MDTypography>

            </Grid>


            <Grid item sm={13} md={13} lg={13} >
                <MDTypography variant="button" fontWeight="bold" sx={{ color: '#ff9800' }}>SETTORI MERCEOLOGICI AMMESSI: 11-12-13-14-15-16-17-18-19-20-21-22</MDTypography>

            </Grid>

            <Grid item sm={13} md={13} lg={13} >
                <MDTypography variant="caption" fontWeight="regular" >
                    La tariffa comprende: area espositiva, servizio antincendio, energia elettrica pari a 1kw/220 V ogni 50 mq, inserimento a catalogo, inviti, pass espositori, pass auto.    </MDTypography>
            </Grid>
            <Grid item sm={2} md={2} lg={2} >
                <MDTypography variant="overline" fontWeight="medium" >
                    TIPO AREA</MDTypography>
            </Grid>


            <Grid item xs={3} md={3} >
                <MDTypography variant="overline" fontWeight="medium" sx={{ textAlign: 'left' }} >
                    SETTORI AMMESSI</MDTypography>
            </Grid>

            <Grid item xs={3} md={3} >
                <MDTypography variant="overline" fontWeight="medium" >
                    TIPO DI STAND</MDTypography>
            </Grid>
            <Grid item xs={1} md={1} >
                <MDTypography variant="overline" fontWeight="medium" >
                    N°</MDTypography>
            </Grid>
            <Grid item xs={2} md={2} >
                <MDTypography variant="overline" fontWeight="medium" >
                    PREZZO UNITARIO</MDTypography>
            </Grid>
            <Grid item xs={2} md={2} >
                <MDTypography variant="overline" fontWeight="medium" >
                    TOT</MDTypography>
            </Grid>
            {/*---------------------------------------------------------------------------------------------*/}
            <Grid item xs={2} md={2} >
                <MDTypography variant="overline" fontWeight="regular"
                    sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}>
                    AREA ALL'APERTO
                </MDTypography>
            </Grid>

            <Grid item xs={3} md={3} >
                <MDTypography variant="overline" fontWeight="regular" >
                    11-12-13-14-15-16-17-18-19-20-21-22</MDTypography>
            </Grid>

            <Grid item xs={3} md={3} >
                <Grid container spacing={2} >
                    <Grid item xs={6} md={6} >
                        <MDTypography variant="overline" fontWeight="regular" >
                            Modulo mq 50
                        </MDTypography></Grid>
                    <Grid item xs={6} md={6} >
                        <MDTypography variant="overline" fontWeight="regular" >
                        </MDTypography></Grid>
                </Grid>
            </Grid>
            <Grid item xs={1} md={1} >
                <MDTypography variant="overline" fontWeight="regular" >
                    {q_13}

                </MDTypography>
            </Grid>
            <Grid item xs={2} md={2} >
                <MDTypography variant="overline" fontWeight="regular" >
                    x  1.000,00 €

                </MDTypography>
            </Grid>
            <Grid item xs={2} md={2} >
                <MDTypography variant="button" fontWeight="overline" > {currencyFormat(tot_13)} €</MDTypography>
            </Grid>
            {/*---------------------------------------------------------------------------------------------*/}


        </Grid>

    );

}
Opzione13.defaultProps = {
    q_13:0,
    tot_13:0
  };
  
  Opzione13.propTypes = {
    q_13: PropTypes.number,
    tot_13:PropTypes.number
  };
  
  export default Opzione13;
  