/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-routers components
import { Link } from "react-router-dom";

// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "common/components/MD/MDBox";

import MDAvatar from "common/components/MD/MDAvatar/MDAvatar";
import MDButton from "common/components/MD/MDButton";
import MDTypography from "common/components/MD/MDTypography";
import Person2Icon from '@mui/icons-material/Person2';
import DeleteIcon from '@mui/icons-material/Delete';


function ProfilesList({ title, profiles, shadow ,onDel}) {


  const renderProfiles = profiles.map(({ image,id_utente,id_proposta, ragione_sociale, protocollo, action }) => (
    <MDBox key={id_utente} component="li" display="flex" alignItems="center" py={1} mb={1}>
      <MDBox mr={2}>
        {/*<MDAvatar src={image} alt="something here" shadow="md" />*/}
      <Person2Icon></Person2Icon>
      </MDBox>
      <MDBox display="flex" flexDirection="column" alignItems="flex-start" justifyContent="center">
        <MDTypography variant="button" fontWeight="medium">
          {ragione_sociale}
        </MDTypography>
        <MDTypography variant="caption" color="text">
          Proposta di Partecipazione num. {protocollo}
        </MDTypography>
      </MDBox>
    
      <MDBox ml="auto">
      <MDButton onClick={() => onDel(id_utente)}
           variant="gradient" color="success"
          >
            <DeleteIcon></DeleteIcon>
            
          </MDButton>
    </MDBox>
    </MDBox>
  ));

  return (
    <Card sx={{ height: "100%", boxShadow: !shadow && "none" }}>
      <MDBox pt={2} px={2}>
        <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          {title}
        </MDTypography>
      </MDBox>
      <MDBox p={2}>
        <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
          {renderProfiles}
        </MDBox>
      </MDBox>
    </Card>
  );
}

// Setting default props for the ProfilesList
ProfilesList.defaultProps = {
  shadow: true,
};

// Typechecking props for the ProfilesList
ProfilesList.propTypes = {
  title: PropTypes.string.isRequired,
  profiles: PropTypes.arrayOf(PropTypes.object).isRequired,
  shadow: PropTypes.bool,
};

export default ProfilesList;
