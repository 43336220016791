import { useState, useEffect } from "react";
import { Link, useNavigate } from 'react-router-dom';
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
// Material Dashboard 2 React components
import MDBox from "common/components/MD/MDBox";
import MDButton from "common/components/MD/MDButton";

import MDTypography from "common/components/MD/MDTypography";


// Material Dashboard 2 React example components
import DashboardLayout from "layouts/DashboardLayout";
import DashboardNavbar from "layouts/components/Navbars/DashboardNavbar";
import Footer from "layouts/components/Footer";
import HeaderForm from "layouts/components/HeaderForm";

//prop-types is a library for typechecking of props
// Data
import { useManifestazione } from "common/context/GlobalContext";
import DefaultInfoCard from "common/components/implemented/Cards/InfoCards/DefaultInfoCard";
import ExpoPartecipazioniService from "services/API/expo_partecipazioni/ExpoPartecipazioniService";
import base64ToArrayBuffer from "common/script/base64ToArrayBuffer";




function DocumentiFiera() {



  const { manifestazione } = useManifestazione();
 
 const navigate = useNavigate();
  const [part, setPart] = useState({
});

const stringa=  (<MDTypography
variant="button"
fontWeight="regular"
color="text"
sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
>
Stampa il contratto e ricaricalo firmato in tutti i suoi punti
<MDButton  onClick={() =>   navigate("/contratto")} color="success">Vai</MDButton>
</MDTypography>);

const[contratto,setContratto]=useState(stringa);
const modulo_a=(
  <Link to="https://far.umbriafiere.it/download/A-Montaggio_Stand.pdf" target="_blank">
  <MDButton variant="outlined" color="info"  >
  A-Montaggio Stand
                                                 </MDButton>
                                                 
                                                 </Link>

);

const modulo_b=(
  <Link to="https://far.umbriafiere.it/download/B-Dichiarazione_Corretto_Montaggio_Stand.pdf" target="_blank">
  <MDButton variant="outlined" color="info"  >
  B-Dichiarazione Corretto Montaggio Stand
                                                 </MDButton>
                                                 
                                                 </Link>
);
const modulo_c=(
  <Link to="https://far.umbriafiere.it/download/C-Prevenzione_Incendi_Allestimenti.pdf" target="_blank">
  <MDButton variant="outlined" color="info"  >
  C-Prevenzione Incendi Allestimenti
                                                 </MDButton>
                                                 
                                                 </Link>

);

const modulo_d=(
  <Link to="https://far.umbriafiere.it/download/D-Dichiarazione_di_conformita.pdf" target="_blank">
  <MDButton variant="outlined" color="info"  >
  D-Dichiarazione di conformità
                                                 </MDButton>
                                                 
                                                 </Link>
);
const modulo_e=(
  <Link to="https://far.umbriafiere.it/download/E-Relazione_Tipologia_dei_Materiali.pdf" target="_blank">
  <MDButton variant="outlined" color="info"  >
  E-Relazione Tipologia dei Materiali
                                                 </MDButton>
                                                 
                                                 </Link>

);

const modulo_f=(
  <Link to="https://far.umbriafiere.it/download/F-OSA.pdf" target="_blank">
  <MDButton variant="outlined" color="info"  >
  F-OSA
                                                 </MDButton>
                                                 
                                                 </Link>
);

useEffect(() => {
      
  seleziona_soggetto();

}, []);

async function seleziona_soggetto() {
  console.log("Cambiata pp->seleziono i dettagli :"+manifestazione);

      const response = await ExpoPartecipazioniService.seleziona_partecipazione(manifestazione);
      response.errors ? console.log(response.errors) : retrive(response.data);
 
}
function retrive(data) {

  setPart({
      idPartecipazione: data.idPartecipazione,
      id_expo_richiesta:data.id_expo_richiesta,
      id_utente: data.id_utente,
      nome_pdf_contratto:data.nome_pdf_contratto
  });

  console.log(":::"+data.nome_pdf_contratto);

if(data.nome_pdf_contratto!=null && !data.nome_pdf_contratto==""){
  setContratto( (
    <MDTypography
      variant="button"
      fontWeight="regular"
      color="text"
      sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
      onClick={() => vedi_contratto(data.idPartecipazione)}
    >
      {data.nome_pdf_contratto}
    </MDTypography>
  ));}

  console.log("selezionata  " + part.idPartecipazione);

}

async function vedi_contratto(idPartecipazione) {

  ExpoPartecipazioniService.download_pdf_contratto(idPartecipazione)
    .then(response => {

      return response.data;
    })
    .then(blob => {

      var arrrayBuffer = base64ToArrayBuffer(blob); //data is the base64 encoded string
      var blobb = new Blob([arrrayBuffer], { type: "application/pdf" });
      var url = URL.createObjectURL(blobb);
      var a = document.createElement('a');
      a.href = url;
      a.target = "_blank";

      a.download = part.nome_pdf_contratto;
      ;

      document.body.appendChild(a); // append the element to the dom
      a.click();
      a.remove(); // afterwards, remove the element  
    })
    .catch(error => {
      console.error(error);
    });




}


  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>

          <Grid item xs={12}>
            <Card>
              <HeaderForm titolo={"I miei documenti per " + manifestazione} />
              <MDBox p={10}>

                <DefaultInfoCard
                  color="success"
                  icon="drawicon"
                  title="Contratto"
                  description={"Il Contratto di partecipazione a "+ manifestazione+" è da firmare e ricaricare entro il 19 Gennaio 2025."}
                  value={contratto}
                />
              </MDBox>

              <MDBox p={10} sx={{ display: 'grid', gap:10, gridTemplateColumns: '1fr 1fr' }}>

<DefaultInfoCard
  color="info"
  icon="drawicon"
  title={modulo_a}
  description={"Scarica il file, compilalo e invialo per email a info@agriumbria.eu."}
  value=""
/>
<DefaultInfoCard
  color="info"
  icon="drawicon"
  title={modulo_b}
  description={"Scarica il file, compilalo e invialo per email a info@agriumbria.eu."}
  value=""
/>
<DefaultInfoCard
  color="info"
  icon="drawicon"
  title={modulo_c}
  description={"Scarica il file, compilalo e invialo per email a info@agriumbria.eu."}
  value=""
/>
<DefaultInfoCard
  color="info"
  icon="drawicon"
  title={modulo_d}
  description={"Scarica il file, compilalo e invialo per email a info@agriumbria.eu."}
  value=""
/>
<DefaultInfoCard
  color="info"
  icon="drawicon"
  title={modulo_e}
  description={"Scarica il file, compilalo e invialo per email a info@agriumbria.eu."}
  value=""
/>
<DefaultInfoCard
  color="info"
  icon="filedownloadicon"
  title={modulo_f}
  description={"Scarica il file, compilalo e invialo per email a info@agriumbria.eu."}
  value=""
/>
</MDBox>


            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}



export default DocumentiFiera;
