import { useState, useEffect, useMemo } from "react";

// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// Material Dashboard 2 React themes
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "common/components/MD/MDBox";

// Material Dashboard 2 React example components
import Sidenav from "common/components/implemented/Sidenav";
import Configurator from "common/components/implemented/Configurator";

import Register from "pages/registrazione/Register";
import ExpoRegister from "pages/registrazione/ExpoRegister";
import ExpoActivation from "pages/registrazione/ExpoActivation";
import SignIn from "pages/login/LoginComponent.js";
import RequestChangePassword from "pages/registrazione/RequestChangePassword"
import ResetPasswordFromEmail from "pages/registrazione/ResetPasswordFromEmail"
import ForbiddenComponent from "pages/errors/403";
import NotFoundComponent from "pages/errors/404"
import VerificationErrorComponent from "pages/errors/verificationError.js";


// Material Dashboard 2 React themes
import theme from "common/assets/theme";


// Material Dashboard 2 React Dark Mode themes
import themeDark from "common/assets/theme-dark";


// RTL plugins
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";

// Material Dashboard 2 React routes
import routesExpo from "routes";
import routesAdmin from "routesAdmin";

// Material Dashboard 2 React contexts
import { useMaterialUIController, setMiniSidenav, setOpenConfigurator } from "common/context";

// Images
import brandWhite from "common/assets/images/logo-ct.png";
import brandDark from "common/assets/images/logo-ct-dark.png";
import AuthService from 'services/API/Auth/AuthService.js';
import './App.css';
import AnagraficaEdit from "pages/riservata/anagrafica/AnagraficaEdit";
import AnagraficaExists from "pages/riservata/anagrafica/AnagraficaExists";
import LocalitaEdit from "pages/riservata/utilita/localita/LocalitaEdit";
import UtentiEdit from "pages/riservata/utenti/UtentiEdit";
import ExpoRichiesteEditStep1 from "pages/riservata/expo_richieste/ExpoRichiesteEditStep1";
import ExpoRichiesteStart from "pages/riservata/expo_richieste/ExpoRichiesteStart";
import ExpoRichiesteEditStep2 from "pages/riservata/expo_richieste/ExpoRichiesteEditStep2";
import ExpoRichiestaConfirm from "pages/riservata/expo_richieste/ExpoRichiestaConfirm";
import ExpoRichiestaPDFVis from "pages/riservata/expo_richieste/ExpoRichiestePDFVis";
import ManifestazioneEdit from "pages/riservata/utilita/manifestazioni/ManifestazioneEdit";
import Test from "pages/test/test.js";

function App() {
  const [controller, dispatch] = useMaterialUIController();
 // console.log("APP");
  const {
    miniSidenav,
    direction,
    layout,
    openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [rtlCache, setRtlCache] = useState(null);
  const { pathname } = useLocation();

  const isAdmin = AuthService.isAdmin();
 // console.log(" APP isadmin=" + isAdmin);
  const [myroutes, setMyroutes] = useState([]);


  useEffect(() => {

 //   console.log("on load isadmin=" + isAdmin);
    if (isAdmin)
      setMyroutes(routesAdmin);
    else
      setMyroutes(routesExpo);

   // console.log(myroutes);
  }, [isAdmin])


  // Cache for the rtl
  useMemo(() => {
    const cacheRtl = createCache({
      key: "rtl",
      stylisPlugins: [rtlPlugin],
    });

    setRtlCache(cacheRtl);
    // console.log(cacheRtl);
  }, []);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {

    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>

    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });

  const configsButton = (
    <MDBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3.25rem"
      height="3.25rem"
      bgColor="white"
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      right="2rem"
      bottom="2rem"
      zIndex={99}
      color="dark"
      sx={{ cursor: "pointer" }}
      onClick={handleConfiguratorOpen}
    >
      <Icon fontSize="small" color="inherit">
        settings
      </Icon>
    </MDBox>
  );
  function PrivateRoute({ children }) {
    const isLoggedIn = AuthService.isLoggedIn()
    return isLoggedIn ? children : <Navigate to="/forbidden" />;
  }
  return (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <CssBaseline />
      {layout === "dashboard" && (
        <>
          <Sidenav
            color={sidenavColor}
            brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
            brandName={isAdmin ? "FARFIERA ADMIN" : "FARFIERA"}
            routes={myroutes}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
          />
          <Configurator />
          {/*configsButton*/}
        </>
      )}
      {layout === "vr" && <Configurator />}
      <Routes>
        {getRoutes(myroutes)}
        <Route path="/login" element={<SignIn />} />
        <Route path="/" exact element={<Navigate to="/login" />} />
        <Route path="/register" exact element={<Register />} />
        <Route path="/expo_register" exact element={<ExpoRegister />} />
        <Route path="/attivazione" element={<ExpoActivation />} />
        <Route path="/request_change_pwd" element={<RequestChangePassword />} />
        <Route path="/reset_pw_from_email" element={<ResetPasswordFromEmail />} />
        <Route path="/expo_richiestaEdit1" exact key="ExpoRichiestaEdit1" element={<PrivateRoute><ExpoRichiesteEditStep1 /></PrivateRoute>} />
        <Route path="/expo_richiestaEdit2" exact key="ExpoRichiestaEdit2" element={<PrivateRoute><ExpoRichiesteEditStep2 /></PrivateRoute>} />
        <Route path="/expo_richiesteStart" exact key="expo_richiesteStart" element={<PrivateRoute><ExpoRichiesteStart /></PrivateRoute>} />
        <Route path="/expo_richiestaConfirm" exact key="expo_richiestaConfirm" element={<PrivateRoute><ExpoRichiestaConfirm /></PrivateRoute>} />
        <Route path="/expo_richiestaPDF" exact key="expo_richiestaPDF" element={<PrivateRoute><ExpoRichiestaPDFVis /></PrivateRoute>} />
        <Route path="/OperatoriEdit" key="Operatore" element={<PrivateRoute><UtentiEdit /></PrivateRoute>} />
        <Route path="/anagEdit" key="Anagrafica" element={<PrivateRoute><AnagraficaEdit /></PrivateRoute>} />
        <Route path="/localitaEdit" key="locEdit" element={<PrivateRoute><LocalitaEdit /></PrivateRoute>} />
        <Route path="/manifestazioneEdit" key="manEdit" element={<PrivateRoute><ManifestazioneEdit /></PrivateRoute>} />
        <Route path="/anagExists" key="Anagrafica" element={<PrivateRoute><AnagraficaExists /></PrivateRoute>} />
        <Route path="/forbidden" element={<ForbiddenComponent />} />
        <Route path="/verification-error" element={<VerificationErrorComponent />} />
        <Route path="/test" element={<Test />} />
        <Route path="/*" element={<NotFoundComponent />} />
      </Routes>
    </ThemeProvider>
  );
}

export default App;
