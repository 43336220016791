
import { createContext, useContext, useEffect } from "react";
import React, { useState } from "react";
import UtilityService from "services/API/utility/UtilityService";

// Crea il contesto
const ManifestazioneContext = createContext();
const ManifestazioneProvider = ({ children }) => {
 
  const [lista_man,setLista_man]=useState([
    { nome_manifestazione: '', iscrizioniAperte: false, pdf_regolamento: '' },
  ]);
  const [manifestazione, setManifestazione] = useState("Agriumbria 2025");


    useEffect(() => {

      console.log("RIEMPO MANIFESTAZIONI");

        UtilityService.getAllManifestazioni()
          .then(response => {
            if (response.errors) {
              console.log(response.errors);
            } else {
              const data = response.data; // Supponiamo che l'API restituisca un array di opzioni
              console.log("lista man=");

              setLista_man(data);
      
              // Imposta il valore selezionato alla prima opzione (o un valore specifico)
              if (data.length > 0) {
                console.log(data);
    
                setManifestazione(data.find(option => option.attiva === true)?.nome_manifestazione || data[0].nome_manifestazione); // Sostituisci `value` con il campo corretto dell'oggetto opzione
              
              }
            
            }
          })
          .catch(e => {
            console.log(e);
          });
      }, []);



    return (
        <ManifestazioneContext.Provider value={{ manifestazione, setManifestazione,lista_man}}>
            {children}
        </ManifestazioneContext.Provider>
    );
};

// Hook personalizzato per usare il contesto
const useManifestazione = () => useContext(ManifestazioneContext);

export {
  ManifestazioneProvider,

    useManifestazione
};
