/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-routers components
import { Link } from "react-router-dom";

// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "common/components/MD/MDBox";
import MDButton from "common/components/MD/MDButton";
import MDTypography from "common/components/MD/MDTypography";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import ComunicazioniService from "services/API/Comunicazioni/ComunicazioniService";
import base64ToArrayBuffer from "common/script/base64ToArrayBuffer";
import DeleteIcon from '@mui/icons-material/Delete';

function AllegatiList({ title, allegati, shadow,onDel }) {

 

  async function download_pdf_allegato(idAllegato) {

    ComunicazioniService.download_allegato(idAllegato)
        .then(response => {

            return response.data;
        })
        .then(blob => {

            var arrrayBuffer = base64ToArrayBuffer(blob); //data is the base64 encoded string
            var blobb = new Blob([arrrayBuffer], { type: "application/pdf" });
            var url = URL.createObjectURL(blobb);
            var a = document.createElement('a');
            a.href = url;
            a.target = "_blank";
           
                a.download =allegati.find((m)=>m.idAllegato==idAllegato).nome_allegato;
         ;
        
            document.body.appendChild(a); // append the element to the dom
            a.click();
            a.remove(); // afterwards, remove the element  
        })
        .catch(error => {
            console.error(error);
        });




}





  const renderAllegato =  allegati.map(({ idAllegato,nome_allegato, descrizione, file_allegato,nome_file, action }) => (
    <MDBox key={idAllegato} component="li" display="flex" alignItems="center" py={1} mb={1}>
      <MDBox mr={2}>
        {/*<MDAvatar src={image} alt="something here" shadow="md" />*/}
        <AttachFileIcon></AttachFileIcon>
      </MDBox>
      {onDel &&  <MDBox display="flex" flexDirection="column" alignItems="flex-start" justifyContent="center">
        <MDTypography variant="button" fontWeight="medium">
          {nome_allegato}
        </MDTypography>
        <MDTypography variant="caption" color="text">
          {descrizione}
        </MDTypography>
      </MDBox>}
 
        <MDBox ml="auto">
          
        <MDTypography
                                                        variant="button"
                                                        fontWeight="regular"
                                                        color="text"
                                                        sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                                                        onClick={() => download_pdf_allegato(idAllegato)}
                                                    >
                                                        {nome_file}
                                                    </MDTypography>
        </MDBox>
        <MDBox ml="auto">
       
       {onDel && 
        <MDButton onClick={() => onDel(idAllegato)}
           variant="gradient" color="success"
          >
            <DeleteIcon></DeleteIcon>
            
          </MDButton>
}
      </MDBox> 
    </MDBox>
  ));

  return (

    <Card sx={{ height: "100%", boxShadow: !shadow && "none" }}>
      <MDBox pt={2} px={2}>
        <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          {title}
        </MDTypography>
      </MDBox>
    
      <MDBox p={2}>
        <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
          {renderAllegato}
        </MDBox>
      </MDBox>




    </Card>







  );
}

// Setting default props for the ProfilesList
AllegatiList.defaultProps = {
  shadow: true,
};

// Typechecking props for the ProfilesList
AllegatiList.propTypes = {
  title: PropTypes.string.isRequired,
  allegati0: PropTypes.arrayOf(PropTypes.object),
  shadow: PropTypes.bool,
};

export default AllegatiList;
