import axios from 'axios';
import authHeader from 'services/API/Auth/AuthHeader';

const getAllAnagrafiche=()=> {
    
    return axios.get(axios.defaults.baseURL + `/anagrafica/all`,{headers: authHeader()});
}
const getAllEspositori=()=> {
    console.log("getAllEspositori");
    //console.log(authHeader());
    return axios.get(axios.defaults.baseURL + `/anagrafica/allEspositori`,{headers: authHeader()});
}
const getAllOperatori=()=> {
    console.log("getAllOperatori");
    console.log(authHeader());
    return axios.get(axios.defaults.baseURL + `/anagrafica/allOperatori`,{headers: authHeader()});
}
const getAllVisitatori=()=> {
    console.log("getAllVisitatori");
    console.log(authHeader());
    return axios.get(axios.defaults.baseURL + `/anagrafica/allVisitatori`,{headers: authHeader()});
}
const getLastVisitatori=()=> {
    console.log(authHeader());
    return axios.get(axios.defaults.baseURL +`/anagrafica/last`,{headers: authHeader()});
}

const getAllProfessioni=()=> {
    console.log(authHeader());
      return axios.get(axios.defaults.baseURL + `/professioni/getAll`);
}
const create = (data) => {   
    return axios.post(axios.defaults.baseURL + '/anagrafica/ins',data, {
        headers: authHeader()
    })  
   };
   const update = (id, data) => {
    console.log("update= "+id);
    return axios.post(axios.defaults.baseURL + `/anagrafica/upd/${id}`, data,{headers: authHeader()});
  };

  const remove = (id) => {
    console.log("remove= "+id);
    return axios.post(axios.defaults.baseURL + `/anagrafica/_remove/${id}`,null,{headers: authHeader()});
  };

  const createOP = (data) => {   
    return axios.post(axios.defaults.baseURL + '/anagrafica/insOperatore',data, {
        headers: authHeader()
    })  
   };
   const updateOP = (id, data) => {
    console.log("update= "+id);
    return axios.post(axios.defaults.baseURL + `/anagrafica/updOperatore/${id}`, data,{headers: authHeader()});
  };
  const createV = (data) => {   
    return axios.post(axios.defaults.baseURL + '/anagrafica/insVisitatore',data, {
        headers: authHeader()
    })  
   };
   const updateV = (id, data) => {
    console.log("update= "+id);
    return axios.post(axios.defaults.baseURL + `/anagrafica/updVisitatore/${id}`, data,{headers: authHeader()});
  };



const controlla_email = (email) =>{

    axios.get(axios.defaults.baseURL + `/anagrafica/checkemail/${email}`,{headers: authHeader()}).then(response => {
        if(response.statusText!== "OK"){
            return "KO";
        }
        else{
            return "OK";
        }
    })
    .catch(errore => {
        return "KO";
    });;

};

const controlla_azienda  = (azienda) => { 

    console.log("controllo nome azienda axios");
 const res=axios.get(axios.defaults.baseURL + `/anagrafica/checkazienda/${azienda}`,{headers: authHeader()})
 console.log("controllo nome azienda axios");
 return res;

};
const seleziona_dattagli =(id)=>{

    console.log("seleziona_dettagli= "+id);
    return axios.get(axios.defaults.baseURL + `/anagrafica/seleziona_dettagli/${id}`,{headers: authHeader()});

}
const esporta_xls_row=(righe)=>{

    const response= axios.post(axios.defaults.baseURL + `/anagrafica/excel_row`, righe,{headers: authHeader()}, {responseType: 'blob'});

    return response;

}
const AnagraficaService = {
    getAllAnagrafiche,
    getAllOperatori,
    getAllVisitatori,
    getLastVisitatori,
    getAllProfessioni,
   
    getAllEspositori,
    create,
    update,
    remove,
    createOP,
    updateOP,
    createV,
    updateV,
    controlla_email,
    controlla_azienda,
    seleziona_dattagli,esporta_xls_row
};

export default AnagraficaService;