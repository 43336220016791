import React, { useState } from 'react';

export const  Messaggio = {

   idMessaggio: 0, 
   id_utente: 0, 
   destinatario:'',
   id_proposta: 0, 
   protocollo_proposta:0,
   oggetto: '', 
   testo: '', 
   testo_email_notifica: '', 
   destinatario_email_notifica: '', 
   allegati: [],
   inviato: false, 
   accettazione: false,
   string_data_ins: '', 
   string_data_invio: '', 
 
 };



export default Messaggio;