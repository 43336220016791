import { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from 'react-router-dom'
import DashboardLayout from "layouts/DashboardLayout";
import DashboardNavbar from "layouts/components/Navbars/DashboardNavbar";
import Footer from "layouts/components/Footer";
import MDBox from "common/components/MD/MDBox";
import MDTypography from "common/components/MD/MDTypography";
import MDInput from "common/components/MD/MDInput";
import MDButton from "common/components/MD/MDButton";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Alert from '@mui/material/Alert';

import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import ReactHookFormCheckBox from "common/components/mycomponents/ReactHookFormCheckBox"
import HeaderForm from "layouts/components/HeaderForm";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


//validazione lato client
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

// Data
import ComunicazioniService from "services/API/Comunicazioni/ComunicazioniService";
import base64ToArrayBuffer from "common/script/base64ToArrayBuffer";
import ReactHookFormSelect from "common/components/mycomponents/ReactHookFormSelect";
import { MenuItem } from "@mui/material";

function ModelloComunicazioneEdit() {

    const location = useLocation();
    const navigate = useNavigate();

    const { modo, soggetto } = location.state;

    // user state for form
    const [modelloC, setModelloC] = useState({
        idModello: 0, nome_modello: '', oggetto: '', testo: '', allegato1: '', nome_allegato1: '',
        allegato2: '', nome_allegato2: '', allegato3: '', nome_allegato3: '', testo_email_notifica: '', destinatario_email_notifica: '', accettazione: false
    });

    const [open, setOpen] = useState(false);
    const [confirm, setConfirm] = useState(false);



    const validationSchema = Yup.object().shape({
        nome_modello: Yup.string().required('Nome richiesto').max(70, 'Nome non deve superare 70 caratteri'),

        nome_allegato1:Yup.string() .nullable() // Permette il valore nullo
        .test(
          'file-extension',
          'Il file deve avere estensione .pdf',
          value => !value || value.endsWith('.pdf') // Passa il test se è nullo o termina con .pdf
        ),
        allegato1: Yup.mixed().nullable()
        .test("fileSize", "The file is too large", (value) => {
            return value == null || value[0].size <= 2000000;
        })
        .test("type", "Only the following formats are accepted: .pdf ", (value) => {
            return (
                value == null || value[0].type === 'application/pdf');
        }),
        /* pdf_regolamento: Yup.mixed().when("$isPresent", {
             is: (val) => {
                 return modo === 'new';
             },
             then: (s) => s.required('VALIDATION_FIELD_REQUIRED')
                             .test("fileSize", "The file is too large", (value) => {
                                 return value && value[0].size <= 2000000;
                             })
                             .test("type", "Only the following formats are accepted: .pdf ", (value) => {
                                 return value && (
                                     value[0].type === 'application/pdf'
                                 );
                             }),
             otherwise: (s) => s,
         }),*/

/*  */
    });


    useEffect(() => {
        if (soggetto != null) {
            console.log("soggetto non nullo: id=" + soggetto.idModello);
            retrive_soggetto();
        } else {
            console.log("soggetto nullo");
        }
    }, [soggetto]);

    async function retrive_soggetto() {
        console.log("Cambiato modello->seleziono i dettagli :" + soggetto.idModello);
        if (soggetto.idModello != null) {
            const response = await ComunicazioniService.seleziona_dettagli_modello(soggetto.idModello);
            //  console.log(response.data);
            response.errors ? console.log(response.errors) : retrive(response.data);


        }
    }

    function retrive(data) {
        console.log("RETRIVE =" + data.idModello);

        setModelloC({
            idModello: data.idModello, nome_modello: data.nome_modello, oggetto: data.oggetto,
            testo: data.testo, allegato1: data.allegato1, nome_allegato1: data.nome_allegato1,
            allegato2: data.allegato2, nome_allegato2: data.nome_allegato2, allegato3: data.allegato3,
            nome_allegato3: data.nome_allegato3, testo_email_notifica: data.testo_email_notifica,
            destinatario_email_notifica: data.destinatario_email_notifica, accettazione: data.accettazione
        })

        console.log("selezionata  " + data.idModello);

    }



    // effect runs when user state is updated
    useEffect(() => {
        // reset form with user data
        setValue("nome_modello", modelloC.nome_modello);
        setValue("oggetto", modelloC.oggetto);
        setValue("testo", modelloC.testo);
        setValue("allegato1", modelloC.allegato1);
        setValue("nome_allegato1", modelloC.nome_allegato1);
        setValue("allegato2", modelloC.allegato2);
        setValue("nome_allegato2", modelloC.nome_allegato2);
        setValue("allegato3", modelloC.allegato3);
        setValue("nome_allegato3", modelloC.nome_allegato3);
        setValue("testo_email_notifica", modelloC.testo_email_notifica);
        setValue("destinatario_email_notifica", modelloC.destinatario_email_notifica);
        setValue("accettazione", modelloC.accettazione);
    }, [modelloC]);


    const {
        register, //register inputs
        reset, setValue, control,
        handleSubmit,//handle form submit
        formState: { errors }
    } = useForm({
        resolver: yupResolver(validationSchema),
    });


    //sono quelli lato server
    const [error, setError] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);


    const insert = (formData) => {
        setError(null);

        console.log( "::::"+formData.accettazione+"::::");
        console.log( "::::"+formData.destinatario_email_notifica+"::::");

        var data = {
            nome_modello: formData.nome_modello, oggetto: formData.oggetto,
            testo: formData.testo, 
            allegato1: formData.allegato1, nome_allegato1: formData.nome_allegato1,
            allegato2: formData.allegato2, nome_allegato2: formData.nome_allegato2, allegato3: formData.allegato3,
            nome_allegato3: formData.nome_allegato3, testo_email_notifica: formData.testo_email_notifica,
            destinatario_email_notifica: formData.destinatario_email_notifica, accettazione: formData.accettazione


        };
        if(data.allegato1==='') data.allegato1=null;
        if(data.allegato2==='') data.allegato2=null;
        if(data.allegato3==='') data.allegato3=null;

        if (modo === 'new') {
            console.log("Inserisci ");
            ComunicazioniService.createModello(data)
                .then(response => {
                    handleResponse(response, 'INS');
                })
                .catch(errore => {
                    handleError(errore);
                });
        } else {
            console.log("update modello "+data.nome_modello);
            ComunicazioniService.updateModello(modelloC.idModello, data)
                .then(response => {
                    handleResponse(response, 'MOD');
                })
                .catch(errore => {
                    handleError(errore);
                });

        }
    };

    function handleClose() {

        setOpen(false);
    }
    function handleConfirm() {

        setConfirm(true);


        setOpen(false);

        deleteLoc();
    }

    const handleOpen = () => {
        console.log("OPEN DIALOG");
        setOpen(true);
    }

    const deleteLoc = () => {
        setError(null);

        if (modo === 'edit') {

            console.log("delete loc");
            ComunicazioniService.remove(modelloC.idModello)
                .then(response => {
                    console.log("handleResponse");
                    handleResponse(response, 'DEL');
                })
                .catch(errore => {
                    console.log("handleError");
                    handleError(errore);
                });


        }
    };


    function handleResponse(response, op) {
        console.log(response.data);
        navigate(`/modelliC`, { state: { submitted: true, salvato: op } });
    }

    function handleError(error) {
        setError(error);
        console.log("ERRORE" + error);
        console.log(error.code);

        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log("ERROR:RESPONSE" + error.response.data);
            setErrorMessage(error.response.data);

        } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.log("ERROR:REQUEST" + error.request);
            setErrorMessage(error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message);
            setErrorMessage(error.message);
        }

    }

    const annulla = () => {
        console.log("ANNULLA");
        navigate("/modelliC");

    }

    const download_pdf_allegato1 = () => {
        download_pdf("ALLEGATO 1");

    }
    const download_pdf_allegato2 = () => {
        download_pdf("ALLEGATO 2");

    }
    const download_pdf_allegato3 = () => {
        download_pdf("ALLEGATO 3");

    }
    async function download_pdf(tipo_file) {



        ComunicazioniService.seleziona_file(modelloC.idModello, tipo_file)
            .then(response => {

                return response.data;
            })
            .then(blob => {

                var arrrayBuffer = base64ToArrayBuffer(blob); //data is the base64 encoded string
                var blobb = new Blob([arrrayBuffer], { type: "application/pdf" });
                var url = URL.createObjectURL(blobb);
                var a = document.createElement('a');
                a.href = url;
                a.target = "_blank";
                if (tipo_file === "ALLEGATO 1")
                    a.download = modelloC.nome_allegato1;
                if (tipo_file === "ALLEGATO 2")
                    a.download = modelloC.nome_allegato2;
                if (tipo_file === "ALLEGATO 3")
                    a.download = modelloC.nome_allegato3;
                document.body.appendChild(a); // append the element to the dom
                a.click();
                a.remove(); // afterwards, remove the element  
            })
            .catch(error => {
                console.error(error);
            });




    }




    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" >
                    <DialogTitle id="alert-dialog-title">
                        Eliminare questo record?
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Sei sicuro di voler eliminare questo record?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <MDButton onClick={handleClose}>No</MDButton>
                        <MDButton onClick={handleConfirm} autoFocus>
                            SI
                        </MDButton>
                    </DialogActions>
                </Dialog>



                <form >
                    <Grid container spacing={6}>
                        <Grid item xs={12}>
                            <Card>
                                <HeaderForm titolo="Modelli Comunicazioni" save={handleSubmit(insert)} remove={handleOpen} exit={annulla} modo={modo} />


                                {error !== null &&
                                    <Alert color="error"><ErrorOutlineOutlinedIcon fontSize="small" />&nbsp;{errorMessage}</Alert>
                                }
                                <MDBox pt={4} pb={3} px={3}>
                                    <MDBox component="form" role="form">
                                        <Grid container spacing={3}>
                                            <Grid item xs={12} sm={12} mb={2}>

                                                <MDInput type="text" label="Nome Modello" fullWidth name="nome_modello"
                                                    {...register('nome_modello')} autocomplete="off"

                                                    error={errors.nome_modello ? true : false} InputLabelProps={{ shrink: true }} />
                                                {
                                                    errors.nome_modello ? <span style={{ color: 'red', fontSize: '12px' }}>{errors.nome_modello?.message}</span> : ''
                                                }
                                            </Grid>
                                            <Grid item xs={12} sm={12} mb={2}>

                                                <MDInput type="text" label="Oggetto Comunicazione" fullWidth name="oggetto"
                                                    {...register('oggetto')} autocomplete="off"

                                                    error={errors.oggetto ? true : false} InputLabelProps={{ shrink: true }} />
                                                {
                                                    errors.oggetto ? <span style={{ color: 'red', fontSize: '12px' }}>{errors.oggetto?.message}</span> : ''
                                                }
                                            </Grid>
                                            <Grid item xs={12} sm={12} mb={2}>

                                                <MDInput type="text" label="Testo dell comunicazione" fullWidth required
                                                    name='testo' {...register('testo')} multiline rows={3} InputLabelProps={{ shrink: true }}
                                                    error={errors.testo ? true : false} />
                                                {errors.testo ? <span style={{ color: 'red', fontSize: '12px' }}>{errors.testo?.message}</span> : ''}
                                            </Grid>
                                            <Grid item xs={12} sm={12} mb={2} sx={{ display: 'grid', gap: 1, gridTemplateColumns: '1fr 1fr 1fr' }}>
                                                <MDBox>
                                                    <MDInput
                                                        type="file"
                                                        label="Allegato 1" fullWidth id="allegato1"
                                                        autocomplete="off"  {...register('allegato1')}
                                                        name="allegato1" InputLabelProps={{ shrink: true }}
                                                    />
                                                    {
                                                        errors.allegato1 ? <span style={{ color: 'red', fontSize: '12px' }}>{errors.allegato1?.message}</span> : ''
                                                    }
                                                </MDBox>
                                                <MDBox>
                                                    <MDTypography
                                                        variant="button"
                                                        fontWeight="regular"
                                                        color="text"
                                                        sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                                                        onClick={download_pdf_allegato1}
                                                    >
                                                        {modelloC.nome_allegato1}
                                                    </MDTypography>
                                                </MDBox>
                                                <MDBox>

                                                    <MDInput type="text" label="Nome Allegato 1" fullWidth name="nome_allegato1"
                                                        {...register('nome_allegato1')} autocomplete="off"

                                                        error={errors.nome_allegato1 ? true : false} InputLabelProps={{ shrink: true }} />
                                                    {
                                                        errors.nome_allegato1 ? <span style={{ color: 'red', fontSize: '12px' }}>{errors.nome_allegato1?.message}</span> : ''
                                                    }
                                                </MDBox>
                                            </Grid>

                                            <Grid item xs={12} sm={12} mb={2} sx={{ display: 'grid', gap: 1, gridTemplateColumns: '1fr 1fr 1fr' }}>
                                                <MDBox>
                                                    <MDInput
                                                        type="file"
                                                        label="Allegato 2" fullWidth id="allegato2"
                                                        autocomplete="off"  {...register('allegato2')}
                                                        name="allegato1" InputLabelProps={{ shrink: true }}
                                                    />
                                                    {
                                                        errors.allegato2 ? <span style={{ color: 'red', fontSize: '12px' }}>{errors.allegato2?.message}</span> : ''
                                                    }
                                                </MDBox>
                                                <MDBox>
                                                    <MDTypography
                                                        variant="button"
                                                        fontWeight="regular"
                                                        color="text"
                                                        sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                                                        onClick={download_pdf_allegato2}
                                                    >
                                                        {modelloC.nome_allegato2}
                                                    </MDTypography>
                                                </MDBox>
                                                <MDBox>

                                                    <MDInput type="text" label="Nome Allegato 2" fullWidth name="nome_allegato2"
                                                        {...register('nome_allegato2')} autocomplete="off"

                                                        error={errors.nome_allegato2 ? true : false} InputLabelProps={{ shrink: true }} />
                                                    {
                                                        errors.nome_allegato2 ? <span style={{ color: 'red', fontSize: '12px' }}>{errors.nome_allegato2?.message}</span> : ''
                                                    }
                                                </MDBox>
                                            </Grid>

                                            <Grid item xs={12} sm={12} mb={2} sx={{ display: 'grid', gap: 1, gridTemplateColumns: '1fr 1fr 1fr' }}>
                                                <MDBox>
                                                    <MDInput
                                                        type="file"
                                                        label="Allegato 3" fullWidth id="allegato3"
                                                        autocomplete="off"  {...register('allegato3')}
                                                        name="allegato3" InputLabelProps={{ shrink: true }}
                                                    />
                                                    {
                                                        errors.allegato3 ? <span style={{ color: 'red', fontSize: '12px' }}>{errors.allegato3?.message}</span> : ''
                                                    }
                                                </MDBox>
                                                <MDBox>
                                                    <MDTypography
                                                        variant="button"
                                                        fontWeight="regular"
                                                        color="text"
                                                        sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                                                        onClick={download_pdf_allegato3}
                                                    >
                                                        {modelloC.nome_allegato3}
                                                    </MDTypography>
                                                </MDBox>
                                                <MDBox>

                                                    <MDInput type="text" label="Nome Allegato 3" fullWidth name="nome_allegato3"
                                                        {...register('nome_allegato3')} autocomplete="off"

                                                        error={errors.nome_allegato3 ? true : false} InputLabelProps={{ shrink: true }} />
                                                    {
                                                        errors.nome_allegato3 ? <span style={{ color: 'red', fontSize: '12px' }}>{errors.nome_allegato3?.message}</span> : ''
                                                    }
                                                </MDBox>
                                            </Grid>          
                                        

                                            <Grid item xs={12} sm={12} mb={2}>

                                                <MDInput type="text" label="Testo email di notifica" fullWidth required
                                                    name='testo_email_notifica' {...register('testo_email_notifica')} multiline rows={3} InputLabelProps={{ shrink: true }}
                                                    error={errors.testo_email_notifica ? true : false} />
                                                {errors.testo_email_notifica ? <span style={{ color: 'red', fontSize: '12px' }}>{errors.testo_email_notifica?.message}</span> : ''}
                                            </Grid>

                                            <Grid item xs={12} sm={12} mb={2}>
                                            <ReactHookFormSelect
                          id="destinatario_email_notifica"
                          name="destinatario_email_notifica"
                          label="Destinatario Email di Notifica"
                          control={control}
                          defaultValue=''
                          variant="outlined"

                        >
                        
                            <MenuItem key='account' value="EMAIL_ACCOUNT">
                             EMAIL ACCOUNT
                            </MenuItem>
                            <MenuItem key='responsabile' value="EMAIL_RESPONSABILE">
                             EMAIL RESPONSABILE
                            </MenuItem>
                            <MenuItem key='azienda' value="EMAIL_AZIENDA">
                             EMAIL AZIENDALE
                            </MenuItem>

                        </ReactHookFormSelect>
                                                </Grid>



                                            <Grid item xs={12} sm={6} mb={2}>
                                                <ReactHookFormCheckBox
                                                    label={<MDBox>
                                                        <MDTypography
                                                            variant="button"
                                                            fontWeight="regular"
                                                            color="text"
                                                            sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                                                        >
                                                            &nbsp;&nbsp;E' la comunicazione di accettazione/non accettazione della proposta&nbsp;
                                                        </MDTypography>

                                                    </MDBox>}
                                                    name="accettazione"
                                                    control={control} />
                                            </Grid>
                                            <Grid item xs={12} sm={6} mb={2}>

                                            </Grid>
                                            <Grid item xs={12} sm={6} mb={2}>

                                            </Grid>
                                        </Grid>


                                    </MDBox>
                                </MDBox>

                            </Card>
                        </Grid>
                    </Grid>
                </form>
            </MDBox>


            <Footer />
        </DashboardLayout>
    );
}

export default ModelloComunicazioneEdit;