/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Material Dashboard 2 React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/
import { Navigate } from "react-router-dom";
// Material Dashboard 2 React layouts
import AnagraficaEdit from "pages/riservata/anagrafica/AnagraficaEdit";
import AnagraficaExists from "pages/riservata/anagrafica/AnagraficaExists";
import AnagraficaList from "pages/riservata/anagrafica/AnagraficaList";
import ModelloComunicazioniEdit from "pages/riservata/comunicazioni_modelli/ModelloComunicazioneEdit";
import Dashboard from "pages/riservata/Dashboard";
import UtentiEdit from "pages/riservata/utenti/UtentiEdit";
import Utenti from "pages/riservata/utenti/UtentiList";
import ElencoLocalita from "pages/riservata/utilita/localita/ElencoLocalita";
import LocalitaEdit from "pages/riservata/utilita/localita/LocalitaEdit";

import ElencoModelliComunicazioni from "pages/riservata/comunicazioni_modelli/ElencoModelliComunicazioni";
import ExpoRichiestaPDFVis from "pages/riservata/expo_richieste/ExpoRichiestePDFVis";
import ExpoRichiestaAccept from "pages/riservata/expo_richieste_admin/ExpoRichiestaAccept";
import ExpoRichiestaMessage from "pages/riservata/expo_richieste_admin/ExpoRichiestaMessage";
import ElencoRichiesteAdmin from "pages/riservata/expo_richieste_admin/ExpoRichiesteList";
import ElencoRichiesteNAAdmin from "pages/riservata/expo_richieste_admin/ExpoRichiesteNonAccettateList";
import ElencoRichiesteNRAdmin from "pages/riservata/expo_richieste_admin/ExpoRichiesteNonRicevuteList";
import ElencoRichiesteOKAdmin from "pages/riservata/expo_richieste_admin/ExpoRichiesteOKList";
import ManifestazioneEdit from "pages/riservata/utilita/manifestazioni/ManifestazioneEdit";
import ElencoManifestazioni from "pages/riservata/utilita/manifestazioni/ManifestazioniList";
import Test from "pages/test/test.js";
//import RTL from "layouts/rtl";
//import Notifications from "layouts/notifications";
//import Profile from "layouts/profile";

//import SignUp from "layouts/authentication/sign-up";


import AuthService from 'services/API/Auth/AuthService.js';

// @mui icons
import Icon from "@mui/material/Icon";
import EspositoriList from "pages/riservata/anagrafica/EspositoriList";
import OperatoriList from "pages/riservata/anagrafica/OperatoriList";
import VisitatoriList from "pages/riservata/anagrafica/VisitatoriList";
import ElencoMessaggi from "pages/riservata/comunicazioni/ElencoMessaggi";
import MessaggioEdit from "pages/riservata/comunicazioni/MessaggioEdit";
import DatiCatalogoAdminList from "pages/riservata/expo_partecipazioni_admin/DatiCatalogoAdminList";
import ExpoPartecipazioneAssign from "pages/riservata/expo_partecipazioni_admin/ExpoPartecipazioneAssign";
import ExpoPartecipazioniList from "pages/riservata/expo_partecipazioni_admin/ExpoPartecipazioniList";
import ElencoDocumenti from "pages/riservata/utilita/documenti/ElencoDocumenti";
import ExpoPartecipazioniVerify from "pages/riservata/expo_partecipazioni_admin/ExpoPartecipazioniVerify";


//console.log("ROUTEADMIN.JS ");


function PrivateRoute({ children }) {
  const isLoggedIn = AuthService.isLoggedIn();
 // console.log("isLoggedIn="+isLoggedIn);

   
  return isLoggedIn ? children : <Navigate to="/forbidden" />;
}
function AdminRoute({ children }) {
  const isLoggedIn = AuthService.isLoggedIn();
  if(isLoggedIn){
  const isAdmin = AuthService.isAdmin();
  //console.log("isAdmin="+isAdmin);
  return isAdmin ? children : <Navigate to="/forbidden" />;
  }else
  return <Navigate to="/forbidden" />;
}


const routesAdmin = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/welcome",
    component: <PrivateRoute><Dashboard /></PrivateRoute>,
  },
  
  {
    type:"title",
    title: "Anagrafiche",
    key: "anagarfica_menu",
},

  {
    type:"collapse",
    name: "Tutte le anagrafiche",
    key: "anagrafica",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    route: "/anagrafica",
    component: <AdminRoute><AnagraficaList subject={"ALL"} /></AdminRoute>,
  },
  {
    type:"collapse",
    name: "Operatori",
    key: "operatori",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    route: "/operatori",
    component: <AdminRoute><OperatoriList /></AdminRoute>,
  },
  {
    type:"collapse",
    name: "Espositori",
    key: "espositori",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    route: "/espositori",
    component: <AdminRoute><EspositoriList /></AdminRoute>,
  },
  {
    type:"collapse",
    name: "Visitatori",
    key: "visitatori",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    route: "/visitatori",
    component: <AdminRoute><VisitatoriList/></AdminRoute>,
  },
  {
    type: "not-visible", 
    name: "Nuova Anagrafica",
    key: "AnagraficaEdit",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/anagEdit",
    component: <AdminRoute><AnagraficaEdit /></AdminRoute>,
  },
  {
    type: "not-visible",
    name: "Cerca Anagrafica",
    key: "AnagraficaExist",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/anagExists",
    component: <AdminRoute><AnagraficaExists /></AdminRoute>,
  },
  {
    type:  "title",
    title: "Proposte di partecipazione",
    key: "pp_menu",
},
{
  type:"collapse",
  name: "Proposte In compilazione",
  key: "expo_richiesteNRList",
  icon: <Icon fontSize="small">table_view</Icon>,
  route: "/expo_richieste_nr_admin",
  component:<AdminRoute><ElencoRichiesteNRAdmin /></AdminRoute>,
},
{
  type:"collapse",
  name: "Proposte Ricevute",
  key: "expo_richiesteList",
  icon: <Icon fontSize="small">table_view</Icon>,
  route: "/expo_richieste_admin",
  component:<AdminRoute><ElencoRichiesteAdmin /></AdminRoute>,
},
{
  type:"collapse",
  name: "Proposte non Accettate",
  key: "expo_richiesteNAList",
  icon: <Icon fontSize="small">table_view</Icon>,
  route: "/expo_richieste_na_admin",
  component:<AdminRoute><ElencoRichiesteNAAdmin /></AdminRoute>,
},
{
  type:"collapse",
  name: "Proposte Accettate",
  key: "expo_richiesteOKList",
  icon: <Icon fontSize="small">table_view</Icon>,
  route: "/expo_richieste_ok_admin",
  component:<AdminRoute><ElencoRichiesteOKAdmin /></AdminRoute>,
},

{
  type:  "title",
  title: "Espositori",
  key: "ee_menu",
},
{
  type:"collapse",
  name: "Gestione Espositori",
  key: "expo_partecipazioni",
  icon: <Icon fontSize="small">table_view</Icon>,
  route: "/expo_partecipazioni_admin",
  component:<AdminRoute><ExpoPartecipazioniList /></AdminRoute>,
},
{
  type: "not-visible", 
  name: "Confirm ",
  key: "expo_partecipazioneAssign",
  icon: <Icon fontSize="small">table_view</Icon>,
  route: "/expo_partecipazioneAssign",
  component: <AdminRoute><ExpoPartecipazioneAssign /></AdminRoute>,
},


{
  type:"collapse",
  name: "Dati per il catalogo",
  key: "dati_catalogo",
  icon: <Icon fontSize="small">table_view</Icon>,
  route: "/expo_partecipazioni_dc",
  component:<AdminRoute><DatiCatalogoAdminList /></AdminRoute>,
},
{
  type:"collapse",
  name: "Verifica Espositori",
  key: "expo_partecipazioni_verifica",
  icon: <Icon fontSize="small">table_view</Icon>,
  route: "/expo_partecipazioni_verifica",
  component:<AdminRoute><ExpoPartecipazioniVerify /></AdminRoute>,
},
{
  type: "not-visible", 
  name: "Confirm ",
  key: "expo_richiestaAccept",
  icon: <Icon fontSize="small">table_view</Icon>,
  route: "/expo_richiestaAccept",
  component: <AdminRoute><ExpoRichiestaAccept /></AdminRoute>,
},
{
  type: "not-visible", 
  name: "Confirm ",
  key: "expo_richiestaMessage",
  icon: <Icon fontSize="small">table_view</Icon>,
  route: "/expo_richiestaMessage",
  component: <AdminRoute><ExpoRichiestaMessage /></AdminRoute>,
},
{
  type: "not-visible", 
  name: "Confirm ",
  key: "expo_richiestaPDF",
  icon: <Icon fontSize="small">table_view</Icon>,
  route: "/expo_richiestaPDFVis",
  component: <PrivateRoute><ExpoRichiestaPDFVis /></PrivateRoute>,
},
  {
    type: "title",
    title: "Configurazioni",
    key: "utenti_menu",
},
  {
    type:"collapse",
    name: "Lista Utenti",
    key: "utenti",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/utenti",
    component: <AdminRoute><Utenti /></AdminRoute>,
  },
  {
    type: "not-visible",
    name: "Nuovo Utente",
    key: "utentiEdt",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/utentiEdit",
    component: <AdminRoute><UtentiEdit /></AdminRoute>,
  },
  {
    type:"collapse",
    name: "Località",
    key: "localita",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/localita",
    component: <AdminRoute><ElencoLocalita /></AdminRoute>,
  },
  {
    type: "not-visible",
    name: "Nuova Località",
    key: "localitaEdt",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/localitaEdit",
    component: <AdminRoute><LocalitaEdit /></AdminRoute>,
  },
  {
    type: "collapse",
    name: "Manifestazioni",
    key: "manifestazioni",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/manifestazioni",
    component: <AdminRoute><ElencoManifestazioni /></AdminRoute>,
  },
  {
    type: "not-visible",
    name: "Nuova Manifestazione",
    key: "manifestazioneEdit",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/manifestazioneEdit",
    component: <AdminRoute><ManifestazioneEdit /></AdminRoute>,
  },
  {
    type:"collapse",
    name: "Modelli di Comunicazioni",
    key: "mc",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/modelliC",
    component: <AdminRoute><ElencoModelliComunicazioni /></AdminRoute>,
  },
  {
    type: "not-visible",
    name: "Nuovo Modello",
    key: "modelloCEdit",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/modelloCEdit",
    component: <AdminRoute><ModelloComunicazioniEdit /></AdminRoute>,
  },
  {
    type:"collapse",
    name: "Documenti",
    key: "doc",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/documenti",
    component: <AdminRoute><ElencoDocumenti /></AdminRoute>,
  },
  {
    type:"collapse",
    name: "Messaggi",
    key: "msgs",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/messaggi",
    component: <AdminRoute><ElencoMessaggi /></AdminRoute>,
  },
  {
    type: "not-visible",
    name: "Nuovo Messaggio",
    key: "messaggioEdit",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/messaggioEdit",
    component: <AdminRoute><MessaggioEdit /></AdminRoute>,
  },
  {
    type: "not-visible",
    name: "Test",
    key: "test",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/test",
    component: <Test />,
  },
  /*{
    type: "collapse",
    name: "Notifications",
    key: "notifications",
    icon: <Icon fontSize="small">notifications</Icon>,
    route: "/notifications",
    component: <Notifications />,
  },
  {
    type: "collapse",
    name: "Profile",
    key: "profile",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/profile",
    component: <Profile />,
  },
  {
    type: "collapse",
    name: "Sign Up",
    key: "sign-up",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/authentication/sign-up",
    component: <SignUp />,
  },*/
];




export default routesAdmin;
