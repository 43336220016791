import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { Link } from "react-router-dom";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "common/components/MD/MDBox";
import MDButton from "common/components/MD/MDButton";


// Material Dashboard 2 React example components
import DashboardLayout from "layouts/DashboardLayout";
import DashboardNavbar from "layouts/components/Navbars/DashboardNavbar";
import DataTable from "common/components/implemented/Tables/DataTable/DataTables"
import Footer from "layouts/components/Footer";
import HeaderForm from "layouts/components/HeaderForm";
import Alert from '@mui/material/Alert';

// Data
import LocDataService from "services/API/Comuni/ComuniService";
import AllegatoEditModal from "./AllegatoEditModal";
import ComunicazioniService from "services/API/Comunicazioni/ComunicazioniService";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import { Dialog, DialogActions, DialogContentText, DialogTitle } from "@mui/material";
import { DialogContent } from "@mui/material";

function ElencoDocumenti() {

  const navigate = useNavigate();

  const [documenti, setDocumenti] = useState([]);
  const [show, setShow] = useState(false);
  const [soggetto, setSoggetto] = useState(null);

  useEffect(() => {
    console.log("LOAD");
    retrieveDoc();

  }, []);

  //per passarmi la variabiule se ho salvato e far comparire l'alert
  const location = useLocation();
  //console.log(location);

  const { state } = location != null ? location : { state: { submitted: false, salvato: 'NO' } }; // Read values passed on state

  const retrieveDoc = () => {
    ComunicazioniService.getAllAllegati()
      .then(response => {
        setDocumenti(response.data);
      })
      .catch(e => {
        console.log(e);
      });
  };

  const docAttributes = []
  documenti.forEach(doc => {

    docAttributes.push({
      idAllegato: doc.id_allegato,
      nome_allegato: doc.nome_allegato,
      descrizione: doc.descrizione,

      action: (
        <MDBox>
          <MDButton onClick={() => edit_allegato(doc)} variant="outlined" color={"success"} iconOnly={true} >
            <Icon>edit</Icon>

          </MDButton>
          <MDButton onClick={() => handleOpenD(doc)} variant="outlined" color={"success"} iconOnly={true} >
            <DeleteIcon></DeleteIcon>

          </MDButton>
      
        </MDBox>
      ),

    })
  });


  const DocTableData = {
    columns: [
      { Header: "Nome Allegato", accessor: "nome_allegato", width: "30%", align: "left" },
      { Header: "Descrizione", accessor: "descrizione", align: "left" },

      { Header: "action", accessor: "action", align: "center", enableGlobalFilter: false },
    ],

    rows: docAttributes,


  };

  const mypagination = { color: 'success', variant: "contained" };

  const aggiungi_allegato = () => {
    handleShow();
  }

  const edit_allegato = (element) => {
    setSoggetto(element);
    handleShow();
  }
  ///////////////////////////////////////////////////////////////////////////
  const [docSel, setDocSel] = useState([]);


  const delete_doc = () => {


    console.log("delete loc");
    ComunicazioniService.removeDoc(docSel.idAllegato)
      .then(response => {
        console.log("handleResponse");
        handleResponse(response, 'DEL');
      })
      .catch(errore => {
        console.log("handleError");
        handleError(errore);
      });



  };

  //sono quelli lato server
  const [error, setError] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  function handleResponse(response, op) {
    setDocumenti((prevItems) => prevItems.filter((item) => item.idAllegato !== docSel.idAllegato));
    navigate(`/documenti`, { state: { submitted: true, salvato: op } });
  }

  function handleError(error) {
    setError(error);
    console.log("ERRORE" + error);
    console.log(error.code);

    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.log("ERROR:RESPONSE" + error.response.data);
      setErrorMessage(error.response.data);

    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      console.log("ERROR:REQUEST" + error.request);
      setErrorMessage(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log('Error', error.message);
      setErrorMessage(error.message);
    }

  }
  const [open, setOpen] = useState(false);
  const [confirm, setConfirm] = useState(false);
  function handleCloseD() {

    setOpen(false);
  }
  function handleConfirmD() {

    setConfirm(true);


    setOpen(false);

    delete_doc();
  }

  const handleOpenD = (doc) => {
    console.log("OPEN DIALOG");
    setDocSel(doc);
    setOpen(true);
  }
  /////////////////////////////////////////////

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // Funzione per aggiungere un nuovo oggetto alla lista
  const handleAddAllegato = (newObject,op) => {
  if(op=='INS')  setDocumenti((prevObjects) => [...prevObjects, newObject]);
  else retrieveDoc();
    handleClose(); // Chiudi la modale dopo il salvataggio
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>

          <Grid item xs={12}>
            <Card>
              <HeaderForm titolo="Documenti" add={aggiungi_allegato} modo="vis" />
              <MDBox pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center">

                {(state != null && state.submitted && (state.salvato === 'INS' || state.salvato === 'MOD')) &&

                  <Alert severity="success">Documento salvato con successo!</Alert>}
                {(state != null && state.submitted && state.salvato === 'DEL') &&

                  <Alert severity="success">Documento eliminata con successo!</Alert>}
              </MDBox>
              <MDBox pt={3}>
                <DataTable canSearch={true}
                  table={DocTableData}
                  isSorted={false}
                  entriesPerPage={true}
                  showTotalEntries={true}
                  pagination={mypagination}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>

      <AllegatoEditModal
        soggetto={soggetto}
        onSave={handleAddAllegato}
        onClose={handleClose}
        show={show}
      />

      <Dialog open={open} onClose={handleCloseD} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" >
        <DialogTitle id="alert-dialog-title">
          Eliminare questo record?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Sei sicuro di voler eliminare questo record?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <MDButton onClick={handleCloseD}>No</MDButton>
          <MDButton onClick={handleConfirmD} autoFocus>
            SI
          </MDButton>
        </DialogActions>
      </Dialog>

      <Footer />
    </DashboardLayout>




  );
}

export default ElencoDocumenti;
