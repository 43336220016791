/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState, forwardRef } from "react";
import PropTypes from 'prop-types';
import { IMaskInput } from 'react-imask';

// react-router-dom components
import { useLocation, useNavigate } from "react-router-dom";

import AuthService from "services/API/Auth/AuthService";
import ComuniService from 'services/API/Comuni/ComuniService.js';
import ERService from 'services/API/ExpoRichieste/ExpoRichiesteService.js';
import UtilityService from "services/API/utility/UtilityService";

// @mui material components
import Card from "@mui/material/Card";
import CardContent from '@mui/material/CardContent';
import Grid from "@mui/material/Grid";

import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';

import Alert from '@mui/material/Alert';
import Autocomplete from '@mui/material/Autocomplete';
import MenuItem from '@mui/material/MenuItem';
import { pink } from '@mui/material/colors';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import ReactHookFormCheckBox from "common/components/mycomponents/ReactHookFormCheckBox";
import ReactHookFormSelect from "common/components/mycomponents/ReactHookFormSelect";
import DashboardLayout from "layouts/DashboardLayout";
import Footer from "layouts/components/Footer";
import HeaderForm from "layouts/components/HeaderForm";
import DashboardNavbar from "layouts/components/Navbars/DashboardNavbar";


// Material Dashboard 2 React components
import MDBox from "common/components/MD/MDBox";
import MDButton from "common/components/MD/MDButton";
import MDInput from "common/components/MD/MDInput";
import MDTypography from "common/components/MD/MDTypography";



// Authentication layout components piu largo

// Images

import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';

//validazione lato client
import { yupResolver } from '@hookform/resolvers/yup';
import ExpoRichiesteStepper from "pages/riservata/expo_richieste/ExpoRichiesteStepper";
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { TextField } from "@mui/material";
import useDebounce from "common/custom_hook/useDebounce";

function ExpoRichiesteEdit() {

  const color_pink = pink[300];


  const location = useLocation();
  const navigate = useNavigate();
  const { modo, soggetto, subject } = location.state;

  // user state for form
  const [prop, setProp] = useState({
    idRichiesta: 0,
    id_utente: AuthService.getCurrentUser().id,
    manifestazione: subject,
    ragione_sociale: '',
    rappresentante_legale: '', indirizzo: '', civico: '', cap: '', localita: '', indirizzo2: '', provincia: '',
    piva: '', cf: '', telefono: '', fax: '', www: '', email: '', pec: '', isEstero: false, iban: '', iban2: '', swift: '', codice_sdi: '',
    cm_code_01: '', cm_desc_01: '', cm_code_02: '', cm_desc_02: '', cm_code_03: '', cm_desc_03: '', cm_code_04: '', cm_desc_04: '',
    responsabile_fiera: '', responsabile_fiera_telefono: '', responsabile_fiera_cell: '', responsabile_fiera_email: '',
    prodotti_servizi: '', produttore: false, concessionario: false, distributore: false, rivenditore: false, importatore: false,
    tipologia_espositiva_scelta: '', note: '',
    q_10: 0, q_11: 0, q_12: 0, q_13: 0, importo_area: 0, imponibile: 0, iva: 0, totale: 0, anticipo_versato: 0, aliquota: 0.22
  });

  const [lista_loc, setLista_loc] = useState([
    { cap: '', localita: '', sigla_provincia: '', denominazione_provincia: '' },
  ]);
  const [lista_codici_m, setLista_codici_m] = useState([
    { codice: '', descrizione: '' },
  ]);


  useEffect(() => {
    console.log("carico i codici merceologici");

    async function fetchInitialData2() {
      const response = await UtilityService.getAllCodiciM();
      response.errors ? console.log(response.errors) : setLista_codici_m([...response.data]);
    }
    fetchInitialData2();

  }, []);
  const [isEstero, setIsEstero] = useState(false);
  const [iban, setIban] = useState("XX 00 X 00000 00000 0000000000000");

  const handleChange = (event) => {
    /*  setValues({
        ...values,
        [event.target.name]: event.target.value,
      });*/
    console.log("setIban");
    setIban(event.target.value);
    console.log("setIban" + iban);
  };


  function IbanInput({ label, handleChange }) {

    /*const [values, setValues] = useState({
      textmask: 'XX 00 X 00000 00000 0000000000000',
      numberformat: '1320',
    });*/


    return (

      <FormControl fullWidth required variant="outlined" error={errors.iban ? true : false} InputLabelProps={{ shrink: true }}>
        <InputLabel >{label}</InputLabel>
        <Input


          onChange={handleChange}
          {...register('iban')}
          name="iban"
          id="formatted-text-mask-input"
          inputComponent={TextMaskCustom}
        />
      </FormControl>

    );

  }

  const TextMaskCustom = forwardRef(function TextMaskCustom(props, ref) {
    const { onChange, ...other } = props;
    return (
      <IMaskInput
        {...other}
        mask="aa 00 a 00000 00000 ************"
        /*  definitions={{
            '#': /[1-9]/,
            '*':/[a-z]/
          }}*/
        inputRef={ref}
        onAccept={(value) => onChange({ target: { name: props.name, value } })}
        overwrite
      />
    );
  });

  TextMaskCustom.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  };


  /* ----------------------------FUNZIONALITA PAGINA------------------------------------------------- */
  const [query_cap, setQuery_cap] = useState('');
  const debouncedQuery = useDebounce(query_cap, 500); // Debounce di 500ms


  // Effettua la ricerca quando `debouncedQuery` cambia
  useEffect(() => {
    if (debouncedQuery) {
      console.log(`Effettua la ricerca per: ${debouncedQuery}`);
      // Qui potresti invocare un'API di ricerca o filtrare i dati localmente
      console.log("retrive");


      setLista_loc([
        { cap: '', localita: '', sigla_provincia: '', denominazione_provincia: '' },
      ]);
      setValue('localita', '');

      ComuniService.getAllComuni(query_cap)
        .then(response => {
          setLista_loc([...response.data]);
          console.log(response.status);
          if (response.status === 200) {
            console.log(response.data[0].localita);
            setValue('provincia', response.data[0].sigla_provincia.toUpperCase());
            console.log("RESPONSE COMUNI=" + prop
              .localita);
            if (prop.localita != null) {
              console.log("LOC=" + prop.localita);
              setValue('localita', prop.localita);

            }
          }


        })
        .catch(e => {
          console.log(e);
        });
    }
  }, [debouncedQuery]);


  const handleChangeE = (event) => {
    setIsEstero(event.target.checked);

  }

  /* ----------------------------CARICAMENTO E RIEMPIMENTO CAMPI------------------------------------------------- */



  useEffect(() => {
    if (soggetto != null) {
      console.log("soggetto non nullo: id=" + soggetto.idRichiesta);
      seleziona_soggetto();

    } else {
      console.log("soggetto nullo");
    }
  }, [soggetto]);

  async function seleziona_soggetto() {
    console.log("Cambiata pp->seleziono  :" + soggetto.idRichiesta);
    if (soggetto.idRichiesta !== 0) {
      const response = await ERService.seleziona(soggetto.idRichiesta);

      response.errors ? console.log(response.errors) : retrive(response.data);


    }
  }


  function retrive(data) {
    console.log(data);
    console.log("retrive =" + data.idRichiesta);

    setProp({
      idRichiesta: data.idRichiesta,
      id_utente: data.id_utente,
      manifestazione: data.manifestazione,
      ragione_sociale: data.ragione_sociale,
      rappresentante_legale: data.rappresentante_legale,
      indirizzo: data.indirizzo,
      civico: data.civico,
      cap: data.cap,
      localita: data.localita,
      provincia: data.provincia,
      indirizzo2: data.indirizzo2,
      cf: data.cf,
      piva: data.piva,
      telefono: data.telefono,
      fax: data.fax,
      email: data.email,
      www: data.www,
      pec: data.pec,
      isEstero: data.estero,
      iban: data.iban,
      iban2: data.iban2,
      swift: data.swift,
      codice_sdi: data.codice_sdi,
      cm_01: data.cm_01,
      cm_02: data.cm_02,
      cm_03: data.cm_03,
      cm_04: data.cm_04,
      responsabile_fiera: data.responsabile_fiera,
      responsabile_fiera_telefono: data.responsabile_fiera_telefono,
      responsabile_fiera_cell: data.responsabile_fiera_cell,
      responsabile_fiera_email: data.responsabile_fiera_email,
      prodotti_servizi: data.prodotti_servizi,
      produttore: data.produttore,
      concessionario: data.concessionario,
      distributore: data.distributore,
      rivenditore: data.rivenditore,
      importatore: data.importatore,
      tipologia_espositiva_scelta: data.tipologia_espositiva_scelta,
      q_10: data.q_10, q_11: data.q_11, q_12: data.q_12, q_13: data.q_13,
      importo_area: data.importo_area, imponibile: data.imponibile, iva: data.iva,
      aliquota: data.aliquota,
      totale: data.totale, anticipo_versato: data.anticipo_versato,
      note: data.note, esigenze_espositive: data.esigenze_espositive,

    });

    console.log("selezionata  " + prop.idRichiesta);

  }


  useEffect(() => {

    console.log("Cambiata pp->riempo i campi  " + prop.idRichiesta);
    // reset form with user data
    setValue("ragione_sociale", prop.ragione_sociale);
    setValue("rappresentante_legale", prop.rappresentante_legale);
    setValue("indirizzo", prop.indirizzo);
    setValue("indirizzo2", prop.indirizzo2);
    setValue("civico", prop.civico);
    setValue("cap", prop.cap);

    setQuery_cap(prop.cap);


    setValue("localita", prop.localita);
    setValue("provincia", prop.provincia);

    setValue("piva", prop.piva);
    setValue("cf", prop.cf);
    setValue("telefono", prop.telefono);
    setValue("fax", prop.fax);
    setValue("www", prop.www);
    setValue("email", prop.email);

    setValue("pec", prop.pec);
    setValue("iban_estero", prop.isEstero);
    setValue("iban", prop.iban);
    setValue("iban2", prop.iban2);
    setValue("swift", prop.swift);
    setValue("codice_sdi", prop.codice_sdi);
    setIsEstero(prop.isEstero);
    setValue("codice_merceologico_01", prop.cm_01);
    setValue("codice_merceologico_02", prop.cm_02);
    setValue("codice_merceologico_03", prop.cm_03);
    setValue("codice_merceologico_04", prop.cm_04);

    setValue("responsabile_fiera", prop.responsabile_fiera);
    setValue("telefono_resp", prop.responsabile_fiera_telefono);
    setValue("cell_resp", prop.responsabile_fiera_cell);
    setValue("email_resp", prop.responsabile_fiera_email);

    setValue("prodotti_servizi", prop.prodotti_servizi);


    setValue("produttore", prop.produttore);
    setValue("concessionario", prop.concessionario);
    setValue("distributore", prop.distributore);
    setValue("rivenditore", prop.rivenditore);
    setValue("importatore", prop.importatore);

  }, [prop]);




  /* ----------------------------VALIDAZIONE E SALVATAGGIO----------------------------------------------------------------------- */

  const validationSchema = Yup.object().shape({
    ragione_sociale: Yup.string().required('Nome Azienda richiesto').max(150, 'Lunghezza massima raggiunta'),
    rappresentante_legale: Yup.string().required('Nome Rappresentante legale richiesto').max(45, 'Lunghezza massima raggiunta'),
    cf: Yup.string()
      .required('CF richiesto'),
    piva: Yup.string()
      .required('Partita IVA richiesto'),
    email: Yup.string()
      .required('Email richiesta')
      .email('Email non valida'),
    pec: Yup.string()
      .required('Pec richiesta')
      .email('Email non valida'),
    indirizzo: Yup.string()
      .required('Indirizzo richiesto')
      .max(70, 'Inserire soltanto l\'indirizzo (via/piazza/vocabolo/...'),
    indirizzo2: Yup.string()

      .max(30, 'Numero massimo di caratteri raggiunto'),
    civico: Yup.string()
      .required('Numero civico richiesto')
      .max(10, 'Lunghezza massima raggiunta'),
    cap: Yup.string()
      .required('Cap richiesto')
      .max(10, 'Lunghezza massima raggiunta'),
    telefono: Yup.string().required('Telefono richiesto')
      .max(20, 'Lunghezza massima raggiunta'),
    localita: Yup.string()
      .required('Località richiesto')
      .max(100, 'Lunghezza massima raggiunta'),
    provincia: Yup.string()
      .required('Sigla Provincia Richiesta')
      .max(4, 'Inserire la sigla della provincia'),
    codice_sdi: Yup.string()
      .required('Codice SDI richiesto: se non del caso, utilizzare 0000000'),
    responsabile_fiera: Yup.string()
      .required('Nome responsabile Fiera richiesto'),
    telefono_resp: Yup.string()
      .required('Telefono responsabile Fiera richiesto'),
    cell_resp: Yup.string()
      .required('Cellulare responsabile Fiera richiesto'),
    email_resp: Yup.string()
      .required('Email per comunicazioni dirette Fiera richiesto')
      .email('Email non valida'),
      codice_merceologico_01: Yup.string()
      .required('Codice merceologico principale richiesto')
      .min(2, 'Codice merceologico principale richiesto'),  
    iban: Yup.string().when("$isEstero", {
      is: (val) => {
        return !isEstero;
      },
      then: (s) => s.required('IBAN richiesto per restituzione acconto in caso di mancata accettazione della richiesta'),
      otherwise: (s) => null,
    }).nullable(),

    /*   iban: Yup.string()
         .required('IBAN richiesto per restituzione acconto in caso di mancata accettazione della richiesta'),*/
    prodotti_servizi: Yup.string()
      .required('E\' richiesta una descrizione dettagliata dei prodotti o servizi venduti ed esposti'),
  });
  /* ----------------------------SALVATAGGIO E REGISTRAZIONE------------------------------------------------- */

  const {
    register, //register inputs
    reset, setValue, control,
    handleSubmit,//handle form submit
    formState: { errors }
  } = useForm({
    resolver: yupResolver(validationSchema)
  });



  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  const inserisci_pp = (formData) => {
    if (AuthService.isTokenOk()) {
    setError(null);

    let manifestazione = subject;



    let cm1 = null, cm2 = null, cm3 = null, cm4 = null;
    let c1 = lista_codici_m.find(c => c.codice === formData.codice_merceologico_01);
    if (c1 != null) cm1 = c1.codice;
    let c2 = lista_codici_m.find(c => c.codice === formData.codice_merceologico_02);
    if (c2 != null) cm2 = c2.codice;

    let c3 = lista_codici_m.find(c => c.codice === formData.codice_merceologico_03);
    if (c3 != null) cm3 = c3.codice;
    let c4 = lista_codici_m.find(c => c.codice === formData.codice_merceologico_04);
    if (c4 != null) cm4 = c4.codice;


    var data = {
      idRichiesta: prop.idRichiesta,
      id_utente: AuthService.getCurrentUser().id,
      manifestazione: prop.manifestazione,
      ragione_sociale: formData.ragione_sociale,
      rappresentante_legale: formData.rappresentante_legale,
      indirizzo: formData.indirizzo,
      civico: formData.civico,
      cap: formData.cap,
      localita: formData.localita,
      indirizzo2: formData.indirizzo2,
      provincia: formData.provincia,
      piva: formData.piva,
      cf: formData.cf,
      telefono: formData.telefono,
      fax: formData.fax,
      www: formData.www,
      email: formData.email,
      pec: formData.pec,
      estero: formData.iban_estero,
      iban: formData.iban,
      iban2: formData.iban2,
      swift: formData.swift,
      codice_sdi: formData.codice_sdi,
      cm_01: cm1, cm_02: cm2, cm_03: cm3, cm_04: cm4,
      responsabile_fiera: formData.responsabile_fiera,
      responsabile_fiera_telefono: formData.telefono_resp,
      responsabile_fiera_cell: formData.cell_resp,
      responsabile_fiera_email: formData.email_resp,
      prodotti_servizi: formData.prodotti_servizi,
      produttore: formData.produttore, concessionario: formData.concessionario, distributore: formData.distributore,
      rivenditore: formData.rivenditore, importatore: formData.importatore,

      tipologia_espositiva_scelta: prop.tipologia_espositiva_scelta,
      q_10: prop.q_10, q_11: prop.q_11, q_12: prop.q_12, q_13: prop.q_13,
      importo_area: prop.importo_area,
      imponibile: prop.imponibile,
      iva: prop.iva, totale: prop.totale, anticipo_versato: prop.anticipo_versato,
      note: prop.note, esigenze_espositive: prop.esigenze_espositive,
      aliquota: prop.aliquota


    };

    if (modo === 'new') {
      ERService.create(data)
        .then(response => {
          handleResponse(response, "INS");
        })
        .catch(errore => {
          handleError(errore);
        });
    }

    if (modo === 'edit') {
      ERService.update(prop.idRichiesta, data)
        .then(response => {
          handleResponse(response, "UPD");
        })
        .catch(errore => {
          handleError(errore);
        });
    }
  }

  }
  function handleResponse(response, operazione) {
    console.log(response.data);
    console.log(response.data.idRichiesta);
    setProp({
      idRichiesta: response.data.idRichiesta,
      id_utente: response.data.id_utente,
      manifestazione: response.data.manifestazione,
      ragione_sociale: response.data.ragione_sociale,
      rappresentante_legale: response.data.rappresentante_legale,
      indirizzo: response.data.indirizzo,
      civico: response.data.civico,
      cap: response.data.cap,
      localita: response.data.localita,
      provincia: response.data.provincia,
      indirizzo2: response.data.indirizzo2,
      cf: response.data.cf,
      piva: response.data.piva,
      telefono: response.data.telefono,
      fax: response.data.fax,
      email: response.data.email,
      www: response.data.www,
      pec: response.data.pec,
      isEstero: response.data.estero,
      iban: response.data.iban,
      iban2: response.data.iban2,
      swift: response.data.swift,
      codice_sdi: response.data.codice_sdi,
      cm_01: response.data.cm_01,
      cm_02: response.data.cm_02,
      cm_03: response.data.cm_03,
      cm_04: response.data.cm_04,
      responsabile_fiera: response.data.responsabile_fiera,
      responsabile_fiera_telefono: response.data.responsabile_fiera_telefono,
      responsabile_fiera_cell: response.data.responsabile_fiera_cell,
      responsabile_fiera_email: response.data.responsabile_fiera_email,
      prodotti_servizi: response.data.prodotti_servizi,
      produttore: response.data.produttore,
      concessionario: response.data.concessionario,
      distributore: response.data.distributore,
      rivenditore: response.data.rivenditore,
      importatore: response.data.importatore,
      tipologia_espositiva_scelta: response.data.tipologia_espositiva_scelta,
      q_10: response.data.q_10, q_11: response.data.q_11, q_12: response.data.q_12, q_13: response.data.q_13,
      importo_area: response.data.importo_area, imponibile: response.data.imponibile, iva: response.data.iva,
      totale: response.data.totale, anticipo_versato: response.data.anticipo_versato,
      note: response.data.note, esigenze_espositive: response.data.esigenze_espositive,
      aliquota: response.aliquota
    });

    setSubmitted(true);
    if (operazione !== "DEL") {
      console.log("/expo_richiestaEdit2");
      navigate("/expo_richiestaEdit2", { state: { modo: 'edit', soggetto: response.data.idRichiesta, subject: null } });
    } else {

      console.log("/expo_richieste");
      navigate("/expo_richieste", { state: { submitted: true, salvato: operazione } });
    }
  }

  function handleError(error) {
    setError(error);
    console.log("ERRORE" + error);
    console.log(error.code);

    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.log("ERROR:RESPONSE" + error.response.data);
      setErrorMessage(error.response.data);

    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      console.log("EEROR:REQUEST" + error.request);
      setErrorMessage(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log('Error', error.message);
      setErrorMessage(error.message);
    }

  }

  /* ---------------- ALERT -------------------------------------------------------------------------------------------------------- */

  const [open, setOpen] = useState(false);
  const [openExistent, setOpenExistent] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [confirmExistent, setConfirmExistent] = useState(false);




  function handleClose() {
    setOpen(false);
  }

  function handleConfirm() {
    setConfirm(true);
    setOpen(false);
    deleteObject();
  }


  const handleOpen = () => {
    console.log("OPEN DIALOG");
    setOpen(true);
  }

  function handleCloseExistent() {
    setOpenExistent(false);
  }

  function handleConfirmExistent() {
    setConfirmExistent(true);
    setOpenExistent(false);

  }

  const deleteObject = () => {
    setError(null);

    if (modo === 'edit') {

      console.log("delete ");

      ERService.remove(prop.idRichiesta)
        .then(response => {
          console.log("handleResponse");
          handleResponse(response, 'DEL');
        })
        .catch(errore => {
          console.log("handleError");
          handleError(errore);
        });

    }
  };



  /* ------------------------------navigazione -----------------------------------------------------------------*/


  const annulla = () => {


    navigate("/expo_richieste");
  }

  const handleKeyDown = (e) => {
    const { key, target } = e

    if (key !== "Enter" || target instanceof HTMLTextAreaElement) {
      return
    }

    e.preventDefault()
  }

  return (
    <DashboardLayout>

      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" >
          <DialogTitle id="alert-dialog-title">
            Eliminare questo record?
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Sei sicuro di voler eliminare questa proposta di partecipazione?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <MDButton onClick={handleClose}>No</MDButton>
            <MDButton onClick={handleConfirm} autoFocus>
              SI
            </MDButton>
          </DialogActions>
        </Dialog>

        <Grid container spacing={6}>

          <Grid item xs={12}>
            <Card >
              <HeaderForm titolo={"Proposta di Partecipazione " + subject} save={handleSubmit(inserisci_pp)} remove={handleOpen} exit={annulla} modo={modo} />
              {error !== null &&
                <Alert color="error"><ErrorOutlineOutlinedIcon fontSize="small" />&nbsp;{errorMessage}</Alert>
              }


              <MDBox pt={1} pb={3} px={3} component="form" role="form" onKeyDown={handleKeyDown}>


                <CardContent>
                  <MDBox>
                    <MDBox opacity={1} textAlign="center">

                    </MDBox>
                    <MDBox pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center" >

                      {(submitted) &&

                        <Alert severity="success">Dati salvati con successo!</Alert>}
                      {error !== null &&
                        <Alert color="error"><ErrorOutlineOutlinedIcon fontSize="small" />&nbsp;{errorMessage}</Alert>
                      }
                    </MDBox>

                    <MDBox pt={4} pb={3} px={3} >
                      <MDBox>
                        <ExpoRichiesteStepper index_attivo={1} />
                      </MDBox>
                    </MDBox>

                    <MDBox mb={4}>

                      <MDButton variant="gradient" color="success" fullWidth>
                        <MDTypography variant="h5" fontWeight="regular" sx={{
                          color: "#FFFFFF"
                        }}>DATI ANAGRAFICI</MDTypography>
                      </MDButton>
                    </MDBox>

                    <MDBox mb={2} sx={{ display: 'grid', gap: 1, gridTemplateColumns: '1fr 1fr' }}>
                      <MDBox>
                        <MDInput type="text" label="Ragione Sociale" name='ragione_sociale' fullWidth required {...register('ragione_sociale')}
                          error={errors.ragione_sociale ? true : false} InputLabelProps={{ shrink: true }}
                        />
                        {errors.ragione_sociale ? <span style={{ color: 'red', fontSize: '12px' }}>{errors.ragione_sociale?.message}</span> : ''}
                      </MDBox>
                      <MDBox>
                        <MDInput type="text" label="Legale Rappresentante" name='rappresentante_legale' fullWidth required {...register('rappresentante_legale')}
                          error={errors.rappresentante_legale ? true : false} InputLabelProps={{ shrink: true }}
                        />
                        {errors.rappresentante_legale ? <span style={{ color: 'red', fontSize: '12px' }}>{errors.rappresentante_legale?.message}</span> : ''}
                      </MDBox>
                    </MDBox>

                    <MDBox mb={2} sx={{ display: 'grid', gap: 1, gridTemplateColumns: '3fr 1fr' }}>
                      <MDBox >
                        <MDInput type="text" fullWidth label="Indirizzo" required name='indirizzo'  {...register('indirizzo')} error={errors.indirizzo ? true : false} InputLabelProps={{ shrink: true }} />
                        {errors.indirizzo ? <span style={{ color: 'red', fontSize: '12px' }}>{errors.indirizzo?.message}</span> : ''}
                      </MDBox>
                      <MDBox>
                        <MDInput type="text" fullWidth label="civico" required name='civico'  {...register('civico')} error={errors.civico ? true : false} InputLabelProps={{ shrink: true }} />
                        {errors.civico ? <span style={{ color: 'red', fontSize: '12px' }}>{errors.civico?.message}</span> : ''}
                      </MDBox>

                    </MDBox>
                    <MDBox mb={2} >
                      <MDInput type="text" label="Dettaglio Indirizzo (frazione, c/o, piano, ...)" fullWidth name='indirizzo2' {...register('indirizzo2')} error={errors.indirizzo2 ? true : false} InputLabelProps={{ shrink: true }} />
                      {errors.indirizzo2 ? <span style={{ color: 'red', fontSize: '12px' }}>{errors.indirizzo2?.message}</span> : ''}
                    </MDBox>
                    {/*  <MDBox mb={2} sx={{ display: 'grid', gap: 1, gridTemplateColumns: '1fr 2fr 2fr 1fr' }}>*/}
                    <Grid container spacing={2} mb={2}>
                      <Grid item xs={4} md={2} >
                        <MDBox>
                          <MDInput type="text" label="CAP" fullWidth name='cap' required
                            {...register('cap', {
                              onChange: (e) => setQuery_cap(e.target.value),

                            })} error={errors.cap ? true : false} InputLabelProps={{ shrink: true }}
                          />
                          {errors.cap ? <span style={{ color: 'red', fontSize: '12px' }}>{errors.cap?.message}</span> : ''}
                        </MDBox>
                      </Grid>

                      <Grid item xs={8} md={8} >
                        <MDBox>
                          <Autocomplete
                            freeSolo
                            value={prop.localita}
                            options={lista_loc.map((loc) => loc.localita)}
                            renderInput={(params) =>
                              <MDInput type="text" {...params} label="località" name='localita' fullWidth
                                {...register('localita')}
                                InputLabelProps={{ shrink: true }} />}
                          />


                        </MDBox>
                      </Grid>
                      <Grid item xs={4} md={2} >
                        <MDBox>
                          <MDInput type="text" label="provincia" name='provincia' fullWidth required  {...register('provincia')} error={errors.provincia ? true : false} InputLabelProps={{ shrink: true }} />
                          {errors.provincia ? <span style={{ color: 'red', fontSize: '12px' }}>{errors.provincia?.message}</span> : ''}
                        </MDBox>
                      </Grid></Grid>


                    <MDBox mb={2} sx={{ display: 'grid', gap: 1, gridTemplateColumns: '1fr 1fr' }}>
                      <MDBox>
                        <MDInput type="text" label="Codice Fiscale" name='cf' fullWidth required {...register('cf')}
                          error={errors.cf ? true : false} InputLabelProps={{ shrink: true }}
                        />
                        {errors.cf ? <span style={{ color: 'red', fontSize: '12px' }}>{errors.cf?.message}</span> : ''}
                      </MDBox>
                      <MDBox>
                        <MDInput type="text" label="Partita IVA" name='piva' fullWidth required {...register('piva')}
                          placeholder="Se non esistente inserire XXXXX"
                          error={errors.piva ? true : false} InputLabelProps={{ shrink: true }}
                        />
                        {errors.piva ? <span style={{ color: 'red', fontSize: '12px' }}>{errors.piva?.message}</span> : ''}
                      </MDBox>
                    </MDBox>

                    <MDBox mb={2} sx={{ display: 'grid', gap: 1, gridTemplateColumns: '1fr 1fr' }}>
                      <MDBox>
                        <MDInput type="text" label="Telefono" name='telefono' fullWidth required {...register('telefono')}
                          error={errors.telefono ? true : false} InputLabelProps={{ shrink: true }}
                        />
                        {errors.telefono ? <span style={{ color: 'red', fontSize: '12px' }}>{errors.telefono?.message}</span> : ''}
                      </MDBox>
                      <MDBox>
                        <MDInput type="text" label="Fax" name='fax' fullWidth {...register('fax')} InputLabelProps={{ shrink: true }} />
                      </MDBox>
                    </MDBox>

                    <MDBox mb={2} sx={{ display: 'grid', gap: 1, gridTemplateColumns: '1fr 1fr' }}>
                      <MDBox>
                        <MDInput type="text" label="WWW" name='www' fullWidth  {...register('www')}
                          error={errors.www ? true : false} InputLabelProps={{ shrink: true }}
                        />
                        {errors.www ? <span style={{ color: 'red', fontSize: '12px' }}>{errors.www?.message}</span> : ''}
                      </MDBox>
                      <MDBox mb={2} >
                        <MDInput type="text" label="Email" fullWidth required name='email' {...register('email')} error={errors.email ? true : false} InputLabelProps={{ shrink: true }} />
                        {errors.email ? <span style={{ color: 'red', fontSize: '12px' }}>{errors.email?.message}</span> : ''}
                      </MDBox>
                    </MDBox>

                    <MDBox mb={2} sx={{ display: 'grid', gap: 1, gridTemplateColumns: '1fr 1fr' }}>
                      <MDBox>
                        <MDInput type="text" label="PEC" name='pec' fullWidth required {...register('pec')}
                          error={errors.pec ? true : false} InputLabelProps={{ shrink: true }}
                        />
                        {errors.pec ? <span style={{ color: 'red', fontSize: '12px' }}>{errors.pec?.message}</span> : ''}
                      </MDBox>
                      <MDBox mb={2} >
                        <MDInput type="text" label="Codice SDI" fullWidth required name='codice_sdi' {...register('codice_sdi')}
                          placeholder="Se non esistente inserire '0000000' "
                          error={errors.codice_sdi ? true : false} InputLabelProps={{ shrink: true }} />
                        {errors.codice_sdi ? <span style={{ color: 'red', fontSize: '12px' }}>{errors.codice_sdi?.message}</span> : ''}
                      </MDBox>
                    </MDBox>

                    <MDBox mb={2} sx={{ display: 'grid', gap: 1, gridTemplateColumns: '1fr 1fr' }}>
                      <MDBox>
                        <MDInput type="text" label="Responsabile Fiera" name='responsabile_fiera' fullWidth required {...register('responsabile_fiera')}
                          error={errors.responsabile_fiera ? true : false} InputLabelProps={{ shrink: true }}
                        />
                        {errors.responsabile_fiera ? <span style={{ color: 'red', fontSize: '12px' }}>{errors.responsabile_fiera?.message}</span> : ''}
                      </MDBox>
                      <MDBox mb={2} >
                        <MDInput type="text" label="Telefono Responsabile" fullWidth required name='telefono_resp' {...register('telefono_resp')}
                          error={errors.telefono_resp ? true : false} InputLabelProps={{ shrink: true }} />
                        {errors.telefono_resp ? <span style={{ color: 'red', fontSize: '12px' }}>{errors.telefono_resp?.message}</span> : ''}
                      </MDBox>
                    </MDBox>

                    <MDBox mb={2} sx={{ display: 'grid', gap: 1, gridTemplateColumns: '1fr 1fr' }}>
                      <MDBox>
                        <MDInput type="text" label="Cellulare" name='cell_resp' fullWidth required {...register('cell_resp')}
                          error={errors.cell_resp ? true : false} InputLabelProps={{ shrink: true }}
                        />
                        {errors.cell_resp ? <span style={{ color: 'red', fontSize: '12px' }}>{errors.cell_resp?.message}</span> : ''}
                      </MDBox>
                      <MDBox mb={2} >
                        <MDInput type="text" label="Email (Da utilizzare per le comunicazioni dirette)" fullWidth required name='email_resp' {...register('email_resp')}
                          error={errors.email_resp ? true : false} InputLabelProps={{ shrink: true }} />
                        {errors.email_resp ? <span style={{ color: 'red', fontSize: '12px' }}>{errors.email_resp?.message}</span> : ''}
                      </MDBox>
                    </MDBox>

                    <MDBox mb={2} sx={{ display: 'grid', gap: 1, gridTemplateColumns: '1fr 8fr' }} >
                      <ReactHookFormCheckBox
                        label={<MDBox>
                          <MDTypography
                            variant="button"
                            fontWeight="regular"
                            color="text"
                            sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                          >
                            &nbsp;&nbsp;IBAN Estero&nbsp;
                          </MDTypography>

                        </MDBox>}
                        name="iban_estero"
                        control={control}
                        onChange={handleChangeE}
                        value
                      />
                      {!isEstero &&
                      <MDBox fullWidth>

                        <IbanInput label="IBAN (Per l'eventuale restituzione d ell'anticipo versato)" onChange={handleChange} ></IbanInput>

                        {/*   <MDInput label="IBAN (Per l'eventuale restituzione d ell'anticipo versato)" name='iban' fullWidth required 
                      
                          error={errors.iban ? true : false} InputLabelProps={{ shrink: true }}
                  
                          {...register('iban')}
                        />*/}
                        {errors.iban ? <span style={{ color: 'red', fontSize: '12px' }}>{errors.iban?.message}</span> : ''}
                      </MDBox>}
                      {isEstero &&
                      <MDBox sx={{ display: 'grid', gap: 1, gridTemplateColumns: '4fr 1fr' }}>
                      <MDBox mb={2} >
                        <MDInput type="text" label="IBAN Estero (Per l'eventuale restituzione d ell'anticipo versato)" fullWidth name='iban2' {...register('iban2')}
                          error={errors.iban2 ? true : false} InputLabelProps={{ shrink: true }} />
                        {errors.iban2 ? <span style={{ color: 'red', fontSize: '12px' }}>{errors.iban2?.message}</span> : ''}
                      </MDBox>
                      <MDBox mb={2} >
                        <MDInput type="text" label="SWIFT" fullWidth name='swift' {...register('swift')}
                          error={errors.swift ? true : false} InputLabelProps={{ shrink: true }} />
                        {errors.swift ? <span style={{ color: 'red', fontSize: '12px' }}>{errors.swift?.message}</span> : ''}
                      </MDBox>
                      </MDBox>}
                    </MDBox>
                    <MDBox mb={2}>
                      <MDTypography variant="button" fontWeight="regular" color="text"> Codici Merceologici (Obbligatori ed in ordine di importanza) </MDTypography>
                    </MDBox>
                    <MDBox mb={2} sx={{ display: 'grid', gap: 1, gridTemplateColumns: '1fr 1fr' }}>
                      <MDBox>
                        <ReactHookFormSelect
                          id="codice_merceologico_01"
                          name="codice_merceologico_01"
                          label="Codice Merceologico 1"
                          control={control}
                          defaultValue=''
                          variant="outlined"
                          required
                          error={errors.codice_merceologico_01 ? true : false}
                        >
                          {lista_codici_m.map((cm) => (
                            <MenuItem
                              key={cm.codice}
                              value={cm.codice}
                            >
                              {cm.codice} - {cm.descrizione}
                            </MenuItem>
                          ))}

                        </ReactHookFormSelect>
                        {errors.codice_merceologico_01 ? <span style={{ color: 'red', fontSize: '12px' }}>{errors.codice_merceologico_01?.message}</span> : ''}
                      </MDBox>
                      <MDBox>
                        <ReactHookFormSelect
                          id="codice_merceologico_02"
                          name="codice_merceologico_02"
                          label="Codice Merceologico 2"
                          control={control}
                          defaultValue=''
                          variant="outlined"
                        >
                          {lista_codici_m.map((cm) => (
                            <MenuItem
                              key={cm.codice}
                              value={cm.codice}
                            >
                              {cm.codice} - {cm.descrizione}
                            </MenuItem>
                          ))}

                        </ReactHookFormSelect>
                      </MDBox>
                    </MDBox>


                    <MDBox mb={2} sx={{ display: 'grid', gap: 1, gridTemplateColumns: '1fr 1fr' }}>
                      <MDBox>
                        <ReactHookFormSelect
                          id="codice_merceologico_03"
                          name="codice_merceologico_03"
                          label="Codice Merceologico 3"
                          control={control}
                          defaultValue=''
                          variant="outlined"

                        >
                          {lista_codici_m.map((cm) => (
                            <MenuItem
                              key={cm.codice}
                              value={cm.codice}
                            >
                              {cm.codice} - {cm.descrizione}
                            </MenuItem>
                          ))}

                        </ReactHookFormSelect>
                      </MDBox>
                      <MDBox>
                        <ReactHookFormSelect
                          id="codice_merceologico_04"
                          name="codice_merceologico_04"
                          label="Codice Merceologico 4"
                          control={control}
                          defaultValue=''
                          variant="outlined"
                        >
                          {lista_codici_m.map((cm) => (
                            <MenuItem
                              key={cm.codice}
                              value={cm.codice}
                            >
                              {cm.codice} - {cm.descrizione}
                            </MenuItem>
                          ))}

                        </ReactHookFormSelect>
                      </MDBox>
                    </MDBox>

                    <MDBox mb={2}>
                      <MDBox>
                        <MDInput type="text" label="Prodotti / Servizi in esposizione (descrivere in maniera dettagliata)" fullWidth required
                          name='prodotti_servizi' {...register('prodotti_servizi')} multiline rows={3} InputLabelProps={{ shrink: true }}
                          error={errors.prodotti_servizi ? true : false} />
                        {errors.prodotti_servizi ? <span style={{ color: 'red', fontSize: '12px' }}>{errors.prodotti_servizi?.message}</span> : ''}
                      </MDBox>
                    </MDBox>

                    <MDBox mb={2} sx={{ display: 'grid', gap: 1, gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr' }}>
                      <MDBox mb={2}>
                        <ReactHookFormCheckBox
                          label={<MDBox>
                            <MDTypography
                              variant="button"
                              fontWeight="regular"
                              color="text"
                              sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                            >
                              &nbsp;&nbsp;Produttore&nbsp;
                            </MDTypography>

                          </MDBox>}
                          name="produttore"
                          control={control} />
                      </MDBox>
                      <MDBox mb={2}>
                        <ReactHookFormCheckBox
                          label={<MDBox>
                            <MDTypography
                              variant="button"
                              fontWeight="regular"
                              color="text"
                              sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                            >
                              &nbsp;&nbsp;Concessionario&nbsp;
                            </MDTypography>

                          </MDBox>}
                          name="concessionario"
                          control={control} />
                      </MDBox>
                      <MDBox mb={2}>
                        <ReactHookFormCheckBox
                          label={<MDBox>
                            <MDTypography
                              variant="button"
                              fontWeight="regular"
                              color="text"
                              sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                            >
                              &nbsp;&nbsp;Distributore&nbsp;
                            </MDTypography>

                          </MDBox>}
                          name="distributore"
                          control={control} />
                      </MDBox>
                      <MDBox mb={2}>
                        <ReactHookFormCheckBox
                          label={<MDBox>
                            <MDTypography
                              variant="button"
                              fontWeight="regular"
                              color="text"
                              sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                            >
                              &nbsp;&nbsp;Rivenditore&nbsp;
                            </MDTypography>

                          </MDBox>}
                          name="rivenditore"
                          control={control} />
                      </MDBox>
                      <MDBox mb={2}>
                        <ReactHookFormCheckBox
                          label={<MDBox>
                            <MDTypography
                              variant="button"
                              fontWeight="regular"
                              color="text"
                              sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                            >
                              &nbsp;&nbsp;Importatore&nbsp;
                            </MDTypography>

                          </MDBox>}
                          name="importatore"
                          control={control} />
                      </MDBox>
                    </MDBox>


                    <MDBox mb={2}>
                    </MDBox>



                    <MDBox sx={{ display: 'flex', flexDirection: 'row', pt: 2 }} mt={4} mb={1}>
                      <MDButton variant="outlined" color="success" onClick={annulla} >
                        annulla
                      </MDButton>
                      <MDBox sx={{ flex: '1 1 auto' }} />
                      <MDBox ml={2}>

                      </MDBox>
                      <MDBox ml={2}>
                        <MDButton variant="gradient" color="success" onClick={handleSubmit(inserisci_pp)} type="submit"  >
                          avanti
                        </MDButton>
                      </MDBox>

                    </MDBox>


                    <MDBox>

                    </MDBox>


                  </MDBox>
                </CardContent>

              </MDBox>

            </Card>
          </Grid >
        </Grid >
      </MDBox >
      <Footer />
    </DashboardLayout >
  );
}

export default ExpoRichiesteEdit;
