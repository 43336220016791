import React, { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import { Link } from "react-router-dom";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "common/components/MD/MDBox";
import MDButton from "common/components/MD/MDButton";

import MDTypography from "common/components/MD/MDTypography";


// Material Dashboard 2 React example components
import DashboardLayout from "layouts/DashboardLayout";
import DashboardNavbar from "layouts/components/Navbars/DashboardNavbar";
import Footer from "layouts/components/Footer";
import HeaderForm from "layouts/components/HeaderForm";


import Alert from '@mui/material/Alert';
import FormControl from "@mui/material/FormControl";
import NativeSelect from '@mui/material/NativeSelect';

import loading_image from 'common/assets/images/icons/loading-green-loading.gif'
// Data
import ExpoRichiesteService from "services/API/ExpoRichieste/ExpoRichiesteService";
import UtilityService from "services/API/utility/UtilityService";
import base64ToArrayBuffer from "common/script/base64ToArrayBuffer";
import { Chip, IconButton, MenuItem, Select } from "@mui/material";
import ReactHookFormSelect from "common/components/mycomponents/ReactHookFormSelect";
import CleaningServicesIcon from '@mui/icons-material/CleaningServices';
import ExpoRichiesteTable from "./ExpoRichiesteTable";
import { ColumnFilter } from "common/components/implemented/Tables/DataTable/ColumnFilter";

import { SelectColumnFilterStato } from "./SelectColumnFilterStato";
import { SelectColumnFilterTipo } from "./SelectColumnFilterTipo";



function ElencoRichieste() {

  const [proposte, setProposte] = useState([]);

  


  const [filtro_man, setFiltro_man] = useState('Agriumbria 2025');
  const [filtro_stato, setFiltro_stato] = useState('INVIATA');
  

  const [loading, setLoading] = useState(true);
  const[semaforo_man, setSemaforo_man]=useState(false);
  const [lista_man, setLista_man] = useState([
    { nome_manifestazione: '', iscrizioni_aperte: false, pdf_regolamento: '' },
  ]);

 
  useEffect(() => {
    setLista_man([]);
    console.log("CARICO MANIFESTAZIONI");
    UtilityService.getManAperte()
      .then(response => {
        console.log(" MANIFESTAZIONI:");
    
        return response.data;
      })
      .then(data =>{
        console.log(data[0]);
        setLista_man([...data]);
        setFiltro_man(data[0].nome_manifestazione);
        setSemaforo_man(true);
      }


      )
        
        
        
          
       //   setFiltro_man(response.data[0].nome_manifestazione);
        //  setSemaforo_man(true);
      /* if(filtro_man==="") {setFiltro_man(response.data[0].nome_manifestazione);
        console.log("0=:::"+filtro_man+":::");
       }
       else console.log("!0=:::"+filtro_man+":::");   
      }
     )*/
      .catch(e => {
        console.log(e);
      //  setSemaforo_man(true);
      });
  }, []);


  //per passarmi la variabiule se ho salvato e far comparire l'alert
  const location = useLocation();
  //console.log(location);

  const { state } = location != null ? location : { state: { submitted: false, salvato: 'NO',indietro:false } }; // Read values passed on state



 

 

  useEffect(() => {  

    console.log("CARICO var di sessione");
      if(state == null || !state.indietro) resetState();
      else{
      //  console.log("STATE!=NULL ->"+filtro_man);
     /* if(sessionStorage.getItem("proposte_filtro_man")!='')
        setFiltro_man( sessionStorage.getItem("proposte_filtro_man"));*/
        
      setFiltro_stato( JSON.parse(sessionStorage.getItem("proposte_filtro_stato")));
        
    
      }
    
     }, []);

     const resetState = () => {
      console.log("RESETTO LA SESSIONE RICERCA")
      sessionStorage.removeItem('proposte_filtro_man');
      setFiltro_man('Agriumbria 2025');
      sessionStorage.removeItem('proposte_filtro_stato');
      setFiltro_stato('INVIATA');
  
    };

     // Salva lo stato della pagina corrente in sessione ogni volta che cambia
 useEffect(() => {
  
    if(filtro_man!=''){
      console.log("CAMBIO FILTROMAN");
    sessionStorage.setItem("proposte_filtro_man", filtro_man);}
  }, [filtro_man]);
   // Salva lo stato della pagina corrente in sessione ogni volta che cambia
   useEffect(() => {
    console.log("CAMBIO FILTRO STATO");
    sessionStorage.setItem("proposte_filtro_stato", JSON.stringify(filtro_stato));
  }, [filtro_stato]);


  useEffect(() => {
   if(semaforo_man){
    console.log("FILTRO");
    setProposte([]);
    setLoading(true);

    ExpoRichiesteService.getAllRichieste(filtro_man, filtro_stato,'ALL')
      .then(response => {
        setProposte(response.data);
        setLoading(false);
      })
      .catch(e => {
        console.log(e);
        setLoading(false);
      });
    }
  }, [semaforo_man,filtro_man,filtro_stato]);

  const cambiatoMan = (e) => {
    console.log('cambiatoMan:');
    setFiltro_man(e.target.value);

  }
  const cambiatoStato = (e) => {
    console.log('cambiatoStato:');
    setFiltro_stato(e.target.value);

  }

  

  async function esporta_xls() {
    console.log("esporta_xls");

    let filename = 'ListaProposte.xls';

    ExpoRichiesteService.esporta_xls(filtro_man, filtro_stato)
      .then(response => {
        /*  const disposition = response.headers['content-disposition'];
         filename = disposition.split(/;(.+)/)[1].splitvv(/=(.+)/)[1];
          if (filename.toLowerCase().startsWith ("utf-8''"))
             filename = decodeURIComponent(filename.replace("utf-8''", ''));
          else
             filename = filename.replace(/['"]/g, '');*/
        return response.data;
      })
      .then(blob => {

        var arrrayBuffer = base64ToArrayBuffer(blob); //data is the base64 encoded string
        var blobb = new Blob([arrrayBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
        var url = URL.createObjectURL(blobb);
        var a = document.createElement('a');
        a.href = url;
        a.target = "_blank";
        a.download = filename;
        document.body.appendChild(a); // append the element to the dom
        a.click();
        a.remove(); // afterwards, remove the element  
      })
      .catch(error => {
        console.error(error);
      });

  }





  const ppAttributes = [];

  if (proposte.length > 0) {
    proposte.forEach(prop => {

      let action = (
        <MDBox>
          <Link to="/expo_richiestaAccept" state={{ modo: 'edit', soggetto: prop, subject: prop.manifestazione }}
          >
            <MDButton variant="outlined" color={"success"} iconOnly={true} >
              <Icon>edit</Icon>
            </MDButton>   </Link>
         
         {/*   <Link  to="/expo_richiestaMessage" state={{ modo: 'edit', soggetto: prop, subject: prop.manifestazione }}
          >
            <MDButton variant="outlined" color={"success"} iconOnly={true}  >
              <Icon>message</Icon>
            </MDButton>   </Link> */}
          <Link to="/expo_richiestaPDFVis" state={{ modo: 'vis', soggetto: prop, subject: prop.manifestazione }}
          >
            <MDButton variant="outlined" color={"success"} iconOnly={true} >
              <Icon>visibility</Icon>
            </MDButton>
          </Link>
        </MDBox>
      );
      if (prop.stato === "IN COMPILAZIONE") {
        action = (

          <MDBox><Link to="/expo_richiestaAccept" state={{ modo: 'edit', soggetto: prop, subject: prop.manifestazione }}>

            <MDButton variant="outlined" color={"success"} iconOnly={true}>
              <Icon>edit</Icon>
            </MDButton>
          </Link>
            <MDButton variant="outlined" color={"success"} iconOnly={true} disabled={true} >
              <Icon>visibility</Icon>
            </MDButton>
          </MDBox>
        );


      }
      //così non funziona il filtro
      // let div_azienda=  <div className="text-wrap">{prop.ragione_sociale}</div> ;
      let etichetta=(
        <Chip label={prop.etichetta} color={prop.color} />

      )
      let te=(
       <MDBox>
         <MDBox> <MDTypography  variant="button"
                    fontWeight="regular"
                    color="text">{prop.tipologia_espositiva_string}</MDTypography></MDBox>
         {prop.esigenze_espositive &&
          <MDBox>  <MDTypography  variant="button"
                    fontWeight="regular"
                    color="text">*** {prop.esigenze_espositive.substring(0,20)}...</MDTypography> </MDBox>     }   
                      {prop.note &&
          <MDBox>  <MDTypography  variant="button"
                    fontWeight="regular"
                    color="text">*** {prop.note.substring(0,20)}...</MDTypography> </MDBox>     }   
       </MDBox>

      )



      ppAttributes.push({
        protocollo: prop.protocollo,
        azienda: prop.ragione_sociale,
        data_invio: prop.string_data_agg,
        data_um: prop.string_data_agg_accettazione,
      note:prop.note,
        cm_string: prop.cm_string,
        stato: prop.stato_accettazione,
        av:prop.anticipo_controllato_string,
        etichetta:etichetta,
        action: action,
        esigenze_espositive:prop.esigenze_espositive,
        tipologia_espositiva_string:prop.tipologia_espositiva_string
      })
    });
  }

  function filterByStatus(rows, filterValue) {
   console.log("personal filter");
    return rows.filter(row => {
      const rowValue = row.tipologia_espositiva_string;
      return rowValue.includes(filterValue);
  });
  }

  const ppTableData = {
    rows: ppAttributes,

    columns: [
      { Header: "Protocollo", accessor: "protocollo",Filter:ColumnFilter, width: "2%", align: "left",id:"proposte_0" },
      { Header: "Azioni", accessor: "action", Filter:ColumnFilter, align: "center", enableGlobalFilter: false,id:"proposte_1" },

      { Header: "Azienda", accessor: "azienda",Filter:ColumnFilter, align: "left", with: "50%" ,id:"proposte_2"},
      { Header: "Codice Merceologico", accessor: "cm_string",Filter:ColumnFilter, width: "10%", align: "left" ,id:"proposte_3"},
      { Header: "Tipologia Espositiva Richiesta", accessor: "tipologia_espositiva_string", 
        id:"proposte_4",
        
        Filter:SelectColumnFilterTipo,
        Cell: ({ row }) => {
          return (
            <MDBox>
            <MDBox> <MDTypography  variant="button"
                       fontWeight="regular"
                       color="text">{row.original.tipologia_espositiva_string}</MDTypography></MDBox>
            {row.original.esigenze_espositive &&
             <MDBox>  <MDTypography  variant="button"
                       fontWeight="regular"
                       color="text">*** {row.original.esigenze_espositive.substring(0,20)}...</MDTypography> </MDBox>     }   
                         {row.original.note &&
             <MDBox>  <MDTypography  variant="button"
                       fontWeight="regular"
                       color="text">*** {row.original.note.substring(0,20)}...</MDTypography> </MDBox>     }   
          </MDBox>
   
          );
        },
        width: "15%", align: "left" },
      { Header: "Stato", accessor: "stato",Filter:SelectColumnFilterStato, align: "left", width: "5%", id:"proposte_5"},
      { Header: "Anticipo Controllato", accessor: "av",Filter:ColumnFilter, align: "left", width: "5%",id:"proposte_6" },
      { Header: "Etichetta", accessor: "etichetta",Filter:ColumnFilter, align: "left", width: "5%",id:"proposte_7" },
      { Header: "Data Ultima Modifica", accessor: "data_um",Filter:ColumnFilter, width: "10%", align: "left",id:"proposte_8" },
     

    ],



  };

  const mypagination = { color: 'success', variant: "contained" };

  const panel_search = (
    <MDBox pt={4} pb={3} px={3} sx={{ display: 'grid', gap: 1, gridTemplateColumns: ' 1fr 1fr 1fr 1fr' }}  >
      <FormControl >
        <MDTypography variant="button" htmlFor="manifestazione" shrink={true} >Manifestazione</MDTypography>
       {semaforo_man &&  <Select  name= 'manifestazione' id= 'manifestazione'
          onChange={cambiatoMan}
       // defaultValue= {lista_man[0].nome_manifestazione}
          value={filtro_man}  disabled={!semaforo_man}
        >  {lista_man.map((man) => (
          <MenuItem key={man.nome_manifestazione}
            value={man.nome_manifestazione}>
            {man.nome_manifestazione}
          </MenuItem>
        ))}
        </Select>}
      </FormControl>
      <FormControl >
        <MDTypography variant="button" htmlFor="stato" shrink={true} >Stato richiesta</MDTypography>
        <Select name= 'stato' id= 'stato'
          onChange={cambiatoStato}
        value={filtro_stato}   >
          <MenuItem key="IN COMPILAZIONE" value="IN COMPILAZIONE">IN COMPILAZIONE</MenuItem>
          <MenuItem key="INVIATA" value="INVIATA">INVIATE</MenuItem>
        </Select>
      </FormControl> 
      <IconButton variant="outlined"  color="success" onClick={resetState}>
                      <CleaningServicesIcon fontSize="small" /></IconButton>
                           
    </MDBox>

  );



  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>

          <Grid item xs={12}>
            <Card>
              <HeaderForm titolo="Proposte di Partecipazione" add="" modo="vis" />
              <MDBox pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center">

                {(state != null && state.submitted && (state.salvato === 'INS' || state.salvato === 'MOD')) &&

                  <Alert severity="success">Dati Proposta salvati con successo!</Alert>}
                {(state != null && state.submitted && (state.salvato === 'SENT')) &&

                  <Alert severity="success">Proposta di partecipazione inviata con successo!</Alert>}
                {(state != null && state.submitted && state.salvato === 'DEL') &&

                  <Alert severity="success">Proposta eliminata con successo!</Alert>}
              </MDBox>


              <MDBox pt={3}>
                <ExpoRichiesteTable canSearch={true}
                  table={ppTableData}
                  nome_tabella="proposte"
                  isSorted={false}
                  entriesPerPage={true}
                  showTotalEntries={true}
                  pagination={mypagination}
                  noEndBorder
                  responsive={true}
                  first_load={state==null || !state.indietro}
                  filtro_server={true}
                  panel_search={panel_search}
                  filtro_0={filtro_man}
                  filtro_1={filtro_stato}
                  canExport={true} esporta_xls={esporta_xls}

                  enableColumnFilters={true}
                  

                />
              </MDBox>
              {loading && 
                       <MDBox opacity={1} textAlign="center">
                       <img src={loading_image} alt="loading" width={100} />   
                       </MDBox>
              }    

              {(proposte == null || proposte.length === 0) &&
                <MDBox style={{ height: "200px" }} textAlign='center'>
                  <MDTypography
                    variant="button"
                    fontWeight="regular"
                    color="text"
                    sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                  >
                    Non ci sono Proposte di partecipazione in archivio. Clicca su Nuovo per crearne una!
                  </MDTypography></MDBox>
              }
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}



export default ElencoRichieste;
