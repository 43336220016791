import axios from 'axios';
import authHeader from 'services/API/Auth/AuthHeader';
import AuthService from 'services/API/Auth/AuthService.js';
import authHeaderMultipart from '../Auth/AuthHeaderMultipart';


const seleziona_partecipazione =(man)=>{
 
    console.log("manifestazione "+ man+" id_utente="+AuthService.getCurrentUser().id);
   
    return axios.get(axios.defaults.baseURL + `/expo_partecipazioni/seleziona/${AuthService.getCurrentUser().id}/${man}`,{headers: authHeader()});
  
  
}
const seleziona =(id)=>{
    console.log("seleziona= "+id);
    return axios.get(axios.defaults.baseURL + `/expo_partecipazioni/seleziona/${id}`,{headers: authHeader()});
}


const getAllPartecipazioni=(man)=> {
    console.log("getAllPartecipazioni man="+man);
    return axios.get(axios.defaults.baseURL + `/expo_partecipazioni/all/${man}`,  {headers: authHeader()});
}

const sendMsg=(datas,id_modello) => {
    console.log(datas);
         return axios.post(axios.defaults.baseURL + `/expo_partecipazioni/invia_messaggi/${id_modello}`,datas, {headers: authHeader()
   })  
  };

const genera_pdf_contratto =(id)=>{

    console.log("genera pdf contratto= "+id);
const response= axios.get(axios.defaults.baseURL + `/expo_partecipazioni/contratto/${id}`,{headers: authHeader()}, {responseType: 'blob'},);

return response;
}

const invia_contratto = (id, data) => {
    console.log("invia contratto= "+id);
    return axios.post(axios.defaults.baseURL + `/expo_partecipazioni/carica_contratto/${id}`, data,{headers: authHeaderMultipart()});
  };


  const download_pdf_contratto= (idPartecipazione) => {

    
    const response = axios.get(axios.defaults.baseURL + `/expo_partecipazioni/download_contratto/${idPartecipazione}`, { headers: authHeader() }, { responseType: 'blob' },);
    console.log(response);
    return response;
}

const salva_spazio = (id, data) => {
    console.log("update= "+id);
    console.log(data);
    return axios.post(axios.defaults.baseURL + `/expo_partecipazioni/salva_spazio/${id}`, data,{headers: authHeader()});
  };
  const esporta_xls_row=(righe)=>{

    const response= axios.post(axios.defaults.baseURL + `/expo_partecipazioni/excel_row`, righe,{headers: authHeader()}, {responseType: 'blob'});

    return response;

}
const esporta_xls_row_lite=(man)=>{

  const response= axios.post(axios.defaults.baseURL + `/expo_partecipazioni/excel_row_lite/${man}`,null, {headers: authHeader()}, {responseType: 'blob'});

  return response;

}

const updateDC = (idPartecipazione, data) => {
    console.log("update= "+idPartecipazione);
    return axios.post(axios.defaults.baseURL + `/expo_partecipazioni/updateDC/${idPartecipazione}`, data,{headers: authHeader()});
  };
const seleziona_catalogo=(id_catalogo)=>{
    return axios.get(axios.defaults.baseURL + `/expo_partecipazioni/seleziona_catalogo/${id_catalogo}`,{headers: authHeader()});

};

const inviaDC = (id) => {
    console.log("invia catalogo= "+id);
    return axios.post(axios.defaults.baseURL + `/expo_partecipazioni/inviaDC/${id}`,null, {headers: authHeader()});
  };
  const getAllDatiCatalogo=(man)=> {
    console.log("getAllDatiCatalogo man="+man);
    return axios.get(axios.defaults.baseURL + `/expo_partecipazioni/allDC/${man}`,  {headers: authHeader()});
}

const esporta_xls_row_dc=(righe)=>{

    const response= axios.post(axios.defaults.baseURL + `/expo_partecipazioni/excel_row_dc`, righe,{headers: authHeader()}, {responseType: 'blob'});

    return response;

}

const segna_lavorato=(righe)=>{

    const response= axios.post(axios.defaults.baseURL + `/expo_partecipazioni/segna_lavorato`, righe,{headers: authHeader()});

    return response;

}


const updateOneDC = (id_dc,data) => {
    //console.log("update= "+idPartecipazione);
    return axios.post(axios.defaults.baseURL + `/expo_partecipazioni/updateOneDC/${id_dc}`, data,{headers: authHeader()});
  };

  const deleteOneDC = (id_dc) => {
    //console.log("update= "+idPartecipazione);
    return axios.post(axios.defaults.baseURL + `/expo_partecipazioni/deleteOneDC/${id_dc}`,null,{headers: authHeader()});
  };
  const genera_pass =(idRichiesta)=>{

    console.log("genera pdf pass idRichiesta= "+idRichiesta);
const response= axios.get(axios.defaults.baseURL + `/expo_partecipazioni/pass/${idRichiesta}`,{headers: authHeader()}, {responseType: 'blob'},);
return response;
}
const ExpoPartecipazioniService = {
    getAllPartecipazioni,
    seleziona_partecipazione,genera_pdf_contratto,invia_contratto,download_pdf_contratto,
    seleziona,salva_spazio,esporta_xls_row,updateDC,seleziona_catalogo,sendMsg,inviaDC,
    getAllDatiCatalogo,esporta_xls_row_dc,segna_lavorato,updateOneDC,deleteOneDC,esporta_xls_row_lite,
    genera_pass
}

export default ExpoPartecipazioniService;