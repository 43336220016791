import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import MuiLink from "@mui/material/Link";

// Material Dashboard 2 React components
import MDBox from "common/components/MD/MDBox";
import MDTypography from "common/components/MD/MDTypography";
import MDButton from "common/components/MD/MDButton";

import LogoAgri2025 from "common/assets/images/logos/logo_data_2025.png"

import UtilityService from "services/API/utility/UtilityService";
import base64ToArrayBuffer from "common/script/base64ToArrayBuffer";

function Banner_2025({ action }) {

  async function download_pdf() {
    console.log("download_pdf " );
    
        let filename = 'TARIFFE_2025.pdf';
     
        UtilityService.download_file(filename)    
            .then(response => {
              
                return response.data;
            })
            .then(blob => {

                var arrrayBuffer = base64ToArrayBuffer(blob); //data is the base64 encoded string
                var blobb = new Blob([arrrayBuffer], { type: "application/pdf" });
                var url = URL.createObjectURL(blobb);
                var a = document.createElement('a');
                a.href = url;
                a.target = "_blank";
                a.download = filename;
                document.body.appendChild(a); // append the element to the dom
                a.click();
                a.remove(); // afterwards, remove the element  
            })
            .catch(error => {
                console.error(error);
            });

          }

  return (
    <Card>
      <MDBox position="relative" borderRadius="lg" mt={-3} mx={2}>
        <MDBox
          component="img"
          src={LogoAgri2025}
          alt="Agriumbria 2025"
          borderRadius="lg"
          shadow="md"
          width="90%"
          height="100%"
          position="relative"
          padding={5}
          zIndex={1}
        />
     
      </MDBox>
      <MDBox p={3}>
        <MDTypography display="inline" variant="h3" textTransform="capitalize" fontWeight="bold">
        "Agriumbria 2025"
        </MDTypography>
        <MDBox mt={2} mb={3}>
          <MDTypography variant="body2" component="p" color="text">
          Questo percorso ti permetterà di accedere alla tua <b>Area</b>, tramite la quale gestire tutte le operazioni per Agriumbria 2025.
Dopo aver creato ed attivato la tua Area Riservata, potrai formulare la tua <b>Proposta di Partecipazione</b>, che sarà valutata dalla Commissione Tecnico-Commerciale.
La data entro cui presentare la Proposta di Partecipazione è il <b>30 novembre 2024</b>.
          </MDTypography>
        </MDBox>
        {action.type === "external" ? (
          <MuiLink href={action.route} target="_blank" rel="noreferrer">
            <MDButton color={action.color ? action.color : "dark"}>{action.label}</MDButton>
          </MuiLink>
      
        ) : (
          <Link to={action.route}>
            <MDButton color={action.color ? action.color : "dark"}>{action.label}</MDButton>
          </Link>

        )}
 
   
      <MDBox mt={2} mb={3} >    
      <Link to="https://far.umbriafiere.it/download/TARIFFE_2025.pdf" target="_blank">
         <MDButton variant="outlined" color="success"  >
                                                            Scarica le Tariffe
                                                        </MDButton>
                                                        
                                                        </Link>
                                                        </MDBox>
                                                        </MDBox>
    </Card>
  );
}

// Typechecking props for the SimpleBlogCard
Banner_2025.propTypes = {
   action: PropTypes.shape({
    type: PropTypes.oneOf(["external", "internal"]).isRequired,
    route: PropTypes.string.isRequired,
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "light",
      "default",
    ]),
    label: PropTypes.string.isRequired,
  }).isRequired,
};

export default Banner_2025;
