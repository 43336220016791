import { useState, useEffect } from "react";
import { Link, useNavigate } from 'react-router-dom';
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
// Material Dashboard 2 React components
import MDBox from "common/components/MD/MDBox";
import MDButton from "common/components/MD/MDButton";

import MDTypography from "common/components/MD/MDTypography";


// Material Dashboard 2 React example components
import DashboardLayout from "layouts/DashboardLayout";
import DashboardNavbar from "layouts/components/Navbars/DashboardNavbar";
import Footer from "layouts/components/Footer";
import HeaderForm from "layouts/components/HeaderForm";

//prop-types is a library for typechecking of props
// Data
import { useManifestazione } from "common/context/GlobalContext";
import DefaultInfoCard from "common/components/implemented/Cards/InfoCards/DefaultInfoCard";
import ExpoPartecipazioniService from "services/API/expo_partecipazioni/ExpoPartecipazioniService";
import base64ToArrayBuffer from "common/script/base64ToArrayBuffer";
import { Alert, AppBar } from "@mui/material";
import MDAlert from "common/components/MD/MDAlert/MDAlert";
import SpazioComponent from "./SpazioComponent";
import AuthService from "services/API/Auth/AuthService";
import MDInput from "common/components/MD/MDInput";
import { useForm } from "react-hook-form";

function AreaFiera() {

  const { manifestazione } = useManifestazione();

  const navigate = useNavigate();
  const [part, setPart] = useState({
    idPartecipazione: 0,
    manifestazione: manifestazione,
    id_utente: AuthService.getCurrentUser().id,

    ragione_sociale: '',
    rappresentante_legale: '', indirizzo: '', civico: '', cap: '', localita: '', indirizzo2: '', provincia: '',
    piva: '', cf: '', telefono: '', fax: '', www: '', email: '', pec: '', iban: '', codice_sdi: '',
    cm_code_01: '', cm_desc_01: '', cm_code_02: '', cm_desc_02: '', cm_code_03: '', cm_desc_03: '', cm_code_04: '', cm_desc_04: '',
    responsabile_fiera: '', responsabile_fiera_telefono: '', responsabile_fiera_cell: '', responsabile_fiera_email: '',
    prodotti_servizi: '', produttore: false, concessionario: false, distributore: false, rivenditore: false, importatore: false,
    richieste: [],
    quota_iscrizione: 0,
    imponibile_ok: 0, aliquota_ok: 22, iva_ok: 0, tot_a_pagare: 0, anticipo_ok: 0, saldo: 0,
  });



  useEffect(() => {
    if (AuthService.isTokenOk()) {
    seleziona_soggetto();
    }
  }, []);

  async function seleziona_soggetto() {
    console.log("Cambiata pp->seleziono i dettagli :" + manifestazione);

    const response = await ExpoPartecipazioniService.seleziona_partecipazione(manifestazione);
    response.errors ? console.log(response.errors) : retrive(response.data);

  }
  function retrive(data) {

    setPart({
      idPartecipazione: data.idPartecipazione,
      id_expo_richiesta: data.id_expo_richiesta,
      id_utente: data.id_utente,
      nome_pdf_contratto: data.nome_pdf_contratto,
      richieste: data.richieste,
      saldato:data.saldato
    });

  }

  const {
    register, //register inputs
    setValue, control,

  } = useForm({
  });


  const [titoli, setTitoli] = useState([]);

    const modulo_a = (
    <Link to="https://far.umbriafiere.it/download/planimetria_ingressi.pdf" target="_blank">
      <MDButton variant="outlined" color="success"  >
        planimetria_ingressi.pdf
      </MDButton>

    </Link>

  );


  useEffect(() => {

    console.log("Cambiata pp->riempo i campi  " + part.idPartecipazione);
    //  setRichiesta0(part.richieste[0]);
  
    let tit = []; // Oggetto per memorizzare i valori dinamici

    part.richieste.map((r) => {
      if (r.spazioAssegnatoDto != null) {
       

       
        const name = "spazio_" + r.idRichiesta;
        setValue(name, r.spazioAssegnatoDto.spazio);

        const num = "numero_" + r.idRichiesta;
        setValue(num, r.spazioAssegnatoDto.numero);

        const key = "key_" + r.idRichiesta; // Nome dinamico della chiave
       let nome_file="Pass_Allestimento_ABC.pdf" ;
        if(r.spazioAssegnatoDto.spazio!=null){
          if (r.spazioAssegnatoDto.spazio ==="PADIGLIONE 7" ||r.spazioAssegnatoDto.spazio ==="PADIGLIONE 8" ||r.spazioAssegnatoDto.spazio ==="PADIGLIONE 9" 
          || r.spazioAssegnatoDto.spazio === "PIAZZALE A"|| r.spazioAssegnatoDto.spazio ==="PIAZZALE B" || r.spazioAssegnatoDto.spazio ==="PIAZZALE C"
          ||r.spazioAssegnatoDto.spazio ==="PIAZZALE D" ||r.spazioAssegnatoDto.spazio ==="PIAZZALE E" || r.spazioAssegnatoDto.spazio ==="PIAZZALE F"
        ) {
          nome_file="Pass_Allestimento_ABC.pdf";
        }else{
      
        if (r.spazioAssegnatoDto.spazio ==="PADIGLIONE 10" ||r.spazioAssegnatoDto.spazio ==="PADIGLIONE 11" || r.spazioAssegnatoDto.spazio ==="PADIGLIONE 12"
        || r.spazioAssegnatoDto.spazio ==="PIAZZALE G" || r.spazioAssegnatoDto.spazio ==="PIAZZALE I"|| r.spazioAssegnatoDto.spazio === "PIAZZALE H"
      ) {
        nome_file="Pass_Allestimento_DEF.pdf";
      }
        }}
      
       
        tit[key] = (
            <MDButton variant="outlined" color="success" onClick={()=>stampa_pass(r.idRichiesta)} >
           {nome_file}
            </MDButton>
       );
     

     
                          }

  
    });



    setTitoli(tit);
 


  }, [part]);



  async function stampa_pass(idRichiesta) {

    ExpoPartecipazioniService.genera_pass(idRichiesta)
      .then(response => {

        return response.data;
      })
      .then(blob => {

        var arrrayBuffer = base64ToArrayBuffer(blob); //data is the base64 encoded string
        var blobb = new Blob([arrrayBuffer], { type: "application/pdf" });
        var url = URL.createObjectURL(blobb);
        var a = document.createElement('a');
        a.href = url;
        a.target = "_blank";

        a.download = "PassEspositore.pdf";
        

        document.body.appendChild(a); // append the element to the dom
        a.click();
        a.remove(); // afterwards, remove the element  
      })
      .catch(error => {
        console.error(error);
      });




  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>

          <Grid item xs={12}>
            <Card>
              <HeaderForm titolo={"Pass Allestimento / Disallestimento " + manifestazione} color="info" />

              <MDBox p={10}>
{part.saldato && 
                <MDBox  >
                  {part.richieste.map((richiesta) => (
                    <MDBox>    
                    {richiesta.spazioAssegnatoDto != null &&
                    <MDBox>

                      <MDBox mb={2} mt={2} sx={{ display: 'grid', gap: 1, gridTemplateColumns: '1fr 1fr ' }}>
                        <MDInput type="text" label="Spazio" name={"spazio_" + richiesta.idRichiesta} fullWidth
                          {...register("spazio_" + richiesta.idRichiesta)} InputLabelProps={{ shrink: true }} disabled />

                        <MDInput type="text" label="Numero" name={"numero_" + richiesta.idRichiesta} fullWidth
                          {...register("numero_" + richiesta.idRichiesta)} InputLabelProps={{ shrink: true }} disabled/>
                      </MDBox>
                      <MDBox mb={2} mt={2} sx={{ display: 'grid', gap: 10, gridTemplateColumns: '1fr 1fr' }}>
                      <DefaultInfoCard
                          color="success"
                          icon="map"
                          title={titoli["key_"+richiesta.idRichiesta]}
                          description={"Qui puoi stampare il tuo pass per l'allestimento / disallestimento fiera"}
                                               />

<DefaultInfoCard
                    color="success"
                    icon="map"
                    title={modulo_a}
                    description={"Planimetria Varchi Agriumbria 2025"}
                    value=""
                  />
                      </MDBox>
                      </MDBox>}

                    </MDBox>
                  ))}
                </MDBox>

}
{!part.saldato &&

                  <MDBox>
                  <DefaultInfoCard
                        
                        color="success"
                        icon="alarmOn"
                        title="Pass allestimento / disallestimento"
                        description={"Attenzione, il pass sarà disponibile una volta effettuato il saldo!"}
                        value=""
                      />
                  </MDBox>
}
              </MDBox>


            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}



export default AreaFiera;
