import axios from "axios";
import { jwtDecode } from "jwt-decode";




const login = (username, password) => {



    // console.log('AUTHService.js username=' +username);
    return axios.post(axios.defaults.baseURL + "/auth/signin",
      {
        username,
        password
      }).then(response => {

        if (response.data.accessToken) {
          localStorage.setItem("user", JSON.stringify(response.data));
          console.log('AUTHService.js login ok');
        };
        //  console.log("role"+ JSON.parse(localStorage.getItem('user').roles));
        return response.data;
      }
      )
    /* .catch(error=>{
       alert("Server non disponibile!")

     });*/
  
}




const getCurrentUser = () => {
  //   console.log('AUTHService.js getCurrentUser =' +localStorage.getItem('user'));
  return JSON.parse(localStorage.getItem('user'));
}

const isLoggedIn = () => {

  const user = JSON.parse(localStorage.getItem('user'));
  /* if (user && user.accessToken) {
       const decoded = jwtDecode(user.accessToken);
       const currentTime = Date.now() / 1000;
   
       if (decoded.exp < currentTime) {
         // Token scaduto, esegui il logout o richiedi un nuovo token
         //alert("Sessione scaduta. Effettua di nuovo il login.");
         localStorage.removeItem(user);
        // window.location.href = "/login";
         return false;
       }
   
       
     }else {
       return true;
     }*/
  return (user != null ? true : false);

}

const isTokenOk = () => {
  const user = JSON.parse(localStorage.getItem('user'));
  if (user && user.accessToken) {
    const decoded = jwtDecode(user.accessToken);
    const currentTime = Date.now() / 1000;

    if (decoded.exp < currentTime) {
      // Token scaduto, esegui il logout o richiedi un nuovo token
      alert("Sessione scaduta. Effettua di nuovo il login.");
     // console.log("dopo alert");
      localStorage.removeItem("user");
      localStorage.removeItem("token");
      sessionStorage.removeItem("token");
      //console.log("rimosso user da localstorage");
       window.location.href = "/login";
      return false;

    } else {
      console.log("ToKEN OK ");
      return true;
    }

  } else {

    return false;
  }

}

const monitorTokenExpiration = (token) => {
  const decoded = jwtDecode(token);
  const expirationTime = decoded.exp * 1000; // Converti in millisecondi
  const timeUntilExpiration = expirationTime - Date.now();

  setTimeout(() => {
    alert("Il tuo token sta per scadere. Aggiorna la sessione!");
  }, timeUntilExpiration - 30000); // Avvisa 30sec  prima della scadenza
};



const isExpo = () => {
  const user = JSON.parse(localStorage.getItem('user'));
  if (user != null) {
    return JSON.parse(localStorage.getItem('user')).roles.includes("ROLE_ESPOSITORE");
  } else {
    return false;

  }
}


const isAdmin = () => {


  const user = JSON.parse(localStorage.getItem('user'));

  if (user != null) {
    //console.log(user.roles);
    const exists = user.roles.includes("ROLE_ADMIN");
    //console.log("ISADMIN RETURN "+exists);
    return exists;
  } else {
    return false;

  }

}

const getRoles=()=>{
  const user = JSON.parse(localStorage.getItem('user'));

  if (user != null) {
    return user.roles;
  }
  return null;
}


const logout = () => {
  localStorage.removeItem("token");
  sessionStorage.removeItem("token");
  localStorage.removeItem("user");
}




const AuthService = {
  login,
  logout,
  isLoggedIn,
  getCurrentUser,
  isAdmin, isExpo,
  isTokenOk,monitorTokenExpiration,getRoles
};

export default AuthService;