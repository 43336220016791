import { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import { Link } from "react-router-dom";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "common/components/MD/MDBox";
import MDButton from "common/components/MD/MDButton";

import MDTypography from "common/components/MD/MDTypography";


// Material Dashboard 2 React example components
import DashboardLayout from "layouts/DashboardLayout";
import DashboardNavbar from "layouts/components/Navbars/DashboardNavbar";
import Footer from "layouts/components/Footer";
import HeaderForm from "layouts/components/HeaderForm";


import Alert from '@mui/material/Alert';

import loading_image from 'common/assets/images/icons/loading-green-loading.gif';
// Data
import ExpoRichiesteService from "services/API/ExpoRichieste/ExpoRichiesteService";

import { Chip } from "@mui/material";
import ExpoRichiesteTable from "./ExpoRichiesteTable";
import { ColumnFilter } from "common/components/implemented/Tables/DataTable/ColumnFilter";

import { SelectColumnFilterStato } from "./SelectColumnFilterStato";
import { SelectColumnFilterTipo } from "./SelectColumnFilterTipo";


import currencyFormat from "common/script/currencyFormat";
import { useManifestazione } from "common/context/GlobalContext";
import { NoneFilter } from "./NoneFilter";

function ElencoRichieste() {

  const [proposte, setProposte] = useState([]);

  const [loading, setLoading] = useState(true);


  //per passarmi la variabiule se ho salvato e far comparire l'alert
  const location = useLocation();
  //console.log(location);

  const { state } = location != null ? location : { state: { submitted: false, salvato: 'NO', indietro: false } }; // Read values passed on state

 // const { manifestazione } = useManifestazione();
  const  manifestazione= "Agriumbria 2025";



  useEffect(() => {

    console.log("FILTRO+" + manifestazione);
    setProposte([]);
    setLoading(true);

    ExpoRichiesteService.getAllRichieste(manifestazione, "INVIATA", 'ALL')
      .then(response => {
        setProposte(response.data);
        setLoading(false);
      })
      .catch(e => {
        console.log(e);
        setLoading(false);
      });

  }, []);


  const ppAttributes = [];

  if (proposte.length > 0) {
    proposte.forEach(prop => {

      let action = (
        <MDBox>
          <Link to="/expo_richiestaAccept" state={{ modo: 'edit', soggetto: prop, subject: prop.manifestazione, chiamante: 'expo_richieste_admin' }}
          >
            <MDButton variant="outlined" color={"success"} iconOnly={true} >
              <Icon>edit</Icon>
            </MDButton>   </Link>

          <Link to="/expo_richiestaPDFVis" state={{ modo: 'vis', soggetto: prop, subject: prop.manifestazione, chiamante: 'expo_richieste_admin' }}
          >
            <MDButton variant="outlined" color={"success"} iconOnly={true} >
              <Icon>visibility</Icon>
            </MDButton>
          </Link>
        </MDBox>
      );
      if (prop.stato === "IN COMPILAZIONE") {
        action = (

          <MDBox><Link to="/expo_richiestaAccept" state={{ modo: 'edit', soggetto: prop, subject: prop.manifestazione, chiamante: 'expo_richieste_admin' }}>

            <MDButton variant="outlined" color={"success"} iconOnly={true}>
              <Icon>edit</Icon>
            </MDButton>
          </Link>
            <MDButton variant="outlined" color={"success"} iconOnly={true} disabled={true} >
              <Icon>visibility</Icon>
            </MDButton>
          </MDBox>
        );


      }
      //così non funziona il filtro
      // let div_azienda=  <div className="text-wrap">{prop.ragione_sociale}</div> ;
      let etichetta = (
        <Chip label={prop.etichetta} color={prop.color} />

      )
      let te = (
        <MDBox>
          <MDBox> <MDTypography variant="button"
            fontWeight="regular"
            color="text">{prop.tipologia_espositiva_string}</MDTypography></MDBox>
          {prop.esigenze_espositive &&
            <MDBox>  <MDTypography variant="button"
              fontWeight="regular"
              color="text">*** {prop.esigenze_espositive.substring(0, 20)}...</MDTypography> </MDBox>}
          {prop.note &&
            <MDBox>  <MDTypography variant="button"
              fontWeight="regular"
              color="text">*** {prop.note.substring(0, 20)}...</MDTypography> </MDBox>}
        </MDBox>

      )



      ppAttributes.push({
        er: prop,
        mqr: prop.mqr,
        protocollo: prop.protocollo,
        azienda: prop.ragione_sociale,
        data_invio: prop.string_data_agg,
        data_um: prop.string_data_agg_accettazione,
        note: prop.note,
        cm_string: prop.cm_string,
        stato: prop.stato_accettazione,
        av: prop.anticipo_controllato_string,
        etichetta: prop.etichetta,
        color: prop.color,
        action: action,
        esigenze_espositive: prop.esigenze_espositive,
        tipologia_espositiva_string: prop.tipologia_espositiva_string,
        rimanenza: currencyFormat(prop.rimanenza)
      })
    });
  }



  const ppTableData = {
    rows: ppAttributes,

    columns: [

      { Header: "Protocollo", accessor: "protocollo", Filter: ColumnFilter, width: "2%", align: "left", id: "proposte_0" },
      { Header: "Azioni", accessor: "action", Filter: NoneFilter, align: "center", enableGlobalFilter: false, id: "proposte_1", disableSortBy: true },
      { Header: "Azienda", accessor: "azienda", Filter: ColumnFilter, align: "left", with: "50%", id: "proposte_2" },
      { Header: "Codice Merceologico", accessor: "cm_string", Filter: ColumnFilter, width: "10%", align: "left", id: "proposte_3" },
      {
        Header: "Tipologia Espositiva Richiesta", accessor: "tipologia_espositiva_string",
        id: "proposte_4",

        Filter: SelectColumnFilterTipo,
        Cell: ({ row }) => {
          return (
            <MDBox>
              <MDBox> <MDTypography variant="button"
                fontWeight="regular"
                color="text">{row.original.tipologia_espositiva_string}</MDTypography></MDBox>
              {row.original.esigenze_espositive &&
                <MDBox>  <MDTypography variant="button"
                  fontWeight="regular"
                  color="text">*** {row.original.esigenze_espositive.substring(0, 20)}...</MDTypography> </MDBox>}
              {row.original.note &&
                <MDBox>  <MDTypography variant="button"
                  fontWeight="regular"
                  color="text">*** {row.original.note.substring(0, 20)}...</MDTypography> </MDBox>}
            </MDBox>

          );
        },
        width: "15%", align: "left"
      },
      { Header: "Mq richiesti", accessor: "mqr", Filter: NoneFilter, align: "left", width: "5%", id: "proposte_9" },
      { Header: "Stato", accessor: "stato", Filter: SelectColumnFilterStato, align: "left", width: "5%", id: "proposte_5" },
      { Header: "Anticipo Controllato", accessor: "av", Filter: ColumnFilter, align: "left", width: "5%", id: "proposte_6" },
      {
        Header: "Etichetta", accessor: "etichetta", Filter: ColumnFilter,
        Cell: ({ row }) => {
          return (
            <Chip label={row.original.etichetta} color={row.original.color} />

          );
        },
        align: "left", width: "5%", id: "proposte_7"
      },
      { Header: "Data Ultima Modifica", accessor: "data_um", Filter: ColumnFilter, width: "10%", align: "left", id: "proposte_8" },
      { Header: "Saldo", accessor: "rimanenza", Filter: NoneFilter, width: "10%", align: "left", id: "proposte_10" },

    ],



  };

  const mypagination = { color: 'success', variant: "contained" };




  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>

          <Grid item xs={12}>
            <Card>
              <HeaderForm titolo="Proposte di Partecipazione" add="" modo="vis" />
              <MDBox pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center">

                {(state != null && state.submitted && (state.salvato === 'INS' || state.salvato === 'MOD')) &&

                  <Alert severity="success">Dati Proposta salvati con successo!</Alert>}
                {(state != null && state.submitted && (state.salvato === 'SENT')) &&

                  <Alert severity="success">Proposta di partecipazione inviata con successo!</Alert>}
                {(state != null && state.submitted && state.salvato === 'DEL') &&

                  <Alert severity="success">Proposta eliminata con successo!</Alert>}
              </MDBox>


              <MDBox pt={3}>
                <ExpoRichiesteTable canSearch={true}
                  table={ppTableData}
                  nome_tabella="proposte"
                  isSorted={true}
                  entriesPerPage={true}
                  showTotalEntries={true}
                  pagination={mypagination}
                  noEndBorder
                  responsive={true}
                  first_load={state == null || !state.indietro}
                  filtro_server={true}
                  canExport={true}
                  canSend={false}
                  enableColumnFilters={true}
                  chiamante='expo_richieste_admin'

                />
              </MDBox>
              {loading &&
                <MDBox opacity={1} textAlign="center">
                  <img src={loading_image} alt="loading" width={100} />
                </MDBox>
              }

              {(proposte == null || proposte.length === 0) &&
                <MDBox style={{ height: "200px" }} textAlign='center'>
                  <MDTypography
                    variant="button"
                    fontWeight="regular"
                    color="text"
                    sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                  >
                    Non ci sono Proposte di partecipazione in archivio. Clicca su Nuovo per crearne una!
                  </MDTypography></MDBox>
              }
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}



export default ElencoRichieste;
