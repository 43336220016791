import React, { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import { Link } from "react-router-dom";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "common/components/MD/MDBox";
import MDButton from "common/components/MD/MDButton";


// Material Dashboard 2 React example components
import DashboardLayout from "layouts/DashboardLayout";
import DashboardNavbar from "layouts/components/Navbars/DashboardNavbar";
import DataTable from "common/components/implemented/Tables/DataTable/DataTables"
import Footer from "layouts/components/Footer";
import HeaderForm from "layouts/components/HeaderForm";
import Alert from '@mui/material/Alert';

// Data
import ComunicazioniService from "services/API/Comunicazioni/ComunicazioniService";
import MDTypography from "common/components/MD/MDTypography";

function ElencoModelliComunicazioni() {

  const [modelli, setModelli] = useState([]);

  useEffect(() => {
    //console.log("retrive op");
    retrieveModelli();

  }, []);

  //per passarmi la variabiule se ho salvato e far comparire l'alert
  const location = useLocation();
  //console.log(location);

  const { state } = location != null ? location : { state: { submitted: false, salvato: 'NO' } }; // Read values passed on state

  const retrieveModelli = () => {
    ComunicazioniService.getAllModelli()
      .then(response => {
        setModelli(response.data);
        // console.log(response.data);

      })
      .catch(e => {
        console.log(e);
      });
  };

  const mcAttributes = [];
  if (modelli.length > 0) {

  modelli.forEach(mc => {
   
    mcAttributes.push({
      nome_modello: mc.nome_modello,
      oggetto: mc.oggetto,
      action: (
        <Link  to="/modelloCEdit" state={{modo:'edit',soggetto: mc }}
       >
          <MDButton variant="outlined" color={ "success"} iconOnly={true} >
            <Icon>edit</Icon>
          </MDButton>
        </Link>
      ),
     
    })
  });

  }
  const mcTableData = {
    columns: [
      { Header: "Nome Modello", accessor: "nome_modello", width: "50%", align: "left" },
      { Header: "Oggetto Comunicazione", accessor: "oggetto", align: "left" },
      { Header: "action", accessor: "action", align: "center", enableGlobalFilter: false },
    ],

    rows: mcAttributes,


  };

  const mypagination = { color: 'success', variant: "contained" };


  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>

          <Grid item xs={12}>
            <Card>
              <HeaderForm titolo="Modelli Comunicazioni" add="/modelloCEdit" modo="vis" />
              <MDBox pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center">

                {(state != null && state.submitted  && (state.salvato==='INS' || state.salvato==='MOD') ) &&

                  <Alert severity="success">Modello salvato con successo!</Alert>}
                   {(state != null && state.submitted  && state.salvato==='DEL')  &&

<Alert severity="success">Modello eliminata con successo!</Alert>}
              </MDBox>
              <MDBox pt={3}>
                <DataTable canSearch={true}
                  table={mcTableData}
                  isSorted={false}
                  entriesPerPage={true}
                  showTotalEntries={true}
                  pagination={mypagination}
                  noEndBorder
                />
              </MDBox>
              {(modelli == null || modelli.length === 0) &&
                <MDBox style={{ height: "200px" }} textAlign='center'>
                  <MDTypography
                    variant="button"
                    fontWeight="regular"
                    color="text"
                    sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                  >
                    Non ci sono Modelli di Comunicazioni in archivio. Clicca su Nuovo per crearne uno!
                  </MDTypography></MDBox>
              }
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default ElencoModelliComunicazioni;
