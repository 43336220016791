import axios from 'axios';
import authHeader from 'services/API/Auth/AuthHeader';
import authHeaderMultipart from 'services/API/Auth/AuthHeaderMultipart';

const getAllModelli=()=> {

  return axios.get(axios.defaults.baseURL + '/comunicazioni/allModelli',{headers: authHeader()});
}
const seleziona_modello_da_nome = (nome) => {

  console.log("seleziona_modello_da_nome= " + nome);
  return axios.get(axios.defaults.baseURL + `/comunicazioni/modello_seleziona_da_nome/${nome}`, { headers: authHeader() });

}
const seleziona_dettagli_modello = (id) => {

  console.log("seleziona_dettagli= " + id);
  return axios.get(axios.defaults.baseURL + `/comunicazioni/modello_seleziona_dettagli/${id}`, { headers: authHeader() });

}

const createModello = (data) => {
    
  return axios.post(axios.defaults.baseURL + '/comunicazioni/modello_ins',data, {
    headers: authHeader()
  })  
 };
 const createMsg = (datas) => {

    return axios.post(axios.defaults.baseURL + '/comunicazioni/messaggio_ins',datas, {headers: authHeader()
  })  
 };

 const sendMsg = (datas) => {
  console.log(datas);
       return axios.post(axios.defaults.baseURL + '/comunicazioni/messaggio_send',datas, {headers: authHeader()
 })  
};



 const seleziona_dettagli_msg = (id) => {

  console.log("seleziona_dettagli= " + id);
  return axios.get(axios.defaults.baseURL + `/comunicazioni/msg_seleziona_dettagli/${id}`, { headers: authHeader() });

}

 const getAllAllegati=()=> {

  return axios.get(axios.defaults.baseURL + '/comunicazioni/allAllegati',{headers: authHeader()});
}
 const uploadAllegato = (data) => {

return axios.post(axios.defaults.baseURL + '/comunicazioni/allegato_ins',data, {headers: authHeaderMultipart()
})  
};
const updateAllegato = (id, data) => {
  console.log(data);

  return axios.post(axios.defaults.baseURL + `/comunicazioni/allegato_upd/${id}`, data,{headers: authHeaderMultipart()});
};
const removeDoc = (id) => {
  console.log("remove= "+id);
  return axios.post(axios.defaults.baseURL + `/comunicazioni/allegato_remove/${id}`,null,{headers: authHeader()});
};

 const updateModello = (id, data) => {
      console.log(data);

      return axios.post(axios.defaults.baseURL + `/comunicazioni/modello_upd/${id}`, data,{headers: authHeader()});
    };

    const updateMsg = (id, data) => {
      console.log(data);

      return axios.post(axios.defaults.baseURL + `/comunicazioni/messaggio_upd/${id}`, data,{headers: authHeader()});
    };
    const removeModello = (id) => {
      console.log("remove= "+id);
      return axios.post(axios.defaults.baseURL + `/comunicazioni/modello_remove/${id}`,null,{headers: authHeader()});
    };

    const removeMsg = (id) => {
      console.log("remove= "+id);
      return axios.post(axios.defaults.baseURL + `/comunicazioni/messaggio_remove/${id}`,null,{headers: authHeader()});
    };

  

  const download_allegato = (idAllegato) => {

    
    const response = axios.get(axios.defaults.baseURL + `/comunicazioni/download_allegato/${idAllegato}`, { headers: authHeader() }, { responseType: 'blob' },);
    console.log(response);
    return response;
}

  const getAllMessaggi=(id_proposta,id_utente)=> {

    return axios.get(axios.defaults.baseURL + `/comunicazioni/allMessaggi/${id_proposta}/${id_utente}`,{headers: authHeader()});
  }

  const getMyMessaggi=(id_utente)=> {

    return axios.get(axios.defaults.baseURL + `/comunicazioni/readMessaggi/${id_utente}`,{headers: authHeader()});
  }


const ComunicazioniService = {
    getAllModelli,seleziona_modello_da_nome,
    seleziona_dettagli_modello,
    createModello,updateModello,
    removeModello,
    getAllMessaggi,createMsg,updateMsg,removeMsg,seleziona_dettagli_msg,uploadAllegato
    ,download_allegato,getAllAllegati,updateAllegato,removeDoc,
    getMyMessaggi,sendMsg
};

export default ComunicazioniService;