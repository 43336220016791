import { useEffect, useState } from "react";
import { Link, useLocation } from 'react-router-dom';
// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "common/components/MD/MDBox";
import MDButton from "common/components/MD/MDButton";

// Material Dashboard 2 React example components
import DashboardLayout from "layouts/DashboardLayout";
import Footer from "layouts/components/Footer";
import HeaderForm from "layouts/components/HeaderForm";
import DashboardNavbar from "layouts/components/Navbars/DashboardNavbar";

import Alert from '@mui/material/Alert';
//prop-types is a library for typechecking of props
// Data
import AnagraficaDataService from "services/API/Anagrafica/AnagraficaService";


import { ColumnFilter } from "common/components/implemented/Tables/DataTable/ColumnFilter";
import base64ToArrayBuffer from "common/script/base64ToArrayBuffer";
import AuthService from "services/API/Auth/AuthService";
import { NoneFilter } from "../expo_richieste_admin/NoneFilter";
import AnagDataTable from "./AnagDataTables";
import { SelectColumnFilterProfessione } from "./SelectColumnFilterProfessione";


function OperatoriList() {

  const [anagrafiche, setAnagrafiche] = useState([]);



  //per passarmi la variabiule se ho salvato e far comparire l'alert
  const location = useLocation();
  //console.log(location);

  const { state } = location != null ? location : { state: { submitted: false, salvato: 'NO', indietro: false } }; // Read values passed on state


  useEffect(() => {
 
    if (AuthService.isTokenOk()) {
 
        AnagraficaDataService.getAllOperatori()
          .then(response => {
            setAnagrafiche(response.data);
          })
          .catch(e => {
            console.log(e);
          });
  
    }


  }, []);






  const anagAttributes = [];
  if (anagrafiche.length > 0) {
    anagrafiche.forEach(anag => {

      let denominazione;
      if (anag.persona_giuridica == 1 || (anag.azienda!=null && anag.azienda!='')) {
        denominazione = anag.azienda;
        if (anag.cognome !== '') denominazione += "(" + anag.cognome + ' ' + anag.nome + ")"
      } else {
        denominazione = anag.cognome + ' ' + anag.nome;

      }
      let tipo = '';
      if (anag.visitatore) tipo += 'V';
      if (anag.operatore) tipo += ' O';
      if (anag.espositore) tipo += ' E';



      anagAttributes.push({
        anag: anag,
        tipo: tipo,
        denominazione: denominazione,
        email: anag.email,
        provenienza: anag.localita + '(' + anag.provincia + ')',
        sorgente:anag.sorgente,
        settore:anag.old_settore,
        professione: anag.espositore ? anag.cm_1_descrizione : anag.professione,
        action: (
          <Link to="/anagEdit" state={{ modo: 'edit', soggetto: anag,subject:'O' }}
          >
            <MDButton variant="outlined" color={"success"} iconOnly={true}>
              <Icon>edit</Icon>
            </MDButton>
          </Link>
        )
      })
    });
  }
  const anagTableData = {
    columns: [
      { Header: "Tipo", accessor: "tipo", Filter: ColumnFilter, width: "5%", align: "left", id: "operatori_0" },
      { Header: "Denominazione", accessor: "denominazione", Filter: ColumnFilter, width: "15%", align: "left", id: "operatori_1" },
      { Header: "Email", accessor: "email", Filter: ColumnFilter, align: "left", id: "operatori_2" },
      { Header: "Provenienza", accessor: "provenienza", Filter: ColumnFilter, align: "left",id: "operatori_3" },
      { Header: "Professione", accessor: "professione", Filter: SelectColumnFilterProfessione, align: "left" , id: "operatori_4"},
      { Header: "Sorgente", accessor: "sorgente", Filter: ColumnFilter, align: "left" , id: "operatori_5"},
      { Header: "Settore Cream", accessor: "settore", Filter: ColumnFilter, align: "left" , id: "operatori_6"},
      { Header: "action", accessor: "action", align: "center", Filter: NoneFilter, enableGlobalFilter: false, id: "operatori_7" },
    ],

    rows: anagAttributes,


  };

  const mypagination = { color: 'success', variant: "contained" };



  async function esporta_xls() {
    console.log("esporta_xls");

    let filename = 'ListaAnagrafica.xls';

    AnagraficaDataService.esporta_xls()
      .then(response => {
        /*  const disposition = response.headers['content-disposition'];
         filename = disposition.split(/;(.+)/)[1].splitvv(/=(.+)/)[1];
          if (filename.toLowerCase().startsWith ("utf-8''"))
             filename = decodeURIComponent(filename.replace("utf-8''", ''));
          else
             filename = filename.replace(/['"]/g, '');*/
        return response.data;
      })
      .then(blob => {

        var arrrayBuffer = base64ToArrayBuffer(blob); //data is the base64 encoded string
        var blobb = new Blob([arrrayBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
        var url = URL.createObjectURL(blobb);
        var a = document.createElement('a');
        a.href = url;
        a.target = "_blank";
        a.download = filename;
        document.body.appendChild(a); // append the element to the dom
        a.click();
        a.remove(); // afterwards, remove the element  
      })
      .catch(error => {
        console.error(error);
      });

  }



  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>

          <Grid item xs={12}>
            <Card>
              <HeaderForm titolo="Operatori"  add="/anagExists" modo="vis" subject={"O"} />
              <MDBox pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center">

                {(state != null && state.submitted && (state.salvato === 'INS' || state.salvato === 'MOD')) &&

                  <Alert severity="success">Dati Anagrafici salvato con successo!</Alert>}
                {(state != null && state.submitted && state.salvato === 'DEL') &&

                  <Alert severity="success">Anagrafica eliminata con successo!</Alert>}
              </MDBox>
              <MDBox pt={3}>
                <AnagDataTable canSearch={true}
                  table={anagTableData}
                  isSorted={true}
                  entriesPerPage={true}
                  showTotalEntries={true}
                  pagination={mypagination}
                  noEndBorder
                  nome_tabella={"operatori"}
                  first_load={state == null || !state.indietro}
                  filtro_server={true}
                  canExport={true} esporta_xls={esporta_xls}
                  enableColumnFilters={true}
                /*     state={pagination}
                   
                     onPaginationChange= {setPagination}*/

                />

              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}
/*
ElencoAnagrafiche.defaultProps = {
  subject: "ALL",

};

// Typechecking props for the Footer
ElencoAnagrafiche.propTypes = {
  subject: PropTypes.string,

};*/

export default OperatoriList;
