import { useState, useEffect } from 'react';

// Custom Hook per il debounce di un valore
function useDebounce(value, delay) {
  // Stato interno per tenere traccia del valore debounced
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    // Imposta un timeout per aggiornare il valore debounced dopo il ritardo
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    // Cancella il timeout se il valore cambia (anche se il componente viene smontato)
    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]); // Solo se `value` o `delay` cambiano, ricalcola il debounced value

  return debouncedValue;
}

export default useDebounce;