import React, { useEffect, useState } from 'react';
//prop-types is a library for typechecking of props
import PropTypes from "prop-types";
import MDBox from "common/components/MD/MDBox";
import MDInput from "common/components/MD/MDInput";
import { MenuItem, Select } from '@mui/material';
import AnagraficaDataService from 'services/API/Anagrafica/AnagraficaService';
export const SelectColumnFilterProfessione = ({ column }) => {

  const [lista_professioni, setLista_professioni] = useState([
    { id: 0, professione: '' },
  ]);


  useEffect(() => {
    //console.log("carico gli spazi");

    async function fetchInitialData2() {  
      const response = await AnagraficaDataService.getAllProfessioni();
      response.errors ? console.log(response.errors) : setLista_professioni([...response.data]);
    }
    fetchInitialData2();

  }, []);

  const { filterValue, setFilter } = column

  return (

    <MDBox ml="auto">

      <Select name="professione" id="professione"
        onChange={e => {
          console.log(e.target.value);
          sessionStorage.setItem("filter_" + column.id, e.target.value);
          if (e.target.value != 'ALL') {
            setFilter(e.target.value);
          }
          else {
            setFilter('');
          }
        }}
        value={lista_professioni.length>1 ?(filterValue || 'ALL'):'ALL'}
      > <MenuItem key="ALL" value="ALL">TUTTI</MenuItem>
        {lista_professioni.length>1 && lista_professioni.map((s) => (<MenuItem
          key={s.id}
          value={s.professione} >
          {s.professione}</MenuItem>
        ))}

      </Select>


    </MDBox>
  )

}
/*
// Setting default values for the props of DataTable
SelectColumnFilterProfessione.propTypes = {

  filter: PropTypes.string,
  setFilter: PropTypes.func,
};

// Typechecking props for the DataTable
SelectColumnFilterProfessione.defaultProps = {
  filter: '',
  setFilter: null,
};*/