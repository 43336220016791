import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "App";
import reportWebVitals from './reportWebVitals';

// Material Dashboard 2 React Context Provider
import { MaterialUIControllerProvider } from "common/context";
import axios from 'axios';


//axios.defaults.baseURL = 'http://localhost:8080/api';
axios.defaults.baseURL = 'https://far.umbriafiere.it/api';


//axios.defaults.baseURL = 'https://umbriafiere.kronositalia.com/api';


////axios.defaults.baseURL = 'http://FARFIERA_SERVER:48081/api';


const container = document.getElementById("app");
const root = createRoot(container);

root.render(
  <BrowserRouter>
    <MaterialUIControllerProvider>
      <App />
    </MaterialUIControllerProvider>
  </BrowserRouter>

);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
