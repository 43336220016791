import { useState, useEffect } from "react";
import { Link, useNavigate } from 'react-router-dom';
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
// Material Dashboard 2 React components
import MDBox from "common/components/MD/MDBox";
import MDButton from "common/components/MD/MDButton";

import MDTypography from "common/components/MD/MDTypography";


// Material Dashboard 2 React example components
import DashboardLayout from "layouts/DashboardLayout";
import DashboardNavbar from "layouts/components/Navbars/DashboardNavbar";
import Footer from "layouts/components/Footer";
import HeaderForm from "layouts/components/HeaderForm";

//prop-types is a library for typechecking of props
// Data
import { useManifestazione } from "common/context/GlobalContext";
import DefaultInfoCard from "common/components/implemented/Cards/InfoCards/DefaultInfoCard";
import ExpoPartecipazioniService from "services/API/expo_partecipazioni/ExpoPartecipazioniService";
import base64ToArrayBuffer from "common/script/base64ToArrayBuffer";
import { Alert, AppBar } from "@mui/material";
import MDAlert from "common/components/MD/MDAlert/MDAlert";




function ServiziFiera() {



  const { manifestazione } = useManifestazione();

  const navigate = useNavigate();
  const [part, setPart] = useState({
  });

  const stringa = (<MDTypography
    variant="button"
    fontWeight="regular"
    color="text"
    sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
  >
    Stampa il contratto e ricaricalo firmato in tutti i suoi punti
    <MDButton onClick={() => navigate("/contratto")} color="success">Vai</MDButton>
  </MDTypography>);

  const [contratto, setContratto] = useState(stringa);
  const modulo_1 = (
    <Link to="https://far.umbriafiere.it/download/1-Pubblicita-Catalogo-Online.pdf" target="_blank">
      <MDButton variant="outlined" color="success"  >
         1 Pubblicita Catalogo Online
      </MDButton>

    </Link>

  );

  const modulo_2= (
    <Link to="https://far.umbriafiere.it/download/2-Pubblicita-Piantina-Espositori.pdf" target="_blank">
      <MDButton variant="outlined" color="success"  >
      2 Pubblicita Piantina Espositori
      </MDButton>

    </Link>
  );
  const modulo_3 = (
    <Link to="https://far.umbriafiere.it/download/3-Pubblicita-Internet.pdf" target="_blank">
      <MDButton variant="outlined" color="success"  >
      3 Pubblicita Internet
      </MDButton>

    </Link>

  );

  const modulo_4 = (
    <Link to="https://far.umbriafiere.it/download/4-Biglietti-ospite-pass-espositori.pdf" target="_blank">
      <MDButton variant="outlined" color="success"  >
      4 Biglietti ospite/pass espositori
      </MDButton>

    </Link>
  );
  const modulo_5 = (
    <Link to="https://far.umbriafiere.it/download/5-Forniture-Tecniche.pdf" target="_blank">
      <MDButton variant="outlined" color="success"  >
      5 Forniture Tecniche
      </MDButton>

    </Link>

  );

  const modulo_6 = (
    <Link to="https://far.umbriafiere.it/download/6-Moquette-2025.pdf" target="_blank">
      <MDButton variant="outlined" color="success"  >
      6 Moquette
      </MDButton>

    </Link>
  );
  const modulo_7 = (
    <Link to="https://far.umbriafiere.it/download/7-Pannelli-Divisori.pdf" target="_blank">
      <MDButton variant="outlined" color="success"  >
      7 Pannelli Divisori
      </MDButton>

    </Link>

  );

  const modulo_8= (
    <Link to="https://far.umbriafiere.it/download/8-Allestimenti-e-Arredi.pdf" target="_blank">
      <MDButton variant="outlined" color="success"  >
      8 Allestimenti e Arredi
            </MDButton>

    </Link>
  );
  const modulo_8bis = (
    <Link to="https://far.umbriafiere.it/download/8BIS-Poltrone-e-Vetrine.pdf" target="_blank">
      <MDButton variant="outlined" color="success"  >
      8 BIS Poltrone e Vetrine 
      </MDButton>

    </Link>

  );

  const modulo_9 = (
    <Link to="https://far.umbriafiere.it/download/9-Materiali-Elettrici.pdf" target="_blank">
      <MDButton variant="outlined" color="success"  >
      9 Materiali Elettrici
      </MDButton>

    </Link>
  );
  const modulo_10 = (
    <Link to="https://far.umbriafiere.it/download/10-Carrello-Elevatore-Pulizie.pdf" target="_blank">
      <MDButton variant="outlined" color="success"  >
      10 Carrello Elevatore Pulizie.pdf
      </MDButton>

    </Link>

  );

  const modulo_11 = (
    <Link to="https://far.umbriafiere.it/download/11-Noleggio-Gazebo-Prefabbricati-Lavelli.pdf" target="_blank">
      <MDButton variant="outlined" color="success"  >
      11 Noleggio Gazebo Prefabbricati Lavelli
      </MDButton>

    </Link>
  );

  const modulo_12 = (
    <Link to="https://far.umbriafiere.it/download/12-Noleggio-Apparecchiature-Audio-e-Video.pdf" target="_blank">
      <MDButton variant="outlined" color="success"  >
      12 Noleggio Apparecchiature Audio e Video
      </MDButton>

    </Link>
  );
  useEffect(() => {

    seleziona_soggetto();

  }, []);

  async function seleziona_soggetto() {
    console.log("Cambiata pp->seleziono i dettagli :" + manifestazione);

    const response = await ExpoPartecipazioniService.seleziona_partecipazione(manifestazione);
    response.errors ? console.log(response.errors) : retrive(response.data);

  }
  function retrive(data) {

    setPart({
      idPartecipazione: data.idPartecipazione,
      id_expo_richiesta: data.id_expo_richiesta,
      id_utente: data.id_utente,
      nome_pdf_contratto: data.nome_pdf_contratto
    });

    console.log(":::" + data.nome_pdf_contratto);

    if (data.nome_pdf_contratto != null && !data.nome_pdf_contratto == "") {
      setContratto((
        <MDTypography
          variant="button"
          fontWeight="regular"
          color="text"
          sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
          onClick={() => vedi_contratto(data.idPartecipazione)}
        >
          {data.nome_pdf_contratto}
        </MDTypography>
      ));
    }

    console.log("selezionata  " + part.idPartecipazione);

  }

  async function vedi_contratto(idPartecipazione) {

    ExpoPartecipazioniService.download_pdf_contratto(idPartecipazione)
      .then(response => {

        return response.data;
      })
      .then(blob => {

        var arrrayBuffer = base64ToArrayBuffer(blob); //data is the base64 encoded string
        var blobb = new Blob([arrrayBuffer], { type: "application/pdf" });
        var url = URL.createObjectURL(blobb);
        var a = document.createElement('a');
        a.href = url;
        a.target = "_blank";

        a.download = part.nome_pdf_contratto;
        ;

        document.body.appendChild(a); // append the element to the dom
        a.click();
        a.remove(); // afterwards, remove the element  
      })
      .catch(error => {
        console.error(error);
      });




  }


  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>

          <Grid item xs={12}>
            <Card>
              <HeaderForm titolo={"Servizi Facoltativi per " + manifestazione}  color="info" />

              <MDBox p={10}>
              

                <MDBox p={10} sx={{ display: 'grid', gap: 10, gridTemplateColumns: '1fr 1fr 1fr' }}>

                  <DefaultInfoCard
                    color="success"
                    icon="drawicon"
                    title={modulo_1}
                    description={"Scarica il file, compilalo e invialo all'email indicata"}
                    value=""
                  />
                 <DefaultInfoCard
                    color="success"
                    icon="drawicon"
                    title={modulo_2}
                    description={"Scarica il file, compilalo e invialo all'email indicata"}
                    value=""
                  />
                  <DefaultInfoCard
                    color="success"
                    icon="drawicon"
                    title={modulo_3}
                    description={"Scarica il file, compilalo e invialo all'email indicata"}
                    value=""
                  />
                  <DefaultInfoCard
                    color="success"
                    icon="drawicon"
                    title={modulo_4}
                    description={"Scarica il file, compilalo e invialo all'email indicata"}
                    value=""
                  />
                 <DefaultInfoCard
                    color="success"
                    icon="drawicon"
                    title={modulo_5}
                    description={"Scarica il file, compilalo e invialo all'email indicata"}
                    value=""
                  />
                   <DefaultInfoCard
                    color="success"
                    icon="drawicon"
                    title={modulo_6}
                    description={"Scarica il file, compilalo e invialo all'email indicata"}
                    value=""
                  />
                  
                  <DefaultInfoCard
                    color="success"
                    icon="drawicon"
                    title={modulo_7}
                    description={"Scarica il file, compilalo e invialo all'email indicata"}
                    value=""
                  />
                 <DefaultInfoCard
                    color="success"
                    icon="drawicon"
                    title={modulo_8}
                    description={"Scarica il file, compilalo e invialo all'email indicata"}
                    value=""
                  />
                  <DefaultInfoCard
                    color="success"
                    icon="drawicon"
                    title={modulo_8bis}
                    description={"Scarica il file, compilalo e invialo all'email indicata"}
                    value=""
                  />
                  <DefaultInfoCard
                    color="success"
                    icon="drawicon"
                    title={modulo_9}
                    description={"Scarica il file, compilalo e invialo all'email indicata"}
                    value=""
                  />
                 <DefaultInfoCard
                    color="success"
                    icon="drawicon"
                    title={modulo_10}
                    description={"Scarica il file, compilalo e invialo all'email indicata"}
                    value=""
                  />
                   <DefaultInfoCard
                    color="success"
                    icon="drawicon"
                    title={modulo_11}
                    description={"Scarica il file, compilalo e invialo all'email indicata"}
                    value=""
                  />
                      <DefaultInfoCard
                    color="success"
                    icon="drawicon"
                    title={modulo_12}
                    description={"Scarica il file, compilalo e invialo all'email indicata"}
                    value=""
                  />
                </MDBox></MDBox>


            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}



export default ServiziFiera;
