/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Material Dashboard 2 React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/
import { Navigate } from "react-router-dom";
// Material Dashboard 2 React layouts
import Dashboard from "pages/riservata/Dashboard";
import Utenti from "pages/riservata/utenti/UtentiList";
import UtentiEdit from "pages/riservata/utenti/UtentiEdit";
import ElencoAnagrafica from "pages/riservata/anagrafica/AnagraficaList";
import AnagraficaEdit from "pages/riservata/anagrafica/AnagraficaEdit";
import AnagraficaExists from "pages/riservata/anagrafica/AnagraficaExists";
import ElencoLocalita from "pages/riservata/utilita/localita/ElencoLocalita";
import LocalitaEdit from "pages/riservata/utilita/localita/LocalitaEdit";
import ElencoRichieste from "pages/riservata/expo_richieste/ExpoRichiesteList";
import ElencoRichiesteAdmin from "pages/riservata/expo_richieste_admin/ExpoRichiesteList";
import ExpoRichiesteEditStep1 from "pages/riservata/expo_richieste/ExpoRichiesteEditStep1";
import ExpoRichiesteEditStep2 from "pages/riservata/expo_richieste/ExpoRichiesteEditStep2";
import ExpoRichiestaConfirm from "pages/riservata/expo_richieste/ExpoRichiestaConfirm";
import ExpoRichiestaPDFVis from "pages/riservata/expo_richieste/ExpoRichiestePDFVis";
import ExpoRichiesteStart from "pages/riservata/expo_richieste/ExpoRichiesteStart";
import ManifestazioneEdit from "pages/riservata/utilita/manifestazioni/ManifestazioneEdit";
import ElencoManifestazioni from "pages/riservata/utilita/manifestazioni/ManifestazioniList";
import Test from "pages/test/test.js";
//import RTL from "layouts/rtl";
//import Notifications from "layouts/notifications";
//import Profile from "layouts/profile";

//import SignUp from "layouts/authentication/sign-up";


import AuthService from 'services/API/Auth/AuthService.js';

// @mui icons
import Icon from "@mui/material/Icon";

const isExpo = AuthService.isExpo();
const isAdmin = AuthService.isAdmin();

//console.log("ROUTE.JS isAdmin="+isAdmin);


function PrivateRoute({ children }) {
  const isLoggedIn = AuthService.isLoggedIn();
 // console.log("isLoggedIn="+isLoggedIn);

   
  return isLoggedIn ? children : <Navigate to="/forbidden" />;
}
function AdminRoute({ children }) {
  const isLoggedIn = AuthService.isLoggedIn();
  if(isLoggedIn){
  const isAdmin = AuthService.isAdmin();
  //console.log("isAdmin="+isAdmin);
  return isAdmin ? children : <Navigate to="/forbidden" />;
  }else
  return <Navigate to="/forbidden" />;
}
function ExpoRoute({ children }) {
  const isLoggedIn = AuthService.isLoggedIn();
  if(isLoggedIn){
  const isExpo = AuthService.isExpo();
 // console.log("isExpo="+isExpo);
  return isExpo ? children : <Navigate to="/forbidden" />;
}else
return <Navigate to="/forbidden" />;
}


const routes= [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/welcome",
    component: <PrivateRoute><Dashboard /></PrivateRoute>,
  },
  
  
  {
    type:  "title",
    title: "Espositori",
    key: "pp_menu",
},
{
  type:"collapse",
  name: "Proposte di Partecipazione",
  key: "expo_richiesteList",
  icon: <Icon fontSize="small">table_view</Icon>,
  route: "/expo_richieste",
  component:<PrivateRoute><ElencoRichieste /></PrivateRoute>,
},
{
  type: "not-visible", 
  name: "Nuova Proposta",
  key: "expo_richiestaEdit1",
  icon: <Icon fontSize="small">table_view</Icon>,
  route: "/expo_richiestaEdit1",
  component: <ExpoRoute><ExpoRichiesteEditStep1 /></ExpoRoute>,
},
{
  type: "not-visible", 
  name: "Nuova Proposta 2",
  key: "expo_richiestaEdit2",
  icon: <Icon fontSize="small">table_view</Icon>,
  route: "/expo_richiestaEdit2",
  component: <ExpoRoute><ExpoRichiesteEditStep2 /></ExpoRoute>,
},
{
  type: "not-visible", 
  name: "Start ",
  key: "expo_richiesteStart",
  icon: <Icon fontSize="small">table_view</Icon>,
  route: "/expo_richiesteStart",
  component: <ExpoRoute><ExpoRichiesteStart /></ExpoRoute>,
},
{
  type: "not-visible", 
  name: "Confirm ",
  key: "expo_richiestaConfirm",
  icon: <Icon fontSize="small">table_view</Icon>,
  route: "/expo_richiestaConfirm",
  component: <ExpoRoute><ExpoRichiestaConfirm /></ExpoRoute>,
},
{
  type: "not-visible", 
  name: "Confirm ",
  key: "expo_richiestaPDF",
  icon: <Icon fontSize="small">table_view</Icon>,
  route: "/expo_richiestaPDFVis",
  component: <PrivateRoute><ExpoRichiestaPDFVis /></PrivateRoute>,
},
  
];





export default routes;
