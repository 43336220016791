import React, { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import { Link } from "react-router-dom";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "common/components/MD/MDBox";
import MDButton from "common/components/MD/MDButton";


// Material Dashboard 2 React example components
import DashboardLayout from "layouts/DashboardLayout";
import DashboardNavbar from "layouts/components/Navbars/DashboardNavbar";
import Footer from "layouts/components/Footer";

import HeaderForm from "layouts/components/HeaderForm";
import MessaggiTable from "./MessaggiTable";
import ComunicazioniService from "services/API/Comunicazioni/ComunicazioniService";

function ElencoMessaggi() {


  const [messaggi, setMessaggi] = useState([]);

  useEffect(() => {
    //console.log("retrive op");
    retrieveMessaggi();

  }, []);


  
  const retrieveMessaggi = () => {

   //console.log(id_proposta);
   // console.log(id_utente);
    ComunicazioniService.getAllMessaggi(0,0)
      .then(response => {
        setMessaggi(response.data);
        //console.log(response.data);

      })
      .catch(e => {
        console.log(e);
      });
  };



  return (
    <DashboardLayout>
      <DashboardNavbar />
    
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>

          <Grid item xs={12}>
            <Card>
            <HeaderForm titolo="Messaggi Salvati " add="/messaggioEdit" chiamante="messaggi"  modo="vis" />
              <MessaggiTable messaggi={messaggi}></MessaggiTable>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default ElencoMessaggi;
