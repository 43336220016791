import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

// Material Dashboard 2 React components
import MDBox from "common/components/MD/MDBox";
import MDTypography from "common/components/MD/MDTypography";

import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import DoDisturbAltIcon from '@mui/icons-material/DoDisturbAlt';
// Data
import VisitatoriDataService from "services/API/Anagrafica/AnagraficaService";
import ExpoPartecipazioniService from "services/API/expo_partecipazioni/ExpoPartecipazioniService";
import { ColumnFilter } from "common/components/implemented/Tables/DataTable/ColumnFilter";
import { NoneFilter } from "../expo_richieste_admin/NoneFilter";
import ExpoPartecipazioniVerifyTable from "./ExpoPartecipazioniVerifyTable";
function VerificaEspositoriBox() {



  const [menu, setMenu] = useState(null);

  const openMenu = ({ currentTarget }) => setMenu(currentTarget);
  const closeMenu = () => setMenu(null);

  const [partecipazioni, setPartecipazioni] = useState([]);
  const manifestazione = "Agriumbria 2025";


  useEffect(() => {

    ExpoPartecipazioniService.getAllPartecipazioni(manifestazione)
      .then(response => {
        console.log(response.data);
        setPartecipazioni(response.data);

      })
      .catch(e => {
        console.log(e);

      });

  }, []);


  const visitatoreAttributes = [];

  if (partecipazioni.length > 0) {
    partecipazioni.forEach(part => {

      let sal = "NO";
      if (part.saldato) sal = "SI";

      let cp = "NO";
      if (part.contratto_protocollato) cp = "SI";


      let semaforo = (
        <CheckCircleRoundedIcon color="success" fontSize="large"></CheckCircleRoundedIcon>
      );
      if (!part.contratto_protocollato || !part.saldato) {
        semaforo = (<DoDisturbAltIcon color="error" fontSize="large"></DoDisturbAltIcon>);
      }


      visitatoreAttributes.push({
        part: part,
        idPartecipazione: part.idPartecipazione,
        mq: part.mqa,
        protocollo: part.protocollo,
        azienda: part.ragione_sociale,
        tipologia_espositiva_string: part.tipologia_espositiva_string,
        postazione: part.postazione,
        semaforo: semaforo,
        contratto_protocollato: cp,
        saldato: sal,

      })

    }
    )


  }
  const ppTableData = {
    rows: visitatoreAttributes,

    columns: [
      { Header: "Verify", accessor: "semaforo", Filter: NoneFilter, width: "5%", align: "left", id: "verificad_0" },
      { Header: "Azienda", accessor: "azienda", Filter: ColumnFilter, align: "left", id: "verificad_1" },
 { Header: "Postazione", accessor: "postazione", Filter: NoneFilter, align: "left", width: "15%", id: "verificad_2" },
    
    ],



  };

  const mypagination = { color: 'success', variant: "contained" };

  const renderMenu = (
    <Menu
      id="simple-menu"
      anchorEl={menu}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={Boolean(menu)}
      onClose={closeMenu}
    >
      <MenuItem onClick={closeMenu}>Action</MenuItem>
      <MenuItem onClick={closeMenu}>Another action</MenuItem>
      <MenuItem onClick={closeMenu}>Something else</MenuItem>
    </Menu>
  );

  return (
    <Card>
      <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
        <MDBox>
          <MDTypography variant="h6" gutterBottom>
           Verifica accesso espositori
          </MDTypography>
        </MDBox>
        <MDBox color="text" px={2}>
          <Icon sx={{ cursor: "pointer", fontWeight: "bold" }} fontSize="small" onClick={openMenu}>
            more_vert
          </Icon>
        </MDBox>
        {/*renderMenu*/}
      </MDBox>
      <MDBox>
        <ExpoPartecipazioniVerifyTable
           table={ppTableData}
          nome_tabella="partecipazioni"
          isSorted={false}
          entriesPerPage={false}
          showTotalEntries={false}
          pagination={mypagination}
          noEndBorder
          responsive={true}
           filtro_server={true}
          canExport={false}
          canSend={false}
          chiamante='dashboard'
    
        />
      </MDBox>
    </Card>
  );
}

export default VerificaEspositoriBox;
