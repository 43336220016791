/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// react-router-dom components

import ComuniService from 'services/API/Comuni/ComuniService.js';
import UtilityService from "services/API/utility/UtilityService";

// @mui material components
import Card from "@mui/material/Card";
import CardContent from '@mui/material/CardContent';
import Grid from "@mui/material/Grid";


import Autocomplete from '@mui/material/Autocomplete';
import Dialog from '@mui/material/Dialog';
import MenuItem from '@mui/material/MenuItem';
import ReactHookFormSelect from "common/components/mycomponents/ReactHookFormSelect";


// Material Dashboard 2 React components
import MDBox from "common/components/MD/MDBox";
import MDButton from "common/components/MD/MDButton";
import MDInput from "common/components/MD/MDInput";
import MDTypography from "common/components/MD/MDTypography";



// Authentication layout components piu largo

// Images


//validazione lato client
import { yupResolver } from '@hookform/resolvers/yup';
import useDebounce from "common/custom_hook/useDebounce";
import { useForm } from 'react-hook-form';
import ExpoPartecipazioniService from "services/API/expo_partecipazioni/ExpoPartecipazioniService";
import * as Yup from 'yup';

function DatiCatalogoEditModal({ onSave, onClose, show, espositore_azienda, id_catalogo, part, modo_vis = false }) {

  const [catalogo, setCatalogo] = useState({
    idCatalogo: 0,
    id_partecipazione: 0,
    espositore_azienda: 0,
    ragione_sociale: '',
    indirizzo: '', civico: '', cap: '', localita: '', indirizzo2: '', provincia: '',
    piva: '', cf: '', telefono: '', fax: '', www: '', email: '', pec: '',
    cm_01: '', cm_02: '',
    prodotti_esposti: '', ordine: 0,string_spazio:''
  });

  const [lista_loc, setLista_loc] = useState([
    { cap: '', localita: '', sigla_provincia: '', denominazione_provincia: '' },
  ]);
  const [lista_codici_m, setLista_codici_m] = useState([
    { codice: '', descrizione: '' },
  ]);

  console.log("modo_vis=" + modo_vis);
  useEffect(() => {
    console.log("carico i codici merceologici");

    async function fetchInitialData2() {
      const response = await UtilityService.getAllCodiciM();
      response.errors ? console.log(response.errors) : setLista_codici_m([...response.data]);
    }
    fetchInitialData2();

  }, []);




  /* ----------------------------FUNZIONALITA PAGINA------------------------------------------------- */
  const [query_cap, setQuery_cap] = useState('');
  const debouncedQuery = useDebounce(query_cap, 500); // Debounce di 500ms


  // Effettua la ricerca quando `debouncedQuery` cambia
  useEffect(() => {
    if (debouncedQuery) {
      console.log(`Effettua la ricerca per: ${debouncedQuery}`);
      // Qui potresti invocare un'API di ricerca o filtrare i dati localmente
      //  console.log("retrive");
      setLista_loc([
        { cap: '', localita: '', sigla_provincia: '', denominazione_provincia: '' },
      ]);
      // setValue('localita', '');

      ComuniService.getAllComuni(query_cap)
        .then(response => {
          setLista_loc([...response.data]);
          //   console.log(response.status);
          if (response.status === 200) {
            console.log(response.data[0].localita);
            setValue('provincia', response.data[0].sigla_provincia.toUpperCase());

            /*  if (prop.localita != null) {
                console.log("LOC=" + prop.localita);
                setValue('localita', prop.localita);
  
              }*/
          }


        })
        .catch(e => {
          console.log(e);
        });
    }
  }, [debouncedQuery]);



  /* ----------------------------CARICAMENTO E RIEMPIMENTO CAMPI------------------------------------------------- */


  useEffect(() => {
    if (show) {
      console.log("LOAD " + id_catalogo);
      if (id_catalogo !== 0){
        seleziona_soggetto();
      }
      else {
        if (espositore_azienda == 0) {
          console.log(part);
          setCatalogo({
            idCatalogo: 0,
            id_partecipazione: part.idPartecipazione,
            espositore_azienda: 0,
            ragione_sociale: part.richiesta0.ragione_sociale,
            indirizzo: part.richiesta0.indirizzo,
            civico: part.richiesta0.civico,
            cap: part.richiesta0.cap, localita: part.richiesta0.localita,
            indirizzo2: part.richiesta0.indirizzo2, provincia: part.richiesta0.provincia,
            piva: part.richiesta0.piva, cf: part.richiesta0.cf, telefono: part.richiesta0.telefono, fax: part.richiesta0.fax,
            www: part.richiesta0.www, email: part.richiesta0.email, pec: part.richiesta0.pec,
            cm_01: part.richiesta0.cm_01, cm_02: part.richiesta0.cm_02,
            prodotti_esposti: part.richiesta0.prodotti_servizi, ordine: 0, inviato: 0,string_spazio:''
          });
          //console.log(catalogo);
        }
        else{
          setCatalogo({
            idCatalogo: 0,
            id_partecipazione:0,
            espositore_azienda: 1,
            string_spazio:'',
            ragione_sociale: '',
            indirizzo: '', civico: '', cap: '', localita: '', indirizzo2: '', provincia: '',
            piva: '', cf: '', telefono: '', fax: '', www: '', email: '', pec: '',
            cm_01: '', cm_02: '',
            prodotti_esposti: '', ordine: 0
          })

        }
      }
    }
  }, [show]);

  async function seleziona_soggetto() {
    //console.log("Cambiata pp->seleziono i dettagli :"+manifestazione);

    const response = await ExpoPartecipazioniService.seleziona_catalogo(id_catalogo);
    response.errors ? console.log(response.errors) : retrive(response.data);

  }

  function retrive(data) {
    // console.log(data);
    //console.log("retrive =" + data.idRichiesta);

    setCatalogo({
      idCatalogo: data.idCatalogo,
      id_partecipazione: data.id_partecipazione,
      string_spazio:data.spazi_assegnati,
      espositore_azienda: data.espositore_azienda,
      ragione_sociale: data.ragione_sociale,
      indirizzo: data.indirizzo,
      civico: data.civico,
      cap: data.cap, localita: data.localita,
      indirizzo2: data.indirizzo2, provincia: data.provincia,
      piva: data.piva, cf: data.cf, telefono: data.telefono, fax: data.fax, www: data.www, email: data.email, pec: data.pec,
      cm_01: data.cm_01, cm_02: data.cm_02,
      prodotti_esposti: data.prodotti_esposti, ordine: data.ordine
    });

    console.log("selezionata  " + data.idCatalogo);

  }


  useEffect(() => {

    console.log("Cambiata pp->riempo i campi  " + catalogo.idCatalogo);

    // reset form with user data

    setValue("ragione_sociale", catalogo.ragione_sociale);
    setValue("string_spazio",catalogo.string_spazio);
    setValue("piva", catalogo.piva);
    setValue("cf", catalogo.cf);

    setValue("indirizzo", catalogo.indirizzo);
    setValue("indirizzo2", catalogo.indirizzo2);
    setValue("civico", catalogo.civico);
    setValue("cap", catalogo.cap);

    setQuery_cap(catalogo.cap);


    setValue("localita", catalogo.localita);
    setValue("provincia", catalogo.provincia);

    setValue("telefono", catalogo.telefono);
    setValue("fax", catalogo.fax);
    setValue("www", catalogo.www);
    setValue("email", catalogo.email);

    setValue("pec", catalogo.pec);
    setValue("codice_merceologico_01", catalogo.cm_01);
    setValue("codice_merceologico_02", catalogo.cm_02);


    setValue("prodotti_esposti", catalogo.prodotti_esposti);



  }, [catalogo]);




  /* ----------------------------VALIDAZIONE E SALVATAGGIO----------------------------------------------------------------------- */

  const validationSchema = Yup.object().shape({
    ragione_sociale: Yup.string().required('Nome Azienda richiesto').max(150, 'Lunghezza massima raggiunta'),
    /* cf: Yup.string()
       .required('CF richiesto'),
     piva: Yup.string()
       .required('Partita IVA richiesto'),*/
    email: Yup.string()
      .required('Email richiesta')
      .email('Email non valida'),
    pec: Yup.string()
      .email('Email non valida'),
    indirizzo: Yup.string()
      .required('Indirizzo richiesto')
      .max(70, 'Inserire soltanto l\'indirizzo (via/piazza/vocabolo/...'),
    indirizzo2: Yup.string()
      .max(30, 'Numero massimo di caratteri raggiunto'),
    civico: Yup.string()
      .required('Numero civico richiesto')
      .max(10, 'Lunghezza massima raggiunta'),
    cap: Yup.string()
      .required('Cap richiesto')
      .max(10, 'Lunghezza massima raggiunta'),
    telefono: Yup.string().required('Telefono richiesto')
      .max(20, 'Lunghezza massima raggiunta'),
    localita: Yup.string()
      .required('Località richiesto')
      .max(100, 'Lunghezza massima raggiunta'),
    provincia: Yup.string()
      .required('Sigla Provincia Richiesta')
      .max(4, 'Inserire la sigla della provincia'),
    codice_merceologico_01: Yup.string()
      .required('Codice merceologico principale richiesto')
      .min(2, 'Codice merceologico principale richiesto'),
    prodotti_esposti: Yup.string()
      .required('E\' richiesta una descrizione dettagliata dei prodotti o servizi venduti ed esposti'),
  });
  /* ----------------------------SALVATAGGIO E REGISTRAZIONE------------------------------------------------- */

  const {
    register, //register inputs
    reset, setValue, control,
    handleSubmit,//handle form submit
    formState: { errors }
  } = useForm({
    resolver: yupResolver(validationSchema)
  });



  /* ---------------- ALERT -------------------------------------------------------------------------------------------------------- */





  /* ------------------------------navigazione -----------------------------------------------------------------*/



  const handleKeyDown = (e) => {
    const { key, target } = e

    if (key !== "Enter" || target instanceof HTMLTextAreaElement) {
      return
    }

    e.preventDefault()
  }

  return (
    <Dialog open={show} onClose={onClose}>

      <MDBox pt={3} pb={3}>


        <Grid container spacing={6}>

          <Grid item xs={12}>
            <Card >

              <MDBox pt={1} pb={3} px={3} component="form" role="form" onKeyDown={handleKeyDown}>


                <CardContent>
                  <MDBox>


                    <MDBox mb={2}>

                      <MDButton variant="gradient" color="success" fullWidth>
                        <MDTypography variant="h5" fontWeight="regular" sx={{
                          color: "#FFFFFF"
                        }}>{espositore_azienda == 0 ? 'DATI ESPOSITORE' : 'DATI AZIENDA COLLEGATA'}</MDTypography>
                      </MDButton>
                    </MDBox>
                    <MDBox mb={2} ><MDTypography variant="button">I campi con * sono obbligatori</MDTypography></MDBox>
                    <MDBox mb={2} >
                      <MDBox>
                        <MDInput type="text" label="Ragione Sociale" name='ragione_sociale' fullWidth required {...register('ragione_sociale')}
                          error={errors.ragione_sociale ? true : false} InputLabelProps={{ shrink: true }}
                          disabled={modo_vis}
                        />
                        {errors.ragione_sociale ? <span style={{ color: 'red', fontSize: '12px' }}>{errors.ragione_sociale?.message}</span> : ''}
                      </MDBox>
                    </MDBox>
                    {(espositore_azienda == 0 && id_catalogo!=0) &&

                    
<MDBox mb={2} >
  <MDBox>
    <MDInput type="text" label="Spazio assegnato" name='string_spazio' fullWidth  {...register('string_spazio')}
     disabled={true}
    />
  
  </MDBox>
</MDBox>
}
                    {espositore_azienda == 0 &&


                      <MDBox mb={2} sx={{ display: 'grid', gap: 1, gridTemplateColumns: '1fr 1fr' }}>
                        <MDBox>
                          <MDInput type="text" label="Codice Fiscale" name='cf' fullWidth  {...register('cf')}
                            error={errors.cf ? true : false} InputLabelProps={{ shrink: true }} disabled={modo_vis}
                          />
                          {errors.cf ? <span style={{ color: 'red', fontSize: '12px' }}>{errors.cf?.message}</span> : ''}
                        </MDBox>
                        <MDBox>
                          <MDInput type="text" label="Partita IVA" name='piva' fullWidth  {...register('piva')}
                            error={errors.piva ? true : false} InputLabelProps={{ shrink: true }} disabled={modo_vis}
                          />
                          {errors.piva ? <span style={{ color: 'red', fontSize: '12px' }}>{errors.piva?.message}</span> : ''}
                        </MDBox>
                      </MDBox>
                    }
                    <MDBox mb={2} sx={{ display: 'grid', gap: 1, gridTemplateColumns: '3fr 1fr' }}>
                      <MDBox >
                        <MDInput type="text" fullWidth label="Indirizzo" required name='indirizzo'  {...register('indirizzo')} error={errors.indirizzo ? true : false} InputLabelProps={{ shrink: true }} disabled={modo_vis} />
                        {errors.indirizzo ? <span style={{ color: 'red', fontSize: '12px' }}>{errors.indirizzo?.message}</span> : ''}
                      </MDBox>
                      <MDBox>
                        <MDInput type="text" fullWidth label="civico" required name='civico'  {...register('civico')} error={errors.civico ? true : false} InputLabelProps={{ shrink: true }} disabled={modo_vis} />
                        {errors.civico ? <span style={{ color: 'red', fontSize: '12px' }}>{errors.civico?.message}</span> : ''}
                      </MDBox>

                    </MDBox>
                    <MDBox mb={2} >
                      <MDInput type="text" label="Dettaglio Indirizzo (frazione, c/o, piano, ...)" fullWidth name='indirizzo2' {...register('indirizzo2')} error={errors.indirizzo2 ? true : false} InputLabelProps={{ shrink: true }} disabled={modo_vis} />
                      {errors.indirizzo2 ? <span style={{ color: 'red', fontSize: '12px' }}>{errors.indirizzo2?.message}</span> : ''}
                    </MDBox>

                    <Grid container spacing={2} mb={2}>
                      <Grid item xs={4} md={2} >
                        <MDBox>
                          <MDInput type="text" label="CAP" fullWidth name='cap' required
                            {...register('cap', {
                              onChange: (e) => setQuery_cap(e.target.value),

                            })} error={errors.cap ? true : false} InputLabelProps={{ shrink: true }} disabled={modo_vis}
                          />
                          {errors.cap ? <span style={{ color: 'red', fontSize: '12px' }}>{errors.cap?.message}</span> : ''}
                        </MDBox>
                      </Grid>

                      <Grid item xs={8} md={8} >
                        <MDBox>
                          <Autocomplete
                            freeSolo
                            value={catalogo.localita}
                            options={lista_loc.map((loc) => loc.localita)}
                            renderInput={(params) =>
                              <MDInput type="text" {...params} label="località" name='localita' fullWidth
                                {...register('localita')}
                                InputLabelProps={{ shrink: true }} disabled={modo_vis} />}
                          />
                        </MDBox>
                      </Grid>
                      <Grid item xs={4} md={2} >
                        <MDBox>
                          <MDInput type="text" label="provincia" name='provincia' fullWidth required  {...register('provincia')}
                            disabled={modo_vis} error={errors.provincia ? true : false} InputLabelProps={{ shrink: true }} />
                          {errors.provincia ? <span style={{ color: 'red', fontSize: '12px' }}>{errors.provincia?.message}</span> : ''}
                        </MDBox>
                      </Grid></Grid>

                    <MDBox mb={2} sx={{ display: 'grid', gap: 1, gridTemplateColumns: '1fr 1fr' }}>
                      <MDBox>
                        <MDInput type="text" label="Telefono" name='telefono' fullWidth required {...register('telefono')} disabled={modo_vis}
                          error={errors.telefono ? true : false} InputLabelProps={{ shrink: true }}
                        />
                        {errors.telefono ? <span style={{ color: 'red', fontSize: '12px' }}>{errors.telefono?.message}</span> : ''}
                      </MDBox>
                      <MDBox>
                        {espositore_azienda == 0 &&
                          <MDInput type="text" label="Fax" name='fax' fullWidth {...register('fax')} InputLabelProps={{ shrink: true }} disabled={modo_vis} />}
                      </MDBox>
                    </MDBox>

                    <MDBox mb={2} sx={{ display: 'grid', gap: 1, gridTemplateColumns: '1fr 1fr' }}>
                      <MDBox>
                        <MDInput type="text" label="WWW" name='www' fullWidth  {...register('www')} disabled={modo_vis}
                          error={errors.www ? true : false} InputLabelProps={{ shrink: true }}
                        />
                        {errors.www ? <span style={{ color: 'red', fontSize: '12px' }}>{errors.www?.message}</span> : ''}
                      </MDBox>
                      <MDBox mb={2} >
                        <MDInput type="text" label="Email" fullWidth required name='email' {...register('email')} disabled={modo_vis} error={errors.email ? true : false} InputLabelProps={{ shrink: true }} />
                        {errors.email ? <span style={{ color: 'red', fontSize: '12px' }}>{errors.email?.message}</span> : ''}
                      </MDBox>
                    </MDBox>

                    {espositore_azienda == 0 &&
                      <MDBox mb={2} >
                        <MDBox>
                          <MDInput type="text" label="PEC" name='pec' fullWidth  {...register('pec')} disabled={modo_vis}
                            error={errors.pec ? true : false} InputLabelProps={{ shrink: true }}
                          />
                          {errors.pec ? <span style={{ color: 'red', fontSize: '12px' }}>{errors.pec?.message}</span> : ''}
                        </MDBox>
                        <MDBox mb={2} >
                        </MDBox>
                      </MDBox>}

                    <MDBox mb={2}>
                      <MDTypography variant="button" fontWeight="regular" color="text"> Codici Merceologici</MDTypography>
                    </MDBox>

                    <MDBox mb={2}>
                      <ReactHookFormSelect
                        id="codice_merceologico_01"
                        name="codice_merceologico_01"
                        label="Codice Merceologico 1"
                        control={control}
                        defaultValue=''
                        variant="outlined"
                        required
                        disabled={modo_vis}
                        error={errors.codice_merceologico_01 ? true : false}
                      >
                        {lista_codici_m.map((cm) => (
                          <MenuItem
                            key={cm.codice}
                            value={cm.codice}
                          >
                            {cm.codice} - {cm.descrizione}
                          </MenuItem>
                        ))}

                      </ReactHookFormSelect>
                      {errors.codice_merceologico_01 ? <span style={{ color: 'red', fontSize: '12px' }}>{errors.codice_merceologico_01?.message}</span> : ''}
                    </MDBox>
                    <MDBox mb={2}>
                      <ReactHookFormSelect
                        id="codice_merceologico_02"
                        name="codice_merceologico_02"
                        label="Codice Merceologico 2"
                        control={control}
                        defaultValue=''
                        variant="outlined"
                        disabled={modo_vis}
                      >
                        {lista_codici_m.map((cm) => (
                          <MenuItem
                            key={cm.codice}
                            value={cm.codice}
                          >
                            {cm.codice} - {cm.descrizione}
                          </MenuItem>
                        ))}

                      </ReactHookFormSelect>
                    </MDBox>




                    <MDBox mb={2}>
                      <MDBox>
                        <MDInput type="text" label="Prodotti / Servizi in esposizione " fullWidth required
                          name='prodotti_esposti' {...register('prodotti_esposti')} multiline rows={3} InputLabelProps={{ shrink: true }}
                          error={errors.prodotti_esposti ? true : false} disabled={modo_vis} />
                        {errors.prodotti_esposti ? <span style={{ color: 'red', fontSize: '12px' }}>{errors.prodotti_esposti?.message}</span> : ''}
                      </MDBox>
                    </MDBox>




                    <MDBox mb={2}>
                    </MDBox>



                    <MDBox sx={{ display: 'flex', flexDirection: 'row', pt: 2 }} mt={4} mb={1}>
                      <MDButton variant="outlined" color="success" onClick={onClose}   >
                        {modo_vis ? "Chiudi" : "Annulla le modifiche"}
                      </MDButton>
                      <MDBox sx={{ flex: '1 1 auto' }} />
                      <MDBox ml={2}>

                      </MDBox>
                      <MDBox ml={2}>
                        <MDButton variant="gradient" color="success" onClick={handleSubmit(onSave)} type="submit" disabled={modo_vis}  >
                          Salva
                        </MDButton>
                      </MDBox>

                    </MDBox>


                    <MDBox>

                    </MDBox>


                  </MDBox>
                </CardContent>

              </MDBox>

            </Card>
          </Grid >
        </Grid >
      </MDBox >

    </Dialog>
  );
}

export default DatiCatalogoEditModal;
