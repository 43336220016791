
import { Navigate } from "react-router-dom";
// Material Dashboard 2 React layouts

import Dashboard from "pages/riservata/Dashboard";
import AuthService from 'services/API/Auth/AuthService.js';

// @mui icons
import Icon from "@mui/material/Icon";

import ExpoPartecipazioniVerify from "pages/riservata/expo_partecipazioni_admin/ExpoPartecipazioniVerify";



//console.log("ROUTEADMIN.JS ");


function PrivateRoute({ children }) {
  const isLoggedIn = AuthService.isLoggedIn();
   return isLoggedIn ? children : <Navigate to="/forbidden" />;
}
function VerifierRoute({ children }) {
  const isLoggedIn = AuthService.isLoggedIn();
  if(isLoggedIn){
  const isVerifier = AuthService.isVerifier() || AuthService.isAdmin();
  //console.log("isAdmin="+isAdmin);
  return isVerifier ? children : <Navigate to="/forbidden" />;
  }else
  return <Navigate to="/forbidden" />;
}


const routesV= [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/welcome",
    component: <PrivateRoute><Dashboard /></PrivateRoute>,
  },
  
 
{
  type:  "title",
  title: "Espositori",
  key: "ee_menu",
},
{
  type:"collapse",
  name: "Verifica Espositori",
  key: "expo_partecipazioni_verifica",
  icon: <Icon fontSize="small">table_view</Icon>,
  route: "/expo_partecipazioni_verifica",
  component:<VerifierRoute><ExpoPartecipazioniVerify /></VerifierRoute>,
},
];




export default routesV;
