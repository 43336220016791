import { useEffect, useState } from "react";
// @mui material components

// Material Dashboard 2 React components
import MDBox from "common/components/MD/MDBox";
import MDButton from "common/components/MD/MDButton";


// Material Dashboard 2 React example components
import DataTable from "common/components/implemented/Tables/DataTable/DataTables";

// Data
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Chip, Dialog, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import ComunicazioniService from "services/API/Comunicazioni/ComunicazioniService";
import ExpoRichiesteService from "services/API/ExpoRichieste/ExpoRichiesteService";
import { ColumnFilter } from "common/components/implemented/Tables/DataTable/ColumnFilter";
import { SelectColumnFilterTipo } from "./SelectColumnFilterTipo";
import MDTypography from "common/components/MD/MDTypography";
import { NoneFilter } from "./NoneFilter";
import { SelectColumnFilterStato } from "./SelectColumnFilterStato";

function ElencoProposteModal({ onSave, onClose, show}) {

 
  const [proposte, setProposte] = useState([]);



  useEffect(() => {
    if(show){
    console.log("LOAD ElencoProposteModal");
    retrieveProposte();
    }
  }, [show]);

   const retrieveProposte = () => {
    ExpoRichiesteService.getAllRichieste('Agriumbria 2025', 'INVIATA', 'ALL')
      .then(response => {
        setProposte(response.data);
      })
      .catch(e => {
        console.log(e);
      });
  };

  const ppAttributes = [];
  proposte.forEach(prop => {

   
    ppAttributes.push({
      er:prop,idRichiesta:prop.idRichiesta,
      mqr:prop.mqr,
      protocollo: prop.protocollo,
      description:"Proposta di Partecipazione prot."+prop.protocollo,
      azienda: prop.ragione_sociale, 
      data_invio: prop.string_data_agg,
      data_um: prop.string_data_agg_accettazione,
      note: prop.note,
      cm_string: prop.cm_string,
      stato: prop.stato_accettazione,
      av: prop.anticipo_controllato_string,
      etichetta: prop.etichetta,
      color:prop.color,
      id_utente:prop.id_utente,
      esigenze_espositive: prop.esigenze_espositive,
      tipologia_espositiva_string: prop.tipologia_espositiva_string,

      action: (
        <MDBox>

            <MDButton onClick={() => onSave(prop)} variant="outlined" color={"success"} iconOnly={true} >
            <CheckCircleIcon></CheckCircleIcon>

          </MDButton>
         
        </MDBox>
      ),

    })
  });


  const ppTableData = {
    rows: ppAttributes,

    columns: [
    
      { Header: "Protocollo", accessor: "protocollo", Filter: ColumnFilter, width: "2%", align: "left", id: "proposte_0"},
     { Header: "Azienda", accessor: "azienda", Filter: ColumnFilter, align: "left", with: "50%", id: "proposte_2" },
      { Header: "Codice Merceologico", accessor: "cm_string", Filter: ColumnFilter, width: "10%", align: "left", id: "proposte_3" },
      {
        Header: "Tipologia Espositiva Richiesta", accessor: "tipologia_espositiva_string",
        id: "proposte_4",

        Filter: SelectColumnFilterTipo,
        Cell: ({ row }) => {
          return (
            <MDBox>
              <MDBox> <MDTypography variant="button"
                fontWeight="regular"
                color="text">{row.original.tipologia_espositiva_string}</MDTypography></MDBox>
              {row.original.esigenze_espositive &&
                <MDBox>  <MDTypography variant="button"
                  fontWeight="regular"
                  color="text">*** {row.original.esigenze_espositive.substring(0, 20)}...</MDTypography> </MDBox>}
              {row.original.note &&
                <MDBox>  <MDTypography variant="button"
                  fontWeight="regular"
                  color="text">*** {row.original.note.substring(0, 20)}...</MDTypography> </MDBox>}
            </MDBox>

          );
        },
        width: "15%", align: "left"
      },
      { Header: "Mq richiesti", accessor: "mqr", Filter: NoneFilter, align: "left", width: "5%", id: "proposte_9" },
      { Header: "Stato", accessor: "stato", Filter: SelectColumnFilterStato, align: "left", width: "5%", id: "proposte_5" },
      { Header: "Anticipo Controllato", accessor: "av", Filter: ColumnFilter, align: "left", width: "5%", id: "proposte_6" },
      { Header: "Etichetta", accessor: "etichetta", Filter: ColumnFilter,
        Cell: ({ row }) => {
          return (
            <Chip label={row.original.etichetta} color={row.original.color} />

          );
        },
        align: "left", width: "5%", id: "proposte_7" },
      { Header: "Data Ultima Modifica", accessor: "data_um", Filter: ColumnFilter, width: "10%", align: "left", id: "proposte_8" },

      { Header: "Azioni", accessor: "action", Filter: NoneFilter, align: "center", enableGlobalFilter: false, id: "proposte_1",disableSortBy:true},
    ],



  };
  const mypagination = { color: 'success', variant: "contained" };


  ///////////////////////////////////////////////////////////////////////////
 
  return (
    <Dialog open={show} onClose={onClose}>
        <DialogTitle>Scegli Proposta</DialogTitle>
            <DialogContent>
                <DialogContentText>
                  Scegli la Proposta di Partecipazione cui è legato il messaggio,  selezionando tra quelle che ti sono pervenute.
                </DialogContentText>
              <MDBox pt={3}>
                <DataTable canSearch={true}
                  table={ppTableData}
                  isSorted={false}
                  entriesPerPage={true}
                  showTotalEntries={true}
                  pagination={mypagination}
                  noEndBorder
                />
              </MDBox>
              </DialogContent>

      </Dialog>



  );
}

export default ElencoProposteModal;
