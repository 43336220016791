import React, { useState } from 'react';

const Anagrafica = () => {
 const anag = {
    idAnagrafica: 0, 
    persona_giuridica: 0, 
    nome: '', 
    cognome: '', 
    azienda: '', 
    referente: '', 
    titolo: 'Spett.le', 
    piva: '', 
    cf: '',
    id_professione: 0, professione_altro: '', 
    cm_code: '', cm_desc: '',
     email: '', 
     indirizzo: '', 
     civico: '', 
     cap: '', 
     localita: '',
    provincia: '', 
    telefono: '', 
    privacy: false, 
    operatore: false, 
    espositore: false, 
    sorgente: '', 
    old_settore: ''
 };

 return (
  <div>
   <p>Name: {anag.nome}</p>
   <p>Age: {anag.cognome}</p>
   <p>aziebnda: {anag.azienda}</p>
  </div>
 );
};

export default Anagrafica;