import React, { useEffect, useState } from 'react';
//prop-types is a library for typechecking of props
import PropTypes from "prop-types";
import MDBox from "common/components/MD/MDBox";
import MDInput from "common/components/MD/MDInput";


export const ColumnFilter = ({column})=>{

  const{filterValue,setFilter,id}=column


  



    return (

        <MDBox ml="auto">
        <MDInput
          placeholder="Search..."
          value={filterValue || ''}
          size="small"
          fullWidth
          onChange={e => {
           setFilter(e.target.value);
           // Salva il valore del filtro in sessione
        sessionStorage.setItem(`filter_${id}`, e.target.value || '');
          }}
        />
      </MDBox>
    )

}

/*
// Setting default values for the props of DataTable
ColumnFilter.defaultProps = {
    filter:PropTypes.string,
    setFilter: PropTypes.func,
  };
  
  // Typechecking props for the DataTable
  ColumnFilter.propTypes = {
    filter:'',
    setFilter: null,
  };*/