import React, { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import { Link } from "react-router-dom";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "common/components/MD/MDBox";
import MDButton from "common/components/MD/MDButton";

import MDTypography from "common/components/MD/MDTypography";


// Material Dashboard 2 React example components
import DashboardLayout from "layouts/DashboardLayout";
import DashboardNavbar from "layouts/components/Navbars/DashboardNavbar";
import Footer from "layouts/components/Footer";
import HeaderForm from "layouts/components/HeaderForm";


import Alert from '@mui/material/Alert';

import loading_image from 'common/assets/images/icons/loading-green-loading.gif'


import ExpoPartecipazioniTable from "./ExpoPartecipazioniTable";
import { ColumnFilter } from "common/components/implemented/Tables/DataTable/ColumnFilter";

import AttachEmailIcon from '@mui/icons-material/AttachEmail';
import base64ToArrayBuffer from "common/script/base64ToArrayBuffer";

import ExpoPartecipazioniService from "services/API/expo_partecipazioni/ExpoPartecipazioniService";
import { NoneFilter } from "../expo_richieste_admin/NoneFilter";
import { SelectColumnFilterPostazione } from "../expo_richieste_admin/SelectColumnFilterPostazione";
import { SelectColumnFilterMsgS } from "./SelectColumnFilterMsgS";
import AuthService from "services/API/Auth/AuthService";


function ExpoPartecipazioniList() {

  const [partecipazioni, setPartecipazioni] = useState([]);
  //const  {manifestazione}= useManifestazione();

  const manifestazione = "Agriumbria 2025";

  const [loading, setLoading] = useState(true);


  //per passarmi la variabiule se ho salvato e far comparire l'alert
  const location = useLocation();
  //console.log(location);

  const { state } = location != null ? location : { state: { submitted: false, salvato: 'NO', indietro: false } }; // Read values passed on state

  useEffect(() => {
    if (AuthService.isTokenOk()) {
    console.log("FILTRO");
    setPartecipazioni([]);
    setLoading(true);

   filtra();
    }
  }, []);

function filtra(){

  ExpoPartecipazioniService.getAllPartecipazioni(manifestazione)
  .then(response => {
    console.log(response.data);
    setPartecipazioni(response.data);
    setLoading(false);
  })
  .catch(e => {
    console.log(e);
    setLoading(false);
  });

}

  async function download_pdf_contratto(idPartecipazione) {

    ExpoPartecipazioniService.download_pdf_contratto(idPartecipazione)
      .then(response => {

        return response.data;
      })
      .then(blob => {

        var arrrayBuffer = base64ToArrayBuffer(blob); //data is the base64 encoded string
        var blobb = new Blob([arrrayBuffer], { type: "application/pdf" });
        var url = URL.createObjectURL(blobb);
        var a = document.createElement('a');
        a.href = url;
        a.target = "_blank";

        a.download = "Contratto.pdf";
        ;

        document.body.appendChild(a); // append the element to the dom
        a.click();
        a.remove(); // afterwards, remove the element  
      })
      .catch(error => {
        console.error(error);
      });




  }


  const ppAttributes = [];

  if (partecipazioni.length > 0) {
    partecipazioni.forEach(part => {


      //console.log(partecipazioni);
      let action = (
        <MDBox>
{/*
          <Link to="/expo_richiestaMessage" state={{ id_proposta: part.id_richiesta0, chiamante: 'expo_partecipazioni_admin' }}
          >
            <MDButton variant="outlined" color={"success"} iconOnly={true}  >
              < AttachEmailIcon />
            </MDButton>   </Link>*/}
          <Link to="/expo_partecipazioneAssign" state={{ modo: 'edit', soggetto: part, subject: part.manifestazione, chiamante: 'expo_partecipazioni_admin' }}>
            <MDButton variant="outlined" color={"success"} iconOnly={true}>
              <Icon>edit</Icon>
            </MDButton>
          </Link>
        </MDBox>
      );

      let contratto = "NO";

      if (part.nome_pdf_contratto != null) {

        contratto = (<MDBox>
          <MDTypography
            variant="button"
            fontWeight="regular"
            color="text"
            sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
            onClick={() => download_pdf_contratto(part.idPartecipazione)}
          > Ric. il {part.data_contratto}  : 
         [Contratto.pdf]
          </MDTypography>
          
          </MDBox>
        );
      }

      let sal="NO";
      if(part.saldato)  sal="SI";

      let cp="NO";
      if(part.contratto_protocollato)  cp="SI";

      let msg_s="DA SCRIVERE";
      if (part.id_messaggio != null && part.id_messaggio != 0) {
        msg_s="DA INVIARE";
        if (part.inviato) {
          msg_s = "INVIATO IL " + part.string_data_invio;
        }
      }
      let msg_c="DA SCRIVERE";
      if (part.id_messaggio_c != null && part.id_messaggio_c != 0) {
        msg_c="DA INVIARE";
        if (part.inviato_c) {
          msg_c = "INVIATO IL " + part.string_data_invio_c;
        }
      }
      let msg_ser="DA SCRIVERE";
      if (part.id_messaggio_s != null && part.id_messaggio_s != 0) {
        msg_ser="DA INVIARE";
        if (part.inviato_s) {
          msg_ser = "INVIATO IL " + part.string_data_invio_s;
        }
      }

      let rx_dc="NO";
      if (part.id_catalogo_e != 0) {
        rx_dc="OK";
   
      }


      ppAttributes.push({
        part:part,
        idPartecipazione:part.idPartecipazione,
        mq: part.mqa,
        protocollo: part.protocollo,
        azienda: part.ragione_sociale,
        action: action,
        tipologia_espositiva_string: part.tipologia_espositiva_string,
        postazione: part.postazione,
        dc: msg_c,
        ser:msg_ser,
        contratto: contratto,
        contratto_protocollato:cp,
        saldato: sal,
        msg_s:msg_s,
        rx_dc:rx_dc,
      })
    });
  }



  const ppTableData = {
    rows: ppAttributes,

    columns: [

      { Header: "Protocollo", accessor: "protocollo", Filter: ColumnFilter, width: "5%", align: "left", id: "partecipazioni_0" },
      { Header: "Azioni", accessor: "action", Filter: NoneFilter, width: "5%", align: "center", enableGlobalFilter: false, id: "partecipazioni_1", disableSortBy: true },
      { Header: "Azienda", accessor: "azienda", Filter: ColumnFilter, align: "left",  id: "partecipazioni_2" },
      { Header: "Tipologia Espositiva Assegnata", accessor: "tipologia_espositiva_string", Filter: ColumnFilter, align: "left", with: "10%", id: "partecipazioni_3" },
      { Header: "Mq Asseganti", accessor: "mq", Filter: ColumnFilter, align: "left", width: "5%", id: "partecipazioni_4" },
      { Header: "Postazione", accessor: "postazione", Filter: SelectColumnFilterPostazione, align: "left", width: "5%", id: "partecipazioni_5" },
      { Header: "Msg Spazio", accessor: "msg_s", Filter:  SelectColumnFilterMsgS, width: "2%", align: "left", id: "partecipazioni_6" },       
      { Header: "Contratto", accessor: "contratto", Filter: ColumnFilter, width: "10%", align: "left", id: "partecipazioni_7" },
      { Header: "Contratto Protocollato", accessor: "contratto_protocollato", Filter: ColumnFilter, width: "5%", align: "left", id: "partecipazioni_8" },
      { Header: "Msg Dati Catalogo", accessor: "dc", Filter: ColumnFilter, align: "left", width: "5%", id: "partecipazioni_9" },
      { Header: "Msg Servizi Facoltativi", accessor: "ser", Filter: ColumnFilter, align: "left", width: "5%", id: "partecipazioni_10" },
       { Header: "DC Espositore Rx", accessor: "rx_dc", Filter: ColumnFilter, align: "left", width: "5%", id: "partecipazioni_11" },
      { Header: "Saldato", accessor: "saldato", Filter: ColumnFilter, align: "left", width: "5%", id: "partecipazioni_12" },

    ],



  };

  const mypagination = { color: 'success', variant: "contained" };




  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>

          <Grid item xs={12}>
            <Card>
              <HeaderForm titolo={"Espositori " + manifestazione} add="" modo="vis" />
              <MDBox pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center">

                {(state != null && state.submitted && (state.salvato === 'INS' || state.salvato === 'MOD')) &&

                  <Alert severity="success">Dati salvati con successo!</Alert>}

                {(state != null && state.submitted && state.salvato === 'DEL') &&

                  <Alert severity="success">Dati eliminati con successo!</Alert>}
              </MDBox>


              <MDBox pt={3}>
                <ExpoPartecipazioniTable 
                  canSearch={true}
                  table={ppTableData}
                  nome_tabella="partecipazioni"
                  isSorted={true}
                  entriesPerPage={true}
                  showTotalEntries={true}
                  pagination={mypagination}
                  noEndBorder
                  responsive={true}
                  first_load={state == null || !state.indietro}
                  filtro_server={true}
                  canExport={true}
                  canSend={true}
                  enableColumnFilters={true}
                  chiamante='expo_partecipazioni_admin'
                  filtra={filtra}
                />
              </MDBox>
              {loading &&
                <MDBox opacity={1} textAlign="center">
                  <img src={loading_image} alt="loading" width={100} />
                </MDBox>
              }

              {(partecipazioni == null || partecipazioni.length === 0) &&
                <MDBox style={{ height: "200px" }} textAlign='center'>
                  <MDTypography
                    variant="button"
                    fontWeight="regular"
                    color="text"
                    sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                  >
                    Non ci sono Espositori x la manifestazione selezionata.
                  </MDTypography></MDBox>
              }
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}



export default ExpoPartecipazioniList;
