import axios from 'axios';
import authHeader from 'services/API/Auth/AuthHeader';
import authHeaderMultipart from 'services/API/Auth/AuthHeaderMultipart';

const getAllModelli=()=> {

  return axios.get(axios.defaults.baseURL + '/comunicazioni/allModelli',{headers: authHeader()});
}

const seleziona_dettagli_modello = (id) => {

  console.log("seleziona_dettagli= " + id);
  return axios.get(axios.defaults.baseURL + `/comunicazioni/modello_seleziona_dettagli/${id}`, { headers: authHeader() });

}

const createModello = (data) => {
    
  return axios.post(axios.defaults.baseURL + '/comunicazioni/modello_ins',data, {
    headers: authHeaderMultipart()
  })  
 };

 const updateModello = (id, data) => {
      console.log(data);

      return axios.post(axios.defaults.baseURL + `/comunicazioni/modello_upd/${id}`, data,{headers: authHeaderMultipart()});
    };

    const removeModello = (id) => {
      console.log("remove= "+id);
      return axios.post(axios.defaults.baseURL + `/comunicazioni/modello_remove/${id}`,null,{headers: authHeader()});
    };
    const seleziona_file = (idModello,tipo) => {

    
      const response = axios.get(axios.defaults.baseURL + `/comunicazioni/download_file/${idModello}/${tipo}`, { headers: authHeader() }, { responseType: 'blob' },);
      console.log(response);
      return response;
  }

  const getAllMessaggi=(id_proposta,id_utente)=> {

    return axios.get(axios.defaults.baseURL + `/comunicazioni/allMessaggi/${id_proposta}/${id_utente}`,{headers: authHeader()});
  }


const ComunicazioniService = {
    getAllModelli,
    seleziona_dettagli_modello,
    createModello,updateModello,
    removeModello,seleziona_file,
    getAllMessaggi
};

export default ComunicazioniService;