import { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from 'react-router-dom'

// Material Dashboard 2 React example components
import DashboardLayout from "layouts/DashboardLayout";
import DashboardNavbar from "layouts/components/Navbars/DashboardNavbar";
import Footer from "layouts/components/Footer";
import HeaderForm from "layouts/components/HeaderForm";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import FormGroup from "@mui/material/FormGroup";
import MenuItem from '@mui/material/MenuItem';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import ReactHookFormSelect from "common/components/mycomponents/ReactHookFormSelect";
import ReactHookFormCheckBox from "common/components/mycomponents/ReactHookFormCheckBox"
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
// Material Dashboard 2 React components

import MDBox from "common/components/MD/MDBox";
import MDTypography from "common/components/MD/MDTypography";
import MDInput from "common/components/MD/MDInput";
import MDButton from "common/components/MD/MDButton";
import Alert from '@mui/material/Alert';
import Autocomplete from '@mui/material/Autocomplete';

//validazione lato client
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// Data
import AnagraficaService from "services/API/Anagrafica/AnagraficaService";
import UtilityService from "services/API/utility/UtilityService";

import ComuniService from 'services/API/Comuni/ComuniService.js';
import useDebounce from "common/custom_hook/useDebounce";
import anag_object from "models/Anagrafica";


const AnagraficaEdit = () => {

    const location = useLocation();
    const navigate = useNavigate();
    console.log("STATE+" + location.state.modo);
    console.log("STATE+" + location.state.subject);
    //  console.log("STATE+"+location.state.soggetto.email);
    const { modo, soggetto, subject } = location.state;

    // user state for form
   const [anag, setAnag] = useState({...anag_object});
   /*{
        idAnagrafica: 0, persona_giuridica: 0, nome: '', cognome: '', azienda: '', referente: '', titolo: 'Spett.le', piva: '', cf: '',
        id_professione: 0, professione_altro: '', cm_code: '', cm_desc: '', email: '', indirizzo: '', civico: '', cap: '', localita: '',
        provincia: '', telefono: '', privacy: false, operatore: false, espositore: false, sorgente: '', old_settore: ''
    });*/

    const [open, setOpen] = useState(false);
    const [openExistent, setOpenExistent] = useState(false);
    const [confirm, setConfirm] = useState(false);
    const [confirmExistent, setConfirmExistent] = useState(false);



    const [lista_loc, setLista_loc] = useState([
        { cap: '', localita: '', sigla_provincia: '', denominazione_provincia: '' },
    ]);
    const [lista_professioni, setLista_professioni] = useState([
        { idProfessione: 0, professione: '' },
    ]);
    const [lista_codici_m, setLista_codici_m] = useState([
        { codice: '', descrizione: '' },
    ]);
    const [lista_categorie, setLista_categorie] = useState([
        { cod_categoria: '', categoria_1: '', categoria_2: '', catergoria_3: '' },
    ]);

    useEffect(() => {
        console.log("fetchInitialData");
        async function fetchInitialData() {
            const response = await AnagraficaService.getAllProfessioni();
            response.errors ? console.log(response.errors) : setLista_professioni([...response.data]);
        }
        fetchInitialData();

    }, []);
    useEffect(() => {
        console.log("fetchInitialData");

        async function fetchInitialData2() {
            const response = await UtilityService.getAllCodiciM();
            response.errors ? console.log(response.errors) : setLista_codici_m([...response.data]);
        }
        fetchInitialData2();

    }, []);

    const [valuePFPG, setValuePFPG] = useState('PF');
    const [isExp, setIsExp] = useState(false);

    const validationSchema = Yup.object().shape({

        nome: Yup.string().when("$isAzienda", {
            is: (val) => {
                return valuePFPG === 'PF';
            },
            then: (s) => s.required('Nome e Cognome richiesto').max(50, 'Lunghezza massima raggiunta'),
            otherwise: (s) => s,
        }),
        cognome: Yup.string().when("$isAzienda", {
            is: (val) => {
                return valuePFPG === 'PF';
            },
            then: (s) => s.required('Nome e Cognome richiesto').max(50, 'Lunghezza massima raggiunta'),
            otherwise: (s) => s,
        }),
        azienda: Yup.string().when("$isAzienda", {
            is: (val) => {
                return valuePFPG === 'PG';
            },
            then: (s) => s.required('Ragione Sociale richiesto').max(50, 'Lunghezza massima raggiunta'),
            otherwise: (s) => s,
        }),
        email: Yup.string().when('no_email', {
            is: (no_email) => !no_email,
            then: (schema) => schema.required('Email richiesta').email('Email non corretta').max(50, 'Lunghezza massima raggiunta'),
            otherwise: (schema) => schema.max(50, 'Lunghezza massima raggiunta')
        }),
        indirizzo: Yup.string()
            .required('Indirizzo richiesto')
            .max(30, 'Inserire soltanto l\'indirizzo (via/piazza/vocabolo/...'),
        civico: Yup.string()
            .required('Numero civico richiesto')
            .max(10, 'Lunghezza massima raggiunta'),
        cap: Yup.string()
            .required('Cap richiesto')
            .max(10, 'Lunghezza massima raggiunta'),
        localita: Yup.string()
            .required('Località richiesto')
            .max(100, 'Lunghezza massima raggiunta'),
        telefono: Yup.string()
            .max(20, 'Lunghezza massima raggiunta'),

        codice_riferimento: Yup.string()
            .max(20, 'Lunghezza massima raggiunta'),
        provincia: Yup.string()
            .required('Sigla Provincia Richiesta')
            .max(4, 'Inserire la sigla della provincia'),
        privacy: Yup.boolean().oneOf([true], 'Privacy richiesto'),
    
        professione: Yup.string().when("$isExp", {
            is:(val) => {
                return !isExp;
            },
            then: (s) => s.required('Professione Richiesta'),
            otherwise: (s) => null,
        }) .nullable(),
        codice_merceologico: Yup.string().when("$isExp", {
            is:(val) => {
                return isExp;
            },
            then: (s) => s.required('Codice Merceologico Richiesto'),
            otherwise: (s) => null,
        }) .nullable(),

        
    });


    useEffect(() => {
        if (soggetto != null) {
            console.log("soggetto non nullo: id=" + soggetto.idAnagrafica);
            retrive_soggetto();
        } else {
            console.log("soggetto nullo");
        }
    }, [soggetto]);

    async function retrive_soggetto() {
        console.log("Cambiata anag->seleziono i dettagli :" + soggetto.idAnagrafica);
        if (soggetto.idAnagrafica !== 0) {
            const response = await AnagraficaService.seleziona_dattagli(soggetto.idAnagrafica);
            console.log(response.data);
            response.errors ? console.log(response.errors) : retrive(response.data);


        }
    }

    const onSubmit = async data => {
    
        console.log("Salva ");
        
      };

    const {
        register, //register inputs
        control,
        setValue,
        handleSubmit,//handle form submit
        formState: { errors }
    } = useForm({
        resolver: yupResolver(validationSchema),
    });


    const handleChangeE = (event) => {
        setIsExp(event.target.checked);

    }

    const handleChangePF = (event) => {
        setValuePFPG(event.target.value);
        console.log("VAL=" + valuePFPG);
    }

    function retrive(data) {
        console.log("RETRIVE =" + data.idAnagrafica);

        setAnag({
            idAnagrafica: data.idAnagrafica,
            persona_giuridica: data.persona_giuridica,
            nome: data.nome,
            cognome: data.cognome,
            azienda: data.azienda,
            referente: data.referente,
            titolo: data.titolo,
            piva: data.piva, cf: data.cf,
            professione_altro: data.professione_altro,
            id_professione: data.id_professione, professione: data.professione,
            cm_code: data.cm_1,
            cm_desc: data.cm_1_descrizione,
            email: data.email,
            indirizzo: data.indirizzo,
             indirizzo2: data.indirizzo2,
            civico: data.civico, cap: data.cap,
            localita: data.localita,
            provincia: data.provincia,
            telefono: data.telefono,
            sorgente: data.sorgente,
            old_settore: data.old_settore,
            privacy: data.privacy,
            operatore: data.operatore,
            espositore: data.espositore,
            interessi: data.interessiDTO
        })

        console.log("selezionata  " + anag.idAnagrafica);

    }


    useEffect(() => {

        console.log("Cambiata anag->riempo i campi  " + anag.idAnagrafica);
        // reset form with user data
        setValuePFPG(anag.persona_giuridica ? 'PG' : 'PF');
        setValue("persona_fisica", !anag.persona_giuridica);
        setValue("nome", anag.nome);
        setValue("cognome", anag.cognome);
        if (anag.email == null) { setValue("no_email", true); } else {
            setValue("email", anag.email);
            setValue("no_email", false);
        }
        setValue("azienda", anag.azienda);
        setValue("referente", anag.referente);
        setValue("titolo", anag.titolo);
        setValue("piva", anag.piva);
        setValue("cf", anag.cf);
        setValue("id_professione", anag.id_professione);
        setValue("professione", anag.professione);
        setValue("professione_altro", anag.professione_altro);


        setValue("codice_merceologico", anag.cm_desc);

        setValue("indirizzo", anag.indirizzo);
        setValue("indirizzo2", anag.indirizzo2);
        setValue("civico", anag.civico);
        setValue("cap", anag.cap);
       setQuery_cap(anag.cap);


       // setValue("localita", anag.localita);
      //  setValue("provincia", anag.provincia);
        setValue("telefono", anag.telefono);
        setValue("operatore", anag.operatore);
        setValue("espositore", anag.espositore);
        setIsExp(anag.espositore);
        console.log("anag.localita " + anag.localita);
        if (anag.interessi != null) {
            anag.interessi.forEach(i => {
                console.log("interesse " + i.cod_categoria);
                if ((i.cod_categoria === 'agricoltura_1')) setValue("agricoltura_1", true);
                if ((i.cod_categoria === 'agricoltura_2')) setValue("agricoltura_2", true);
                if ((i.cod_categoria === 'agricoltura_3')) setValue("agricoltura_3", true);
                if ((i.cod_categoria === 'agricoltura_4')) setValue("agricoltura_4", true);
                if ((i.cod_categoria === 'agricoltura_5')) {
                    setValue("agricoltura_5", true);

                    setValue("agricoltura_altro", i.categoria_2);
                }
                if ((i.cod_categoria === 'zootecnia_1')) setValue("zootecnia_1", true);
                if ((i.cod_categoria === 'zootecnia_2')) setValue("zootecnia_2", true);
                if ((i.cod_categoria === 'zootecnia_3')) {
                    setValue("zootecnia_3", true);
                    setValue("zootecnia_3_det", i.categoria_2);
                }
                if ((i.cod_categoria === 'zootecnia_4')) {
                    setValue("zootecnia_4", true);
                    setValue("zootecnia_altro", i.categoria_2);
                }
                if ((i.cod_categoria === 'alimentazione_1')) setValue("alimentazione_1", true);
                if ((i.cod_categoria === 'alimentazione_2')) {
                    setValue("alimentazione_2", true);


                    if ((i.categoria_2 === 'Bancotec')) setValue("alimentazione_21", true);
                    if ((i.categoria_2 === 'Enotec')) setValue("alimentazione_22", true);
                    if ((i.categoria_2 === 'Oleatec')) setValue("alimentazione_23", true);
                    if ((i.categoria_2 === 'Milktec')) setValue("alimentazione_24", true);
                }



            });
        }
    }, [anag]);

    const [query_cap, setQuery_cap] = useState('');
    const debouncedQuery = useDebounce(query_cap, 500); // Debounce di 500ms
  
  
    // Effettua la ricerca quando `debouncedQuery` cambia
    useEffect(() => {
      if (debouncedQuery) {
        console.log(`Effettua la ricerca per: ${debouncedQuery}`);
        // Qui potresti invocare un'API di ricerca o filtrare i dati localmente
        console.log("retrive");
  
     
        setLista_loc([
            { cap: '', localita: '', sigla_provincia: '', denominazione_provincia: '' },
        ]);
        setValue('localita', '');
    
        ComuniService.getAllComuni(query_cap)
        .then(response => {
            setLista_loc([...response.data]);
            console.log(response.status);
            if (response.status === 200) {
                console.log(response.data[0].localita);
                setValue('provincia', response.data[0].sigla_provincia.toUpperCase());
                console.log("RESPONSE COMUNI=" + anag.localita);
                if (anag.localita != null) {
                    console.log("LOC=" + anag.localita);
                    setValue('localita', anag.localita);

                }
            }


        })
        .catch(e => {
            console.log(e);
        })}
    }, [debouncedQuery]);
  

   

    //sono quelli lato server
    const [error, setError] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);

   


    const insertAnag = (formData) => {
        console.log("Salva ");
        setError(null);
 
        //costruisco l'array delle preferenze
        let myArray = [];

        if (formData.agricoltura_1) {
            myArray.push({ categoria_0: "AGRICOLTURA", categoria_1: "Macchine e Attrezzature Agricole", categoria_2: '' });
        }
        if (formData.agricoltura_2) {
            myArray.push({ categoria_0: "AGRICOLTURA", categoria_1: "Giardinaggio", categoria_2: '' });
        }
        if (formData.agricoltura_3) {
            myArray.push({ categoria_0: "AGRICOLTURA", categoria_1: "Forestazione", categoria_2: '' });
        }
        if (formData.agricoltura_4) {
            myArray.push({ categoria_0: "AGRICOLTURA", categoria_1: "Sementi,Piante,Fiori", categoria_2: '' });
        }
        if (formData.agricoltura_5) {
            myArray.push({ categoria_0: "AGRICOLTURA", categoria_1: "Altro...", categoria_2: formData.agricoltura_altro });
        }

        if (formData.zootecnia_1) {
            myArray.push({ categoria_0: "ZOOTECNIA", categoria_1: "Attrezzature Zootecniche", categoria_2: '' });
        }
        if (formData.zootecnia_2) {
            myArray.push({ categoria_0: "ZOOTECNIA", categoria_1: "Mangimi", categoria_2: '' });
        }
        if (formData.zootecnia_3) {
            myArray.push({ categoria_0: "ZOOTECNIA", categoria_1: "Allevamenti di ...", categoria_2: formData.zootecnia_3_det });
        }
        if (formData.zootecnia_4) {
            myArray.push({ categoria_0: "ZOOTECNIA", categoria_1: "Altro...", categoria_2: formData.zootecnia_altro });
        }

        if (formData.alimentazione_1) {
            myArray.push({ categoria_0: "ALIMENTAZIONE", categoria_1: "Prodotti Agroalimentari", categoria_2: '' });
        }
        if (formData.alimentazione_2) {

            if (formData.alimentazione_21) {
                myArray.push({ categoria_0: "ALIMENTAZIONE", categoria_1: "Attrezzature e impianti Agroalimentari", categoria_2: 'Bancotec' });
            }
            if (formData.alimentazione_22) {
                myArray.push({ categoria_0: "ALIMENTAZIONE", categoria_1: "Attrezzature e impianti Agroalimentari", categoria_2: 'Enotec' });
            }
            if (formData.alimentazione_23) {
                myArray.push({ categoria_0: "ALIMENTAZIONE", categoria_1: "Attrezzature e impianti Agroalimentari", categoria_2: 'Oleatec' });
            }
            if (formData.alimentazione_24) {
                myArray.push({ categoria_0: "ALIMENTAZIONE", categoria_1: "Attrezzature e impianti Agroalimentari", categoria_2: 'Milktec' });
            }

        }
        console.log(myArray);

        let pg = valuePFPG === 'PG';

        let id_professione = 0;

        let cm = null;
        if (formData.espositore) {
            cm = lista_codici_m.find(c => c.descrizione === formData.codice_merceologico).codice;
            console.log(cm);
        } else {
            id_professione = lista_professioni.find(p => p.professione === formData.professione).id;
            console.log(id_professione);
        }

        let em = formData.email;
        if (em != null && em.includes('@')) setValue("no_email", false);
        if (formData.no_email) em = null;
        let visitatore = (subject === "V");

        var data = {
            persona_giuridica: pg,
            nome: formData.nome,
            cognome: formData.cognome,
            azienda: formData.azienda,
            referente: formData.referente,
            piva: formData.piva,
            cf: formData.cf,
            professione_altro: formData.professione_altro,
            email: em,
            id_professione: id_professione,
            cm_1: cm,
            indirizzo: formData.indirizzo,
            indirizzo2: formData.indirizzo2,
            civico: formData.civico,
            cap: formData.cap,
            localita: formData.localita,
            provincia: formData.provincia,
            telefono: formData.telefono,
            codice_riferimento: formData.codice_riferimento,
            titolo: formData.titolo,
            privacy: formData.privacy,
            operatore: formData.operatore,
            espositore: formData.espositore,
            visitatore: visitatore,
            id_sorgente: 4, //farfiera operatore uf   
            interessiDTO: [...myArray],
        };

        if (modo === 'new') {
            console.log("Inserisci Anagrafica");

            if (subject === "ALL" || subject === "E" ) {
                AnagraficaService.create(data)
                    .then(response => {
                        handleResponse(response, 'INS');
                    })
                    .catch(errore => {
                        handleError(errore);
                    });
            }
            if (subject === "O") {
                AnagraficaService.createOP(data)
                    .then(response => {
                        console.log("RISPOSTA OK");
                        handleResponse(response, 'INS');
                    })
                    .catch(errore => {
                        console.log("ERRORE");
                        handleError(errore);
                    });
            }
            if (subject === "V") {
                AnagraficaService.createV(data)
                    .then(response => {
                        console.log("RISPOSTA OK");
                        handleResponse(response, 'INS');
                    })
                    .catch(errore => {
                        console.log("ERRORE");
                        handleError(errore);
                    });
            }
        } else {
            console.log("update Anagrafica");
            if (subject === "ALL" || subject === "E" ) {
                AnagraficaService.update(anag.idAnagrafica, data)
                    .then(response => {
                        handleResponse(response, 'MOD');
                    })
                    .catch(errore => {
                        handleError(errore);
                    });
            }
            if (subject === "O") {
                AnagraficaService.updateOP(anag.idAnagrafica, data)
                    .then(response => {
                        handleResponse(response, 'MOD');
                    })
                    .catch(errore => {
                        handleError(errore);
                    });
            }
            if (subject === "V") {
                AnagraficaService.updateV(anag.idAnagrafica, data)
                    .then(response => {
                        handleResponse(response, 'MOD');
                    })
                    .catch(errore => {
                        handleError(errore);
                    });
            }
        }
    };

    function handleClose() {
        setOpen(false);
    }

    function handleConfirm() {
        setConfirm(true);
        setOpen(false);
        deleteAnag();
    }


    const handleOpen = () => {
        console.log("OPEN DIALOG");
        setOpen(true);
    }

    function handleCloseExistent() {
        setOpenExistent(false);
    }

    function handleConfirmExistent() {
        setConfirmExistent(true);
        setOpenExistent(false);

    }

    const deleteAnag = () => {
        setError(null);

        if (modo === 'edit') {

            console.log("delete anag");

            AnagraficaService.remove(anag.idAnagrafica)
                .then(response => {
                    console.log("handleResponse");
                    handleResponse(response, 'DEL');
                })
                .catch(errore => {
                    console.log("handleError");
                    handleError(errore);
                });

        }
    };

    function handleResponse(response, op) {
        console.log(response.data);

        navigate(`/anagrafica`, { state: { submitted: true, salvato: op,indietro:true } });
        if (subject === "O")
            navigate("/operatori", { subject: 'O', state: { submitted: true, salvato: op } });
        if (subject === "V")
            navigate("/visitatori", { subject: 'V', state: { submitted: true, salvato: op } });
        if (subject === "E")
            navigate("/espositori", { subject: 'E', state: { submitted: true, salvato: op } });
    }

    function handleError(error) {
        setError(error);
        console.log("ERRORE" + error);
        console.log(error.code);

        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log("ERROR:RESPONSE" + error.response.data);
            setErrorMessage(error.response.data);

        } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.log("ERROR:REQUEST" + error.request);
            setErrorMessage(error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message);
            setErrorMessage(error.message);
        }

    }
    
    const annulla = () => {
        console.log("ANNULLA subject=" + subject);
        if (subject === "ALL")
            navigate("/anagrafica", { state: {indietro:true } });
        if (subject === "O")
            navigate("/operatori", { state: {indietro:true } });
        if (subject === "V")
            navigate("/visitatori", { state: {indietro:true } });
        if (subject === "E")
            navigate("/espositori", { state: {indietro:true } });
    }

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" >
                    <DialogTitle id="alert-dialog-title">
                        Eliminare questo record?
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Sei sicuro di voler eliminare questo record?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <MDButton onClick={handleClose}>No</MDButton>
                        <MDButton onClick={handleConfirm} autoFocus>
                            SI
                        </MDButton>
                    </DialogActions>
                </Dialog>

                <Dialog open={openExistent} onClose={handleCloseExistent} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" >
                    <DialogTitle id="alert-dialog-title">
                        Anagrafica esistente: modifico?
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Questa anagrafica è già esistente. La vuoi modificare?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <MDButton onClick={handleCloseExistent}>No</MDButton>
                        <MDButton onClick={handleConfirmExistent} autoFocus>
                            SI
                        </MDButton>
                    </DialogActions>
                </Dialog>



                <form>
                    <Grid container spacing={6}>
                        <Grid item xs={12}>
                            <Card>
                                <HeaderForm titolo={subject === 'O' ? "Operatore" : (subject === 'V' ? "Visitatore" :(subject === 'E' ? "Espositore" : "Anagrafica"))} save={handleSubmit(insertAnag)} remove={handleOpen} exit={annulla} modo={modo} subject={subject} />
                                {error !== null &&
                                    <Alert color="error"><ErrorOutlineOutlinedIcon fontSize="small" />&nbsp;{errorMessage}</Alert>
                                }
                                <MDBox pt={4} pb={3} px={3}>
                                    <MDBox>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12} sm={12} mb={2}>
                                                <MDTypography
                                                    variant="button"
                                                    fontWeight="regular"
                                                    color="text"
                                                    sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                                                > ORIGINE: {anag.sorgente}&nbsp;{anag.old_settore != null ? " SETTORE DI PROVENIENZA:" + anag.old_settore : ""}
                                                </MDTypography>


                                            </Grid>
                                            <Grid item xs={6} sm={3} mb={2}>
                                                <ReactHookFormCheckBox
                                                    label={<MDBox>
                                                        <MDTypography
                                                            variant="button"
                                                            fontWeight="regular"
                                                            color="text"
                                                            sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                                                        >
                                                            &nbsp;&nbsp;Operatore&nbsp;
                                                        </MDTypography>

                                                    </MDBox>}
                                                    name="operatore"
                                                    control={control} />
                                            </Grid>
                                            <Grid item xs={6} sm={3} mb={2}>
                                                <ReactHookFormCheckBox
                                                    label={<MDBox>
                                                        <MDTypography
                                                            variant="button"
                                                            fontWeight="regular"
                                                            color="text"
                                                            sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                                                        >
                                                            &nbsp;&nbsp;Espositore&nbsp;
                                                        </MDTypography>

                                                    </MDBox>}
                                                    name="espositore"
                                                    control={control}
                                                    onChange={handleChangeE}
                                                    value                                            
                                                        />
                                            </Grid>
                                            <Grid item xs={0} sm={6} mb={2}>
                                            </Grid>


                                            <Grid item xs={12} sm={12} mb={2}>
                                                <RadioGroup
                                                    row
                                                    aria-labelledby="demo-radio-buttons-group-label"
                                                    defaultValue="Privato"
                                                    name="MyButtonGroup"
                                                    value={valuePFPG}
                                                    onChange={handleChangePF}
                                                >
                                                    <FormControlLabel value="PF" control={<Radio />} label="Privato" />
                                                    <FormControlLabel value="PG" control={<Radio />} label="Azienda" />

                                                </RadioGroup>

                                            </Grid>



                                            {valuePFPG === "PF" &&
                                                <Grid item xs={12} sm={12} mb={2}>
                                                    <Grid container spacing={6}>
                                                        <Grid item xs={12} sm={6} >
                                                            <MDInput type="text" label="Nome" fullWidth name="nome" {...register('nome')} autocomplete="off"
                                                                error={errors.nome ? true : false} InputLabelProps={{ shrink: true }} />
                                                            {
                                                                errors.nome ? <span style={{ color: 'red', fontSize: '12px' }}>{errors.nome?.message}</span> : ''
                                                            }
                                                        </Grid>
                                                        <Grid item xs={12} sm={6}>
                                                            <MDInput type="text" label="Cognome" fullWidth id="cognome" autocomplete="off"
                                                                name="cognome" {...register('cognome')} InputLabelProps={{ shrink: true }} />
                                                            {
                                                                errors.cognome ? <span style={{ color: 'red', fontSize: '12px' }}>{errors.cognome?.message}</span> : ''
                                                            }
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            }
                                            {valuePFPG === "PG" &&
                                                <Grid item xs={12} sm={12} mb={2}>
                                                    <Grid container spacing={6}>
                                                        <Grid item xs={12} sm={6} >
                                                            <MDInput type="text" label="Azienda" fullWidth name='azienda'  {...register('azienda')} InputLabelProps={{ shrink: true }} />
                                                        </Grid>
                                                        <Grid item xs={12} sm={6}>
                                                            <MDInput type="text" label="Referente/Incaricato" fullWidth name='referente' {...register('referente')} InputLabelProps={{ shrink: true }} />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            }



                                            <Grid item xs={12} sm={2} mb={2}  >
                                                <MDBox>
                                                    <MDInput type="text" label="Titolo" fullWidth name='titolo' {...register('titolo')} InputLabelProps={{ shrink: true }} />
                                                </MDBox>
                                            </Grid>
                                            <Grid item xs={6} sm={5} mb={2}>
                                                <MDInput type="text" label="Partita IVA" fullWidth name='piva' {...register('piva')} InputLabelProps={{ shrink: true }} />
                                            </Grid>
                                            <Grid item xs={6} sm={5} mb={2}>
                                                <MDInput type="text" label="Codice Fiscale" fullWidth name='cf' {...register('cf')} InputLabelProps={{ shrink: true }} />
                                            </Grid>

                                            {!isExp &&
                                                <Grid item xs={12} sm={12} mb={2}>

                                                    <Grid container spacing={6}>
                                                        <Grid item xs={12} sm={6} >

                                                            <MDBox>
                                                                <ReactHookFormSelect
                                                                    id="professione"
                                                                    name="professione"
                                                                    label="Professione"
                                                                    control={control}
                                                                    defaultValue=''
                                                                    variant="outlined"
                                                                    required
                                                                    error={errors.professione ? true : false}
                                                                >
                                                                    {lista_professioni.map((p) => (
                                                                        <MenuItem
                                                                            key={p.professione}
                                                                            value={p.professione}
                                                                        >
                                                                            {p.professione}
                                                                        </MenuItem>
                                                                    ))}
                                                                </ReactHookFormSelect>
                                                                {/*<MDInput type="text" label="Professione" fullWidth required name='professione' {...register('professione')} error={errors.professione ? true : false} InputLabelProps={{ shrink: true }} />*/}
                                                                {errors.professione ? <span style={{ color: 'red', fontSize: '12px' }}>{errors.professione?.message}</span> : ''}
                                                            </MDBox>
                                                        </Grid>
                                                        <Grid item xs={12} sm={6} >
                                                            <MDInput type="text" label="Altro" name='professione_altro' {...register('professione_altro')} fullWidth InputLabelProps={{ shrink: true }} />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>}


                                            {isExp &&
                                                <Grid item xs={12} sm={12} mb={2}>

                                                    <Grid container spacing={6}>
                                                        <Grid item xs={12} sm={12} >

                                                            <MDBox>
                                                                <ReactHookFormSelect
                                                                    id="codice_merceologico"
                                                                    name="codice_merceologico"
                                                                    label="Codice Merceologico"
                                                                    control={control}
                                                                    defaultValue=''
                                                                    variant="outlined"
                                                                    required
                                                                    error={errors.codice_merceologico ? true : false}
                                                                >
                                                                    {lista_codici_m.map((cm) => (
                                                                        <MenuItem
                                                                            key={cm.codice}
                                                                            value={cm.descrizione}
                                                                        >
                                                                            {cm.descrizione}
                                                                        </MenuItem>
                                                                    ))}

                                                                </ReactHookFormSelect>
                                                                {errors.codice_merceologico ? <span style={{ color: 'red', fontSize: '12px' }}>{errors.codice_merceologico?.message}</span> : ''}
                                                            </MDBox>
                                                        </Grid>

                                                    </Grid>
                                                </Grid>}

                                            <Grid item xs={2} sm={2} mb={2}  >

                                                <ReactHookFormCheckBox
                                                    label={<MDBox>
                                                        <MDTypography
                                                            variant="button"
                                                            fontWeight="regular"
                                                            color="text"
                                                            sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                                                        >
                                                            &nbsp;&nbsp;No Email&nbsp;
                                                        </MDTypography>

                                                    </MDBox>}
                                                    name="no_email"
                                                    control={control} />
                                            </Grid>

                                            <Grid item xs={10} sm={10} mb={2}>
                                                <MDInput type="email" label="Email" fullWidth id="email" autocomplete="off"
                                                    name="email"   {...register('email')} InputLabelProps={{ shrink: true }} />
                                                {
                                                    errors.email ? <span style={{ color: 'red', fontSize: '12px' }}>{errors.email?.message}</span> : ''
                                                }
                                            </Grid>


                                            <Grid item xs={8} sm={8} mb={2}  >
                                                <MDBox>
                                                    <MDInput type="text" label="Telefono" fullWidth name='telefono' {...register('telefono')} InputLabelProps={{ shrink: true }} error={errors.telefono ? true : false} />
                                                    {errors.telefono ? <span style={{ color: 'red', fontSize: '12px' }}>{errors.telefono?.message}</span> : ''}
                                                </MDBox>
                                            </Grid>
                                            <Grid item xs={4} sm={4} mb={2}  >
                                                <MDBox>
                                                    <MDInput type="text" label="Codice Riferimento" fullWidth name='codice_riferimento' {...register('codice_riferimento')} error={errors.codice_riferiemnto ? true : false} InputLabelProps={{ shrink: true }} />
                                                    {errors.codice_riferimento ? <span style={{ color: 'red', fontSize: '12px' }}>{errors.codice_riferimento?.message}</span> : ''}</MDBox>
                                            </Grid>

                                            <Grid item xs={10} sm={9} mb={2} >
                                                <MDBox >
                                                    <MDInput type="text" fullWidth label="Indirizzo" required name='indirizzo'  {...register('indirizzo')} error={errors.indirizzo ? true : false} InputLabelProps={{ shrink: true }} />
                                                    {errors.indirizzo ? <span style={{ color: 'red', fontSize: '12px' }}>{errors.indirizzo?.message}</span> : ''}
                                                </MDBox>
                                            </Grid>
                                            <Grid item xs={2} sm={3} mb={2} >
                                                <MDBox>
                                                    <MDInput type="text" fullWidth label="civico" required name='civico'  {...register('civico')} error={errors.civico ? true : false} InputLabelProps={{ shrink: true }} />
                                                    {errors.civico ? <span style={{ color: 'red', fontSize: '12px' }}>{errors.civico?.message}</span> : ''}
                                                </MDBox>
                                            </Grid>
                                            <Grid item xs={12} sm={12} mb={2}>
                                                <MDInput type="text" label="Dettaglio Indirizzo (frazione, c/o, piano, ..." fullWidth  name='indirizzo2' {...register('indirizzo2')}  InputLabelProps={{ shrink: true }} />
                                              
                                            </Grid>

                                            <Grid item xs={4} sm={2} mb={2}>
                                                <MDBox>
                                                    <MDInput type="text" label="CAP" fullWidth name='cap' required
                                                        {...register('cap', {
                                                         
                                                                onChange: (e) => setQuery_cap(e.target.value)                                   
                                                              ,

                                                        })} error={errors.cap ? true : false} InputLabelProps={{ shrink: true }}
                                                    />
                                                    {errors.cap ? <span style={{ color: 'red', fontSize: '12px' }}>{errors.cap?.message}</span> : ''}
                                                </MDBox>
                                            </Grid>
                                            <Grid item xs={8} sm={8} >
                                                <MDBox>
                                                    <MDBox>
                                                        <Autocomplete
                                                            freeSolo
                                                            name='localita'
                                                            options={lista_loc.map((loc) => loc.localita)}
                                                            value={typeof value === 'string' ? lista_loc.find(o => o.name === anag.localita) : (anag.localita || null)}
                                                           
                                                            renderInput={(params) =>
                                                                <MDInput type="text" {...params} label="Località"  fullWidth
                                                                    {...register('localita')}
                                                                    InputLabelProps={{ shrink: true }} />}
                                                        />

                                                    </MDBox>
                                                </MDBox>    </Grid>

                                            <Grid item xs={4} md={2} >
                                                <MDBox>
                                                    <MDInput type="text" label="provincia" name='provincia' fullWidth required  {...register('provincia')} error={errors.provincia ? true : false} InputLabelProps={{ shrink: true }} />
                                                    {errors.provincia ? <span style={{ color: 'red', fontSize: '12px' }}>{errors.provincia?.message}</span> : ''}
                                                </MDBox>
                                                {/* </Grid>*/}
                                            </Grid>
                                            {subject === 'V' &&
                                                <Grid item xs={12} sm={12} mb={2}  >
                                                    <Grid >
                                                        <Grid item xs={12} sm={12} mb={2}  >
                                                            <MDBox mb={2}>
                                                                <MDTypography variant="button" fontWeight="regular" color="text"> Interessato a: </MDTypography>
                                                            </MDBox>
                                                        </Grid>
                                                        {/*-------------------AGRICOLTURA---------------------------------------------- */}
                                                        <Grid item xs={12} md={4}>
                                                            <MDBox mb={2}>
                                                                <FormControl component="fieldset">
                                                                    <FormLabel component="legend"> <MDTypography variant="body1" fontWeight="regular" sx={{
                                                                        color: "#FFCC00"
                                                                    }}>AGRICOLTURA</MDTypography></FormLabel>
                                                                    <FormGroup>
                                                                        <ReactHookFormCheckBox
                                                                            label={<MDTypography variant="button" fontWeight="regular">Macchine e Attrezzature Agricole</MDTypography>}
                                                                            name="agricoltura_1"
                                                                            control={control}
                                                                            className="agricoltura" />
                                                                        <ReactHookFormCheckBox
                                                                            label={<MDTypography variant="button" fontWeight="regular">Giardinaggio</MDTypography>}
                                                                            name="agricoltura_2"
                                                                            control={control}
                                                                            className="agricoltura" />
                                                                        <ReactHookFormCheckBox
                                                                            label={<MDTypography variant="button" fontWeight="regular">Forestazione</MDTypography>}
                                                                            name="agricoltura_3"
                                                                            control={control}
                                                                            className="agricoltura" />
                                                                        <ReactHookFormCheckBox
                                                                            label={<MDTypography variant="button" fontWeight="regular">Sementi, Piante, Fiori</MDTypography>}
                                                                            name="agricoltura_4"
                                                                            control={control}
                                                                            className="agricoltura" />
                                                                        <ReactHookFormCheckBox
                                                                            label={<MDTypography variant="button" fontWeight="regular">Altro</MDTypography>}
                                                                            name="agricoltura_5"
                                                                            control={control}
                                                                            className="agricoltura" />
                                                                        <MDInput type="text" label="Altro..." fullWidth name='agricoltura_altro'   {...register('agricoltura_altro')} InputLabelProps={{ shrink: true }} />

                                                                    </FormGroup>
                                                                    {/* <FormHelperText>
          Ice cream may be harmful, consult your doctor.
          </FormHelperText>*/}
                                                                </FormControl>
                                                            </MDBox>{/*fine agricoltura*/}
                                                        </Grid>
                                                        {/*-------------------ZOOTECNIA---------------------------------------------- */}
                                                        <Grid item xs={12} md={4}>
                                                            <MDBox mb={2}>
                                                                <FormControl component="fieldset">
                                                                    <FormLabel component="legend"> <MDTypography variant="body1" fontWeight="regular" color="error">ZOOTECNIA</MDTypography></FormLabel>
                                                                    <FormGroup>
                                                                        <ReactHookFormCheckBox
                                                                            label={<MDTypography variant="button" fontWeight="regular">Attrezzature Zootecniche</MDTypography>}
                                                                            name="zootecnia_1"
                                                                            control={control}
                                                                            className="zootecnia" />
                                                                        <ReactHookFormCheckBox
                                                                            label={<MDTypography variant="button" fontWeight="regular">Mangimi</MDTypography>}
                                                                            name="zootecnia_2"
                                                                            control={control}
                                                                            className="zootecnia" />
                                                                        <ReactHookFormCheckBox
                                                                            label={<MDTypography variant="button" fontWeight="regular">Allevamenti di ...</MDTypography>}
                                                                            name="zootecnia_3"
                                                                            control={control}
                                                                            className="zootecnia" />
                                                                        <MDInput type="text" name='zootecnia_3_det'    {...register('zootecnia_3_det')} />
                                                                        <ReactHookFormCheckBox
                                                                            label={<MDTypography variant="button" fontWeight="regular">Altro ...</MDTypography>}
                                                                            name="zootecnia_4"
                                                                            control={control}
                                                                            className="zootecnia" />
                                                                        <MDInput type="text" label="Altro..." fullWidth name='zootecnia_altro'  {...register('zootecnia_altro')} InputLabelProps={{ shrink: true }} />

                                                                    </FormGroup>
                                                                    {/* <FormHelperText>
          Ice cream may be harmful, consult your doctor.
          </FormHelperText>*/}
                                                                </FormControl>
                                                            </MDBox>{/*fine zootecnia*/}
                                                        </Grid>
                                                        {/*-------------------ALIMENTAZIONE---------------------------------------------- */}
                                                        <Grid item xs={12} md={4}>
                                                            <MDBox mb={2}>
                                                                <FormControl component="fieldset">
                                                                    <FormLabel component="legend"> <MDTypography variant="body1" fontWeight="regular" color="warning">ALIMENTAZIONE</MDTypography></FormLabel>

                                                                    <FormGroup>
                                                                        <ReactHookFormCheckBox
                                                                            label={<MDTypography variant="button" fontWeight="regular">Prodotti Agroalimentari</MDTypography>}
                                                                            name="alimentazione_1"
                                                                            control={control}
                                                                            className="alimentazione" />
                                                                        <ReactHookFormCheckBox
                                                                            label={<MDTypography variant="button" fontWeight="regular">Attrezzature e impianti Agroalimentari</MDTypography>}
                                                                            name="alimentazione_2"
                                                                            control={control}
                                                                            className="alimentazione" fullWidth />
                                                                        <MDBox mb={2} ml={2} sx={{ display: 'grid', gap: 1, gridTemplateColumns: '1fr 1fr' }}>
                                                                            <ReactHookFormCheckBox
                                                                                label={<MDTypography variant="button" fontWeight="regular">Bancotec</MDTypography>}
                                                                                name="alimentazione_21"
                                                                                control={control}
                                                                                className="alimentazione"
                                                                                size="small" />
                                                                            <ReactHookFormCheckBox
                                                                                label={<MDTypography variant="button" fontWeight="regular">Enotec</MDTypography>}
                                                                                name="alimentazione_22"
                                                                                control={control}
                                                                                className="alimentazione"
                                                                                size="small" />
                                                                            <ReactHookFormCheckBox
                                                                                label={<MDTypography variant="button" fontWeight="regular">Oleatec</MDTypography>}
                                                                                name="alimentazione_23"
                                                                                control={control}
                                                                                className="alimentazione"
                                                                                size="small" />
                                                                            <ReactHookFormCheckBox
                                                                                label={<MDTypography variant="button" fontWeight="regular">Milktec</MDTypography>}
                                                                                name="alimentazione_24"
                                                                                control={control}
                                                                                className="alimentazione"
                                                                                size="small" />


                                                                        </MDBox>
                                                                    </FormGroup>
                                                                    {/* <FormHelperText>
          Ice cream may be harmful, consult your doctor.
          </FormHelperText>*/}
                                                                </FormControl>
                                                            </MDBox>{/*fine agricoltura*/}
                                                        </Grid>



                                                    </Grid>
                                                </Grid>
                                            }

                                        </Grid>


                                    </MDBox>
                                </MDBox>

                            </Card>
                        </Grid>
                    </Grid>
                </form>
            </MDBox>


            <Footer />
        </DashboardLayout>
    );
}

export default AnagraficaEdit;
