import React from 'react';
//prop-types is a library for typechecking of props
import PropTypes from "prop-types";
import MDBox from "common/components/MD/MDBox";
import MDInput from "common/components/MD/MDInput";
import { MenuItem, Select } from '@mui/material';
export const SelectColumnFilterStato = ({column})=>{

  const{filterValue,setFilter}=column

    return (

        <MDBox ml="auto">

    <Select  name="stato_accettazione"  id="stato_accettazione" 
          onChange={e => {
            console.log(e.target.value);
           if( e.target.value != 'ALL'){
            setFilter(e.target.value);}
           else {
           setFilter('');}
           }}
          value={filterValue || 'ALL'}
        >
          <MenuItem key="ALL" value="ALL">TUTTI</MenuItem>
          <MenuItem key="NON LAVORATO" value="NON LAVORATO">NON LAVORATO</MenuItem>
          <MenuItem key="SOSPESO" value="SOSPESO">SOSPESO</MenuItem>
          <MenuItem key="MODIFICATO DA ACCETTARE" value="MODIFICATO DA ACCETTARE">MODIFICATO DA ACCETTARE</MenuItem>
          <MenuItem key="ACCETTATO" value="ACCETTATO">ACCETTATO</MenuItem>
          <MenuItem key="ACCETTATO CON MODIFICHE" value="ACCETTATO CON MODIFICHE">ACCETTATO CON MODIFICHE</MenuItem>
          <MenuItem key="RIAMMESSO" value="RIAMMESSO">RIAMMESSO</MenuItem>
          <MenuItem key="NON ACCETTATO" value="NON ACCETTATO">NON ACCETTATO</MenuItem>
          <MenuItem key="RINUNCIATO" value="RINUNCIATO">RINUNCIATO</MenuItem>
          
        </Select>


      </MDBox>
    )

}

// Setting default values for the props of DataTable
SelectColumnFilterStato.defaultProps = {
    filter:PropTypes.string,
    setFilter: PropTypes.func,
  };
  
  // Typechecking props for the DataTable
 SelectColumnFilterStato.propTypes = {
    filter:'',
    setFilter: null,
  };