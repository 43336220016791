/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect, useRef } from "react";

// react-router-dom components
import { Link, useNavigate } from "react-router-dom";

import AnagraficaService from 'services/API/Anagrafica/AnagraficaService.js';
import ComuniService from 'services/API/Comuni/ComuniService.js';
import VisitatoriService from "services/API/Visitatori/VisitatoriService";

// @mui material components
import Card from "@mui/material/Card";
import CardContent from '@mui/material/CardContent';
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import FormGroup from "@mui/material/FormGroup";
import Grid from "@mui/material/Grid";
import ReactHookFormSelect from "common/components/mycomponents/ReactHookFormSelect";
import ReactHookFormCheckBox from "common/components/mycomponents/ReactHookFormCheckBox"
import NativeSelect from '@mui/material/NativeSelect';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Alert from '@mui/material/Alert';

import Autocomplete from '@mui/material/Autocomplete';

// Material Dashboard 2 React components
import MDBox from "common/components/MD/MDBox";
import MDTypography from "common/components/MD/MDTypography";
import MDInput from "common/components/MD/MDInput";
import MDButton from "common/components/MD/MDButton";


// Authentication layout components piu largo
import SignupLayout from "layouts/SignupLayout";

// Images
import bgImage from "common/assets/images/bg-sign-in-basic.png";

import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';

//validazione lato client
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import useDebounce from "common/custom_hook/useDebounce";



function Register() {

  const [lista_loc, setLista_loc] = useState([
    { cap: '', localita: '', comune: '', sigla_provincia: '', denominazione_provincia: '' },
  ]);

  const [lista_professioni, setLista_professioni] = useState([
    { idProfessione: 0, professione: '' },
  ]);
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    async function fetchInitialData() {
      const response = await AnagraficaService.getAllProfessioni();
      response.errors ? console.log(response.errors) : setLista_professioni([...response.data]);
    }

    fetchInitialData();
  }, []);

  const validationSchema = Yup.object().shape({
    manifestazione: Yup.string().required('Manifestazione richiesta'),
    nome: Yup.string().required('Nome e Cognome richiesti').max(50, 'Lunghezza massima raggiunta'),
    cognome: Yup.string().required('Nome e Cognome richiesti').max(50, 'Lunghezza massima raggiunta'),
    email: Yup.string().email().max(50, 'Lunghezza massima raggiunta'),
    indirizzo: Yup.string()
      .required('Indirizzo richiesto')
      .max(45, 'Inserire soltanto l\'indirizzo (via/piazza/vocabolo/...'),
    civico: Yup.string()
     .required('Numero civico richiesto')
      .max(10, 'Lunghezza massima raggiunta'),
    cap: Yup.string()
     .required('Cap richiesto')
      .max(10, 'Lunghezza massima raggiunta'),
    telefono: Yup.string()
      .max(20, 'Lunghezza massima raggiunta'),
    localita: Yup.string()
      .required('Località richiesto')
      .max(100, 'Lunghezza massima raggiunta'),
    codice_riferimento: Yup.string()
      .max(20, 'Lunghezza massima raggiunta'),
    provincia: Yup.string()
      .required('Sigla Provincia Richiesta')
      .max(4, 'Inserire la sigla della provincia'),
    privacy: Yup.boolean().oneOf([true], 'Privacy richiesto'),
    professione: Yup.string()
      .required('Professione richiesta')
  });

  const {
    register, //register inputs
    reset, setValue, control,
    handleSubmit,//handle form submit
    formState: { errors }
  } = useForm({
    resolver: yupResolver(validationSchema)
  });

  const [error, setError] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);


  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };



  const [query_cap, setQuery_cap] = useState('');
  const debouncedQuery = useDebounce(query_cap, 500); // Debounce di 500ms


  // Effettua la ricerca quando `debouncedQuery` cambia
  useEffect(() => {
    if (debouncedQuery) {
      console.log(`Effettua la ricerca per: ${debouncedQuery}`);
      // Qui potresti invocare un'API di ricerca o filtrare i dati localmente
    
      setLista_loc([
        { cap: '', localita: '', comune: '', sigla_provincia: '', denominazione_provincia: '' },
      ]);
      setValue('localita', '');
  
      ComuniService.getAllComuni(query_cap)
        .then(response => {
          setLista_loc([...response.data]);
          console.log(response.status);
          if (response.status === 200) {
            console.log(response.data[0].localita);
            setValue('provincia', response.data[0].sigla_provincia);
            //setValue('localita', response.data[0].localita, { shouldValidate: true });
            //setValue('comune', response.data[0].comune, { shouldValidate: true });
          }
  
        })
        .catch(e => {
          console.log(e);
        });


    }
  }, [debouncedQuery]);


 


  const inserisci_visitatore = (formData) => {
    console.log("INSERISCI");
    setError(null);

    //costruisco l'array delle preferenze
    let myArray = [];

    if (formData.agricoltura_1) {
      myArray.push({ categoria_0: "AGRICOLTURA", categoria_1: "Macchine e Attrezzature Agricole", categoria_2: '' });
    }
    if (formData.agricoltura_2) {
      myArray.push({ categoria_0: "AGRICOLTURA", categoria_1: "Giardinaggio", categoria_2: '' });
    }
    if (formData.agricoltura_3) {
      myArray.push({ categoria_0: "AGRICOLTURA", categoria_1: "Forestazione", categoria_2: '' });
    }
    if (formData.agricoltura_4) {
      myArray.push({ categoria_0: "AGRICOLTURA", categoria_1: "Sementi,Piante,Fiori", categoria_2: '' });
    }
    if (formData.agricoltura_5) {
      myArray.push({ categoria_0: "AGRICOLTURA", categoria_1: "Altro...", categoria_2: formData.agricoltura_altro });
    }

    if (formData.zootecnia_1) {
      myArray.push({ categoria_0: "ZOOTECNIA", categoria_1: "Attrezzature Zootecniche", categoria_2: '' });
    }
    if (formData.zootecnia_2) {
      myArray.push({ categoria_0: "ZOOTECNIA", categoria_1: "Mangimi", categoria_2: '' });
    }
    if (formData.zootecnia_3) {
      myArray.push({ categoria_0: "ZOOTECNIA", categoria_1: "Allevamenti di ...", categoria_2: formData.zootecnia_3_det });
    }
    if (formData.zootecnia_4) {
      myArray.push({ categoria_0: "ZOOTECNIA", categoria_1: "Altro...", categoria_2: formData.zootecnia_altro });
    }

    if (formData.alimentazione_1) {
      myArray.push({ categoria_0: "ALIMENTAZIONE", categoria_1: "Prodotti Agroalimentari", categoria_2: '' });
    }
    if (formData.alimentazione_2) {

      if (formData.alimentazione_21) {
        myArray.push({ categoria_0: "ALIMENTAZIONE", categoria_1: "Attrezzature e impianti Agroalimentari", categoria_2: 'Bancotec' });
      }
      if (formData.alimentazione_22) {
        myArray.push({ categoria_0: "ALIMENTAZIONE", categoria_1: "Attrezzature e impianti Agroalimentari", categoria_2: 'Enotec' });
      }
      if (formData.alimentazione_23) {
        myArray.push({ categoria_0: "ALIMENTAZIONE", categoria_1: "Attrezzature e impianti Agroalimentari", categoria_2: 'Oleatec' });
      }
      if (formData.alimentazione_24) {
        myArray.push({ categoria_0: "ALIMENTAZIONE", categoria_1: "Attrezzature e impianti Agroalimentari", categoria_2: 'Milktec' });
      }

    }
    console.log(myArray);
    let id_professione = lista_professioni.find(p => p.professione === formData.professione).id;
   
    var data = {
      manifestazione: formData.manifestazione,
      nome: formData.nome,
      cognome: formData.cognome,
      azienda: formData.azienda,
      piva: formData.piva,
      email: formData.email,
      id_professione: id_professione,
      professione_altro: formData.professione_altro,
      indirizzo: formData.indirizzo,
      indirizzo2: formData.indirizzo2,
      civico: formData.civico,
      cap: formData.cap,
      localita: formData.localita,
      provincia: formData.provincia,
      telefono: formData.telefono,
      codice_riferimento: formData.codice_riferimento,
      id_sorgente:5,
      interessi: [...myArray],
      privacy: formData.privacy,

    };



    console.log("saveVisitor");
    VisitatoriService.create(data)
      .then(response => {
        handleResponse(response);
      })
      .catch(errore => {
        handleError(errore);


      });
    handleTop();
  };

  function handleResponse(response) {
    // console.log(response.data);
    /*  setVisitor({
        idVisitatore: response.data.idVisitatore,
        nome_cognome: response.data.nome_cognome,
        manifestazione: response.data.manifestazione,
        azienda: response.data.azienda,
        piva: response.data.piva,
        telefono: response.data.telefono,
        email: response.data.email,
        professione: response.data.professione,
        indirizzo: response.data.indirizzo,
        civico: response.data.civico,
        cap: response.data.cap,
        comune: response.data.comune,
        localita: response.data.localita,
        provincia: response.data.provincia
      });*/
    console.log(response.data);
    setSubmitted(true);
    reset({
      nome: '',
      cognome: '',
      manifestazione: "Agriumbria 2025",
      azienda: '',
      piva: '',
      telefono: '',
      email: '',
      id_professione: 0,
      professione_altro: '',
      indirizzo: '',
      indirizzo2: '',
      civico: '',
      cap: '',
      comune: '',
      localita: '',
      provincia: '',
      codice_riferimento: '',
      privacy: false,
      agricoltura_1: false, agricoltura_2: false, agricoltura_3: false, agricoltura_4: false, agricoltura_5: false, agricoltura_altro: '',
      zootecnia_1: false, zootecnia_2: false, zootecnia_3: false, zootecnia_3_det: '', zootecnia_4: false, zootecnia_altro: '',
      alimentazione_1: false, alimentazione_2: false, alimentazione_21: false, alimentazione_22: false, alimentazione_23: false, alimentazione_24: false,
    });

  }

  function handleError(error) {
    setError(error);
    console.log("ERRORE" + error);
    console.log(error.code);

    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.log("ERROR:RESPONSE" + error.response.data);
      setErrorMessage(error.response.data);

    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      console.log("EEROR:REQUEST" + error.request);
      setErrorMessage(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log('Error', error.message);
      setErrorMessage(error.message);
    }

  }
  const myref = useRef();

  const [pos, setPos] = useState(false);

  const handleTop = () => {
    myref.current.scrollTop = 0;
    myref.current.focus();
    setPos(false);
  };

  const handleScroll = () => {

    if (myref.current.scrollTop > 50) {
      if (!pos) setPos(true);
    } else {
      if (pos) setPos(false);
    }
  };

  useEffect(() => {
    const temp = myref.current;
    temp.addEventListener("scroll", handleScroll);
    return () => temp.removeEventListener("scroll", handleScroll);
  });


  return (
    <SignupLayout image={bgImage} ref={myref} >
      <Card sx={{ mt: 10, mb: 15 }} >
        <MDBox
          variant="gradient"
          bgColor="success"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >

          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            FARFIERA
          </MDTypography>
          <Grid container spacing={3} justifyContent="center" sx={{ mt: 1, mb: 2 }}>
          </Grid>
        </MDBox>

        <MDBox pt={1} pb={3} px={3} component="form" role="form">


          <CardContent>
            <MDBox  >
              <MDBox opacity={1} textAlign="center">
                <MDTypography variant="h5" fontWeight="medium" sx={{
                  color: "#388e3c"
                }} mt={1} mb={2} >
                  Registrazione visitatore
                </MDTypography>
              </MDBox>
              <MDBox pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center" ref={myref}>

                {(submitted) &&

                  <Alert severity="success">Dati salvati con successo!</Alert>}
                {error !== null &&
                  <Alert color="error"><ErrorOutlineOutlinedIcon fontSize="small" />&nbsp;{errorMessage}</Alert>
                }
              </MDBox>



              <MDBox mb={2}>
                <FormControl fullWidth>
                  <InputLabel variant="standard" htmlFor="manifestazione" shrink={true} >Manifestazione</InputLabel>
                  <NativeSelect fullWidth required inputProps={{ name: 'manifestazione', id: 'manifestazione', }} {...register('manifestazione')}
                    defaultValue={"Agriumbria 2025"}  // must match type of value so (convert to string / number)
                  >
                    <option value={"Agriumbria 2025"}>Agriumbria 2025</option>
                  </NativeSelect>
                </FormControl>
              </MDBox>

              <MDBox mb={2} sx={{ display: 'grid', gap: 1, gridTemplateColumns: '1fr 1fr' }}>
                <MDBox>
                  <MDInput type="text" label="Cognome" name='cognome' fullWidth required {...register('cognome')}
                    error={errors.cognome ? true : false} InputLabelProps={{ shrink: true }}
                  />
                  {errors.cognome ? <span style={{ color: 'red', fontSize: '12px' }}>{errors.cognome?.message}</span> : ''}
                </MDBox>
                <MDBox>
                  <MDInput type="text" label="Nome" name='nome' fullWidth required {...register('nome')}
                    error={errors.nome ? true : false} InputLabelProps={{ shrink: true }}
                  />
                  {errors.nome ? <span style={{ color: 'red', fontSize: '12px' }}>{errors.nome?.message}</span> : ''}
                </MDBox>
              </MDBox>
              <MDBox mb={2} sx={{ display: 'grid', gap: 1, gridTemplateColumns: '1fr 1fr' }}>
                <MDInput type="text" label="Azienda" name='azienda' {...register('azienda')} InputLabelProps={{ shrink: true }} />
                <MDInput type="text" label="Partita IVA" name='piva' {...register('piva')} InputLabelProps={{ shrink: true }} />
              </MDBox>
              <MDBox mb={2} sx={{ display: 'grid', gap: 1, gridTemplateColumns: '8fr 6fr' }}>
                <MDBox>
                  <ReactHookFormSelect
                    id="professione"
                    name="professione"
                    label="Professione"
                    control={control}
                    defaultValue=''
                    variant="outlined"
                    required
                    error={errors.professione ? true : false}
                  >
                    {lista_professioni.map((p) => (
                      <MenuItem
                        key={p.professione}
                        value={p.professione}
                      >
                        {p.professione}
                      </MenuItem>
                    ))}
                  </ReactHookFormSelect>
                  {/*<MDInput type="text" label="Professione" fullWidth required name='professione' {...register('professione')} error={errors.professione ? true : false} InputLabelProps={{ shrink: true }} />*/}
                  {errors.professione ? <span style={{ color: 'red', fontSize: '12px' }}>{errors.professione?.message}</span> : ''}
                </MDBox>
                <MDBox>  <MDInput type="text" label="Altro" name='professione_altro' {...register('professione_altro')} fullWidth InputLabelProps={{ shrink: true }} /></MDBox>
              </MDBox>
              <MDBox mb={2} sx={{ display: 'grid', gap: 1, gridTemplateColumns: '3fr 1fr' }}>
                <MDBox>
                  <MDInput type="text" label="Telefono" fullWidth name='telefono' {...register('telefono')} InputLabelProps={{ shrink: true }} error={errors.telefono ? true : false} />
                  {errors.telefono ? <span style={{ color: 'red', fontSize: '12px' }}>{errors.telefono?.message}</span> : ''}
                </MDBox>
                <MDBox>
                  <MDInput type="text" label="Codice Riferimento" fullWidth name='codice_riferimento' {...register('codice_riferimento')} error={errors.codice_riferiemnto ? true : false} InputLabelProps={{ shrink: true }} />
                  {errors.codice_riferimento ? <span style={{ color: 'red', fontSize: '12px' }}>{errors.codice_riferimento?.message}</span> : ''}</MDBox>
              </MDBox>
              <MDBox mb={2} >
        
                <MDInput type="text" label="Email" fullWidth required name='email' {...register('email')} error={errors.email ? true : false} InputLabelProps={{ shrink: true }} />
                {errors.email ? <span style={{ color: 'red', fontSize: '12px' }}>{errors.email?.message}</span> : ''}
              </MDBox>
              <MDBox mb={2} sx={{ display: 'grid', gap: 1, gridTemplateColumns: '3fr 1fr' }}>
                <MDBox >
                  <MDInput type="text" fullWidth label="Indirizzo" required name='indirizzo'  {...register('indirizzo')} error={errors.indirizzo ? true : false} InputLabelProps={{ shrink: true }} />
                  {errors.indirizzo ? <span style={{ color: 'red', fontSize: '12px' }}>{errors.indirizzo?.message}</span> : ''}
                </MDBox>
                <MDBox>
                  <MDInput type="text" fullWidth label="civico" required name='civico'  {...register('civico')} error={errors.civico ? true : false} InputLabelProps={{ shrink: true }} />
                  {errors.civico ? <span style={{ color: 'red', fontSize: '12px' }}>{errors.civico?.message}</span> : ''}
                </MDBox>

              </MDBox>
              <MDBox mb={2} >
                <MDInput type="text" label="Dettaglio Indirizzo (frazione, c/o, piano, ..." fullWidth  name='indirizzo2' {...register('indirizzo2')} error={errors.indirizzo2 ? true : false} InputLabelProps={{ shrink: true }} />
                {errors.indirizzo2 ? <span style={{ color: 'red', fontSize: '12px' }}>{errors.indirizzo2?.message}</span> : ''}
              </MDBox>
              {/*  <MDBox mb={2} sx={{ display: 'grid', gap: 1, gridTemplateColumns: '1fr 2fr 2fr 1fr' }}>*/}
              <Grid container spacing={2} mb={2}>
                <Grid item xs={4} md={2} >
                  <MDBox>
                    <MDInput type="text" label="CAP" fullWidth name='cap' required
                      {...register('cap', {
                        onChange: (e) => setQuery_cap(e.target.value)
                      })} error={errors.cap ? true : false} InputLabelProps={{ shrink: true }}
                    />
                    {errors.cap ? <span style={{ color: 'red', fontSize: '12px' }}>{errors.cap?.message}</span> : ''}
                  </MDBox>
                  
                </Grid>
                {/*} <Autocomplete 
              disablePortal
              autoHighlight autoSelect clearOnBlur
              name="comune" 
              options={lista_comuni}  

              getOptionLabel={(option) => option.denominazione_ita}
              renderOption={(props, option) => {
                return (
                  <li {...props} key={option.codice_istat}>
                    {option.denominazione_ita}
                  </li>
                );
              }}
              renderInput={(params) => <MDInput {...params} label="Comune"  required  InputLabelProps={{ shrink: true }} 
              {...register('comune')} error={errors.comune ? true : false}
            />}
             
              
            />*/}

                {/*   <MDBox>     <FormControl fullWidth>
                    <InputLabel id="label_comune" shrink={true} required>Comune</InputLabel>
                    <Select labelId="label_comune" label="Comune" required {...register('comune')} 
                      renderValue={(p) => p}
                      notched={true}
                      MenuProps={MenuProps}
                      defaultValue = '' 
                      error={errors.comune ? true : false} >
                        
                      {lista_comuni.map((comune) => (
                        <MenuItem
                          key={comune.denominazione_ita}
                          value={comune.denominazione_ita}
                        >
                          {comune.denominazione_ita}
                        </MenuItem>
                      ))}
                    </Select>
                    {errors.comune ? <span style={{ color: 'red', fontSize: '12px' }}>{errors.comune?.message}</span> : ''}
               
                  </FormControl> 
*/}

                <Grid item xs={8} md={8} >
                  {/* <MDInput type="text" label="localita" name='localita'  {...register('localita')} fullWidth InputLabelProps={{ shrink: true }} />*/}
                  <MDBox>
                                                                              <Autocomplete
                                                                                  freeSolo
                                                                                  name='localita'
                                                                                  options={lista_loc.map((loc) => loc.localita)}
                                                                                
                                                                                  renderInput={(params) =>
                                                                                      <MDInput type="text" {...params} label="Località" fullWidth
                                                                                          {...register('localita')}
                                                                                          InputLabelProps={{ shrink: true }} />}
                                                                              />
                      
                                                                          </MDBox>
                  
                </Grid>

               {/*    <Grid item xs={8} md={4} >
                  <MDBox>

                   <ReactHookFormSelect 
                  
              id="comune"
              name="comune"
              label="Comune"
              control={control}
              defaultValue=''
              variant="outlined"
              required
               error={errors.comune ? true : false} 
            >
               {lista_loc.map((comune) => (
                        <MenuItem
                          key={comune.comune}
                          value={comune.comune}
                        >
                          {comune.comune}
                        </MenuItem>
                      ))}
            </ReactHookFormSelect>

                    <MDInput type="text" label="Comune" fullWidth name='comune' required
                      {...register('comune')} error={errors.comune ? true : false} InputLabelProps={{ shrink: true }}
                    />
                    {errors.comune ? <span style={{ color: 'red', fontSize: '12px' }}>{errors.comune?.message}</span> : ''}

                  </MDBox>    </Grid>*/}

                <Grid item xs={4} md={2} >
                  <MDBox>
                    <MDInput type="text" label="provincia" name='provincia' fullWidth required  {...register('provincia')} error={errors.provincia ? true : false} InputLabelProps={{ shrink: true }} />
                    {errors.provincia ? <span style={{ color: 'red', fontSize: '12px' }}>{errors.provincia?.message}</span> : ''}
                  </MDBox>
                  {/* </Grid>*/}
                </Grid></Grid>
              <MDBox mb={2}>
                <MDTypography variant="button" fontWeight="regular" color="text"> Sono interessato a: </MDTypography>
              </MDBox>
              {/* <MDBox mb={2} sx={{ display: 'grid', gap: 1, gridTemplateColumns: '1fr 1fr' }}>*/}
              <Grid container spacing={2}>
                {/*-------------------AGRICOLTURA---------------------------------------------- */}
                <Grid item xs={12} md={6}>
                  <MDBox mb={2}>
                    <FormControl component="fieldset">
                      <FormLabel component="legend"> <MDTypography variant="body1" fontWeight="regular" sx={{
                        color: "#FFCC00"
                      }}>AGRICOLTURA</MDTypography></FormLabel>
                      <FormGroup>
                        <ReactHookFormCheckBox
                          label={<MDTypography variant="button" fontWeight="regular">Macchine e Attrezzature Agricole</MDTypography>}
                          name="agricoltura_1"
                          control={control}
                          className="agricoltura" />
                        <ReactHookFormCheckBox
                          label={<MDTypography variant="button" fontWeight="regular">Giardinaggio</MDTypography>}
                          name="agricoltura_2"
                          control={control}
                          className="agricoltura" />
                        <ReactHookFormCheckBox
                          label={<MDTypography variant="button" fontWeight="regular">Forestazione</MDTypography>}
                          name="agricoltura_3"
                          control={control}
                          className="agricoltura" />
                        <ReactHookFormCheckBox
                          label={<MDTypography variant="button" fontWeight="regular">Sementi, Piante, Fiori</MDTypography>}
                          name="agricoltura_4"
                          control={control}
                          className="agricoltura" />
                        <ReactHookFormCheckBox
                          label={<MDTypography variant="button" fontWeight="regular">Altro</MDTypography>}
                          name="agricoltura_5"
                          control={control}
                          className="agricoltura" />
                        <MDInput type="text" label="Altro..." fullWidth name='agricoltura_altro'   {...register('agricoltura_altro')} InputLabelProps={{ shrink: true }} />

                      </FormGroup>
                      {/* <FormHelperText>
          Ice cream may be harmful, consult your doctor.
          </FormHelperText>*/}
                    </FormControl>
                  </MDBox>{/*fine agricoltura*/}
                </Grid>
                {/*-------------------ZOOTECNIA---------------------------------------------- */}
                <Grid item xs={12} md={6}>
                  <MDBox mb={2}>
                    <FormControl component="fieldset">
                      <FormLabel component="legend"> <MDTypography variant="body1" fontWeight="regular" color="error">ZOOTECNIA</MDTypography></FormLabel>
                      <FormGroup>
                        <ReactHookFormCheckBox
                          label={<MDTypography variant="button" fontWeight="regular">Attrezzature Zootecniche</MDTypography>}
                          name="zootecnia_1"
                          control={control}
                          className="zootecnia" />
                        <ReactHookFormCheckBox
                          label={<MDTypography variant="button" fontWeight="regular">Mangimi</MDTypography>}
                          name="zootecnia_2"
                          control={control}
                          className="zootecnia" />
                        <ReactHookFormCheckBox
                          label={<MDTypography variant="button" fontWeight="regular">Allevamenti di ...</MDTypography>}
                          name="zootecnia_3"
                          control={control}
                          className="zootecnia" />
                        <MDInput type="text" name='zootecnia_3_det'    {...register('zootecnia_3_det')} />
                        <ReactHookFormCheckBox
                          label={<MDTypography variant="button" fontWeight="regular">Altro ...</MDTypography>}
                          name="zootecnia_4"
                          control={control}
                          className="zootecnia" />
                        <MDInput type="text" label="Altro..." fullWidth name='zootecnia_altro'  {...register('zootecnia_altro')} InputLabelProps={{ shrink: true }} />

                      </FormGroup>
                      {/* <FormHelperText>
          Ice cream may be harmful, consult your doctor.
          </FormHelperText>*/}
                    </FormControl>
                  </MDBox>{/*fine zootecnia*/}
                </Grid>
                {/*-------------------ALIMENTAZIONE---------------------------------------------- */}
                <Grid item xs={12} md={6}>
                  <MDBox mb={2}>
                    <FormControl component="fieldset">
                      <FormLabel component="legend"> <MDTypography variant="body1" fontWeight="regular" color="warning">ALIMENTAZIONE</MDTypography></FormLabel>

                      <FormGroup>
                        <ReactHookFormCheckBox
                          label={<MDTypography variant="button" fontWeight="regular">Prodotti Agroalimentari</MDTypography>}
                          name="alimentazione_1"
                          control={control}
                          className="alimentazione" />
                        <ReactHookFormCheckBox
                          label={<MDTypography variant="button" fontWeight="regular">Attrezzature e impianti Agroalimentari</MDTypography>}
                          name="alimentazione_2"
                          control={control}
                          className="alimentazione" fullWidth />
                        <MDBox mb={2} ml={2} sx={{ display: 'grid', gap: 1, gridTemplateColumns: '1fr 1fr' }}>
                          <ReactHookFormCheckBox
                            label={<MDTypography variant="button" fontWeight="regular">Bancotec</MDTypography>}
                            name="alimentazione_21"
                            control={control}
                            className="alimentazione"
                            size="small" />
                          <ReactHookFormCheckBox
                            label={<MDTypography variant="button" fontWeight="regular">Enotec</MDTypography>}
                            name="alimentazione_22"
                            control={control}
                            className="alimentazione"
                            size="small" />
                          <ReactHookFormCheckBox
                            label={<MDTypography variant="button" fontWeight="regular">Oleatec</MDTypography>}
                            name="alimentazione_23"
                            control={control}
                            className="alimentazione"
                            size="small" />
                          <ReactHookFormCheckBox
                            label={<MDTypography variant="button" fontWeight="regular">Milktec</MDTypography>}
                            name="alimentazione_24"
                            control={control}
                            className="alimentazione"
                            size="small" />


                        </MDBox>
                      </FormGroup>
                      {/* <FormHelperText>
          Ice cream may be harmful, consult your doctor.
          </FormHelperText>*/}
                    </FormControl>
                  </MDBox>{/*fine agricoltura*/}
                </Grid>
              </Grid>{/*fine interessi*/}


              <MDBox display="flex" alignItems="center" ml={-1}>
                <MDBox>

                  <ReactHookFormCheckBox
                    label={<MDBox>
                      <MDTypography
                        variant="button"
                        fontWeight="regular"
                        color="text"
                        sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                      >
                        &nbsp;&nbsp;Do il consenso per&nbsp;
                      </MDTypography>
                      <Link to={'https://www.umbriafiere.it/privacy-policy'} target="_blank">
                        <MDTypography
                          variant="button"
                          fontWeight="bold"
                          color="info"
                          textGradient
                        >
                          Termini and Condizioni
                        </MDTypography></Link>
                    </MDBox>}
                    name="privacy"
                    control={control} defaultvalue={false} />
                  {errors.privacy ? <span style={{ ml: '5px', color: 'red', fontSize: '12px' }}>{errors.privacy?.message}</span> : ''}
                </MDBox>
              </MDBox>
            </MDBox>

          </CardContent>

          <MDBox mt={4} mb={1}>
            <MDButton variant="gradient" color="success" fullWidth onClick={handleSubmit(inserisci_visitatore)} type="submit">
              Iscriviti
            </MDButton>
          </MDBox>
          <MDBox mt={3} mb={1} textAlign="center">
            <Link to={`/login`}>
              <MDTypography

                variant="button"
                color="success"
                fontWeight="medium"
                textGradient
              >
                Torna al login
              </MDTypography></Link>
          </MDBox>

        </MDBox>

      </Card>
    </SignupLayout>
  );
}

export default Register;
