import React, { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import { Link } from "react-router-dom";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "common/components/MD/MDBox";
import MDButton from "common/components/MD/MDButton";


// Material Dashboard 2 React example components
import DashboardLayout from "layouts/DashboardLayout";
import DashboardNavbar from "layouts/components/Navbars/DashboardNavbar";
import Footer from "layouts/components/Footer";
import HeaderForm from "layouts/components/HeaderForm";
import DataTable from "common/components/implemented/Tables/DataTable/DataTables"

import Alert from '@mui/material/Alert';

// Data
import UtentiDataService from "services/API/Utenti/UtentiService";

function UtentiList() {

  const [utenti, setUtenti] = useState([]);

  useEffect(() => {
    //console.log("retrive op");
    retrieveUtenti();

  }, []);

  //per passarmi la variabiule se ho salvato e far comparire l'alert
  const location = useLocation();
  //console.log(location);

  const { state } = location != null ? location : { state: { submitted: false, salvato: 'NO' } }; // Read values passed on state

  const retrieveUtenti = () => {
    UtentiDataService.getAllUtenti()
      .then(response => {
        setUtenti(response.data);
        // console.log(response.data);

      })
      .catch(e => {
        console.log(e);
      });
  };



  const add="/utentiEdit";




  const userAttributes = []
  utenti.forEach(utente => {
   
    userAttributes.push({
      username: utente.username,
      email: utente.email,
      ruolo0:utente.ruolo0,
      action: (
        <Link  to="/utentiEdit" state={{modo:'edit',soggetto: utente }}
       >
          <MDButton variant="outlined" color={ "success"} iconOnly={true} >
            <Icon>edit</Icon>
          </MDButton>
        </Link>
      ),
      action2: (
        <Link to={{ pathname: '/utentiEdit', state: {utente} }} >
          <MDButton variant="outlined" color={ "success"} iconOnly={true} >
            <Icon>edit</Icon>
          </MDButton>
        </Link>
      ),
    })
  });


  const utentiTableData = {
    columns: [
      { Header: "Username", accessor: "username", width: "30%", align: "left" },
      { Header: "Email", accessor: "email", align: "left" },
      { Header: "Ruolo", accessor: "ruolo0", align: "left" },
      { Header: "action", accessor: "action", align: "center", enableGlobalFilter: false },
    ],

    rows: userAttributes,


  };

  const mypagination = { color: 'success', variant: "contained" };


  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>

          <Grid item xs={12}>
            <Card>
              <HeaderForm titolo="Operatori" add="/utentiEdit" modo="vis" />
              <MDBox pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center">

                {(state != null && state.submitted  && (state.salvato==='INS' || state.salvato==='MOD') ) &&

                  <Alert severity="success">Utente salvato con successo!</Alert>}
                   {(state != null && state.submitted  && state.salvato==='DEL')  &&

<Alert severity="success">Utente eliminato con successo!</Alert>}
              </MDBox>
              <MDBox pt={3}>
                <DataTable canSearch={true}
                  table={utentiTableData}
                  isSorted={false}
                  entriesPerPage={true}
                  showTotalEntries={true}
                  pagination={mypagination}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default UtentiList;
