import React, { useState, useEffect } from "react";
import { useNavigate, useLocation, Navigate } from 'react-router-dom';

import AuthService from "services/API/Auth/AuthService";
import ERService from 'services/API/ExpoRichieste/ExpoRichiesteService.js';

// Material Dashboard 2 React components
import MDBox from "common/components/MD/MDBox";
import MDButton from "common/components/MD/MDButton";
import MDInput from "common/components/MD/MDInput";
import MDTypography from "common/components/MD/MDTypography";
import DashboardLayout from "layouts/DashboardLayout";

import HeaderForm from "layouts/components/HeaderForm";
import DashboardNavbar from "layouts/components/Navbars/DashboardNavbar";
// @mui material components
import Card from "@mui/material/Card";
import CardContent from '@mui/material/CardContent';
import Grid from "@mui/material/Grid";

import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import ExpoRichiesteVis from "pages/riservata/expo_richieste/ExpoRichiesteVis";

import { useManifestazione } from "common/context/GlobalContext";

//validazione lato client
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';

//script
import base64ToArrayBuffer from "common/script/base64ToArrayBuffer";
import { Alert, MenuItem } from "@mui/material";
import ExpoPartecipazioniService from "services/API/expo_partecipazioni/ExpoPartecipazioniService";


const Contratto = () => {




    const { manifestazione} = useManifestazione( );
     
 const navigate = useNavigate();
  
    // user state for form
    const [part, setPart] = useState({
        idPartecipazione: 0,
        manifestazione: manifestazione,
        id_utente: AuthService.getCurrentUser().id,
       
        ragione_sociale: '',
        rappresentante_legale: '', indirizzo: '', civico: '', cap: '', localita: '', indirizzo2: '', provincia: '',
        piva: '', cf: '', telefono: '', fax: '', www: '', email: '', pec: '', iban: '', codice_sdi: '',
        cm_code_01: '', cm_desc_01: '', cm_code_02: '', cm_desc_02: '', cm_code_03: '', cm_desc_03: '', cm_code_04: '', cm_desc_04: '',
        responsabile_fiera: '', responsabile_fiera_telefono: '', responsabile_fiera_cell: '', responsabile_fiera_email: '',
        prodotti_servizi: '', produttore: false, concessionario: false, distributore: false, rivenditore: false, importatore: false,
        tipologia_concessa: '', note_accettazione: '',settore_concesso:'',dettaglio:'',mq_concessi:0,prezzo:0,quota_iscrizione:0,
        imponibile_ok:0, aliquota_ok:22,iva_ok:0,tot_a_pagare:0,anticipo_ok:0, saldo:0,
      importo_area: 0, imponibile: 400, iva: 400 * 0.22, totale: 488, anticipo_versato: 120
    });



    /* ----------------------------CARICAMENTO E RIEMPIMENTO CAMPI------------------------------------------------- */

    useEffect(() => {
      
            seleziona_soggetto();
     
    }, []);

    async function seleziona_soggetto() {
        console.log("Cambiata pp->seleziono i dettagli :");
   
            const response = await ExpoPartecipazioniService.seleziona_partecipazione(manifestazione);
            response.errors ? console.log(response.errors) : retrive(response.data);
       
    }



    function retrive(data) {

        setPart({
            idPartecipazione: data.idPartecipazione,
            id_expo_richiesta:data.id_expo_richiesta,
            id_utente: data.id_utente,
            manifestazione: data.manifestazione,
            ragione_sociale: data.ragione_sociale,
            rappresentante_legale: data.rappresentante_legale,
            indirizzo: data.indirizzo,
            civico: data.civico,
            cap: data.cap,
            localita: data.localita,
            provincia: data.provincia,
            indirizzo2: data.indirizzo2,
            cf: data.cf,
            piva: data.piva,
            telefono: data.telefono,
            fax: data.fax,
            email: data.email,
            www: data.www,
            pec: data.pec,
            isEstero: data.estero,
            iban: data.iban,
            iban2: data.iban2,
            swift: data.swift,
            codice_sdi: data.codice_sdi,
            cm_01: data.cm_01,
            cm_02: data.cm_02,
            cm_03: data.cm_03,
            cm_04: data.cm_04,
            cm_01_descrizione: data.cm_01_descrizione,
            cm_02_descrizione: data.cm_02_descrizione,
            cm_03_descrizione: data.cm_03_descrizione,
            cm_04_descrizione: data.cm_04_descrizione,
            responsabile_fiera: data.responsabile_fiera,
            responsabile_fiera_telefono: data.responsabile_fiera_telefono,
            responsabile_fiera_cell: data.responsabile_fiera_cell,
            responsabile_fiera_email: data.responsabile_fiera_email,
            prodotti_servizi: data.prodotti_servizi,
            produttore: data.produttore,
            concessionario: data.concessionario,
            distributore: data.distributore,
            rivenditore: data.rivenditore,
            importatore: data.importatore,
            idAccettazione:data.idAccettazione,
            stato_accettazione:data.stato_accettazione,
            dettaglio:data.dettaglio,
            tipologia_concessa:data.tipologia_concessa,
            settore_concesso:data.settore_concesso,
            mq_concessi:data.mq_concessi,
            prezzo:data.prezzo,
            quota_iscrizione:data.quota_iscrizione,
            imponibile_ok:data.imponibile_ok,
            aliquota_ok:data.aliquota_ok,
            iva_ok:data.iva_ok,
            tot_a_pagare:data.tot_a_pagare,anticipo_ok:data.anticipo_ok,
            saldo:data.saldo,
            note_accettazione:data.note_accettazione,
        });

     
        console.log("selezionata  " + part.idPartecipazione);

    }



    useEffect(() => {

        console.log("Cambiata pp->riempo i campi  " + part.idPartecipazione);



    }, [part]);
    /* ---------------------------------pdf---------------------------------------------------------------------*/



    async function download_pdf() {
        console.log("download_pdf :" + part.idPartecipazione);
        if (part.idPartecipazione != 0) {
            let filename = 'ContrattoAgriumbria2025.pdf';

            ExpoPartecipazioniService.genera_pdf_contratto(part.idPartecipazione)
                .then(response => {
                    /*  const disposition = response.headers['content-disposition'];
                     filename = disposition.split(/;(.+)/)[1].splitvv(/=(.+)/)[1];
                      if (filename.toLowerCase().startsWith ("utf-8''"))
                         filename = decodeURIComponent(filename.replace("utf-8''", ''));
                      else
                         filename = filename.replace(/['"]/g, '');*/
                    return response.data;
                })
                .then(blob => {

                    var arrrayBuffer = base64ToArrayBuffer(blob); //data is the base64 encoded string
                    var blobb = new Blob([arrrayBuffer], { type: "application/pdf" });
                    var url = URL.createObjectURL(blobb);
                    var a = document.createElement('a');
                    a.href = url;
                    a.target = "_blank";
                    a.download = filename;
                    document.body.appendChild(a); // append the element to the dom
                    a.click();
                    a.remove(); // afterwards, remove the element  
                })
                .catch(error => {
                    console.error(error);
                });

            /*           let filename = 'StampaRichiesta.pdf';
                       var url=    ERService.download_pdf(soggetto.idRichiesta);
           
                             var a = document.createElement('a');
                             a.href = url;
                             a.target = "_blank";
                             a.download = filename;
                             document.body.appendChild(a); // append the element to the dom
                             a.click();
                             a.remove();*/

        }
    }



    /* ----------------------------VALIDAZIONE E SALVATAGGIO----------------------------------------------------------------------- */


    const validationSchema = Yup.object().shape({
        pdf_contratto: Yup.mixed()
            .required("Devi caricare un file")
            .test("fileSize", "Il file è troppo grande o non c'è!", (file) => {
                //if u want to allow only certain file sizes
                return file && file[0] && file[0].size <= 2000000;
            })

    });



    /* ----------------------------SALVATAGGIO E REGISTRAZIONE------------------------------------------------- */



    const {
        register, //register inputs
        reset, setValue, control,
        handleSubmit,//handle form submit
        formState: { errors }
    } = useForm({
        resolver: yupResolver(validationSchema)
    });



    const [submitted, setSubmitted] = useState(false);


    const [error, setError] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);



    const inserisci = (formData) => {

        setError(null);

        var data = {
            idPartecipazione: part.idPartecipazione,
            id_utente: AuthService.getCurrentUser().id,
            manifestazione: part.manifestazione,
            pdf_contratto: formData.pdf_contratto,


        };

       // if (modo === 'edit') {
            ExpoPartecipazioniService.invia_contratto(part.idPartecipazione, data)
                .then(response => {
                    handleResponse(response, 'SENT');
                })
                .catch(errore => {
                    handleError(errore);
                });
     //   }


    }

    function handleResponse(response, op) {
        console.log(response.data);
        setSubmitted(true);

       navigate("/documenti_fiera", { state: { submitted: true, salvato: op } });
    }


    function handleError(error) {
        setError(error);
        console.log("ERRORE" + error);
        console.log(error.code);

        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log("ERROR:RESPONSE" + error.response.data);
            setErrorMessage(error.response.data);

        } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.log("EEROR:REQUEST" + error.request);
            setErrorMessage(error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message);
            setErrorMessage(error.message);
        }

    }

  
 
    const annulla = () => {
        navigate("/documenti_fiera", { state: {indietro:true } });
    }


    /* ------------------------------navigazione -----------------------------------------------------------------*/


  



    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
              
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card >
                            <HeaderForm titolo={"Contratto di Partecipazione " + part.manifestazione} exit={annulla}/>
                            {error !== null &&
                                    <Alert color="error"><ErrorOutlineOutlinedIcon fontSize="small" />&nbsp;{errorMessage}</Alert>
                                }
                            
                            <MDBox pt={1} pb={3} px={3} component="form" role="form">
                                <CardContent>
                                    <MDBox>
                                        <MDBox opacity={1} textAlign="center">

                                        </MDBox>
                                        <MDBox pt={4} pb={3} px={3} >
                                        
                                            <MDBox mt={5}>
                                                <ExpoRichiesteVis prop={part}></ExpoRichiesteVis>

                                                <MDBox>
                                                        <MDBox mb={2} sx={{ display: 'grid', gap: 1, gridTemplateColumns: '1fr 1fr 2fr' }}>
                                                        <MDBox>
          <MDInput type="text" label="Tipologia Espositiva" name='tipologia' fullWidth disabled value={part.tipologia_concessa} />
        </MDBox>  
        <MDBox>
          <MDInput type="text" label="Settore Merceologico" name='settore' fullWidth disabled value={part.settore_concesso} />
        </MDBox>    
        <MDBox>
          <MDInput type="text" label="Dettaglio" name='dettaglio' fullWidth disabled value={part.dettaglio} />
        </MDBox>    
                                                             <MDBox></MDBox>
                                                            <MDBox></MDBox>
                                                            <MDBox></MDBox>
                                                            <MDBox></MDBox>

                                                        </MDBox>

                                                        <MDBox mb={2} >
                                                            <MDInput type="text" label="Note " multiline rows={5} fullWidth variant="outlined" disabled value={part.note_accettazione} />
                                                        </MDBox>



                                                        </MDBox>
                                                        <MDBox mb={2} sx={{ display: 'grid', gap: 1, gridTemplateColumns: '1fr 1fr 1fr 1fr ' }}>

                                                        <MDBox></MDBox>
                                                        <MDBox></MDBox>

<MDInput type="text" label="Mq concessi" name='mq_concessi' fullWidth disabled value={part.mq_concessi}  />

<MDInput type="text" label="Prezzo" name='prezzo' fullWidth  disabled value={part.prezzo}  />
<MDBox></MDBox>
<MDBox></MDBox>
<MDBox>+</MDBox>
<MDInput type="text" label="Quota Iscrizione" name='quota_iscrizione' fullWidth   disabled value={part.quota_iscrizione}  />
<MDBox></MDBox><MDBox></MDBox><MDBox></MDBox>
<MDInput type="text" label="Imponibile" name='imponibile_ok' fullWidth disabled  value={part.imponibile_ok}/>


<MDBox></MDBox>
<MDBox></MDBox>
<MDInput type="text" label="Aliquota %" name='aliquota_ok' fullWidth  value={part.aliquota_ok} />
<MDInput type="text" label="Iva" name='iva_ok' fullWidth disabled value={part.iva_ok}/>
<MDBox></MDBox><MDBox></MDBox><MDBox></MDBox>
<MDInput type="text" label="Totale ivato" name='tot_a_pagare' fullWidth disabled value={part.tot_a_pagare}/>
<MDBox></MDBox>
<MDBox></MDBox>
<MDBox></MDBox>
<MDInput type="text" label="Anticipo Versato" name='anticipo_ok' fullWidth disabled value={part.anticipo_ok} />
<MDBox></MDBox>
<MDBox></MDBox>
<MDBox></MDBox>
<MDInput type="text" label="Saldo" name='rimanenza' fullWidth disabled value={part.saldo} />




</MDBox>
                                                <MDBox mb={2}>
                                                </MDBox>



                                                <MDBox sx={{ display: 'flex', flexDirection: 'row', pt: 2 }} mt={4} mb={1}>

                                                    <MDTypography variant="h5" fontWeight="bold" >Scarica e stampa il Contratto. Timbra e firma il documento e ricaricalo tramite il pulsante seguente! </MDTypography>
                                                    <MDBox sx={{ flex: '1 1 auto' }} />
                                                    <MDBox ml={2}>

                                                        <MDButton variant="outlined" color="success" onClick={download_pdf}  >
                                                            Scarica il pdf
                                                        </MDButton>
                                                    </MDBox>

                                                </MDBox>
                                                <MDBox mb={2}>
                                                </MDBox>
                                                <MDBox>


                                                    <MDInput
                                                        type="file"
                                                        label="Contratto di partecipazione" fullWidth required
                                                        autocomplete="off"  {...register('pdf_contratto')}
                                                        name="pdf_contratto" InputLabelProps={{ shrink: true }}

                                                    />
                                                    <MDTypography
                                                        variant="button"
                                                        fontWeight="regular"
                                                        color="text"
                                                        sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                                                    >
                                                        {part.nome_pdf_contratto}
                                                    </MDTypography>

                                                    {
                                                        errors.pdf_contratto ? <span style={{ color: 'red', fontSize: '12px' }}>{errors.pdf_contratto?.message}</span> : ''
                                                    }
                                                </MDBox>

                                                {error !== null &&
                                    <Alert color="error"><ErrorOutlineOutlinedIcon fontSize="small" />&nbsp;{errorMessage}</Alert>
                                }
                                                <MDBox sx={{ display: 'flex', flexDirection: 'row', pt: 2 }} mt={4} mb={1}>
                                                  
                                                    <MDBox sx={{ flex: '1 1 auto' }} />

                                                    <MDBox ml={2}>
                                                        <MDButton variant="gradient" color="success" onClick={handleSubmit(inserisci)} type="submit"  >
                                                            INVIA CONTRATTO
                                                        </MDButton>
                                                    </MDBox>

                                                </MDBox>

                                            </MDBox>
                                        </MDBox>
                                    </MDBox>
                                </CardContent>
                            </MDBox>
                        </Card>
                    </Grid></Grid></MDBox>
        </DashboardLayout>
    )
}


export default Contratto;