import { useState,useEffect } from "react";
import { useLocation, useNavigate } from 'react-router-dom'
import DashboardLayout from "layouts/DashboardLayout";
import DashboardNavbar from "layouts/components/Navbars/DashboardNavbar";
import Footer from "layouts/components/Footer";
import HeaderForm from "layouts/components/HeaderForm";

import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Alert from '@mui/material/Alert';

import MDBox from "common/components/MD/MDBox";
import MDInput from "common/components/MD/MDInput";
import MDButton from "common/components/MD/MDButton";


import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
//validazione lato client
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

// Data
import UtentiDataService from "services/API/Utenti/UtentiService";
import ReactHookFormSelect from "common/components/mycomponents/ReactHookFormSelect";
import { MenuItem } from "@mui/material";

function UtentiEdit() {
    const location = useLocation();
    const navigate = useNavigate();
    console.log("STATE+"+location.state.modo);
  //  console.log("STATE+"+location.state.soggetto.email);
    const {modo,soggetto}=location.state;
    
    // user state for form
    const [utente, setUtente] = useState({ id:0, nome:'', cognome:'',username :'',email:''});

    const [open, setOpen] = useState(false);
    const [confirm, setConfirm] = useState(false);


    const validationSchema = Yup.object().shape({
        nome: Yup.string().required('Nome richiesto'),
        cognome: Yup.string().required('Cognome richiesto'),
        username: Yup.string()
            .required('Username is required')
            .min(6, 'Username must be at least 6 characters')
            .max(20, 'Username must not exceed 20 characters'),
        email: Yup.string()
            .required('Email is required')
            .email('Email is invalid'),
        password: Yup.string()
            .required('Password is required')
            .min(6, 'Password must be at least 6 characters')
            .max(40, 'Password must not exceed 40 characters'),
        confirmPassword: Yup.string()
            .required('Confirm Password is required')
            .oneOf([Yup.ref('password'), null], 'Confirm Password does not match')
        // acceptTerms: Yup.bool().oneOf([true], 'Accept Terms is required')
    });


    useEffect(() => {
        // simulate async api call with set timeout
       // setTimeout(() => setUser({ title: 'Mr', firstName: 'Frank', lastName: 'Murphy' }), 10  
      if(soggetto!= null){
        console.log(soggetto.id);
       setUtente({ id:soggetto.idUtente, nome:soggetto.nome, cognome:soggetto.cognome,username :soggetto.username,email:soggetto.email,ruolo0:soggetto.ruolo0});}
    }, []);

      // effect runs when user state is updated
      useEffect(() => {
        // reset form with user data
      setValue("nome",utente.nome);
      setValue("cognome",utente.cognome);
      setValue("email",utente.email);
      setValue("username",utente.username);
      setValue("ruolo",utente.ruolo0)
    }, [utente]);
    

    const {
        register, //register inputs
        reset, setValue, control,
        handleSubmit,//handle form submit
        formState: { errors }
    } = useForm({
        resolver: yupResolver(validationSchema),
    });

  
    //sono quelli lato server
    const [error, setError] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);

    const insertUtente = (formData) => {
        setError(null);
        var data = {
            nome: formData.nome,
            cognome: formData.cognome,
            email: formData.email,
            username: formData.username,
            password: formData.password,
            ruolo0:formData.ruolo,
            ragione_sociale: "UMBRIAFIERE",
            attivo:true
        };

        if (modo==='new') {
            console.log("Inserisci utente");
            UtentiDataService.create(data)
                .then(response => {
                    handleResponse(response,'INS');
                })
                .catch(errore => {
                    handleError(errore);
                });
        } else {
            console.log("update utente");
            UtentiDataService.update(utente.id, data)
                .then(response => {
                    handleResponse(response,'MOD');                
                })
                .catch(errore => {
                    handleError(errore);
                });

        }
    };

    function handleClose(){
    
    setOpen(false);
}
    function handleConfirm(){
       
            setConfirm(true);
        

        setOpen(false);

        deleteUtente();
    }

const handleOpen = () => {
    console.log("OPEN DIALOG");
    setOpen(true);
}

    const deleteUtente = () => {
         setError(null);
        
        if (modo ==='edit') {
          
            console.log("delete utente");
            UtentiDataService.remove(utente.id)
                .then(response => {
                    console.log("handleResponse");
                    handleResponse(response,'DEL');                
                })
                .catch(errore => {
                    console.log("handleError");
                    handleError(errore);
                });

        
    }
    };


    function handleResponse(response,op) {
        console.log(response.data);
        navigate(`/utenti`, { state: { submitted: true, salvato: op} });
    }

    function handleError(error) {
        setError(error);
        console.log("ERRORE" + error);
        console.log(error.code);
    
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log("ERROR:RESPONSE" + error.response.data);
          setErrorMessage(error.response.data);
    
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log("ERROR:REQUEST" + error.request);
          setErrorMessage(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message);
          setErrorMessage(error.message);
        }
       
      }

      const annulla = () => {
        console.log("ANNULLA");
      navigate("/utenti");

    }




    return (
        <DashboardLayout>
        <DashboardNavbar />
        <MDBox pt={6} pb={3}>
        <Dialog open={open}  onClose={handleClose}  aria-labelledby="alert-dialog-title"  aria-describedby="alert-dialog-description" >
        <DialogTitle id="alert-dialog-title">
        Eliminare questo record?
        </DialogTitle>
        <DialogContent>
        <DialogContentText id="alert-dialog-description">
            Sei sicuro di voler eliminare questo record?
        </DialogContentText>
        </DialogContent>
        <DialogActions>
        <MDButton onClick={handleClose}>No</MDButton>
        <MDButton onClick={handleConfirm} autoFocus>
            SI
        </MDButton>
        </DialogActions>
        </Dialog>



            <form >
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <HeaderForm titolo="Utente" save={handleSubmit(insertUtente)} remove={handleOpen} exit={annulla} modo={modo} />

                           
                            {error !== null &&
                    <Alert color="error"><ErrorOutlineOutlinedIcon fontSize="small" />&nbsp;{errorMessage}</Alert>
                  }
                            <MDBox pt={4} pb={3} px={3}>
                                <MDBox component="form" role="form">
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={6} mb={2}>

                                            <MDInput type="text" label="Nome" fullWidth name="nome" {...register('nome')} autocomplete="off"
                                                required error={errors.nome ? true : false} InputLabelProps={{ shrink: true }}/>
                                            {
                                                errors.nome ? <span style={{ color: 'red', fontSize: '12px' }}>{errors.nome?.message}</span> : ''
                                            }
                                        </Grid>
                                        <Grid item xs={12} sm={6} mb={2}>
                                            <MDInput type="text" label="Cognome" fullWidth id="cognome" required autocomplete="off"
                                                name="cognome" {...register('cognome')} InputLabelProps={{ shrink: true }}/>
                                            {
                                                errors.cognome ? <span style={{ color: 'red', fontSize: '12px' }}>{errors.cognome?.message}</span> : ''
                                            }
                                        </Grid>
                                        <Grid item xs={12} sm={6} mb={2}>
                                            <MDInput type="email" label="Email" fullWidth id="email" required  autocomplete="off"
                                                name="email" {...register('email')} InputLabelProps={{ shrink: true }}/>
                                            {
                                                errors.email ? <span style={{ color: 'red', fontSize: '12px' }}>{errors.email?.message}</span> : ''
                                            }
                                        </Grid>
                                        <Grid item xs={12} sm={6} mb={2}>
                                        <ReactHookFormSelect
                          id="ruolo"
                          name="ruolo"
                          label="Ruolo"
                          control={control}
                          defaultValue=''
                          variant="outlined"
                          required
                          error={errors.ruolo ? true : false}
                        >
                        
                            <MenuItem
                              key={'ROLE_ADMIN'}
                              value={'ROLE_ADMIN'}
                            >
                             AMMINISTRATORE
                            </MenuItem>
                            <MenuItem
                              key={'ROLE_VERIFICATORE'}
                              value={'ROLE_VERIFICATORE'}
                            >
                             VERIFICATORE
                            </MenuItem>
                       

                        </ReactHookFormSelect>
                                            {
                                                errors.ruolo ? <span style={{ color: 'red', fontSize: '12px' }}>{errors.ruolo?.message}</span> : ''
                                            }
                                        </Grid>
                                        <Grid item xs={12} sm={6} mb={2}>
                                            <MDInput type="text" label="Username" fullWidth id="username" required autocomplete="off"
                                                name="username" {...register('username')} InputLabelProps={{ shrink: true }}/>
                                            {
                                                errors.username ? <span style={{ color: 'red', fontSize: '12px' }}>{errors.username?.message}</span> : ''
                                            }
                                        </Grid>
                                        <Grid item xs={12} sm={6} mb={2}></Grid>
                                        <Grid item xs={12} sm={6} mb={2}>
                                            <MDInput type="password" label="Password" fullWidth id="password" required
                                                name="password" {...register('password')} InputLabelProps={{ shrink: true }} autocomplete="off" />
                                            {
                                                errors.password ? <span style={{ color: 'red', fontSize: '12px' }}>{errors.password?.message}</span> : ''
                                            }
                                        </Grid>
                                        <Grid item xs={12} sm={6} mb={2}>
                                            <MDInput type="password" label="Conferma Password" fullWidth {...register('confirmPassword')} name="confirmPassword" autocomplete="off" InputLabelProps={{ shrink: true }} />
                                            {
                                                errors.confirmPassword ? <span style={{ color: 'red', fontSize: '12px' }}>{errors.confirmPassword?.message}</span> : ''
                                            }
                                        </Grid>
                                    </Grid>


                                </MDBox>
                            </MDBox>

                        </Card>
                    </Grid>
                </Grid>
            </form>
        </MDBox>


        <Footer />
    </DashboardLayout>
    );
}

export default UtentiEdit;